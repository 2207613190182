import { RadioGroup } from '@/components/RadioGroup';
import { selectBusinessManualEntry, setManualEntry } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getBoolean } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'BusinessManualLookup';

export function BusinessManualLookup({
    baseId,
    showErrors,
}: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectBusinessManualEntry);
    const dispatch = useAppDispatch();

    const stateToShow = currentState === undefined ? true : !currentState; // select business manual entry = false = use smart lookup true

    // ***** Event Handlers *****
    function handleChange(value: string | boolean | undefined): void {
        const booleanValue = getBoolean(value);
        dispatch(setManualEntry(booleanValue));
    }

    // ***** Render *****
    const options = [
        {
            label: 'Yes',
            value: true,
            id: `${baseId}-button-businessManual-yes`,
        },
        {
            label: 'No',
            value: false,
            id: `${baseId}-button-businessManual-no`,
        },
    ];
    return (
        <div data-testid={testId}>
            <RadioGroup
                title='Use ABN lookup?'
                showErrors={showErrors}
                onChange={handleChange}
                options={options}
                value={stateToShow}
                tooltipContent='ABN lookup helps you pre-fill your business details.'
            />
        </div>
    );
}
