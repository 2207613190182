import { IRealEstate } from '@/context/borrower';

export function isRealEstateAssetComplete(realEstate: IRealEstate): boolean {
  if (realEstate.assetValue && !realEstate.isLoanPresent) return true;
  if (realEstate.assetValue && realEstate.isLoanPresent === undefined)
    return true;
  if (
    realEstate.assetValue === undefined &&
    realEstate.isLoanPresent === undefined
  )
    return true;
  if (
    realEstate.assetValue &&
    realEstate.isLoanPresent &&
    realEstate.lenderName &&
    realEstate.monthlyRepayment &&
    realEstate.loanBalance
  )
    return true;

  return false;
}
