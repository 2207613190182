import { ESupportingDocCodes } from '@/context/borrower';
import { store } from '@/context/store';

import { areAllRequiredDocsPresent } from '../areAllRequiredDocsPresent';

export function canSkipSupportingDocs(): boolean {
    const allRequiredDocsPresent = areAllRequiredDocsPresent();
    const state = store.getState();
    const requiredDocs =
        state.borrowerReducer.selectedLender?.requiredDocuments;

    if (!allRequiredDocsPresent) return false;

    if (requiredDocs) {
        // Check if required docs includes other then ESupportingDocCodes.DRIVERS_LICENSE_FRONT and ESupportingDocCodes.DRIVERS_LICENSE_BACK
        Object.keys(requiredDocs).forEach((key) => {
            if (
                key !== ESupportingDocCodes.DRIVERS_LICENSE_FRONT &&
                key !== ESupportingDocCodes.DRIVERS_LICENSE_BACK
            ) {
                return false;
            }
        });
    } else {
        return false;
    }

    return true; // All required documents are present
}
