import { setIsVehicleKnown } from '@/context/borrower';
import { setIsAssetKnownViaParams } from '@/context/display';
import { store } from '@/context/store';

export function setInitialVehicleIsKnown(
    buildYear: string | null,
    make: string | null,
    model: string | null,
    variant: string | null,
    nvic: string | null,
    vin: string | null
): void {
    // Year, Make, Model all required
    if (buildYear && make && model) {
        store.dispatch(setIsVehicleKnown(true));
        store.dispatch(setIsAssetKnownViaParams(true));
        return;
    }

    // If nvic or vin, lookupVehicle should take care of it
    if (nvic || vin) {
        const state = store.getState();
        const stateDescription = state.borrowerReducer.vehicle.description;
        if (stateDescription) {
            store.dispatch(setIsVehicleKnown(true));
            store.dispatch(setIsAssetKnownViaParams(true));
            return;
        }
    }
}
