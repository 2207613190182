import { Input } from '@/components/Input';
import {
    selectTransportVehicle,
    setTransportVehicle,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'TransportVehicle';

export function TransportVehicle({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const transportVehicle = useAppSelector(selectTransportVehicle);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        // MAXIMUM
        const MAXIMUM = 99999;
        const intValue = getNumber(value, MAXIMUM);
        if (intValue === transportVehicle) return;
        dispatch(setTransportVehicle(intValue));
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Transport & Vehicle'
                placeholder='Required'
                showErrors={
                    showErrors &&
                    (transportVehicle === undefined ||
                        transportVehicle === null)
                }
                onChange={handleChange}
                id={`${baseId}-input-transportVehicle`}
                value={transportVehicle?.toLocaleString() ?? ''}
                prefix='$'
                showNumpad={true}
                tooltipContent='This includes public transport, ubers, petrol, rego and vehicle running costs'
            />
        </div>
    );
}
