import { lookupVin } from '@/apiCalls/assetLookup';
import { getBorrower } from '@/apiCalls/borrower';
import { Button, EButtonType } from '@/components/Button';
import { ModalComponent } from '@/components/ModalComponent';
import { selectFirstName } from '@/context/borrower';
import { selectIsWhiteLabel, setIsAssetKnownViaParams, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export const testId = 'ReturnUser';

export function ReturnUser(): JSX.Element {
    // ***** Constants *****
    const baseId = 'modal-returnUser';

    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const firstName = useAppSelector(selectFirstName);
    const isWhiteLabel = useAppSelector(selectIsWhiteLabel);
    const partnerId = useAppSelector((state) => state.displayReducer.partner.id);
    const glassValuation = useAppSelector((state) => state.borrowerReducer.vehicle.glassValuation);

    // ***** Handlers *****
    async function handleCloseClick(): Promise<void> {
        // Only lookup the VIN if we have a partnerId and it's not UNKNOWN
        if (!glassValuation && partnerId && partnerId !== 'UNKNOWN') {
            await lookupVin(partnerId).then((isSuccessful) => {
                if (isSuccessful) {
                    dispatch(setIsAssetKnownViaParams(true));
                }
            });
        }

        getBorrower();
        dispatch(setModal(null));
    }

    // ***** Render *****
    return (
        <ModalComponent baseId={baseId}>
            <div
                className={`flex-center w-full ${isWhiteLabel && 'filter grayscale'
                    }`}
            >
                <img
                    src='https://driveiq-assets.s3.ap-southeast-2.amazonaws.com/vroom/finger-print-circle.gif'
                    className='h-40 '
                />
            </div>
            <p className='font-bold text-2xl text-center pb-2 text-primary'>
                Welcome, {firstName}
            </p>
            <p className='font-bold text-center px-14'>
                We&apos;ve saved the details you shared last time
            </p>
            <div className='text-center px-14 py-4'>
                <p>You choose the vehicle</p>
                <p>We&apos;ll sort the finance</p>
            </div>
            <div className='flex-center mx-16'>
                <Button
                    onClick={handleCloseClick}
                    type={EButtonType.SECONDARY_OUTLINE}
                    id={`${baseId}-button-continue`}
                >
                    Continue
                </Button>
            </div>
        </ModalComponent>
    );
}
