import { getUrl } from '@/apiCalls/fetch';
import { EVehicleAssetType } from '@/context/borrower';
import { IMakeApiResults } from '@/context/display';
import { getGlassAssetType } from '@/utils/glassGuideHelpers';

import { IGetMakeTypesResponse } from './getMakeTypes';

export async function getMake(
    partner: string,
    year: number,
    assetType?: EVehicleAssetType
): Promise<IMakeApiResults[]> {
    const url = `/api/asset/select/${partner}/${getGlassAssetType(assetType)}/${year}`;
    const response = await getUrl<IGetMakeTypesResponse>(url);
    return response.data;
}
