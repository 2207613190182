import { RadioGroup } from '@/components/RadioGroup';
import {
    ESelfAssessedCreditHistory,
    selectSelfAssessedCreditHistory,
    setSelfAssessedCreditHistory,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getEnumValueFromString } from '@/utils/getEnumValueFromString';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'SelfAssessedCredit';

export function SelfAssessedCredit({
    baseId,
    showErrors,
}: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectSelfAssessedCreditHistory);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean | undefined): void {
        dispatch(
            setSelfAssessedCreditHistory(
                getEnumValueFromString(ESelfAssessedCreditHistory, value)
            )
        );
    }

    // ***** Render *****
    const options = [
        {
            label: 'Great',
            value: ESelfAssessedCreditHistory.GREAT,
            id: `${baseId}-button-selfAssessedCredit-great`,
        },
        {
            label: 'Decent',
            value: ESelfAssessedCreditHistory.DECENT,
            id: `${baseId}-button-selfAssessedCredit-decent`,
        },
        {
            label: 'Poor',
            value: ESelfAssessedCreditHistory.POOR,
            id: `${baseId}-button-selfAssessedCredit-poor`,
        },
    ];
    return (
        <div data-testid={testId} className='w-full py-2'>
            <RadioGroup
                title="What's your credit history like?"
                showErrors={showErrors}
                onChange={handleChange}
                options={options}
                value={currentState}
                tooltipContent='Take your best guess but if incorrect the lender might change the offer.'
            />
        </div>
    );
}
