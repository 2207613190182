import { setTestDriveTime } from '@/context/borrower';
import { store } from '@/context/store';

export function setInitialTestDriveTime(date: string | null): void {
    if (!date) {
        console.error('Date is null');
        return;
    }

    // Get today's date
    const today = new Date();

    // Change today to the time of the test drive
    const dateArray = date.split(':');
    const hours = parseInt(dateArray[0], 10);
    const minutes = parseInt(dateArray[1], 10);
    const testDriveDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        hours,
        minutes
    );

    // Dispatch
    store.dispatch(setTestDriveTime(testDriveDate));
}
