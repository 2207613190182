import { Button } from '@/components/Button';
import { ModalComponent } from '@/components/ModalComponent';
import { RemoteImage } from '@/components/RemoteImage';
import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';

export const testId = 'ApplicationSubmitted';
const baseId = 'modal-applicationSubmitted';

export function ApplicationSubmitted(): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();

    // ***** Handlers *****
    function handleClick(): void {
        dispatch(setDisplay(EDisplays.BASIC));
        dispatch(setModal(EModals.APPLICATION_IN_PROGRESS));
    }

    // ***** Render *****
    return (
        <ModalComponent baseId={baseId} className='px-8'>
            <RemoteImage fileName='confetti.svg' className='w-full h-40' />
            <h3 className='text-center font-bold text-[26px]'>
                Congratulations!
            </h3>
            <p className='-mt-8 pb-4 font-semi-bold text-lg text-center text-textBody'>
                <br />
                Your application has
                <br />
                been sent!
            </p>

            <p className='p-3 text-textBody  font-semi-bold text-sm _flex flex-col justify-center align-middle items-center text-center border border-primary rounded-lg'>
                <strong> Don&apos;t worry, we&apos;ll stay in touch. </strong>
                One of our friendly team will be in <br />
                touch with you shortly.
            </p>
            <div className='mt-10 -mx-4'>
                <Button id={`${baseId}-button-close`} onClick={handleClick}>
                    <p>Close</p>
                </Button>
            </div>
        </ModalComponent>
    );
}
