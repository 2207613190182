import { selectSelectedLender, setSelectedLender } from '@/context/borrower';
import { selectLenders } from '@/context/lender';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { LenderSortBySelect } from '@/UserInput/LenderSortBySelect';
import { RepaymentSelect } from '@/UserInput/RepaymentSelect';

import { LenderCard } from '../LenderCard';

export const testId = 'LenderRepayments';

interface IlenderRepaymentProps {
    baseId?: string;
}

export function LenderRepayments({
    baseId,
}: IlenderRepaymentProps): JSX.Element {
    // ***** Redux *****
    const lenders = useAppSelector(selectLenders);
    const selectedLender = useAppSelector(selectSelectedLender);
    const dispatch = useAppDispatch();

    // *** Helpers ***
    function showAllLenders(): void {
        dispatch(setSelectedLender(undefined));
    }

    function getLowest(): string {
        if (!lenders) return '';

        let lowestLender = '';
        let lowestRepayment: number | null = null;

        lenders.map((lender) => {
            if (!lender.monthlyRepaymentAmount) return;
            if (!lender.lenderName) return;
            if (lowestRepayment === null) {
                lowestRepayment = lender.monthlyRepaymentAmount;
                lowestLender = lender.lenderName;

                return;
            }

            if (lender.monthlyRepaymentAmount < lowestRepayment) {
                lowestLender = lender.lenderName;
                lowestRepayment = lender.monthlyRepaymentAmount;
            }
        });
        return lowestLender;
    }

    // ***** Render *****
    if (lenders === null) return <div />;

    return (
        <div data-testid={testId} className='py-2'>
            {/* Heading / Toggle */}
            {lenders?.length > 1 && (
                <div className='_flex space-x-10 justify-between items-center w-full pb-2'>
                    <div className='w-full'>
                        <h4 className='font-semibold text-sm sm:text-[16px]'>
                            Payment:
                        </h4>
                        <RepaymentSelect
                            baseId={baseId}
                            valueOfElement='repayments'
                        />
                    </div>
                    <div className='w-full'>
                        <h4 className='font-semibold text-sm sm:text-[16px]'>
                            Sort By:
                        </h4>
                        <LenderSortBySelect
                            baseId={baseId}
                            valueOfElement='sortLenderOptions'
                        />
                    </div>
                </div>
            )}
            {selectedLender && lenders.length > 1 && (
                <div className=' text-sm w-full _flex justify-center'>
                    <span
                        className={`fieldHeading underline hover:cursor-pointer hover:text-secondaryDark`}
                    >
                        <span onClick={showAllLenders}>See all lenders</span>
                    </span>
                </div>
            )}
            {/* Lender Cards */}
            <div className='_flex flex-col space-y-4'>
                {selectedLender ? (
                    <LenderCard
                        baseId={baseId}
                        lender={selectedLender}
                        lowest={
                            getLowest() === selectedLender.lenderName &&
                            lenders.length > 1
                        }
                    />
                ) : (
                    lenders.map((lender, index) => (
                        <LenderCard
                            baseId={baseId}
                            key={lender.lenderName}
                            lender={lender}
                            lowest={getLowest() === lender.lenderName}
                        />
                    ))
                )}
            </div>
        </div>
    );
}
