import { EUserFlow } from '@/context/borrower';
import { EDisplays } from '@/context/display';
import { IPartnerParams } from '@/partners/partnerTypes';

export function getInitialDisplayScreen(partner: IPartnerParams): EDisplays {
    if (partner.splitTest) {
        // flip a coin to decide which user flow to use
        const random = Math.random();
        if (random < 0.5) {
            return mapUserFlow(partner.userFlow);
        }

        return mapUserFlow(partner.splitTestUserFlow);
    }

    if (partner.showQuickQuote) {
        return mapUserFlow(partner.userFlow);
    } else {
        return EDisplays.NO_LENDER_HOME;
    }
}

function mapUserFlow(userFlow: EUserFlow | undefined): EDisplays {
    switch (userFlow) {
        case EUserFlow.TRUCKSTOP_NEW_SCREEN:
            return EDisplays.TRUCKSTOP_LANDING_PAGE;
        case EUserFlow.TRUCKSTOP_NORMAL:
        case EUserFlow.NORMAL:
        case EUserFlow.EARLY_SIGNUP:
        default:
            return EDisplays.QUICK_CALCULATOR;
    }
}
