import { ModalComponent } from '@/components/ModalComponent';
import { ModalHeader } from '@/components/ModalHeader';
import { StockNumber } from '@/UserInput/StockNumber';

import { IMotorStockButton } from './IMotorStockButton/IMotorStockButton';

export const baseId = 'modal-stockNumber';

export function IMotorStock(): JSX.Element {
    // ***** Render *****
    return (
        <ModalComponent
            baseId={baseId}
            className='py-2 flex flex-col space-y-2'
        >
            <ModalHeader
                title={`Enter the stock number, it's on this card or on the bike and if you're stuck just ask one of our salespeople!`}
                baseId={baseId}
                className='text-center'
                showCloseButton={false}
            />
            <div className='flex-center'>
                <img className='w-40' src='/outline.svg' />
                <div className='w-8' />
            </div>
            <StockNumber baseId={baseId} showErrors={false} />
            <IMotorStockButton />
        </ModalComponent>
    );
}
