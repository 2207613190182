import { EBorrowerCitizenshipStatus } from '@/context/borrower';
import { store } from '@/context/store';
import { AddressState } from '@/UserInput/AddressState';
import { BirthDate } from '@/UserInput/BirthDate';
import { Email } from '@/UserInput/Email';
import { FirstName } from '@/UserInput/FirstName';
import { LastName } from '@/UserInput/LastName';
import { LicenseExpiry } from '@/UserInput/LicenseExpiry';
import { LicenseNumber } from '@/UserInput/LicenseNumber';
import { LicenseState } from '@/UserInput/LicenseState';
import { MiddleName } from '@/UserInput/MiddleName';
import { MobileNumber } from '@/UserInput/MobileNumber';
import { PostCode } from '@/UserInput/PostCode';
import { StreetName } from '@/UserInput/StreetName';
import { StreetNumber } from '@/UserInput/StreetNumber';
import { SuburbName } from '@/UserInput/SuburbName';
import { UnitNumber } from '@/UserInput/UnitNumber';
import { VisaExpiryDate } from '@/UserInput/VisaExpiryDate';
import { VisaNumber } from '@/UserInput/VisaNumber';

import { isBirthDateValid } from '../checkBirthDate';
import { isLicenseExpiryDateValid } from '../checkLicenseExpiryDate';
import { isVisaExpiryDateValid } from '../checkVisaExpiryDate';
import { isEmail } from '../isEmail';

export function getOcrFields(baseId, showErrors): JSX.Element[] {
  const fields: JSX.Element[] = [];
  const stateCall = store.getState();
  const firstName = stateCall.borrowerReducer.personalDetails.firstName;
  const lastName = stateCall.borrowerReducer.personalDetails.lastName;
  const middleName = stateCall.borrowerReducer.personalDetails.middleName;
  const email = stateCall.borrowerReducer.personalDetails.email;
  const mobileNumber = stateCall.borrowerReducer.personalDetails.mobilePhone;
  const licenseNumber = stateCall.borrowerReducer.personalDetails.licenseNumber;
  const licenseState = stateCall.borrowerReducer.personalDetails.licenseState;
  const visaNumber = stateCall.borrowerReducer.personalDetails.visaNumber;
  const residencyStatus =
    stateCall.borrowerReducer.personalDetails.citizenshipStatus;

  const address = stateCall.borrowerReducer.address.address[0];
  const { streetNumber, streetName, suburb, state, postcode, unitNumber } =
    address;

  if (firstName === '' || firstName === undefined) {
    fields.push(<FirstName baseId={baseId} showErrors={showErrors} />);
  }
  if (middleName === '' || middleName === undefined) {
    fields.push(<MiddleName baseId={baseId} showErrors={showErrors} />);
  }
  if (lastName === '' || lastName === undefined) {
    fields.push(<LastName baseId={baseId} showErrors={showErrors} />);
  }
  if (mobileNumber && (mobileNumber === '' || mobileNumber?.length < 10)) {
    fields.push(<MobileNumber baseId={baseId} showErrors={showErrors} />);
  }
  if (!isEmail(email)) {
    fields.push(<Email baseId={baseId} showErrors={showErrors} />);
  }
  if (
    residencyStatus === EBorrowerCitizenshipStatus.VISA_HOLDER &&
    !isVisaExpiryDateValid()
  ) {
    fields.push(<VisaExpiryDate baseId={baseId} showErrors={showErrors} />);
  }
  if (
    residencyStatus === EBorrowerCitizenshipStatus.VISA_HOLDER &&
    (!visaNumber ||
      (visaNumber &&
        (visaNumber === undefined || visaNumber.toString().length < 3)))
  ) {
    fields.push(<VisaNumber baseId={baseId} showErrors={showErrors} />);
  }
  if (!isBirthDateValid()) {
    fields.push(<BirthDate baseId={baseId} showErrors={showErrors} />);
  }
  if (!isLicenseExpiryDateValid()) {
    fields.push(<LicenseExpiry baseId={baseId} showErrors={showErrors} />);
  }
  if (licenseNumber === '' || licenseNumber === undefined) {
    fields.push(<LicenseNumber baseId={baseId} showErrors={showErrors} />);
  }
  if (licenseState === null || licenseState === undefined) {
    fields.push(<LicenseState baseId={baseId} showErrors={showErrors} />);
  }
  if (unitNumber === '' || unitNumber === undefined) {
    if (streetNumber === '' || streetNumber === undefined) {
      fields.push(<UnitNumber baseId={baseId} showErrors={showErrors} />);
    }
  }
  if (streetNumber === '' || streetNumber === undefined) {
    fields.push(<StreetNumber baseId={baseId} showErrors={showErrors} />);
  }
  if (streetName === '' || streetName === undefined) {
    fields.push(<StreetName baseId={baseId} showErrors={showErrors} />);
  }
  if (suburb === '' || suburb === undefined) {
    fields.push(<SuburbName baseId={baseId} showErrors={showErrors} />);
  }
  if (state === null || state === undefined) {
    fields.push(<AddressState baseId={baseId} showErrors={showErrors} />);
  }
  if (
    postcode === '' ||
    postcode === undefined ||
    postcode.toString().length < 4
  ) {
    fields.push(<PostCode baseId={baseId} showErrors={showErrors} />);
  }
  return fields;
}
