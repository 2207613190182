import { setAddressByIndex, setPersonalDetails } from '@/context/borrower';
import { setIsLoading } from '@/context/display';
import { store } from '@/context/store';

import { createOrUpdateBorrower } from '../borrower';
import { postUrl } from '../fetch';

import { IOcrLookupRequest, IOcrLookupResponse } from './ocrLookupTypes';

export async function ocrLookup(): Promise<void> {
  // ***** Redux State *****
  const state = store.getState();
  const driverLicenseFrontArray =
    state.borrowerReducer.supportingDocs?.app_drivers_licence_front;

  if (!driverLicenseFrontArray) {
    return;
  }

  const driverLicenseFront = driverLicenseFrontArray[0].url;

  if (!driverLicenseFront) {
    return;
  }

  // ***** The Request *****
  const url = '/api/ocr/driverlicense';

  const body: IOcrLookupRequest = {
    type: 'url',
    frontUrl: driverLicenseFront,
  };

  const response = await postUrl<IOcrLookupResponse>(url, body);
  store.dispatch(setIsLoading(true));

  // ***** Use the Response *****

  const personalDetails = state.borrowerReducer.personalDetails;
  store.dispatch(
    setPersonalDetails({
      ...personalDetails,
        firstName: response.firstName || personalDetails.firstName,
      middleName: response.middleName || personalDetails.middleName,
      lastName: response.lastName || personalDetails.lastName,
      licenseNumber: response.licenseNumber || personalDetails.licenseNumber,
      licenseExpiry:
        response.licenseDateOfExpiry || personalDetails.licenseExpiry,
      licenseType: response.licenseType || personalDetails.licenseType,
      birthdate: response.dateOfBirth || personalDetails.birthdate,
      licenseState: response.licenseState || personalDetails.licenseState,
    })
  );

  const address = state.borrowerReducer.address.address[0];
  store.dispatch(
    setAddressByIndex({
      index: 0,
      address: {
        ...address,
        unitNumber: response.addressUnitNumber || address.unitNumber,
        streetNumber: response.addressStreetNumber || address.streetNumber,
        streetName: response.addressStreetName || address.streetName,
        suburb: response.addressSuburb || address.suburb,
        state: response.addressState || address.state,
        postcode: response.addressPostcode || address.postcode,
      },
    })
  );

  // Note: response.licenseClass and response.licenseType is not saved

  // Save changes to the database
  createOrUpdateBorrower();

  store.dispatch(setIsLoading(false));
}
