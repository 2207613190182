import { IPartnerParams } from '@/partners/partnerTypes';
import { EParameters } from '@/utils/setParametersFromParams';

import { IInitialApplication } from './IInitialApplication';

export function getInitialApplicationData(
    partner: IPartnerParams,
    params: URLSearchParams
): IInitialApplication {
    return {
        partnerPartnerOverwriteId: partner.partnerOverwriteId,
        paramPartnerOverwriteId: params.get(EParameters.PARTNER_OVERWRITE_ID),
        paramIqPartnerEmail: params.get(EParameters.IQ_PARTNER_EMAIL),
    };
}
