import { PayloadAction } from '@reduxjs/toolkit';

import { IRealEstate } from '../types';

export const realEstateReducers = {
  setRealEstateByIndex: (
    state,
    action: PayloadAction<{ index: number; realEstate: IRealEstate }>
  ): void => {
    if (state.realEstates.realEstates === null) return;
    state.realEstates.realEstates[action.payload.index] =
      action.payload.realEstate;
  },
  setRealEstateActiveIndex: (state, action: PayloadAction<number>): void => {
    let length = action.payload;
    const min = 0;
    if (length < min) {
      length = min;
    }
    state.realEstates.realEstateActiveIndex = length;
  },

  setRealEstates: (
    state,
    action: PayloadAction<{
      realEstates: IRealEstate[];
      realEstateActiveIndex: number;
    }>
  ): void => {
    state.realEstates.realEstates = action.payload.realEstates;
    state.realEstates.realEstateActiveIndex =
      action.payload.realEstateActiveIndex;
  },
  deleteRealEstate: (state): void => {
    if (
      state.realEstates.realEstates?.length === 1 &&
      state.realEstates.realEstateActiveIndex === 0
    ) {
      state.realEstates.realEstates = [];
      return;
    }
    state.realEstates.realEstates?.splice(
      state.realEstates.realEstateActiveIndex,
      1
    );
  },
};
