import { Input } from '@/components/Input';
import {
  selectSolarRenewableEnergyEquipment,
  setSolarRenewableEnergyEquipment,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'SolarProductDetails';

export function SolarProductDetails({
  baseId,
  showErrors,
}: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectSolarRenewableEnergyEquipment);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    if (typeof value === 'boolean') return;
    if (value === currentState) return;
    if (value?.length > 50) return;
    dispatch(setSolarRenewableEnergyEquipment(value));
  }

  // ***** Render *****
  return (
    <div data-testid={testId} className='w-full'>
      <Input
        title='Renewable energy equipment'
        placeholder='Type your text here'
        showErrors={showErrors && !currentState}
        onChange={handleChange}
        id={`${baseId}-input-solarProductDetails`}
        value={currentState?.toLocaleString() ?? ''}
      />
    </div>
  );
}
