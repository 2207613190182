import { setModal } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';

export function IMotorStockButton(): JSX.Element {
    const dispatch = useAppDispatch();

    function handleClick(): void {
        dispatch(setModal(null));
    }

    return (
        <p
            className='text-xs text-center hover:underline cursor-pointer'
            onClick={handleClick}
        >
            Or search manually
        </p>
    );
}
