import { store } from '@/context/store';

import { lookupNvic } from '../lookupNvic';

export async function lookupVehicle(partnerId: string): Promise<void> {
    const { vin, nvic } = store.getState().borrowerReducer.vehicle;

    // Skip lookup if there is no NVIC or VIN and partnerId is UNKNOWN or missing
    if ((!vin && !nvic) || !partnerId || partnerId === 'UNKNOWN') return;

    // We prefer to use NVIC over VIN
    if (nvic) {
        await lookupNvic(partnerId);
        const { glassValuation } = store.getState().borrowerReducer.vehicle;

        // If glassValuation is set, then we have a valid vehicle and VIN is not required
        if (glassValuation) {
            return;
        }
    }

    return;
}
