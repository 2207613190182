import { useCallback, useEffect, useRef, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/solid';

import { getQuickQuote } from '@/apiCalls/matchingEngine';
import {
    ELoanUsage,
    selectLoanTerm,
    selectLoanUsage,
    selectPurchasePrice,
    selectSelfAssessedCreditHistory,
} from '@/context/borrower';
import {
    EDisplays,
    EModals,
    selectInitialLoad,
    selectIsLoading,
    selectPartnerName,
    setModal,
} from '@/context/display';
import { ILender, selectLenders } from '@/context/lender';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { TRUCKSTOP_PARTNER_NAME } from '@/partners/truckstop';
import { getCheapestLender } from '@/utils/getCheapestLender';
import { roundToTwoDecimalPlaces } from '@/utils/roundToTwoDecimalPlaces';
import { updateShownLenders } from '@/utils/updateShownLenders';

import { Loading } from '../Loading';

interface ILenderRowProps {
    title: string;
    value: string;
    valueClass?: string;
}

function useIsInitialRender(): boolean {
    const initialRenderRef = useRef(true);
    useEffect(() => {
        initialRenderRef.current = false;
    }, []);

    return initialRenderRef.current;
}

function LenderRow({
    title,
    value,
    valueClass = '',
}: ILenderRowProps): JSX.Element {
    const initialLoad = useAppSelector(selectInitialLoad);
    const isLoading = useAppSelector(selectIsLoading);

    if (!initialLoad) return <div />;

    return (
        <div className='_flex w-full justify-center'>
            <div className='_flex flex-col justify-start items-start'>
                <h3
                    className='fieldHeading pb-0 text-[0.5rem]'
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <h2
                    className={`whitespace-normal overflow-hidden h-8 ${valueClass}`}
                >
                    {isLoading ? <Loading /> : value}
                </h2>
            </div>
        </div>
    );
}

export function GenericLenderCard(): JSX.Element {
    const lenders = useAppSelector(selectLenders);
    const [cheapestLender, setCheapestLender] = useState<ILender | undefined>(
        getCheapestLender()
    );

    const dispatch = useAppDispatch();
    const partnerName = useAppSelector(selectPartnerName);
    const loanUsage = useAppSelector(selectLoanUsage);

    const purchasePrice = useAppSelector(selectPurchasePrice);
    const loanTerm = useAppSelector(selectLoanTerm);
    const selfAssessedCredit = useAppSelector(selectSelfAssessedCreditHistory);

    const quickQuoteCallTimeoutRef = useRef<any>(null);
    const isInitialRender = useIsInitialRender(); // use the custom hook

    useEffect(() => {
        if (quickQuoteCallTimeoutRef.current) {
            clearTimeout(quickQuoteCallTimeoutRef.current);
        }

        quickQuoteCallTimeoutRef.current = setTimeout(() => {
            getQuickQuote();
        }, 1500);
    }, [purchasePrice, loanTerm, loanUsage]);

    useEffect(() => {
        setCheapestLender(getCheapestLender());
    }, [lenders]);

    useEffect(() => {
        updateShownLenders(cheapestLender, EDisplays.QUICK_CALCULATOR, false);
    }, [cheapestLender]);

    useEffect(() => {
        if (!isInitialRender) {
            getQuickQuote();
        }
    }, [selfAssessedCredit]);

    const {
        weeklyRepaymentAmount,
        advertisedRateInPercent,
        comparisonRateInPercent,
    } = cheapestLender ?? {};
    const weeklyRepayment = weeklyRepaymentAmount
        ? `$${weeklyRepaymentAmount.toLocaleString()}`
        : '-';
    const advertisedRate = advertisedRateInPercent
        ? `${roundToTwoDecimalPlaces(advertisedRateInPercent)}%`
        : '-';
    const comparisonRate = comparisonRateInPercent
        ? `${roundToTwoDecimalPlaces(comparisonRateInPercent)}%`
        : '-';

    // ***** Callbacks *****
    const handleClick = useCallback(() => {
        dispatch(setModal(EModals.DISCLAIMER));
    }, [dispatch]);

    return (
        <>
            <div className='_flex space-x-1 items-center'>
                <p className='fieldHeading pb-0'>Estimated Quote</p>
                <InformationCircleIcon
                    height={20}
                    width={20}
                    className='text-quaternary cursor-pointer'
                    onClick={handleClick}
                />
            </div>
            <div
                className={`
                    flex flex-col
                    text-center
                    font-bold
                    p-2
                    pt-4
                    shadow-md
                    rounded-[10px]
                    bg-white
                    text-tertiary
                `}
            >
                <div className='text text-left pl-6 text-black mb-2'>
                    You can own this from only*
                </div>
                <div
                    className={`grid ${
                        partnerName === TRUCKSTOP_PARTNER_NAME
                            ? 'grid-cols-1'
                            : loanUsage !== ELoanUsage.BUSINESS
                            ? 'grid-cols-3'
                            : 'grid-cols-2'
                    }`}
                >
                    <LenderRow
                        title='Per week'
                        value={weeklyRepayment}
                        valueClass='text-2xl'
                    />
                    {partnerName !== TRUCKSTOP_PARTNER_NAME && (
                        <>
                            <LenderRow
                                title='Advertised Rate'
                                value={advertisedRate}
                                valueClass='text-sm'
                            />
                            {loanUsage !== ELoanUsage.BUSINESS && (
                                <LenderRow
                                    title='Comparison Rate'
                                    value={comparisonRate}
                                    valueClass='text-sm'
                                />
                            )}
                        </>
                    )}
                </div>
                <div className='text-[0.5rem] text-gray-500 mt-2 text-right'>
                    *Rates are indicative
                </div>
            </div>
        </>
    );
}
