import { Button, EButtonType } from '@/components/Button';
import { selectRealEstates } from '@/context/borrower';
import { EModals, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { IMAGE_URL } from '@/utils/constants';

export const testId = 'ApplicationSubmittedSolar';

export function SolarApplicationSubmitted(): JSX.Element {
  const realEstates = useAppSelector(selectRealEstates);

  function hasNoMortgage(): boolean {
    return realEstates?.some((realEstate) => realEstate.isLoanPresent);
  }
  const homeLoan = !hasNoMortgage();

  // ***** Redux State *****
  const dispatch = useAppDispatch();

  // ***** Handlers *****
  function handleClick(): void {
    dispatch(setModal(EModals.APPLICATION_IN_PROGRESS));
  }

  // ***** Render *****
  return (
    <div
      data-testid={testId}
      className='_flex flex-col justify-center align-middle h-full py-2 px-8'
    >
      <img
        src={`${IMAGE_URL}/solar.svg`}
        width='198'
        height='91'
        className='mx-auto'
      />
      <h3 className='text-center font-bold text-xl py-4 text-primary'>
        Congratulations!
        <br />
        Your application has been sent!
      </h3>
      <p className='pb-4 font-semi-bold text-lg text-center'>
        We will be in touch with you soon.
      </p>
      {homeLoan ? (
        <>
          <p className='pb-4 font-semibold text-sm text-center text-secondary'>
            A copy of your application has been sent to your email.
          </p>
          <div className='mx-16'>
            <Button onClick={handleClick} type={EButtonType.SECONDARY_OUTLINE}>
              <p>Close</p>
            </Button>
          </div>
        </>
      ) : (
        <>
          <p className='pb-4 font-semibold text-sm text-center text-secondary'>
            We noticed you have a home loan, would you like us to contact you
            for a review?
          </p>
          <div className='_flex space-x-4'>
            <Button onClick={handleClick} type={EButtonType.SECONDARY_OUTLINE}>
              <p>Yes</p>
            </Button>
            <Button onClick={handleClick} type={EButtonType.SECONDARY_OUTLINE}>
              <p>No</p>
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
