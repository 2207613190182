import { Input } from '@/components/Input';
import { selectBalloon, selectLoanTerm, setBalloon } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import {
    MAXIMUM_BALLOON_PERCENT,
    MAXIMUM_LOANTERM_FOR_BALLOON,
} from '@/utils/constants';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'Balloon';

export function Balloon({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectBalloon);
    const loanTerm = useAppSelector(selectLoanTerm);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        // MAXIMUM
        const MAXIMUM =
            loanTerm > MAXIMUM_LOANTERM_FOR_BALLOON
                ? 0
                : MAXIMUM_BALLOON_PERCENT;
        const intValue = getNumber(value, MAXIMUM);
        if (intValue === currentState) return;
        dispatch(setBalloon(intValue));
    }

    // ***** Render *****
    return (
        <div data-testid={testId} className='w-full'>
            <Input
                title='Balloon (percentage)'
                placeholder='Optional'
                showErrors={showErrors}
                onChange={handleChange}
                id={`${baseId}-input-balloon`}
                value={currentState?.toString() ?? ''}
                showNumpad={true}
                tooltipContent='The % of the loan amount you want to pay as a lump sum at the end.'
            />
        </div>
    );
}
