import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/context/store';

import {
    ELenderRepaymentFrequency,
    ELenderSortedBy,
    ILender,
    LenderState,
} from './LenderTypes';
import { sortLenders } from './sortLenders';

const initialState: LenderState = {
    // lenders: [mockLender2],
    lenders: [],
    isLenderLoading: false,
    lenderRepaymentFrequency: ELenderRepaymentFrequency.WEEKLY,
    lenderSortedBy: ELenderSortedBy.REPAYMENT,
};

export const lenderSlice = createSlice({
    name: 'lenders',
    initialState,
    reducers: {
        setAndSortLenders: (state, action: PayloadAction<ILender[]>) => {
            if (action.payload && action.payload.length) {
                state.lenders = sortLenders(
                    state.lenderSortedBy,
                    action.payload
                );
            } else {
                state.lenders = [];
            }
        },
        toggleLenderLoading: (state) => {
            state.isLenderLoading = !state.isLenderLoading;
        },
        setLenderRepaymentFrequency: (
            state,
            action: PayloadAction<ELenderRepaymentFrequency>
        ) => {
            state.lenderRepaymentFrequency = action.payload;
            if (state.lenders === null) return;
            const lenders = state.lenders;
            const sortedLenders = sortLenders(action.payload, lenders);
            state.lenders = sortedLenders;
        },
        setLenderSortedBy: (state, action: PayloadAction<ELenderSortedBy>) => {
            state.lenderSortedBy = action.payload;
            if (state.lenders === null) return;
            const lenders = state.lenders;
            const sortedLenders = sortLenders(action.payload, lenders);
            state.lenders = sortedLenders;
        },
        setIsLenderLoading: (state, action: PayloadAction<boolean>): void => {
            state.isLenderLoading = action.payload;
        },
    },
});

export const { setAndSortLenders } = lenderSlice.actions;
export const { toggleLenderLoading } = lenderSlice.actions;
export const { setLenderRepaymentFrequency } = lenderSlice.actions;
export const { setLenderSortedBy } = lenderSlice.actions;
export const { setIsLenderLoading } = lenderSlice.actions;

export const selectLenders = (state: RootState): ILender[] | null =>
    state.lenderReducer.lenders;
export const selectLenderRepaymentFrequency = (
    state: RootState
): ELenderRepaymentFrequency => state.lenderReducer.lenderRepaymentFrequency;
export const selectLenderSortedBy = (state: RootState): ELenderSortedBy =>
    state.lenderReducer.lenderSortedBy;
export const selectIsLenderLoading = (state: RootState): boolean =>
    state.lenderReducer.isLenderLoading;

const lenderReducer = lenderSlice.reducer;
export { lenderReducer };
