import { getUrl } from '../fetch';

import { IMatchingEngineWaterfall } from './IMatchingEngineWaterfallResponse';

export async function getWaterfallById(
    id: string
): Promise<IMatchingEngineWaterfall | null> {
    try {
        const response = await getUrl<{ data: IMatchingEngineWaterfall }>(
            `/api/data/waterfall/${id}`
        );
        return response.data;
    } catch (error) {
        return null;
    }
}
