import { Input } from '@/components/Input';
import { selectVehicleMake, setVehicleMake } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { userFacingAssetTitle } from '@/utils/userFacingAssetTitle';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'MakeManual';

export function MakeManual({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectVehicleMake);
    const dispatch = useAppDispatch();
    const assetTitle = userFacingAssetTitle();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        // MAXIMUM
        const MAXIMUM = 30;
        if (value.toString()?.length > MAXIMUM) return;
        dispatch(setVehicleMake(value.toString()));
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title={`${assetTitle} Make`}
                placeholder={`Enter the ${assetTitle} make`}
                showErrors={showErrors}
                onChange={handleChange}
                id={`${baseId}-input-vehicleMake`}
                value={currentState?.toLocaleString() ?? ''}
                tooltipContent='The brand of vehicle'
            />
        </div>
    );
}
