import { setLoanTerm } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialLoanTerm(
    partnerLoanTerm: number,
    parameterLoanTerm: IParameter
): void {
    const currentTerm = store.getState().borrowerReducer.loan.loanTerm;

    const parsedParameter = Number(parameterLoanTerm);
    if (
        !isNaN(parsedParameter) &&
        parsedParameter > 0 &&
        parsedParameter <= 7
    ) {
        if (currentTerm !== parsedParameter) {
            store.dispatch(setLoanTerm(parsedParameter));
            return;
        }
    }

    if (currentTerm !== partnerLoanTerm) {
        store.dispatch(setLoanTerm(partnerLoanTerm));
    }
}
