import { getStockNumber } from '@/apiCalls/stockNumber';
import {
    ECondition,
    EVehicleAssetType,
    setPartnerOverwriteId,
    setPurchasePrice,
    setVehicleAssetCategory,
    setVehicleBuildYear,
    setVehicleCondition,
    setVehicleDescription,
    setVehicleKilometers,
    setVehicleMake,
    setVehicleModel,
    setVehicleNvic,
    setVehicleVin,
} from '@/context/borrower';
import {
    setIsAssetKnownViaParams,
    setIsPriceKnownViaExternal,
} from '@/context/display';
import { store } from '@/context/store';

export async function getMchStockDetails(
    fromQr: boolean,
    stockNumber?: string
): Promise<boolean> {
    const data = await getStockNumber(stockNumber);

    if (!data) {
        return false;
    }

    // Note: Assuming this is MCH only
    store.dispatch(setVehicleAssetCategory(EVehicleAssetType.MOTORBIKES_REG));

    const {
        condition,
        price,
        make,
        model,
        year,
        title,
        vin,
        nvic,
        odometer,
        iqPartnerId,
    } = data;

    if (condition) store.dispatch(setVehicleCondition(ECondition[condition]));
    if (price) {
        store.dispatch(setPurchasePrice(price));
        store.dispatch(setIsPriceKnownViaExternal(true));
    }
    if (odometer) store.dispatch(setVehicleKilometers(odometer));
    if (year) store.dispatch(setVehicleBuildYear(year));
    if (make) store.dispatch(setVehicleMake(make));
    if (model) store.dispatch(setVehicleModel(model));
    if (title) store.dispatch(setVehicleDescription(title));
    if (vin) store.dispatch(setVehicleVin(vin));
    if (nvic) store.dispatch(setVehicleNvic(nvic));

    if (nvic || vin || title) {
        // If any of these are present, we can assume the asset is known
        // And we don't need to show the "Is the asset known?" question
        store.dispatch(setIsAssetKnownViaParams(true));
    }

    // If we're coming from a QR code, we don't want to overwrite the partner ID
    // Partner ID should only be overwritten if it's coming from the URL
    if (fromQr) return true;

    if (iqPartnerId)
        store.dispatch(setPartnerOverwriteId(iqPartnerId.toString()));

    return true;
}
