import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid';

import { DisplayComponent } from '@/components/DisplayComponent';
import { Header } from '@/components/Header';
import { RemoteImage } from '@/components/RemoteImage';
import { selectPartnerEmail, selectPartnerNumber } from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';
import { EMAIL, PHONE } from '@/utils/constants';

export const baseId = 'screen-IndicativelyReferred';

export function IndicativelyReferred(): JSX.Element {
    const phoneNumber = useAppSelector(selectPartnerNumber) || PHONE;
    const email = useAppSelector(selectPartnerEmail) || EMAIL;

    return (
        <DisplayComponent baseId={baseId} data-testid={baseId}>
            <div className='px-8'>
                <Header />
            </div>
            <div className='flex-center pt-20 pb-10 relative'>
                <div className='bg-primary h-40 w-40 rounded-full' />
                <RemoteImage fileName='humans.svg' className='absolute' />
            </div>
            <div className='text-center _flex flex-col space-y-2'>
                <h3 className='uppercase text-xl text-primary font-bold '>
                    We&apos;ll be in touch soon!
                </h3>
                <p className='text-secondary '>
                    Our friendly team will get in touch with <br />
                    you to finalise the application.
                </p>
            </div>

            <div className='flex-center w-full pt-4'>
                <div className='border-lg border-2 text-sm bg-white py-2 rounded-xl px-10 text-gray-500 text-center'>
                    <h3 className='font-bold pb-4'>
                        Have a question? <br />
                        Get in touch:
                    </h3>
                    <a
                        href={`tel:${phoneNumber}`}
                        className='flex-center space-x-2'
                    >
                        <PhoneIcon className='h-4 w-4' />
                        <p>{phoneNumber}</p>
                    </a>
                    <a
                        href={`mailto:${email}`}
                        className='flex-center space-x-2'
                    >
                        <EnvelopeIcon className='h-4 w-4' />
                        <p>{email}</p>
                    </a>
                </div>
            </div>
        </DisplayComponent>
    );
}
