import { setHideVehicleScreen } from '@/context/display';
import { store } from '@/context/store';

export function setInitialHideVehicleScreen(
  partnerHideVehicleScreen: boolean
): void {
  const stateShowVehicleScreen =
    store.getState().displayReducer.hideVehicleScreen;
  if (stateShowVehicleScreen !== partnerHideVehicleScreen) {
    store.dispatch(setHideVehicleScreen(partnerHideVehicleScreen));
  }
}
