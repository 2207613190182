export interface ISupportingDoc {
  id: string;
  filename: string;
  url: string;
}

// Note: This is not an exhaustive list
// The app is setup to take in a dynamic list from the API
// This list should NOT be updated in the future unless it has to be for a similar reason to below
// These ones listed here are important because they are used NOT dynamically but hardcoded on certain screens (eg, identification) or require a different approach if present then the normal upload a document (eg, bank statements)
export enum ESupportingDocCodes {
    // Important for Identification Display
    DRIVERS_LICENSE_FRONT = 'app_drivers_licence_front',
    DRIVERS_LICENSE_BACK = 'app_drivers_licence_back',
    // Important for Bank Statements API
    BANK_STATEMENTS_3M = 'app_bank_statement_three_months',
    BANK_STATEMENTS_6M = 'app_bank_statement_six_months',
    BANK_STATEMENTS_12M = 'app_bank_statement_twelve_months',
    PRIVACY = 'app_privacy_form',
    LENDER_SPECIFIC_PRIVACY = 'app_lender_specific_privacy_form',
    RATES_TITLE_SEARCH = 'set_rates_notice',
}
