import { ModalComponent } from '@/components/ModalComponent';
import { ModalHeader } from '@/components/ModalHeader';

export function CampaignDisclaimer(): JSX.Element {
    // ***** Constants *****
    const baseId = 'modal-CampaignDisclaimer';
    // ***** Render *****
    return (
        <ModalComponent
            baseId={baseId}
            className=' w-full h-full max-h-[90vh] '
        >
            <ModalHeader title='Disclaimer' baseId={baseId} />

            <p className='text-sm pb-4'>
                The comparison rate is based on a secured consumer fixed rate
                loan of $30,000 over a term of 5 years. WARNING: This comparison
                rate is true only for the examples given and may not include all
                fees and charges. Different terms, fees or other loan amounts
                might result in a different comparison rate. Finance to approved
                consumer and commercial applicants (excluding government, large
                fleet and rental buyers). Available on new and on-sold
                demonstrator Ranger Bi-Turbo vehicles. Finance applications must
                be approved by 30 June 2024 and settled by 12 July 2024. Minimum
                deposit of 10% required. Maximum finance term of 48 months
                applies. Credit criteria, fees, charges and terms and conditions
                apply. Finance provided by Allied Retail Finance Pty Ltd trading
                as MyFord Finance ABN 31 609 859 985 Australian credit licence
                483211, pursuant to a licence.
            </p>
        </ModalComponent>
    );
}
