import { useState } from 'react';
import { useSelector } from 'react-redux';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { AssetsNavigationButtons } from '@/components/AssetsNavigationButtons';
import { Button } from '@/components/Button';
import { ModalComponent } from '@/components/ModalComponent';
import { ModalHeader } from '@/components/ModalHeader';
import {
    deleteMotorVehicle,
    selectMotorVehicleActiveIndex,
    selectMotorVehiclebyIndex,
    selectMotorVehicles,
    setMotorVehicleActiveIndex,
} from '@/context/borrower';
import { setModal } from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { MotorVehicleAssetValue } from '@/UserInput/MotorVehicleAssetValue';
import {
    isAllMotorVehicleAssetsComplete,
    isMotorVehicleAssetComplete,
} from '@/utils/checkAssetsAndLiabilities';
import { titleHelper } from '@/utils/titleHelper';

export const testId = 'MotorVehicleAsset';

export const MotorVehiclesAsset = (): JSX.Element => {
    // ***** Constants *****
    const baseId = 'modal-motorVehicle';
    const modaltitle = 'Motor Vehicle';

    // ***** Redux *****

    const dispatch = useAppDispatch();
    const activeMotorVehicleIndex = useAppSelector(
        selectMotorVehicleActiveIndex
    );
    const titleNumber = activeMotorVehicleIndex + 1;
    const motorVehicle = useSelector((state: RootState) =>
        selectMotorVehiclebyIndex(state, activeMotorVehicleIndex)
    );
    const motorVehicles = useSelector(selectMotorVehicles);
    const hasPreviousMotorVehicle =
        motorVehicles?.length > 0 && activeMotorVehicleIndex !== 0;
    const hasNextMotorVehicle =
        motorVehicles?.length - 1 > activeMotorVehicleIndex;

    // ***** Local State *****
    const [showErrors, setShowErrors] = useState<boolean>(false);

    // ***** Handlers *****
    function handleSaveClick(): void {
        setShowErrors(true);
        if (!isAllMotorVehicleAssetsComplete()) return;
        createOrUpdateBorrower();
        dispatch(setModal(null));
    }

    function handleNextClick(): void {
        if (!isMotorVehicleAssetComplete(motorVehicle)) return;
        dispatch(setMotorVehicleActiveIndex(activeMotorVehicleIndex + 1));
    }

    function handleDeleteClick(): void {
        // Delete Current Real Estate
        dispatch(deleteMotorVehicle());
        // Change Active Index
        if (
            motorVehicles?.length >= 1 &&
            motorVehicles?.length > activeMotorVehicleIndex - 1
        ) {
            dispatch(setMotorVehicleActiveIndex(activeMotorVehicleIndex - 1));
        }
    }

    function handlePreviousClick(): void {
        if (
            motorVehicles?.length >= 1 &&
            motorVehicles?.length > activeMotorVehicleIndex - 1
        ) {
            dispatch(setMotorVehicleActiveIndex(activeMotorVehicleIndex - 1));
        }
    }

    // ***** Render *****
    return (
        <ModalComponent baseId={baseId}>
            <ModalHeader
                title={`${modaltitle} ${titleNumber} ${
                    titleNumber <= motorVehicles.length
                        ? titleHelper(motorVehicles)
                        : ''
                }`}
                baseId={baseId}
            />

            <AssetsNavigationButtons
                baseId={baseId}
                handleDeleteClick={handleDeleteClick}
                handlePreviousClick={handlePreviousClick}
                handleNextClick={handleNextClick}
                hasPrevious={hasPreviousMotorVehicle}
                hasNext={hasNextMotorVehicle}
            />
            <div className='mt-2'>
                <MotorVehicleAssetValue
                    baseId={baseId}
                    showErrors={showErrors}
                    setShowErrors={setShowErrors}
                />
            </div>
            <div className='pt-6 mx-2 '>
                <Button onClick={handleSaveClick} id={`${baseId}-button-save`}>
                    Save
                </Button>
            </div>
        </ModalComponent>
    );
};
