import { IPersonalLoan } from '@/context/borrower';

export function isPersonalLoanComplete(personalLoan: IPersonalLoan): boolean {
  if (
    personalLoan.monthlyRepayment &&
    personalLoan.lenderName &&
    personalLoan.loanBalance
  ) {
    return true;
  }

  return false;
}
