import { PayloadAction } from '@reduxjs/toolkit';

import { EFrequency, EOtherIncomeSource } from '../types';

export const incomeReducers = {
  setPaidFrequency: (
    state,
    action: PayloadAction<EFrequency | undefined>
  ): void => {
    state.income.paidFrequency = action.payload;
  },
  setPaidAmount: (state, action: PayloadAction<number | undefined>): void => {
    state.income.paidAmount = action.payload;
  },
  setOtherIncome: (state, action: PayloadAction<number | undefined>): void => {
    state.income.otherIncome = action.payload;
  },
  setOtherIncomeSource: (
    state,
    action: PayloadAction<EOtherIncomeSource | undefined>
  ): void => {
    state.income.otherIncomeSource = action.payload;
  },
};
