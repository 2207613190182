// import 'flowbite';
import { useSelector } from 'react-redux';

import { Input } from '@/components/Input';
import {
    EAddressOwnershipType,
    IRealEstate,
    selectAddressByIndex,
    selectMortgageRent,
    selectRealEstatebyIndex,
    setMortgageRent,
    setRealEstateByIndex,
} from '@/context/borrower';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'MortgageRent';

export function MortgageRent({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const mortgageRent = useAppSelector(selectMortgageRent);
    const dispatch = useAppDispatch();
    const address = useSelector((state: RootState) =>
        selectAddressByIndex(state, 0)
    );
    const realEstate = useSelector((state: RootState) =>
        selectRealEstatebyIndex(state, 0)
    );

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        // MAXIMUM
        const MAXIMUM = 99999;
        const intValue = getNumber(value, MAXIMUM);
        if (intValue === mortgageRent) return;
        dispatch(setMortgageRent(intValue));
        // same for real state loan
        if (address.ownershipType === EAddressOwnershipType.OWNER) {
            const newRealEstate: IRealEstate = {
                ...realEstate,
                monthlyRepayment: intValue,
                isLoanPresent: true,
            };

            dispatch(
                setRealEstateByIndex({
                    index: 0,
                    realEstate: newRealEstate,
                })
            );
        }
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Mortgage / Rent'
                placeholder='Required'
                showErrors={
                    showErrors &&
                    (mortgageRent === undefined || mortgageRent === null)
                }
                onChange={handleChange}
                id={`${baseId}-input-mortgageRent`}
                value={mortgageRent?.toLocaleString() ?? ''}
                prefix='$'
                showNumpad={true}
                tooltipContent='Mortgage/rent - What mortgage or rent do you pay?'
            />
        </div>
    );
}
