import { Input } from '@/components/Input';
import {
  ESellerType,
  selectSellerPhone,
  selectSellerType,
  setSellerPhone,
} from '@/context/borrower';
import { EDisplays, selectDisplay } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors?: boolean;
}

export const testId = 'DealershipPhone';

export function DealershipPhone({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectSellerPhone);
  const sellerType = useAppSelector(selectSellerType);
  const currentDisplay = useAppSelector(selectDisplay);

  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    if (typeof value === 'boolean') return;
    const protectedValue = value.replace(/[^0-9]/g, '');
    if (protectedValue?.length > 10) return;
    dispatch(setSellerPhone(protectedValue));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Phone Number'
        placeholder=''
        showErrors={showErrors}
        onChange={handleChange}
        id={`${baseId}-input-dealershipPhoneNumber`}
        value={currentState?.toLocaleString() ?? ''}
        disabled={
          sellerType === ESellerType.SOLAR ||
          currentDisplay === EDisplays.INDICATIVELY_APPROVED
            ? true
            : false
        }
      />
    </div>
  );
}
