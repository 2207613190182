import { DisplayButtons } from '@/components/DisplayButtons';
import { EUserFlow, selectEmployments, selectIsPreapproved } from '@/context/borrower';
import { EDisplays, selectPartner, selectUserFlow, setDisplay } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { MIN_EMPLOYMENT_YEAR } from '@/utils/constants';
import { getTotalEmploymentYears } from '@/utils/getTotalEmploymentYears';
import { isEmploymentComplete } from '@/utils/isEmploymentComplete';

interface IEmploymentButtonsProps {
    setShowErrors: (value: boolean) => void;
    baseId?: string;
}

export function EmploymentButtons({
    setShowErrors,
    baseId,
}: IEmploymentButtonsProps): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const employments = useAppSelector(selectEmployments);
    const partner = useAppSelector(selectPartner);
    const { earlyReferredScreen, showQuickQuote } = partner;
    const isApproved = useAppSelector(selectIsPreapproved);
    const userFlow = useAppSelector(selectUserFlow);

    // ***** Event Handlers *****
    function handleBackClick(): void {
        const backScreen = getBackScreen();
        dispatch(setDisplay(backScreen));
    }

    function handleNextClick(): void {
        setShowErrors(true);
        if (!isComplete()) return;
        const nextScreen = getNextScreen();
        dispatch(setDisplay(nextScreen));
    }

    // ***** Helpers ******
    function isComplete(): boolean {
        let isComplete = true;
        employments.forEach((employment) => {
            if (!isEmploymentComplete(employment)) isComplete = false;
        });
        if (getTotalEmploymentYears() < MIN_EMPLOYMENT_YEAR) isComplete = false;
        return isComplete;
    }

    function getNextScreen(): EDisplays {
        return EDisplays.ASSETS_AND_LIABILITIES;
    }

    function getBackScreen(): EDisplays {
        if (!isApproved && !earlyReferredScreen) return EDisplays.OCR_SUMMARY;
        if (userFlow === EUserFlow.CAMPAIGN) return EDisplays.OCR_SUMMARY;
        if (!showQuickQuote) return EDisplays.OCR_SUMMARY;

        return EDisplays.INDICATIVELY_APPROVED;
    }

    return (
        <DisplayButtons
            idLeftButton={`${baseId}-button-back`}
            idRightButton={`${baseId}-button-next`}
            rightClick={handleNextClick}
            leftClick={handleBackClick}
        />
    );
}
