import { RadioGroup } from '@/components/RadioGroup';
import {
    selectNumberOfChildren,
    setNumberOfChildren,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface INumberOfKidsProps {
    baseId: string;
    showErrors: boolean;
}

export function NumberOfKids({
    baseId,
    showErrors,
}: INumberOfKidsProps): JSX.Element {
    // ***** Redux *****
    const numberOfChildren = useAppSelector(selectNumberOfChildren);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean | undefined): void {
        if (!value) return;
        // MAXIMUM
        const intValue = getNumber(value);
        if (intValue === numberOfChildren) return;
        dispatch(setNumberOfChildren(intValue));
    }

    // ***** Render *****
    const options = [
        {
            label: '1',
            value: '1',
            id: `${baseId}-button-numberOfKids-1`,
        },
        {
            label: '2',
            value: '2',
            id: `${baseId}-button-numberOfKids-2`,
        },
        {
            label: '3+',
            value: '3',
            id: `${baseId}-button-numberOfKids-3+`,
        },
    ];

    // ***** Render *****
    return (
        <div data-testid='numberOfKids'>
            <RadioGroup
                title='Number Of Kids'
                showErrors={showErrors}
                onChange={handleChange}
                options={options}
                value={`${numberOfChildren}`}
                tooltipContent='This should be based on where you currently live.'
            />
        </div>
    );
}
