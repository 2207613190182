import { useEffect, useState } from 'react';

import {
    getBorrower,
    getTermsAndConditionsAgreement,
} from '@/apiCalls/borrower';
import { Button, EButtonType } from '@/components/Button';
import { ModalComponent } from '@/components/ModalComponent';
import { selectFirstName, selectSellerName } from '@/context/borrower';
import {
    EDisplays,
    selectAgreedToTermsAndConditions,
    selectLogo,
    setAgreedToTermsAndConditions,
    setDisplay,
    setModal,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { InsuranceOptIn } from '@/UserInput/InsuranceOptIn';
import { TermsAndConditions } from '@/UserInput/TermsAndConditions';
import { IMAGE_URL } from '@/utils/constants';

export const testId = 'dealershipWelcome';

export function DealershipWelcome(): JSX.Element {
    // ***** Constants *****
    const baseId = 'modal-dealershipWelcome';

    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const firstName = useAppSelector(selectFirstName);
    const sellerName = useAppSelector(selectSellerName);
    const agreedToTermsAndConditions = useAppSelector(
        selectAgreedToTermsAndConditions
    );
    const logo = useAppSelector(selectLogo);
    const [hidePrivacyCheckbox, setHidePrivacyCheckbox] =
        useState<boolean>(false);

    // ***** Handlers *****
    function handleCloseClick(): void {
        getBorrower().then(() => {
            dispatch(setDisplay(EDisplays.QUICK_CALCULATOR));
            dispatch(setModal(null));
        });
    }

    useEffect(() => {
        getTermsAndConditionsAgreement().then((termsAndConditions) => {
            dispatch(setAgreedToTermsAndConditions(termsAndConditions));

            if (termsAndConditions?.isDefaultPrivacyAgreed?.agreed) {
                setHidePrivacyCheckbox(true);
            }
        });
    }, []);

    // ***** Render *****
    return (
        <ModalComponent baseId={baseId}>
            <div
                className={`flex-center w-full h-32 ${
                    logo === 'lennock.png' && '!h-8 object-contain pt-4 pb-6'
                }`}
            >
                <img
                    src={
                        logo
                            ? `${IMAGE_URL}/${logo}`
                            : 'https://driveiq-assets.s3.ap-southeast-2.amazonaws.com/vroom/finger-print-circle.gif'
                    }
                    className={`h-20 ${
                        logo === 'lennock.png' && '!h-8 object-contain'
                    }`}
                />
            </div>
            <p className='font-bold text-2xl text-center pb-2 text-primary'>
                Hey, {firstName}
            </p>
            <p className='text-center text-xl'>
                Welcome to your finance journey!
            </p>
            <div className='text-center px-14 py-4'>
                <p className='text-xl'>You&apos;ve been sent here by</p>
                <p className='text-xl font-bold'>{sellerName}</p>
            </div>
            {!hidePrivacyCheckbox && (
                <div className='px-14 flex-center w-full pb-4 flex-col'>
                    <TermsAndConditions baseId={baseId} />
                    <InsuranceOptIn baseId={baseId} />
                </div>
            )}
            <div className='flex-center mx-16'>
                <Button
                    onClick={handleCloseClick}
                    type={
                        agreedToTermsAndConditions.isDefaultPrivacyAgreed
                            ?.agreed
                            ? EButtonType.SECONDARY_OUTLINE
                            : EButtonType.DISABLED
                    }
                >
                    Continue
                </Button>
            </div>
        </ModalComponent>
    );
}
