export function maximumDateHelper(limit: number): Date {
  const today = new Date();
  const currentYear = today.getFullYear(); // get current year
  const maxDate = new Date(
    currentYear + limit,
    today.getMonth(),
    today.getDate()
  );
  return maxDate;
}
