import { useEffect, useState } from 'react';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { AnimationHelper } from '@/components/AnimationHelper';
import { DisplayComponent } from '@/components/DisplayComponent';
import { Header } from '@/components/Header';
import {
    EDisplays,
    EJourneys,
    selectInitialJourney,
    selectIsLoading,
    setDisplay,
    setShowOcrDisplay,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export const baseId = 'screen-ocrAnimation';

export const testId = 'OcrAnimation';

const delayExecution = (mls: number): Promise<unknown> => {
    return new Promise((resolve) => {
        setTimeout(() => resolve('ok'), mls);
    });
};

export function OcrAnimation(): JSX.Element {
    // ***** Redux State *****
    const isOcrLoading = useAppSelector(selectIsLoading);
    const dispatch = useAppDispatch();
    const initialJourney = useAppSelector(selectInitialJourney);

    // ***** Local State *****
    const placeholderText = [
        'UPLOADING YOUR LICENCE...',
        'READING YOUR DATA...',
        'CHECKING YOUR DETAILS...',
        'PREFILLING THE FORM...',
        'AND...',
        "IT'S DONE!!",
    ];
    const [state, setState] = useState('');

    const changePlaceholder = async (): Promise<void> => {
        for (let i = 0; i < placeholderText?.length; i++) {
            await delayExecution(2000);
            setState(placeholderText[i]);
        }
    };

    useEffect(() => {
        changePlaceholder();
    }, []);

    // If isOcrLoading is false, we assume that the external API has completed
    // We still only want to show the summary after all the text has been displayed
    if (
        state === placeholderText[placeholderText?.length - 1] &&
        !isOcrLoading
    ) {
        createOrUpdateBorrower();

        const nextDisplay = getNextDisplay();
        dispatch(setDisplay(nextDisplay));
        dispatch(setShowOcrDisplay(false));
    }

    function getNextDisplay(): EDisplays {
        if (initialJourney === EJourneys.TEST_DRIVE) {
            return EDisplays.TEST_DRIVE_OCR_SUMMARY;
        }

        return EDisplays.OCR_SUMMARY;
    }

    return (
        <DisplayComponent baseId={baseId} data-testid={testId}>
            <Header />
            <div className='h-full flex-center relative flex-col'>
                <AnimationHelper array={placeholderText} />
            </div>
        </DisplayComponent>
    );
}
