import { EVehicleAssetType } from '@/context/borrower';
import { DEFAULT_PARTNER } from '@/partners/defaultPartner';
import { ELenderSorting, LODGE_LENDERS } from '@/partners/matchingEngineEnums';
import {
    DEFAULT_TERMS_AND_CONDITIONS_LINK,
    REACT_APP_BANK_STATEMENTS_REFERRER_CODE_3M,
    REACT_APP_BANK_STATEMENTS_REFERRER_CODE_6M,
    REACT_APP_BANK_STATEMENTS_REFERRER_CODE_12M,
} from '@/utils/constants';

import { EDisplays, EJourneys, EModals, IDisplaySlice } from '../types';

import { initialGlassGuideLookUpVehicle } from './initialGlassGuideLookUpVehicle';

export const initialDisplaySlice: IDisplaySlice = {
    display: EDisplays.BASIC,
    previousDisplay: undefined,
    modal: EModals.WELCOME,
    showWelcomeScreen: false,
    isLoaded: false,
    isWhiteLabel: false,
    logo: 'lodge.png',
    fullLogoUrl: false,
    initialLoad: false,
    showOcrDisplay: false,
    isLoading: false,
    agreedToTermsAndConditions: {},
    glassGuideLookUpVehicleResults: initialGlassGuideLookUpVehicle,
    termsAndConditionsLink: DEFAULT_TERMS_AND_CONDITIONS_LINK,
    hideSellerScreen: false,
    hideVehicleScreen: false,
    showTotalAmountPayable: false,
    partnerName: null,
    partnerNumber: null,
    partnerEmail: null,
    waterfallLenders: [{ lenders: LODGE_LENDERS, showLenderDetails: true }],
    isAssetKnownViaParams: false,
    partner: DEFAULT_PARTNER,
    askTermsAndConditions: false,
    lenderSorting: ELenderSorting.CHEAPEST,
    sendLeadEmail: false,
    availableVehicleAssetTypes: [
        {
            label: 'Car, Van or Ute',
            type: EVehicleAssetType.COMMERCIAL_VEHICLES_LESS_THEN_4_5_TONNES,
        },
    ],
    requireGlassGuide: true,
    illionCodes: {
        threeMonths: REACT_APP_BANK_STATEMENTS_REFERRER_CODE_3M,
        sixMonths: REACT_APP_BANK_STATEMENTS_REFERRER_CODE_6M,
        twelveMonths: REACT_APP_BANK_STATEMENTS_REFERRER_CODE_12M,
    },
    isAssetAndLiabilitiesSubmitSkipped: false,
    initialJourney: EJourneys.FINANCE,
    showScanwarePopup: false,
    vehicleManualEntry: false,
    isPriceKnownViaExternal: false,
    isDemoMode: false,
};
