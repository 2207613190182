import { setVehicleUrl } from '@/context/borrower';
import { store } from '@/context/store';

export function setVehicleUrlFromSite(originalUrl: string | null): void {
    const { url: stateUrl } = store.getState().borrowerReducer.vehicle;

    if (originalUrl && stateUrl !== originalUrl) {
        store.dispatch(setVehicleUrl(originalUrl));
        return;
    }

    const fullUrl = window.location.href;

    if (fullUrl && stateUrl !== fullUrl) {
        store.dispatch(setVehicleUrl(fullUrl));
    }
}
