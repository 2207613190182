import { getProgressPercent } from '@/utils/getProgressPercent';

export function SolarProgressBar(): JSX.Element {
  const percent = getProgressPercent();

  // Solar selection
  const progressBarSections = [
    { name: 'Loan Details', progress: 25 },
    { name: 'Your Details', progress: 50 },
    { name: 'Assets & Liabilities', progress: 75 },
    { name: 'Lender Questions', progress: 99 },
  ];
  return (
    <>
      <div
        data-testid='SolarProgressBar'
        className={`

          bg-white rounded-lg _flex flex-col justify-items-center align-middle p-5 pt-4
        `}
      >
        {/* Progress bar Labels */}

        <div className='_flex  text-center'>
          {progressBarSections.map((item, index) => (
            <div
              key={index}
              className={`text-xs p-2 text-center ${
                item.progress === getProgressPercent() ? 'text-secondary' : ''
              }`}
            >
              {item.name}
            </div>
          ))}
        </div>

        {/* Progress Bar */}
        <div className='w-full rounded-full self-center bg-gray-200 dark:bg-gray-200 h-4'>
          <div
            className={`rounded-full bg-primary h-4
            `}
            style={{ width: `${percent}%` }}
          ></div>
        </div>
      </div>
    </>
  );
}
