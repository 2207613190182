import { Input } from '@/components/Input';
import {
  selectPrimaryBusinessStreetNumber,
  setPrimaryBusinessStreetNumber,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'PrimaryBusinessStreetNumber';

export function PrimaryBusinessStreetNumber({
  baseId,
  showErrors,
}: IProps): JSX.Element {
  // ***** Redux *****

  const dispatch = useAppDispatch();
  const primaryBusinessStreetNumber = useAppSelector(
    selectPrimaryBusinessStreetNumber
  );
  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    if (typeof value !== 'string') return;

    dispatch(setPrimaryBusinessStreetNumber(value));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Street Number'
        placeholder='12'
        showErrors={showErrors && !primaryBusinessStreetNumber}
        onChange={handleChange}
        id={`${baseId}-input-unitNumber`}
        value={primaryBusinessStreetNumber?.toLocaleString() ?? ''}
      />
    </div>
  );
}
