import React from 'react';

interface IMultipleUserInputProps {
  children: React.ReactNode;
}

export function MultipleUserInput({
  children,
}: IMultipleUserInputProps): JSX.Element {
  return <div className='_flex space-x-6'>{children}</div>;
}
