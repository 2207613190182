import { store } from '@/context/store';

export function getNumberOfKids(): number {
  const state = store.getState();
  const numberOfKids = state.borrowerReducer.personalDetails.numberOfChildren;
  const hasKids = state.borrowerReducer.personalDetails.hasChildren;

  if (numberOfKids === undefined) return 0;
  if (hasKids === undefined) return 0;
  if (!hasKids) return 0;
  return numberOfKids;
}
