import axios from 'axios';

import { EModals, setModal } from '@/context/display';
import { store } from '@/context/store';
import { API_URL } from '@/utils/constants';
import {
    ELocalStorageVariables,
    getLocalStorageItem,
    removeLocalStorageItem,
} from '@/utils/localStorage';

// Create an axios instance
const httpClient = axios.create({
    baseURL: API_URL,
});

// Add a request interceptor
httpClient.interceptors.request.use(
    (config) => {
        const token = getLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
httpClient.interceptors.response.use(
    (response) => {
        // For successful responses, pass the response through
        return response;
    },
    (error) => {
        // Handle 401 (Unauthorized) errors here
        if (error.response && error.response.status === 401) {
            // Clean old token
            removeLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);
            // Show the login modal
            store.dispatch(setModal(EModals.LOGIN));
        }
        return Promise.reject(error);
    }
);

export async function getUrl<T>(url: string): Promise<T> {
    return await httpClient.get(url);
}

export async function postUrl<T>(url: string, body: unknown): Promise<T> {
    return (await httpClient.post<T>(url, body)).data;
}

export function deleteUrl(url: string): Promise<Response> {
    return httpClient.delete(url);
}

export async function putUrl<T>(url: string, body: unknown): Promise<T> {
    return (await httpClient.put<T>(url, body)).data;
}
