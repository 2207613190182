import { EEvents } from './EEvents';

const SSE_SUBSCRIPTION_BASE_URL =
    process.env.REACT_APP_SSE_SUBSCRIPTION_BASE_URL;
const SERVICE = process.env.REACT_APP_SSE_SERVICE_NAME;
const ENVIRONMENT = process.env.REACT_APP_SSE_ENV_NAME;

type openCallback = (event: Event) => void;
type messageCallback<T> = (message: T) => void;
type errorCallback = (error: Event) => void;

/**
 * Subscribe to an event update stream.
 * @param {string} token Required - the token to be used for verification
 * @param {string} event Required - the event to subscribe to
 * @param {messageCallback<T>} messageCallback Required - callback to handle updates
 * @param {errorCallback} errorCallback Optional - callback to handle errors
 * @param {openCallback} openCallback Optional - callback to handle connection initiation
 * @returns an instance of EventSource class that can be used to close the connection
 *
 * @example const eventSource = subscribeToEvent(EEvents.DOCUMENT_UPLOAD, (message) => console.log(message));
 *
 * @author Danyyil
 * @date 2023-10-23
 */
export function subscribeToSseEvent(
    token: string,
    event: EEvents,
    messageCallback: messageCallback<Record<string, string>>,
    errorCallback?: errorCallback,
    openCallback?: openCallback
): EventSource {
    const url = `${SSE_SUBSCRIPTION_BASE_URL}/${SERVICE}/${ENVIRONMENT}/${event}/${token}`;

    // Open connection
    const eventSource = new EventSource(url);

    // Create new server event listener
    eventSource.onmessage = (event): void => {
        messageCallback(JSON.parse(event.data));
    };

    // If callback for error listener is provided, create new error listener
    if (errorCallback != null) {
        eventSource.onerror = (error): void => {
            errorCallback(error);
        };
    }

    // If callback for new connection (onopen) listener is provided, create the listener
    if (openCallback != null) {
        eventSource.onopen = (event): void => {
            openCallback(event);
        };
    }

    return eventSource;
}
