import { postUrl } from '../fetch';

export async function createLoginUrl(
    pushLocation: string,
    firstName: string | undefined
): Promise<string> {
    return postUrl<string>('/api/urlShortener/create-login', {
        pushLocation: pushLocation,
        name: firstName,
    });
}
