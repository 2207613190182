import { RadioGroup } from '@/components/RadioGroup';
import {
    ECondition,
    selectVehicleCondition,
    setVehicleCondition,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'VehicleCondition';

export function VehicleCondition({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectVehicleCondition);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean | undefined): void {
        dispatch(setVehicleCondition(value as ECondition));
    }

    // ***** Render *****
    const options = [
        {
            label: 'New',
            value: ECondition.NEW,
            id: `${baseId}-button-VehicleCondition-new`,
        },
        {
            label: 'Used',
            value: ECondition.USED,
            id: `${baseId}-button-VehicleCondition-used`,
        },
    ];
    return (
        <div data-testid={testId}>
            <RadioGroup
                title='Is the vehicle new or used?'
                showErrors={showErrors}
                onChange={handleChange}
                options={options}
                value={currentState}
                tooltipContent='If the car is a "Demo" then select "Used"'
            />
        </div>
    );
}
