import { setCssVariables } from '@/utils/cssVariables';
import { IParameter } from '@/utils/setParametersFromParams';

export interface ISetInitialColors {
  parameterPrimaryColor: IParameter;
  parameterSecondaryColor: IParameter;
  parameterTertiaryColor: IParameter;
  parameterQuaternaryColor: IParameter;
  partnerPrimaryColor: string | null;
  partnerSecondaryColor: string | null;
  partnerTertiaryColor: string | null;
  partnerQuaternaryColor: string | null;
}

export function setInitialColors(settings: ISetInitialColors): void {
  const primaryColor = getColour(
    settings.parameterPrimaryColor,
    settings.partnerPrimaryColor
  );
  const secondaryColor = getColour(
    settings.parameterSecondaryColor,
    settings.partnerSecondaryColor
  );
  const tertiaryColor = getColour(
    settings.parameterTertiaryColor,
    settings.partnerTertiaryColor
  );
  const quaternaryColor = getColour(
    settings.parameterQuaternaryColor,
    settings.partnerQuaternaryColor
  );
  setCssVariables({
    primaryColor,
    secondaryColor,
    tertiaryColor,
    quaternaryColor,
  });
}

function getColour(
  paramColour: string | null,
  partnerColour: string | null
): string | null {
  if (paramColour === null && partnerColour === null) return null;

  if (paramColour !== null) {
    return `#${paramColour}`;
  }

  if (partnerColour === null) {
    return null;
  }

  return partnerColour;
}
