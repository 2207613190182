import { ECampaign } from '@/context/borrower';
import { store } from '@/context/store';

import {
    CampaignVehicle,
    campaignVehicles,
    demoCampaignVehicles,
    demoLotusVehicles,
} from './campaignVehicles';
import { yamahaCampaignVehicles } from './yamahaCampaignVehicles';

export function getCampaignVehicles(): CampaignVehicle[] {
    const state = store.getState();
    const campaign = state.displayReducer.partner.campaign;
    const url = window.location.href;
    const isDemo = url.includes('demo');
    const isLotus = url.includes('lotus');

    if (campaign === ECampaign.YAMAHA) {
        return yamahaCampaignVehicles;
    } else if (campaign === ECampaign.DEMO || isDemo) {
        return demoCampaignVehicles;
    } else if (isLotus) {
        return demoLotusVehicles;
    }

    return campaignVehicles;
}
