import { store } from '@/context/store';

import { getUrl } from '../fetch';

import { IMotorStock } from './IMotorStock';

export async function getStockNumber(
    stock?: string
): Promise<IMotorStock | null> {
    let stockNumber = stock;
    if (!stockNumber) {
        const state = store.getState();
        stockNumber = state.borrowerReducer.vehicle.stockNumber;
    }

    const url = `/api/iMotor/stockNumber/${stockNumber}`;

    const response = await getUrl<any>(url);

    return response.data;
}
