import { setAskTermsAndConditions } from '@/context/display';
import { store } from '@/context/store';

export function getInitialAskTermsAndConditions(
  parameterValue: string | null
): void {
  // By default askTermsAndConditions is always false.
  // Only dispatch if the parameter is true.
  if (parameterValue === 'true') {
    store.dispatch(setAskTermsAndConditions(true));
  }
}
