import { setTestDriveDate } from '@/context/borrower';
import { store } from '@/context/store';

export function setInitialTestDriveDate(date: string | null): void {
    console.log('setInitialTestDriveDate, : ', date);
    if (!date) return;

    // Change date format to Date object
    const dateArray = date.split('-');
    const day = parseInt(dateArray[0]);
    const month = parseInt(dateArray[1]) - 1;
    const year = parseInt(dateArray[2]);
    const testDriveDate = new Date(year, month, day);

    // Dispatch
    store.dispatch(setTestDriveDate(testDriveDate));
}
