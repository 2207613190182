import { Button, EButtonType } from '../Button';

interface IDisplayButtonsProps {
    leftClick: () => void; // Todo: Remove the optional
    rightClick: () => void; // Todo: Remove the optional
    leftText?: string;
    rightText?: string;
    leftType?: EButtonType;
    rightType?: EButtonType;
    idLeftButton?: string;
    idRightButton?: string;
}

function DisplayButtons({
    idLeftButton,
    idRightButton,
    leftClick,
    rightClick,
    leftText = 'Back',
    rightText = 'Next',
    leftType = EButtonType.SECONDARY_OUTLINE,
    rightType = EButtonType.PRIMARY,
}: IDisplayButtonsProps): JSX.Element {
    return (
        <div className='_flex w-full space-x-[25px] pt-2'>
            <Button onClick={leftClick} type={leftType} id={idLeftButton}>
                <p className='text-xs'>{leftText}</p>
            </Button>
            <Button onClick={rightClick} type={rightType} id={idRightButton}>
                <p className='text-xs'> {rightText}</p>
            </Button>
        </div>
    );
}

export { DisplayButtons };
