import { PayloadAction } from '@reduxjs/toolkit';

import { EPartners } from '@/partners/partnerTypes';

import { ECondition, EState, EVehicleAssetType, IVehicle } from '../types';

export const vehicleReducers = {
    setVehicle: (state, action: PayloadAction<IVehicle>): void => {
        state.vehicle = action.payload;
    },
    setVehicleVin: (state, action: PayloadAction<string | undefined>): void => {
        state.vehicle.vin = action.payload;
    },
    setVehicleNvic: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.vehicle.nvic = action.payload;
    },
    setVehicleBuildYear: (
        state,
        action: PayloadAction<number | undefined>
    ): void => {
        state.vehicle.buildYear = action.payload;
        state.vehicle.make = undefined;
        state.vehicle.model = undefined;
        state.vehicle.variant = undefined;
        state.vehicle.description = undefined;
    },
    setVehicleMake: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.vehicle.make = action.payload;
        state.vehicle.model = undefined;
        state.vehicle.variant = undefined;
        state.vehicle.description = undefined;
    },
    setVehicleModel: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.vehicle.model = action.payload;
        state.vehicle.variant = undefined;
        state.vehicle.description = undefined;
    },
    setVehicleVariant: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.vehicle.variant = action.payload;
        state.vehicle.description = undefined;
    },
    setVehicleDescription: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.vehicle.description = action.payload;
    },
    setIsVehicleKnown: (state, action: PayloadAction<boolean>): void => {
        state.vehicle.isVehicleKnown = action.payload;
    },
    setVehicleKilometers: (
        state,
        action: PayloadAction<number | undefined>
    ): void => {
        state.vehicle.kilometers = action.payload;
    },
    setVehicleRegistrationNumber: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.vehicle.registrationNumber = action.payload;
        state.vehicle.description = undefined;
    },
    setVehicleRegistrationState: (
        state,
        action: PayloadAction<EState | undefined>
    ): void => {
        state.vehicle.registrationState = action.payload;
        state.vehicle.description = undefined;
    },
    setIsVehicleRegistrationKnown: (
        state,
        action: PayloadAction<boolean>
    ): void => {
        state.vehicle.isRegistrationKnown = action.payload;
        state.vehicle.buildYear = undefined;
        state.vehicle.make = undefined;
        state.vehicle.model = undefined;
        state.vehicle.variant = undefined;
        state.vehicle.description = undefined;
        state.vehicle.registrationNumber = undefined;
        state.vehicle.registrationState = undefined;
        state.vehicle.kilometers = undefined;
    },
    setVehicleAssetType: (
        state,
        action: PayloadAction<EVehicleAssetType>
    ): void => {
        state.vehicle.assetType = action.payload;
    },
    setVehicleAssetCategory: (state, action: PayloadAction<string>): void => {
        state.vehicle.assetCategory = action.payload;
    },
    setIsVehicleAssetTypeKnownViaParams: (
        state,
        action: PayloadAction<boolean>
    ): void => {
        state.vehicle.isAssetTypeKnownViaParams = action.payload;
    },
    setVehicleSourcePrice: (
        state,
        action: PayloadAction<number | undefined>
    ): void => {
        state.vehicle.sourcePrice = action.payload;
    },
    setVehicleUrl: (state, action: PayloadAction<string | undefined>): void => {
        state.vehicle.url = action.payload;
    },
    setVehicleStockNumber: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.vehicle.stockNumber = action.payload;
    },
    setVehicleSource: (
        state,
        action: PayloadAction<EPartners | undefined>
    ): void => {
        state.vehicle.source = action.payload;
    },
    setGlassValuation: (
        state,
        action: PayloadAction<number | undefined>
    ): void => {
        state.vehicle.glassValuation = action.payload;
    },
    setVehicleCondition: (
        state,
        action: PayloadAction<ECondition | undefined>
    ): void => {
        state.vehicle.condition = action.payload;
    },
    setVehicleSourceYardId: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.vehicle.sourceYardId = action.payload;
    },
};
