import { EFrequency } from '@/context/borrower';

export function getMonthlyAmount(
  frequency: EFrequency | null | undefined,
  amount: number | null | undefined
): number {
  if (frequency === null || frequency === undefined) {
    return 0;
  }
  if (amount === null || amount === undefined) {
    return 0;
  }

  switch (frequency) {
    case EFrequency.WEEKLY:
      return amount * 4.3;
    case EFrequency.FORTNIGHTLY:
      return amount * 2;
    case EFrequency.MONTHLY:
      return amount;
    default:
      return 0;
  }
}
