import { uniqueIdTransfer } from '@/apiCalls/uniqueIDTransferHelper';

import { EEventType } from '../eventType';

export interface ICheckBox {
    onClick: () => void;
    checked: boolean;
    id?: string;
    eventType?: EEventType;
    className?: string;
}

export function CheckBox({
    onClick,
    checked,
    id,
    eventType = EEventType.CLICK,
    className,
}: ICheckBox): JSX.Element {
    function handleClick(): void {
        onClick();
        uniqueIdTransfer({
            eventType: eventType,
            applicationData: {
                id: id,
            },
        });
    }
    return (
        <div className={`${className} _flex items-center`}>
            <input
                id={id}
                type='checkbox'
                value=''
                checked={checked}
                onChange={handleClick}
                className='select-none w-4 h-4'
            />
        </div>
    );
}
