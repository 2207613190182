import {
    ELoanType,
    ELoanUsage,
    EUserFlow,
    EVehicleAssetType,
} from '@/context/borrower';
import {
    DEFAULT_COMMISSION,
    DEFAULT_COMMISSION_TYPE,
    DEFAULT_TERMS_AND_CONDITIONS_LINK,
    EMAIL,
    PHONE,
} from '@/utils/constants';

import { LODGE_LENDERS } from './matchingEngineEnums';
import { EPartners, IPartnerParams } from './partnerTypes';

export const DEFAULT_PARTNER: IPartnerParams = {
    id: EPartners.UNKNOWN,
    url: null,
    dealerName: '',
    dealerAccountNumber: null,
    vehicleStockNumber: null,
    price: null,
    nvic: null,
    vin: null,
    dealerAbn: null,
    dealerEmail: EMAIL,
    dealerPhone: PHONE,
    leadEmail: null,
    vehicleKilometers: null,
    quickQuotePrivacyPolicy: '',
    loanUsage: ELoanUsage.PERSONAL,
    loanTerm: 5,
    welcomeScreen: false,
    assetType: EVehicleAssetType.COMMERCIAL_VEHICLES_LESS_THEN_4_5_TONNES,
    logo: null,
    whiteLabel: false,
    primaryColor: null,
    secondaryColor: null,
    tertiaryColor: null,
    quaternaryColor: null,
    termsAndConditionsLink: DEFAULT_TERMS_AND_CONDITIONS_LINK,
    hideSelectVehicleScreen: true,
    hideSellerScreen: true,
    showTotalAmountPayable: false,
    sendLeadEmail: false,
    waterfallLenders: [
        {
            lenders: LODGE_LENDERS,
            showLenderDetails: true,
        },
    ],
    initialLoanType: ELoanType.CHATTEL_MORTGAGE,
    financeTypeCommission: {
        consumer: {
            value: DEFAULT_COMMISSION,
            type: DEFAULT_COMMISSION_TYPE,
        },
        commercial: {
            value: DEFAULT_COMMISSION,
            type: DEFAULT_COMMISSION_TYPE,
        },
    },
    availableVehicleAssetTypes: [
        {
            label: 'Car, Van or Ute',
            type: EVehicleAssetType.COMMERCIAL_VEHICLES_LESS_THEN_4_5_TONNES,
        },
    ],
    requireGlassGuide: true,
    defaultVehicleAssetCategory: '',
    defaultVehicleAssetType: EVehicleAssetType.PASSENGER_VEHICLE,
    userFlow: EUserFlow.NORMAL,
    showQuickQuote: true,
    earlyReferredScreen: true,
    isDemoMode: false,
    manageOrganisationId: 'UNKNOWN',
};
