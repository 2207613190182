import { DateInput } from '@/components/DateInput';
import { selectLicenseExpiry, setLicenseExpiry } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { isLicenseExpiryDateValid } from '@/utils/checkLicenseExpiryDate';
import { maximumDateHelper } from '@/utils/maximumDateHelper';
import { minimumDateHelper } from '@/utils/minimumDateHelper';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'LicenseExpiry';

export const LicenseExpiry = ({ showErrors, baseId }: IProps): JSX.Element => {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const licenseExpiry = useAppSelector(selectLicenseExpiry);

    // *****Functions*****

    const handlOnChange = (date: Date): void => {
        dispatch(setLicenseExpiry(date));
    };

    // *****Render*****
    return (
        <DateInput
            id={`${baseId}-input-licenseExpiryDate`}
            title='License Expiry Date'
            placeholder='License Expiry Date'
            onChange={handlOnChange}
            value={licenseExpiry}
            showErrors={showErrors && !isLicenseExpiryDateValid()}
            maxDate={maximumDateHelper(10)}
            minDate={minimumDateHelper(10)}
        />
    );
};
