import { RadioGroup } from '@/components/RadioGroup';
import {
    EOtherIncomeSource,
    selectOtherIncomeSource,
    setOtherIncomeSource,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IOtherIncomeSource {
    baseId: string;
    showErrors: boolean;
}

export function OtherIncomeSource({
    baseId,
    showErrors,
}: IOtherIncomeSource): JSX.Element {
    // ***** Redux *****
    const otherIncomeSource = useAppSelector(selectOtherIncomeSource);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean | undefined): void {
        dispatch(setOtherIncomeSource(value as EOtherIncomeSource));
    }

    // ***** Render *****
    const options = [
        {
            label: 'Rental / Investment',
            value: EOtherIncomeSource.RENTAL_OR_INVESMENT,
            id: `${baseId}-button-otherIncomeSource-rentalOrInvestment`,
        },
        {
            label: 'Government Benefits',
            value: EOtherIncomeSource.GOVERNMENT_BENEFITS,
            id: `${baseId}-button-otherIncomeSource-governmentBenefits`,
        },
        {
            label: 'Other',
            value: EOtherIncomeSource.OTHER,
            id: `${baseId}-button-otherIncomeSource-other`,
        },
    ];

    return (
        <div data-testid='OtherIncomeSource'>
            <RadioGroup
                title='Source of other income'
                showErrors={showErrors && otherIncomeSource === undefined}
                onChange={handleChange}
                options={options}
                value={otherIncomeSource}
                tooltipContent='Select the main one. If your income source is not listed, select other.'
            />
        </div>
    );
}
