import { Button, EButtonType } from '../Button';

export interface ISolarNavigationButtonProps {
  handleNextClick: () => void;
  handleBackClick: () => void;
  hasPrevious?: boolean;
}

export function SolarNavigationButtons({
  handleNextClick,
  handleBackClick,
  hasPrevious = true,
}: ISolarNavigationButtonProps): JSX.Element {
  return (
    <div className='_flex space-x-4 justify-between p-4 mb-2'>
      <Button
        onClick={handleBackClick}
        type={
          !hasPrevious
            ? EButtonType.ROUNDED_DISABLED
            : EButtonType.ROUNDED_FULL_SECONDARY_OUTLINE
        }
      >
        Back
      </Button>
      <Button onClick={handleNextClick} type={EButtonType.ROUNDED_FULL_PRIMARY}>
        Next
      </Button>
    </div>
  );
}
