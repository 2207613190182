import { Input } from '@/components/Input';
import { selectAnnualRevenue, setAnnualRevenue } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'AnnualRevenue';

export function AnnualRevenue({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  const annualRevenue = useAppSelector(selectAnnualRevenue);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    // MAXIMUM
    const MAXIMUM = 999999999; // 999,999,999
    const intValue = getNumber(value, MAXIMUM);
    if (intValue === annualRevenue) return;
    dispatch(setAnnualRevenue(intValue));
    if (intValue !== 0) return;
    dispatch(setAnnualRevenue(undefined));
  }

  // ***** Render *****
  return (
    <Input
      title='What is the annual revenue of your business?'
      placeholder='$20,000'
      showErrors={showErrors && !annualRevenue}
      onChange={handleChange}
      id={`${baseId}-input-annualRevenue`}
      value={annualRevenue?.toLocaleString() ?? ''}
      prefix='$'
      data-testid={testId}
    />
  );
}
