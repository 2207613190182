import { lookupVin } from '@/apiCalls/assetLookup';
import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button } from '@/components/Button';
import { EUserFlow, selectPurchasePrice } from '@/context/borrower';
import {
    EDisplays,
    EModals,
    selectUserFlow,
    setDisplay,
    setIsAssetKnownViaParams,
    setModal,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { selectIsLoggedIn } from '@/context/user';
import { EConversionType, reportConversion } from '@/utils/reportConversion';

export function QuickCalculatorButton(): JSX.Element {
    const dispatch = useAppDispatch();
    const partnerId = useAppSelector(
        (state) => state.displayReducer.partner.id
    );
    const glassValuation = useAppSelector(
        (state) => state.borrowerReducer.vehicle.glassValuation
    );
    const userFlow = useAppSelector(selectUserFlow);
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const purchasePrice = useAppSelector(selectPurchasePrice);

    async function handleClick(): Promise<void> {
        if (!purchasePrice) {
            return;
        }
        // User Flow for A
        if (
            userFlow === EUserFlow.NORMAL ||
            userFlow === EUserFlow.NORMAL.toLocaleUpperCase()
        ) {
            normalUserFlow();
        }
        if (
            userFlow === EUserFlow.EARLY_SIGNUP ||
            userFlow === EUserFlow.EARLY_SIGNUP.toLocaleUpperCase()
        ) {
            if (isLoggedIn) {
                normalUserFlow();
            } else {
                dispatch(setModal(EModals.REGISTER));
            }
        }
    }

    async function normalUserFlow(): Promise<void> {
        // Only lookup the VIN if we have a partnerId and it's not UNKNOWN
        if (!glassValuation && partnerId && partnerId !== 'UNKNOWN') {
            await lookupVin(partnerId).then((isSuccessful) => {
                if (isSuccessful) {
                    dispatch(setIsAssetKnownViaParams(true));
                }
            });
        }
        reportConversion(EConversionType.LEAD_CAPTURE);

        createOrUpdateBorrower();
        dispatch(setDisplay(EDisplays.LOAN));
    }

    return (
        <Button
            onClick={handleClick}
            title='Apply Now'
            id='screen-quickCalculator-button-getQuoteNow'
        />
    );
}
