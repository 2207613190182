import { ELoanUsage, setLoanUsage } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialLoanUsage(
  partnerLoanUsage: ELoanUsage,
  parameterLoanUsage: IParameter
): void {
  const loanUsageToSave = (
    partnerLoanUsage ?? parameterLoanUsage
  )?.toUpperCase();

  if (!loanUsageToSave || !(loanUsageToSave in ELoanUsage)) {
    return;
  }

  const stateLoanUsage = store.getState().borrowerReducer.loan.loanUsage;

  if (stateLoanUsage !== loanUsageToSave) {
    store.dispatch(setLoanUsage(loanUsageToSave as ELoanUsage));
  }
}
