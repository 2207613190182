import { Input } from '@/components/Input';
import {
    selectForseeableChangesExplanation,
    setForseeableChangesExplanation,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'ForseeableChangesExplanation';

export function ForseeableChangesExplanation({
    baseId,
    showErrors,
}: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectForseeableChangesExplanation);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        if (typeof value === 'boolean') return;
        if (value === currentState) return;
        if (value?.length > 180) return;
        dispatch(setForseeableChangesExplanation(value));
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Explanation'
                placeholder='eg, I am expecting a baby'
                showErrors={showErrors && !currentState}
                onChange={handleChange}
                id={`${baseId}-input-forseeableChangesExplanation`}
                value={currentState?.toLocaleString() ?? ''}
                tooltipContent='Please provide an explanation for any forseeable changes in your circumstances that may affect your ability to repay your loan.'
            />
        </div>
    );
}
