import { setHideSellerScreen } from '@/context/display';
import { store } from '@/context/store';

export function setInitialHideSellerScreen(
  partnerHideSellerScreen: boolean
): void {
  const stateHideSellerScreen =
    store.getState().displayReducer.hideSellerScreen;

  if (stateHideSellerScreen !== partnerHideSellerScreen) {
    store.dispatch(setHideSellerScreen(partnerHideSellerScreen));
  }
}
