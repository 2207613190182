// eslint-disable-next-line simple-import-sort/imports
import { postUrl } from '@/apiCalls/fetch';
import { IBillingLog } from './getBillingLogTypes';
import { IVehicleByPlateType } from './getVehicleByPlateTypes';

export async function getVehicleByPlate(
    plate: string | undefined,
    state: string | undefined,
    partner: string,
    billingData: IBillingLog
): Promise<IVehicleByPlateType> {
    const data = { billingData };
    const url = `/api/asset/select/${partner}/plate/${state}/${plate}`;
    const response = await postUrl<IVehicleByPlateType>(url, data);
    return response;
}
