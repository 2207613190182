import { PayloadAction } from '@reduxjs/toolkit';

export const otherAssetsReducers = {
  setPlantandEquipment: (
    state,
    action: PayloadAction<number | undefined>
  ): void => {
    state.otherAssets.plantandEquipment = action.payload;
  },
  setHomeandContents: (
    state,
    action: PayloadAction<number | undefined>
  ): void => {
    state.otherAssets.homeandContents = action.payload;
  },
  setSavings: (state, action: PayloadAction<number | undefined>): void => {
    state.otherAssets.savings = action.payload;
  },
  setSuperandShares: (
    state,
    action: PayloadAction<number | undefined>
  ): void => {
    state.otherAssets.superandShares = action.payload;
  },
  setOther: (state, action: PayloadAction<number | undefined>): void => {
    state.otherAssets.other = action.payload;
  },
};
