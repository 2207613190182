export function getDateYearsBack(year: number): Date {
  // Current date
  const today = new Date();

  // Calculate the year two years ago
  const previousYear = today.getFullYear() - year;

  // Create a new Date object for two years ago
  const previousDate = new Date(
    previousYear,
    today.getMonth(),
    today.getDate()
  );
  return previousDate;
}
