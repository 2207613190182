import { DisplayButtons } from '@/components/DisplayButtons';
import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';

interface IIndicativelyApprovedButtonsProps {
    baseId?: string;
}

export function IndicativelyApprovedButtons({
    baseId,
}: IIndicativelyApprovedButtonsProps): JSX.Element {
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleBackClick(): void {
        dispatch(setDisplay(EDisplays.OCR_SUMMARY));
    }

    function handleNextClick(): void {
        dispatch(setModal(EModals.POSTAPPROVED_PERMISSION_MODAL));
    }

    return (
        <DisplayButtons
            idLeftButton={`${baseId}-button-back`}
            idRightButton={`${baseId}-button-next`}
            rightClick={handleNextClick}
            leftClick={handleBackClick}
        />
    );
}
