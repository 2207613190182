// eslint-disable-next-line no-restricted-imports
import { getWaterfallById } from '@/apiCalls/getWaterfallById/getWaterfallById';
import { setWaterfallLenders } from '@/context/display';
import { store } from '@/context/store';
import { EMatchingEngineLenders } from '@/partners/matchingEngineEnums';

export async function setInitialWaterfallLenders(
    partnerWaterfallLenders: {
        lenders: EMatchingEngineLenders[];
        showLenderDetails: boolean;
    }[],
    customWaterfallId: string | null,
    partnerMakeWaterfallIdOverwrite: { [key: string]: string } | undefined
): Promise<void> {
    let waterfallLenders = partnerWaterfallLenders;

    if (customWaterfallId) {
        const response = await getWaterfallById(customWaterfallId);

        if (response?.tiers) {
            waterfallLenders = response.tiers;
        }
    }

    const currentWaterfallLenders =
        store.getState().displayReducer.waterfallLenders;
    if (waterfallLenders !== currentWaterfallLenders) {
        store.dispatch(setWaterfallLenders(waterfallLenders));
    }
}
