import moment from 'moment';

export function getStringFromState(
  text: string | null | undefined | number | Date,
  optional?: boolean
): string {
  if (optional && (text === null || text === undefined || text === '')) {
    return '';
  }

  if (text === null || text === undefined || text === '') {
    return '___';
  }

  if (text instanceof Date) {
    const date = moment(text);
    return date.format('D/M/YYYY');
  }

  return text.toString();
}
