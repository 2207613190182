import React from 'react';

import { Button, EButtonType } from '@/components/Button';
import { setAgreedToTestDriveAgreement } from '@/context/borrower';
import { EDisplays, setDisplay } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';

interface ITestDriveAgreementButton {
    baseId: string;
    disabled: boolean;
}

export function TestDriveAgreementButton({
    baseId,
    disabled,
}: ITestDriveAgreementButton): JSX.Element {
    // ***** Redux *****
    const dispatch = useAppDispatch();

    // ***** Handlers *****
    function handleClick(): void {
        if (disabled) return;
        dispatch(setAgreedToTestDriveAgreement(true));
        dispatch(setDisplay(EDisplays.TEST_DRIVE_READY));
    }

    return (
        <Button
            id={`${baseId}-button`}
            type={
                disabled
                    ? EButtonType.LONGER_ROUNDED_DISABLED
                    : EButtonType.PRIMARY
            }
            onClick={handleClick}
        >
            I Accept
        </Button>
    );
}
