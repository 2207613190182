import { EVehicleAssetType } from '@/context/borrower';
import { store } from '@/context/store';

export function userFacingAssetTitle(): string {
    const assetType = store.getState().borrowerReducer.vehicle.assetType;
    switch (assetType) {
        case EVehicleAssetType.BOAT:
        case EVehicleAssetType.BOATS:
            return 'Asset';

        case EVehicleAssetType.PRIME_MOVER:
            return 'Prime Mover';

        case EVehicleAssetType.JET_SKIES:
            return 'Jet Skis';

        case EVehicleAssetType.MOTORCYCLE:
        case EVehicleAssetType.MOTORBIKES_REG:
        case EVehicleAssetType.MOTORBIKES_UNREG:
            return 'Motorbike';

        case EVehicleAssetType.OTHER:
            return 'Asset';

        case EVehicleAssetType.COMMERCIAL_VEHICLES_LESS_THEN_4_5_TONNES:
        case EVehicleAssetType.TRUCK:
        case EVehicleAssetType.TRAILERS:
        default:
            return 'Vehicle';
    }
}
