export interface CampaignVehicle {
    id: number;
    nvic: string;
    displayName: string;
}

export const yamahaCampaignVehicles: CampaignVehicle[] = [
    {
        id: 1,
        nvic: '03OY24',
        displayName: 'WR450F',
    },
    {
        id: 2,
        nvic: '03OW24',
        displayName: 'WR250F',
    },
];
