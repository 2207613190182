import {
  setInitialSellerAbn,
  setInitialSellerAccountNumber,
  setInitialSellerEmail,
  setInitialSellerLeadEmail,
  setInitialSellerName,
  setInitialSellerPhone,
} from './helpers';
import { ISetInitialSeller } from './ISetInitialSeller';

export function setInitialSeller({
  partnerName,
  paramaterPartnerName,
  partnerAccountNumber,
  parameterAccountNumber,
  partnerAbn,
  parameterAbn,
  partnerEmail,
  parameterEmail,
  partnerPhone,
  parameterPhone,
  partnerLeadEmail,
  parameterLeadEmail,
}: ISetInitialSeller): void {
  setInitialSellerName(paramaterPartnerName, partnerName);
  setInitialSellerAccountNumber(parameterAccountNumber, partnerAccountNumber);
  setInitialSellerAbn(parameterAbn, partnerAbn);
  setInitialSellerEmail(parameterEmail, partnerEmail);
  setInitialSellerPhone(parameterPhone, partnerPhone);
  setInitialSellerLeadEmail(parameterLeadEmail, partnerLeadEmail);
}
