/* eslint-disable no-restricted-imports */

import { Button, EButtonType } from '@/components/Button';
import { setIsVehicleRegistrationKnown } from '@/context/borrower';
import {
    EDisplays,
    setDisplay,
    setVehicleManualEntry,
} from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';
import { BuildYear } from '@/UserInput/BuildYear';
import { VehicleMake } from '@/UserInput/VehicleMake';
import { VehicleModel } from '@/UserInput/VehicleModel';
import { VehicleVariant } from '@/UserInput/VehicleVariant';

import { DisplayGlassOptions } from '../DisplayVehicleResults/DisplayGlassOptions/DisplayGlassOptions';

export const testId = 'VehicleDetailsRegoUnKnown';

export interface IVehicleDetailsRegoUnknownProps {
    showErrors: boolean;
}

export function VehicleDetailsRegoUnKnown({
    showErrors,
}: IVehicleDetailsRegoUnknownProps): JSX.Element {
    // ***** Redux State *****
    const baseId = 'screen-vehicleDetailsRegoUnknown';
    const dispatch = useAppDispatch();

    function handleRegoClick(): void {
        dispatch(setIsVehicleRegistrationKnown(true));
    }

    function handleManualClick(): void {
        dispatch(setVehicleManualEntry(true));
        dispatch(setDisplay(EDisplays.MANUAL_VEHICLE));
    }

    return (
        <div>
            <BuildYear baseId={baseId} showErrors={showErrors} />
            <VehicleMake baseId={baseId} showErrors={showErrors} />
            <VehicleModel baseId={baseId} showErrors={showErrors} />
            <VehicleVariant baseId={baseId} showErrors={showErrors} />

            <DisplayGlassOptions />
            <p className='text-lg px-4 text-center pb-4'>
                Can&apos;t find the vehicle?
            </p>
            <div className='flex space-x-4 pb-4'>
                <Button
                    type={EButtonType.SECONDARY_OUTLINE}
                    onClick={handleRegoClick}
                >
                    Search by Rego
                </Button>
                <Button
                    type={EButtonType.SECONDARY_OUTLINE}
                    onClick={handleManualClick}
                >
                    Enter Manually
                </Button>
            </div>
        </div>
    );
}
