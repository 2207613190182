import { getUrl } from '@/apiCalls/fetch';
import { EVehicleAssetType } from '@/context/borrower';
import { getGlassAssetType } from '@/utils/glassGuideHelpers';

import { IVariant } from './getVariantTypes';

export async function getVariant(
    partner: string,
    year: number | undefined,
    make: string | undefined,
    model: string | undefined,
    assetType?: EVehicleAssetType
): Promise<string[]> {
    const url = `/api/asset/select/${partner}/${getGlassAssetType(assetType)}/${year}/${make}/${model}`;
    const response = await getUrl<IVariant>(url);
    return response.data;
}
