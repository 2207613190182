import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button } from '@/components/Button';
import { EDisplays, setDisplay } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';

export function TruckstopLandingPageButton(): JSX.Element {
    const dispatch = useAppDispatch();

    async function handleClick(): Promise<void> {
        createOrUpdateBorrower();
        dispatch(setDisplay(EDisplays.QUICK_CALCULATOR));
    }

    return (
        <Button
            onClick={handleClick}
            title='Get Started'
            id='screen-truckstopLandingPage-button-getStarted'
        />
    );
}
