import { Button, EButtonType } from '../Button';

export interface IProps {
    onClick: () => void;
    heading: string;
    id: string;
    text: string | { __html: string };
    showErrors: boolean;
}

export const testId = 'SummarySection';

export function SummarySection({
    onClick,
    heading,
    id,
    text,
    showErrors,
}: IProps): JSX.Element {
    const solar = false; // todo: delete
    return (
        <div
            data-testid={testId}
            className={`border  bg-white ${
                solar && 'shadow-lg'
            } p-4 rounded-lg my-1 ${showErrors && 'bg-error'}`}
        >
            <div className='_flex justify-between items-center'>
                <h3
                    className={`w-full font-bold ${
                        solar ? 'text-lg' : 'text-sm'
                    }`}
                >
                    {heading}
                </h3>
                <div>
                    <Button
                        type={
                            !solar
                                ? EButtonType.EDIT_BUTTON_OCR_SUMMARY_SECONDARY_OUTLINE
                                : EButtonType.PRIMARY
                        }
                        onClick={onClick}
                        id={id}
                    >
                        Edit
                    </Button>
                </div>
            </div>
            <p
                className={`pt-2 ${!solar && ' text-textBody text-[13px]'}`}
                dangerouslySetInnerHTML={
                    typeof text === 'string'
                        ? { __html: text }
                        : { __html: text.__html }
                }
            ></p>
        </div>
    );
}
