import { store } from '@/context/store';

import { getUrl } from '../fetch';

import { ScanwareWithId } from './scanwareTypes';

export async function getScanwareById(): Promise<ScanwareWithId | null> {
    try {
        const { scanwareQrId } = store.getState().displayReducer;
        const url = `/api/scanware/id/${scanwareQrId}`;

        const res = await getUrl<any>(url);
        return res.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}
