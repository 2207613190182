import { setTermsAndConditionsLink } from '@/context/display';
import { store } from '@/context/store';

export function setInitialSellerTermsLink(partnerTermsLink: string): void {
  const stateTermsAndConditionsLink =
    store.getState().displayReducer.termsAndConditionsLink;

  if (partnerTermsLink !== stateTermsAndConditionsLink) {
    store.dispatch(setTermsAndConditionsLink(partnerTermsLink));
  }
}
