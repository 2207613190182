import { current, PayloadAction } from '@reduxjs/toolkit';

import { ISupportingDoc } from '../types';

export const supportingDocumentsReducers = {
    setSupportingDocs: (
        state,
        action: PayloadAction<{ code: string; document: ISupportingDoc[] }>
    ): void => {
        if (Array.isArray(action.payload.document)) {
            state.supportingDocs[action.payload.code] = action.payload.document;
        } else {
            state.supportingDocs[action.payload.code] = [
                action.payload.document,
            ];
        }
    },
    deleteSupportingDoc: (state, action: PayloadAction<string>): void => {
        const documentId = action.payload;
        const currentSupportingDocs: { [code: string]: ISupportingDoc[] } =
            current(state.supportingDocs);

        Object.entries(currentSupportingDocs).forEach(([code, documents]) => {
            state.supportingDocs[code] = documents.filter(
                (document) => document.id !== documentId
            );
        });
    },
};
