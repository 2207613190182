import { useEffect, useState } from 'react';

interface IAnimationHelper {
    array: string[];
}

export function AnimationHelper({ array }: IAnimationHelper): JSX.Element {
    const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (currentItemIndex < array.length - 1) {
                setCurrentItemIndex(currentItemIndex + 1);
            }
        }, 2000);
        return () => clearTimeout(timeoutId);
    }, [currentItemIndex, array.length]);

    return (
        <div className='w-full h-full flex-center relative flex-col'>
            <div>
                {array.slice(0, currentItemIndex + 1).map((item, index) => (
                    <div key={index}>
                        <div className='_flex justify-start mb-10'>
                            <div
                                className={`_flex items-center space-x-2 ${
                                    index < currentItemIndex
                                        ? 'text-primary'
                                        : 'text-black'
                                }`}
                            >
                                {index < currentItemIndex ? (
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        className='h-5 w-5 text-white border border-primary rounded-full bg-primary'
                                        viewBox='0 0 20 20'
                                        fill='currentColor'
                                    >
                                        <path
                                            fillRule='evenodd'
                                            d='M10 18a8 8 0 100-16 8 8 0 000 16zm4.293-9.707a1 1 0 00-1.414-1.414l-3.5 3.5-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                                            clipRule='evenodd'
                                        />
                                    </svg>
                                ) : (
                                    <div className='h-5 w-5 rounded-full bg-white border border-primary'></div>
                                )}
                            </div>
                            <p
                                className={`text-primary ml-4 ${
                                    index < currentItemIndex ? 'font-bold' : ''
                                }    `}
                                key={index}
                            >
                                {item}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
