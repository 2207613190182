import { RadioGroup } from '@/components/RadioGroup';
import {
    ELoanUsage,
    EVehicleAssetType,
    selectLoanUsage,
    selectVehicleAssetType,
    setVehicleAssetType,
} from '@/context/borrower';
import { selectAvailableVehicleAssetTypes } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'VehicleAssetType';

export function VehicleAssetType({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectVehicleAssetType);
    const availableVehicleAssetTypes = useAppSelector(
        selectAvailableVehicleAssetTypes
    );
    const loanUsage = useAppSelector(selectLoanUsage);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean | undefined): void {
        dispatch(setVehicleAssetType(value as EVehicleAssetType));
        // Note: This assumes that this occurs every time, which does atm but may not in the future
        if (
            value === EVehicleAssetType.OTHER &&
            loanUsage === ELoanUsage.BUSINESS
        ) {
            dispatch(setVehicleAssetType(EVehicleAssetType.WORKING_CAPITAL));
        }
    }

    const options = availableVehicleAssetTypes.map(
        ({ tooltip, label, type }) => {
            const labelId = label
                .toLowerCase()
                .replace(/[^a-z0-9\s]/, '')
                .replace(/ ([a-z0-9])/g, (_, match) => match.toUpperCase()); // convert label to camel case

            return {
                label,
                value: type,
                id: `${baseId}-button-vehicleAssetType-${labelId}`,
                tooltip,
            };
        }
    );

    if (options.length <= 1) {
        return <></>;
    }

    function getTooltipContent(): string {
        let tooltipContent = '';
        options.forEach((option, index) => {
            tooltipContent += `${index !== 0 ? '\n' : ''}${option.tooltip}`;
        });

        return tooltipContent;
    }

    return (
        <div data-testid={testId}>
            <RadioGroup
                title='Vehicle Type'
                showErrors={showErrors}
                onChange={handleChange}
                options={options}
                value={currentState}
                tooltipContent={getTooltipContent()}
            />
        </div>
    );
}
