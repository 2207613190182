import { displaySlice } from '../displaySlice';

export const { setShowWelcomeScreen } = displaySlice.actions;
export const { setIsWhiteLabel } = displaySlice.actions;
export const { setLogo } = displaySlice.actions;
export const { setInitialLoad } = displaySlice.actions;
export const { setShowOcrDisplay } = displaySlice.actions;
export const { setIsLoading } = displaySlice.actions;
export const { setAgreedToTermsAndConditions } = displaySlice.actions;
export const { setTermsAndConditionsLink } = displaySlice.actions;
export const { setHideSellerScreen } = displaySlice.actions;
export const { setHideVehicleScreen } = displaySlice.actions;
export const { setShowTotalAmountPayable } = displaySlice.actions;
export const { setPartnerName } = displaySlice.actions;
export const { setSignupOptions } = displaySlice.actions;
export const { setPartnerEmail } = displaySlice.actions;
export const { setPartnerNumber } = displaySlice.actions;
export const { setWaterfallLenders } = displaySlice.actions;
export const { setIsAssetKnownViaParams } = displaySlice.actions;
export const { setPartner } = displaySlice.actions;
export const { setAskTermsAndConditions } = displaySlice.actions;
export const { setLenderSorting } = displaySlice.actions;
export const { setSendLeadEmail } = displaySlice.actions;
export const { setAvailableVehicleAssetTypes } = displaySlice.actions;
export const { setIsDemoMode } = displaySlice.actions;
