import { useEffect, useState } from 'react';

import { getVariant } from '@/apiCalls/glassGuideVehicleLookup';
import { Combobox, IComboboxOption } from '@/components/Combobox';
import {
    selectVehicleAssetType,
    selectVehicleBuildYear,
    selectVehicleModel,
    selectVehicleVariant,
    setVehicleVariant,
} from '@/context/borrower';
import {
    selectVehicleMakeCodeForApiCall,
    selectVehicleModelCodeForApiCall,
    selectVehicleVariantApiResults,
    setVehicleVariantApiResults,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    showErrors: boolean;
    baseId: string;
}

export const testId = 'VehicleVariant';

export function VehicleVariant({ showErrors, baseId }: IProps): JSX.Element {
    // * Redux State
    const variant = useAppSelector(selectVehicleVariant);
    const dispatch = useAppDispatch();
    const vehicleVariantApiResults = useAppSelector(
        selectVehicleVariantApiResults
    );
    const modelCodeForApiCall = useAppSelector(
        selectVehicleModelCodeForApiCall
    );
    const buildYear = useAppSelector(selectVehicleBuildYear);
    const makeCodeForApiCall = useAppSelector(selectVehicleMakeCodeForApiCall);
    const model = useAppSelector(selectVehicleModel);
    const partner = useAppSelector((state) => state.displayReducer.partner.id);
    const assetType = useAppSelector(selectVehicleAssetType);

    // * Local State
    const [query, setQuery] = useState<undefined | string>(variant ?? ''); // Keep track of search
    const [loading, setLoading] = useState(false);

    // *****Functions*****
    // Handle the onChange event for the input.
    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        setQuery(value);
        if (typeof value === 'boolean') return;
        if (value === variant) return;
        if (value?.length > 4) return;
    }

    // When the user selects an option
    const handleSelect = (option: IComboboxOption): void => {
        if (option.value === variant) return;
        if (!option.value) {
            setQuery('---');
            dispatch(setVehicleVariant('---'));
        }
        dispatch(setVehicleVariant(option.value));
    };

    useEffect(() => {
        if (model) {
            if (variant) return;
            // make API call for vehicle variant options
            const apiCall = async (): Promise<void> => {
                setLoading(true);
                await getVariant(
                    partner,
                    buildYear,
                    makeCodeForApiCall,
                    modelCodeForApiCall,
                    assetType
                ).then((response) => {
                    const castedOptionsForVariant = response.map(
                        (value, index) => ({
                            id: index + 1,
                            value: value,
                        })
                    );
                    dispatch(
                        setVehicleVariantApiResults(castedOptionsForVariant)
                    );
                    setLoading(false);
                });
            };
            apiCall();
        }
    }, [model]);

    useEffect(() => {
        setQuery(variant);
    }, [variant]);
    return (
        <div data-testid={testId}>
            <Combobox
                options={vehicleVariantApiResults ?? []}
                placeholder='Select variant'
                heading='Vehicle Variant Options'
                onChange={handleChange}
                query={query ?? ''}
                onSelect={handleSelect}
                showErrors={showErrors && !variant}
                loading={loading}
                baseId={baseId}
                tooltipContent='Check this looks correct but if not, search using the Business Name again.'
            />
        </div>
    );
}
