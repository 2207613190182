import {
    ECondition,
    ELoanType,
    ELoanUsage,
    EMoneyType,
    ESellerType,
    EUserFlow,
    EVehicleAssetType,
} from '@/context/borrower';
import { ECampaign } from '@/context/borrower';

import { ELenderSorting, EMatchingEngineLenders } from './matchingEngineEnums';

export enum EPartners {
    EAGERS_1 = 'EAGERS_1',
    YOU_X_DEMO_YAMAHA_PARTNER = 'YOU_X_DEMO_YAMAHA_PARTNER',
    EAGERS_2 = 'EAGERS_2',
    EAGERS_3 = 'EAGERS_3',
    YOUX_DEMO_PARTNER = 'YOUX_DEMO_PARTNER',
    YOU_X_DEMO_SKIP_EXPENSE_PARTNER = 'YOU_X_DEMO_SKIP_EXPENSE_PARTNER',
    FUEL_ASSET_FINANCE = 'FUEL_ASSET_FINANCE',
    LOTUS = 'LOTUS',
    WORTHINGTON_MOTORCYCLE_WEBSITE = 'WORTHINGTON_MOTORCYCLE_WEBSITE',
    WORTHINGTON_MOTORCYCLE_SCANWARE = 'WORTHINGTON_MOTORCYCLE_SCANWARE',
    // ***** MORGAN WACKER *****
    MORGAN_WACKER_BMW_WEBSITE = 'MORGAN_WACKER_BMW_WEBSITE',
    MORGAN_WACKER_HD_NEWSTEAD_WEBSITE = 'MORGAN_WACKER_HD_NEWSTEAD_WEBSITE',
    MORGAN_WACKER_HD_MELBOURNE_WEBSITE = 'MORGAN_WACKER_HD_MELBOURNE_WEBSITE',
    MORGAN_WACKER_HD_SYDNEY_WEBSITE = 'MORGAN_WACKER_HD_MELBOURNE_WEBSITE',
    MORGAN_WACKER_HD_SYDNEY_SCANWARE = 'MORGAN_WACKER_HD_SYDNEY_SCANWARE',

    MORGAN_WACKER_BMW_SCANWARE = 'MORGAN_WACKER_BMW_SCANWARE',
    MORGAN_WACKER_BMW_BRISBANE_SCANWARE = 'MORGAN_WACKER_BMW_BRISBANE_SCANWARE',
    MORGAN_WACKER_HD_NEWSTEAD_SCANWARE = 'MORGAN_WACKER_HD_NEWSTEAD_SCANWARE',
    MORGAN_WACKER_HD_MELBOURNE_SCANWARE = 'MORGAN_WACKER_HD_MELBOURNE_SCANWARE',

    TEAM_MOTO_WEBSITE = 'TEAM_MOTO_WEBSITE',
    TEAM_MOTO_SCANWARE = 'TEAM_MOTO_SCANWARE',
    TEAM_MOTO_YAMAHA_CAIRNS_SCANWARE = 'TEAM_MOTO_YAMAHA_CAIRNS_SCANWARE',
    TEAM_MOTO_WIDE_BAY_SCANWARE = 'TEAM_MOTO_WIDE_BAY_SCANWARE',
    TEAM_MOTO_BOWEN_HILLS_SCANWARE = 'TEAM_MOTO_BOWEN_HILLS_SCANWARE',
    TEAM_MOTO_CANBERRA_SCANWARE = 'TEAM_MOTO_CANBERRA_SCANWARE',
    TEAM_MOTO_EURO_SPRINGWOOD_SCANWARE = 'TEAM_MOTO_EURO_SPRINGWOOD_SCANWARE',
    TEAM_MOTO_YAMAHA_NORTHSIDE_SCANWARE = 'TEAM_MOTO_YAMAHA_NORTHSIDE_SCANWARE',
    TEAM_MOTO_YAMAHA_MOOROKA_SCANWARE = 'TEAM_MOTO_YAMAHA_MOOROKA_SCANWARE',
    TEAM_MOTO_KAWASAKI_NORTH_COAST_SCANWARE = 'TEAM_MOTO_KAWASAKI_NORTH_COAST_SCANWARE',
    TEAM_MOTO_YAMAHA_GOLD_COAST_SCANWARE = 'TEAM_MOTO_YAMAHA_GOLD_COAST_SCANWARE',
    TEAM_MOTO_YAMAHA_BLACKTOWN_SCANWARE = 'TEAM_MOTO_YAMAHA_BLACKTOWN_SCANWARE',
    TEAM_MOTO_YAMAHA_FRANKSTON_SCANWARE = 'TEAM_MOTO_YAMAHA_FRANKSTON_SCANWARE',
    TEAM_MOTO_YAMAHA_SUNSHINE_COAST_SCANWARE = 'TEAM_MOTO_YAMAHA_SUNSHINE_COAST_SCANWARE',
    TEAM_MOTO_HONDA_GOLD_COAST_SCANWARE = 'TEAM_MOTO_HONDA_GOLD_COAST_SCANWARE',
    TEAM_MOTO_HONDA_SPRINGWOOD_SCANWARE = 'TEAM_MOTO_HONDA_SPRINGWOOD_SCANWARE',
    TEAM_MOTO_HONDA_EPPING_SCANWARE = 'TEAM_MOTO_HONDA_EPPING_SCANWARE',
    TEAM_MOTO_SUZUKI_VIRGINIA_SCANWARE = 'TEAM_MOTO_SUZUKI_VIRGINIA_SCANWARE',
    TEAM_MOTO_TOWNSVILLE_SCANWARE = 'TEAM_MOTO_TOWNSVILLE_SCANWARE',
    TEAM_MOTO_KEILOR_PARK_SCANWARE = 'TEAM_MOTO_KEILOR_PARK_SCANWARE',
    CF_MOTO_SPRINGWOOD_SCANWARE = 'CF_MOTO_SPRINGWOOD_SCANWARE',
    TEAM_MOTO_DANDENONG_SCANWARE = 'CF_MOTO_DANDENONG_SCANWARE',
    TEAM_MOTO_PENRITH_SCANWARE = 'TEAM_MOTO_PENRITH_SCANWARE',
    TEAM_MOTO_CARINGBAH_SCANWARE = 'TEAM_MOTO_CARINGBAH_SCANWARE',
    TEAM_MOTO_CAMPBELLTOWN_SCANWARE = 'TEAM_MOTO_CAMPBELLTOWN_SCANWARE',
    TEAM_MOTO_AUBURN_SCANWARE = 'TEAM_MOTO_AUBURN_SCANWARE',

    // **** HARLEY-DAVIDSON ****
    SUNSHINE_COAST_HARLEY_DAVIDSON_WEBSITE = 'SUNSHINE_COAST_HARLEY_DAVIDSON_WEBSITE',
    SUNSHINE_COAST_HARLEY_DAVIDSON_SCANWARE = 'SUNSHINE_COAST_HARLEY_DAVIDSON_SCANWARE',
    GOLD_COAST_HARLEY_DAVIDSON_WEBSITE = 'GOLD_COAST_HARLEY_DAVIDSON_WEBSITE',
    GOLD_COAST_HARLEY_DAVIDSON_SCANWARE = 'GOLD_COAST_HARLEY_DAVIDSON_SCANWARE',
    CENTRAL_COAST_HARLEY_DAVIDSON_SCANWARE = 'CENTRAL_COAST_HARLEY_DAVIDSON_SCANWARE',
    CENTRAL_COAST_HARLEY_DAVIDSON_WEBSITE = 'CENTRAL_COAST_HARLEY_DAVIDSON_WEBSITE',
    CANBERRA_HARLEY_DAVIDSON_WEBSITE = 'CANBERRA_HARLEY_DAVIDSON_WEBSITE',
    CANBERRA_HARLEY_DAVIDSON_SCANWARE = 'CANBERRA_HARLEY_DAVIDSON_SCANWARE',
    SY_HARLEY_DAVIDSON_WEBSITE = 'SY_HARLEY_DAVIDSON_WEBSITE',
    SY_HARLEY_DAVIDSON_SCANWARE = 'SY_HARLEY_DAVIDSON_SCANWARE',

    ULTIMATE_MOTORBIKES_WEBSITE = 'ULTIMATE_MOTORBIKES_WEBSITE',
    ULTIMATE_SUZUKI_IPSWICH_SCANWARE = 'ULTIMATE_SUZUKI_IPSWICH_SCANWARE',
    ULTIMATE_YAMAHA_SPRINGWOOD_SCANWARE = 'ULTIMATE_YAMAHA_SPRINGWOOD_SCANWARE',
    ULTIMATE_POLARIS_SPRINGWOOD_SCANWARE = 'ULTIMATE_POLARIS_SPRINGWOOD_SCANWARE',
    ULTIMATE_MOTORBIKES_SCANWARE = 'ULTIMATE_MOTORBIKES_SCANWARE',
    ULTIMATE_KTM_GOLD_COAST = 'ULTIMATE_KTM_GOLD_COAST',
    ULTIMATE_KAWASAKI_GOLD_COAST_SCANWARE = 'ULTIMATE_KAWASAKI_GOLD_COAST_SCANWARE',

    DARWIN_MAZDA_WEBSITE = 'DARWIN_MAZDA_WEBSITE',
    COL_CRAWFORD_WEBSITE = 'COL_CRAWFORD_WEBSITE',
    COL_CRAWFORD_SC1 = 'COL_CRAWFORD_SC1',
    COL_CRAWFORD_SC2 = 'COL_CRAWFORD_SC2',
    COL_CRAWFORD_SC3 = 'COL_CRAWFORD_SC3',
    COL_CRAWFORD_SC4 = 'COL_CRAWFORD_SC4',
    SYDNEY_CAR_EXCHANGE_WEBSITE = 'SYDNEY_CAR_EXCHANGE_WEBSITE',
    HUNTER_BESPOKE_WEBSITE = 'HUNTER_BESPOKE_WEBSITE',
    BRADDON_AUTO_MART_WEBSITE = 'BRADDON_AUTO_MART_WEBSITE',
    HALYARD_MOTORS_WEBSITE = 'HALYARD_MOTORS_WEBSITE',
    JAGS_MELBOURNE_MOTOR_GROUP_WEBSITE = 'JAGS_MELBOURNE_MOTOR_GROUP_WEBSITE',
    WESTERN_MOTORS_CAR_WHOLESALERS_WEBSITE = 'WESTERN_MOTORS_CAR_WHOLESALERS_WEBSITE',
    CAR_MAX_MOTORS_WEBSITE = 'CAR_MAX_MOTORS_WEBSITE',
    RED_HOT_CAR_SALES_WEBSITE = 'RED_HOT_CAR_SALES_WEBSITE',
    PETER_NORBURY_MOTORS_WEBSITE = 'PETER_NORBURY_MOTORS_WEBSITE',
    BUY_NOW_CARS_WEBSITE = 'BUY_NOW_CARS_WEBSITE',
    WHEELS_ON_BOURKE_WEBSITE = 'WHEELS_ON_BOURKE_WEBSITE',
    NORTHERN_RIVER_USED_CARS_WEBSITE = 'NORTHERN_RIVER_USED_CARS_WEBSITE',
    WASHINGTON_PARTNERS_PRESTIGE_WEBSITE = 'WASHINGTON_PARTNERS_PRESTIGE_WEBSITE',
    MOTOR_POINT_WEBSITE = 'MOTOR_POINT_WEBSITE',
    JESMOND_LIGHT_COMMERCIALS_WEBSITE = 'JESMOND_LIGHT_COMMERCIALS_WEBSITE',
    MOTOR_STORE_WEBSITE = 'MOTOR_STORE_WEBSITE',
    SR_AUTO_TRADERS_WEBSITE = 'SR_AUTO_TRADERS_WEBSITE',
    LEN_VINE_AUTOMOBILES_WEBSITE = 'LEN_VINE_AUTOMOBILES_WEBSITE',
    LJ_AUTO_WHOLESALE_WEBSITE = 'LJ_AUTO_WHOLESALE_WEBSITE',
    MACLEAY_VALLEY_MOTORS_WEBSITE = 'MACLEAY_VALLEY_MOTORS_WEBSITE',
    CRYSTAL_CAR_CARE_WEBSITE = 'CRYSTAL_CAR_CARE_WEBSITE',
    YOUX_DEMO = 'YOUX_DEMO',
    YOUX_DEMO_CAMPAIGN = 'YOUX_DEMO_CAMPAIGN',
    DRIVE_DEMO = 'DRIVE_DEMO',
    CARS360 = 'CARS360',
    SHOPFORCARS = 'SHOPFORCARS',
    EASYCARS = 'EASYCARS',
    CARSALES = 'CARSALES',
    DEMO = 'DEMO',
    TRUCKSTOP = 'TRUCKSTOP',
    BEST_LOANS = 'BEST_LOANS',
    MYMOTO = 'MYMOTO',
    YCCARS = 'YCCARS',
    NONE = 'NONE',
    ALLBIDS = 'ALLBIDS',
    SCOTTS_GROUP = 'SCOTTS_GROUP',
    OC_AUTOMOTIVE = 'OC_AUTOMOTIVE',
    JC_AUTOTRADERS_PTY_LTD = 'JC_AUTOTRADERS_PTY_LTD',
    DOZO_CAR_SALES = 'DOZO_CAR_SALES',
    DEMO_PARTNER = 'DEMO_PARTNER',
    CARIZMA_MOTORS = 'CARIZMA_MOTORS',
    AUTO_HOUSE = 'AUTO_HOUSE',
    BEEPZ_AUTO_SOLUTIONS = 'BEEPZ_AUTO_SOLUTIONS',
    CARNET_WESTERN_SYDNEY = 'CARNET_WESTERN_SYDNEY',
    TONY_LAHOOD = 'TONY_LAHOOD',
    UNKNOWN = 'UNKNOWN',
    CHERY = 'CHERY',
    SOLAR = 'SOLAR',
    ROSTRON = 'ROSTRON',
    ROSTRON_AUTOMAX = 'ROSTRON_AUTOMAX',
    ROSTRON_CENTENARY = 'ROSTRON_CENTENARY',
    DRIVEON = 'DRIVEON',
    BOORANG_SOUTH_MORANG_CHERY_WEBSITE = 'BOORANG_SOUTH_MORANG_CHERY_WEBSITE',
    BOORANG_SOUTH_MORANG_CHERY_SCANWARE = 'BOORANG_SOUTH_MORANG_CHERY_SCANWARE',
    BOORANG_SOUTH_LANDS_CHERY_WEBSITE = 'BOORANG_SOUTH_LANDS_CHERY_WEBSITE',
    BOORANG_SOUTH_LANDS_CHERY_SCANWARE = 'BOORANG_SOUTH_LANDS_CHERY_SCANWARE',
    BOORANG_DANDENONG_CHERY_WEBSITE = 'BOORANG_DANDENONG_CHERY_WEBSITE',
    BOORANG_DANDENONG_CHERY_SCANWARE = 'BOORANG_DANDENONG_CHERY_SCANWARE',
    BOORANG_CRANBOURNE_CHERY_WEBSITE = 'BOORANG_CRANBOURNE_CHERY_WEBSITE',
    BOORANG_CRANBOURNE_CHERY_SCANWARE = 'BOORANG_CRANBOURNE_CHERY_SCANWARE',
    PATRICK_AUTO_GROUP_CHERY_WEBSITE = 'PATRICK_AUTO_GROUP_CHERY_WEBSITE',
    PATRICK_AUTO_GROUP_CHERY_SCANWARE = 'PATRICK_AUTO_GROUP_CHERY_SCANWARE',
    PATRICK_AUTO_GROUP_WEBSITE = 'PATRICK_AUTO_GROUP_WEBSITE',
    PATRICK_AUTO_GROUP_SCANWARE = 'PATRICK_AUTO_GROUP_SCANWARE',
    FORMULA_MOTOR_CHERY_WEBSITE = 'FORMULA_MOTOR_CHERY_WEBSITE',
    FORMULA_MOTOR_SCANWARE = 'FORMULA_MOTOR_SCANWARE',
    FORMULA_MOTOR_CHERY_SCANWARE = 'FORMULA_MOTOR_CHERY_SCANWARE',
    LENNOCK_MOTOR_CHERY_WEBSITE = 'LENNOCK_MOTOR_CHERY_WEBSITE',
    LENNOCK_MOTOR_CHERY_SCANWARE = 'LENNOCK_MOTOR_CHERY_SCANWARE',
    LENNOCK_MOTOR_WEBSITE = 'LENNOCK_MOTOR_WEBSITE',
    LENNOCK_MOTOR_SCANWARE = 'LENNOCK_MOTOR_SCANWARE',
    JOHN_HUGHES_WARANGA_CHERY_WEBSITE = 'JOHN_HUGHES_WARANGA_CHERY_WEBSITE',
    JOHN_HUGHES_WARANGA_CHERY_SCANWARE = 'JOHN_HUGHES_WARANGA_CHERY_SCANWARE',
    JOHN_HUGHES_WARANGA_WEBSITE = 'JOHN_HUGHES_WARANGA_WEBSITE',
    JOHN_HUGHES_WARANGA_SCANWARE = 'JOHN_HUGHES_WARANGA_SCANWARE',
    JUST_HONK_ERINA = 'JUST_HONK_ERINA',
    JUST_HONK_LAVERTON = 'JUST_HONK_LAVERTON',
    LENDA = 'LENDA',
    REX_GORELL_WEBSITE = 'REX_GORELL_WEBSITE',
    REX_GORELL_SCANWARE = 'REX_GORELL_SCANWARE',
    REX_GORELL_CAMPAIGN = 'REX_GORELL_CAMPAIGN',
    REX_GORELL_CAMPAIGN_2 = 'REX_GORELL_CAMPAIGN_2',
    REX_GORELL_WEBSITE_STOCK = 'REX_GORELL_WEBSITE_STOCK',
    PATRICK_AUTO_TRADERS_WEBSITE = 'PATRICK_AUTO_TRADERS_WEBSITE',
    PATRICK_AUTO_TRADERS_SCANWARE = 'PATRICK_AUTO_TRADERS_SCANWARE',
    GERHARDS_QUALITY_CARS = 'GERHARDS_QUALITY_CARS',
    CRITCH_AUTOMOTIVE_GROUP = 'CRITCH_AUTOMOTIVE_GROUP',
    WASHINGTON_CARS = 'WASHINGTON_CARS',
    PACIFIC_BLUE_CARSALES = 'PACIFIC_BLUE_CARSALES',
    MG_PARRAMATTA_WEBSITE = 'MG_PARRAMATTA_WEBSITE',
    LDV_PARRAMATTA_WEBSITE = 'LDV_PARRAMATTA_WEBSITE',
    LDV_FIVE_DOCK_WEBSITE = 'LDV_FIVE_DOCK_WEBSITE',
    LDV_FIVE_DOCK_SCANWARE = 'LDV_FIVE_DOCK_SCANWARE',
    LDV_CHATSWOOD_WEBSITE = 'LDV_CHATSWOOD_WEBSITE',
    LDV_CHATSWOOD_SCANWARE = 'LDV_CHATSWOOD_SCANWARE',
    AMH_WEBSITE = 'AMH_WEBSITE',
    AMH_TOYOTA_WEBSITE = 'AMH_TOYOTA_WEBSITE',
    AMH_TOYOTA_SCANWARE = 'AMH_TOYOTA_SCANWARE',
    AMH_SCANWARE = 'AMH_SCANWARE',
    AMH_CARAVAN = 'AMH_CARAVAN',
    COASTAL_AUTO_GROUP_WEBSITE = 'COASTAL_AUTO_GROUP_WEBSITE',
    COASTAL_AUTO_GROUP_SCANWARE = 'COASTAL_AUTO_GROUP_SCANWARE',
    COASTAL_AUTO_GROUP_TOYOTA_WEBSITE = 'COASTAL_AUTO_GROUP_TOYOTA_WEBSITE',
    COASTAL_AUTO_GROUP_TOYOTA_SCANWARE = 'COASTAL_AUTO_GROUP_TOYOTA_SCANWARE',
    KINGMASTER_AUTOS_WEBSITE = 'KINGMASTER_AUTOS_WEBSITE',
    AUTOSELLERS_WEBSITE = 'AUTOSELLERS_WEBSITE',
    PERTH_CARS_AND_COMMERCIALS_WEBSITE = 'PERTH_CARS_AND_COMMERCIALS_WEBSITE',
    VON_BIBRA_FINANCE = 'VON_BIBRA_FINANCE',
    VON_BIBRA_STOCK = 'VON_BIBRA_STOCK',
    TRADEME = 'TRADEME',

    // IMPORTANT!
    // If you want to do a new test, add a new test id. DO NOT re-use an existing one. Each one serves a specific purpose!
    TEST_1 = 'TEST_1',
    TEST_2 = 'TEST_2',
    TEST_3 = 'TEST_3',
    TEST_4 = 'TEST_4',
    TEST_5 = 'TEST_5', // Free to use, not currently required
    TEST_MOTORBIKE = 'TEST_MOTORBIKE',
    NO_LENDER = 'NO_LENDER',
}

export interface IVehicleAssetTypeButton {
    label: string;
    type: EVehicleAssetType;
    tooltip?: string;
}

export interface IPartnerParams {
    // both
    dealerAbn: string | null;
    dealerEmail: string | null;
    dealerPhone: string | null;
    leadEmail: string | null;
    loanUsage: ELoanUsage;
    loanTerm: number;
    assetType: EVehicleAssetType;
    type?: ESellerType;
    initialLoanType: ELoanType;
    quickQuotePrivacyPolicy: string;
    initialRegistrationKnown?: boolean;

    // parameters
    id: EPartners;
    url: string | null;
    dealerName: string | null;
    dealerAccountNumber: string | null;
    vehicleStockNumber: string | null;
    price: number | null;
    nvic: string | null;
    vin: string | null;
    vehicleKilometers: string | null;
    welcomeScreen: boolean;
    partnerOverwriteId?: string;

    // partner
    primaryColor: string | null;
    secondaryColor: string | null;
    tertiaryColor: string | null;
    quaternaryColor: string | null;
    termsAndConditionsLink: string;
    logo: string | null;
    whiteLabel: boolean;
    hideSellerScreen: boolean;
    hideSelectVehicleScreen: boolean;
    showTotalAmountPayable: boolean;
    sendLeadEmail: boolean;
    waterfallLenders: Array<{
        lenders: EMatchingEngineLenders[];
        showLenderDetails: boolean;
    }>;
    lenderSorting?: ELenderSorting;
    financeTypeCommission: {
        consumer: {
            value: number;
            type: EMoneyType;
        };
        commercial: {
            value: number;
            type: EMoneyType;
        };
    };
    availableVehicleAssetTypes: IVehicleAssetTypeButton[];
    requireGlassGuide: boolean;
    illionCodes?: {
        threeMonths: string;
        sixMonths: string;
        twelveMonths: string;
    };
    defaultVehicleCondition?: ECondition;
    defaultVehicleAssetType: EVehicleAssetType;
    defaultVehicleAssetCategory: string;
    userFlow: EUserFlow;
    splitTestUserFlow?: EUserFlow;
    splitTest?: boolean;
    locationName?: string;
    locationAddress?: string;
    locationAddress2?: string;
    makeWaterfallIdOverwrite?: { [make: string]: string };
    sendToDealerSolutions?: boolean;
    showQuickQuote: boolean;
    earlyReferredScreen: boolean;
    isDemoMode: boolean;
    manageOrganisationId?: string;
    signupOptions?: ISignupOptions;
    skipExpensesScreen?: boolean;
    campaign?: ECampaign;
}

export interface ISignupOptions {
    zipcode: boolean;
}
