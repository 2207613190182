import { setPartnerEmail } from '@/context/display';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialDisplaySellerEmail(
  parameterEmail: IParameter,
  partnerEmail: string | null
): void {
  const displayStateEmail = store.getState().displayReducer.partnerEmail;

  const emailToSave = parameterEmail ?? partnerEmail;

  if (emailToSave && displayStateEmail !== emailToSave) {
    store.dispatch(setPartnerEmail(emailToSave));
  }
}
