import { getUrl } from '@/apiCalls/fetch';

import { ILookupAbnOrAcn, ILookupAbnOrAcnResponse } from '../commonTypes';

export async function lookupAbn(
  abn: string
): Promise<ILookupAbnOrAcn | undefined> {
  const url = `/api/abnLookup/abn/${abn}`;

  const response = await getUrl<ILookupAbnOrAcnResponse>(url);

  return response.data;
}
