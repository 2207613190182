import { setPurchasePrice } from '@/context/borrower';
import { setIsPriceKnownViaExternal } from '@/context/display';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialVehiclePrice(price: IParameter): void {
    if (!price) {
        return;
    }

    const intPrice = parseInt(price, 10);
    if (isNaN(intPrice)) {
        return;
    }

    store.dispatch(setIsPriceKnownViaExternal(true));

    const { purchasePrice: statePrice } = store.getState().borrowerReducer.loan;

    if (statePrice !== intPrice) {
        store.dispatch(setPurchasePrice(intPrice));
    }
}
