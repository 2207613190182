import { PayloadAction } from '@reduxjs/toolkit';

import { ICreditCard } from '../types';

export const creditCardReducers = {
  setCreditCardByIndex: (
    state,
    action: PayloadAction<{ index: number; creditCard: ICreditCard }>
  ): void => {
    state.creditCards.creditCards[action.payload.index] =
      action.payload.creditCard;
  },
  setCreditCardActiveIndex: (state, action: PayloadAction<number>): void => {
    let length = action.payload;
    const min = 0;
    if (length < min) {
      length = min;
    }
    state.creditCards.creditCardActiveIndex = length;
  },
  setCreditCards: (
    state,
    action: PayloadAction<{
      creditCards: ICreditCard[];
      creditCardActiveIndex: number;
    }>
  ): void => {
    state.creditCards.creditCards = action.payload.creditCards;
    state.creditCards.creditCardActiveIndex =
      action.payload.creditCardActiveIndex;
  },
  deleteCreditCard: (state): void => {
    if (
      state.creditCards.creditCards?.length === 1 &&
      state.creditCards.creditCardActiveIndex === 0
    ) {
      state.creditCards.creditCards = [];
      return;
    }
    state.creditCards.creditCards?.splice(
      state.creditCards.creditCardActiveIndex,
      1
    );
  },
};
