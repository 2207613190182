import { ICreditCard } from '@/context/borrower';

export function isCreditCardComplete(creditCard: ICreditCard): boolean {
  if (
    creditCard.lenderName &&
    creditCard.creditCardLimit &&
    creditCard.creditCardBalance
  )
    return true;
  if (
    creditCard.lenderName &&
    creditCard.creditCardLimit &&
    creditCard.creditCardBalance === 0
  )
    return true;

  return false;
}
