import { useSelector } from 'react-redux';

import { Input } from '@/components/Input';
import {
    IPersonalLoan,
    selectPersonalLoanActiveIndex,
    selectPersonalLoanByIndex,
    setPersonalLoanByIndex,
} from '@/context/borrower';
// eslint-disable-next-line no-restricted-imports
// eslint-disable-next-line no-restricted-imports
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
    setShowErrors: (value: boolean) => void;
}

export const testId = 'PersonalLoanBalance';

export function PersonalLoanBalance({
    baseId,
    showErrors,
    setShowErrors,
}: IProps): JSX.Element {
    // ***** Redux *****
    const activeCardIndex = useAppSelector(selectPersonalLoanActiveIndex);
    const personalLoan = useSelector((state: RootState) =>
        selectPersonalLoanByIndex(state, activeCardIndex)
    );
    const personalLoanBalance = personalLoan?.loanBalance;
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        setShowErrors(false);
        if (typeof value !== 'string') return;
        const MAXIMUM = 999999;
        const intValue: number | undefined = getNumber(value, MAXIMUM);
        if (intValue && intValue > 9999999) return;
        if (personalLoan === null) return;

        const newPersonalLoan: IPersonalLoan = {
            ...personalLoan,
            loanBalance: intValue,
        };

        if (intValue && isNaN(intValue)) {
            newPersonalLoan.loanBalance = undefined;
        }
        dispatch(
            setPersonalLoanByIndex({
                index: activeCardIndex,
                personalLoan: newPersonalLoan,
            })
        );
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Balance'
                placeholder='$2000'
                showErrors={showErrors && !personalLoanBalance}
                onChange={handleChange}
                id={`${baseId}-input-balance`}
                value={personalLoanBalance?.toLocaleString() ?? ''}
                prefix='$'
                showNumpad={true}
            />
        </div>
    );
}
