import { Input } from '@/components/Input';
import {
    selectRegistrationNumber,
    setVehicleRegistrationNumber,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'RegistrationNumber';

export function RegistrationNumber({
    baseId,
    showErrors,
}: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectRegistrationNumber);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        if (typeof value === 'boolean') return;
        if (value === currentState) return;
        if (value?.length > 50) return;
        dispatch(setVehicleRegistrationNumber(value.toLocaleUpperCase()));
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Registration Number'
                placeholder='Enter the registration number'
                showErrors={showErrors}
                onChange={handleChange}
                id={`${baseId}-input-registrationNumber`}
                value={currentState?.toLocaleString() ?? ''}
                tooltipContent='The registration number of the vehicle you want to finance.'
            />
        </div>
    );
}
