import { getUrl } from '@/apiCalls/fetch';
import { setBorrower } from '@/context/borrower';
import {
    EDisplays,
    EModals,
    setAgreedToTermsAndConditions,
    setDisplay,
    setModal,
} from '@/context/display';
import { store } from '@/context/store';
import {
    ELocalStorageVariables,
    getLocalStorageItem,
    setLocalStorageItem,
} from '@/utils/localStorage';

import { IGetBorrowerReturn } from './getBorrowerTypes';

export async function getBorrower(): Promise<void> {
    // ***** Redux *****
    const state = store.getState();
    const display = state.displayReducer.display;
    const token = getLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);
    const sellerName = state.borrowerReducer.seller.name;
    const vehicle = state.borrowerReducer.vehicle;
    const seller = state.borrowerReducer.seller;
    const loan = state.borrowerReducer.loan;
    const isAssetKnownViaParams = state.displayReducer.isAssetKnownViaParams;
    const currentLeadPartnerId =
        state.borrowerReducer.application.partnerOverwriteId;
    const currentAssetType = state.borrowerReducer.vehicle.assetType;
    const currentAssetCategory = state.borrowerReducer.vehicle.assetCategory;
    const isAssetTypeKnownViaParams =
        state.borrowerReducer.vehicle.isAssetTypeKnownViaParams;
    const currentStockNumber = state.borrowerReducer.vehicle.stockNumber;

    if (!token) {
        console.log('No token found in local storage');
        return;
    }

    // ***** Request *****
    const url = '/api/borrower/get';

    const response = await getUrl<IGetBorrowerReturn>(url);

    // ***** Update Redux *****
    const returnedBorrower = { ...response.data.borrower };

    // BE does not save these values
    // BE does not save these values
    if (!returnedBorrower.address) {
        returnedBorrower.address = {
            addressIndex: 0,
            address: [],
        };
    }
    if (!returnedBorrower.employments) {
        returnedBorrower.employments = {
            currentEmploymentIndex: 0,
            employments: [],
        };
    }
    if (!returnedBorrower.personalLoans) {
        returnedBorrower.personalLoans = {
            personalLoanActiveIndex: 0,
            personalLoans: [],
        };
    }
    if (!returnedBorrower.creditCards) {
        returnedBorrower.creditCards = {
            creditCardActiveIndex: 0,
            creditCards: [],
        };
    }
    if (!returnedBorrower.motorVehicles) {
        returnedBorrower.motorVehicles = {
            motorVehicleActiveIndex: 0,
            motorVehicles: [],
        };
    }
    if (!returnedBorrower.realEstates) {
        returnedBorrower.realEstates = {
            realEstateActiveIndex: 0,
            realEstates: [],
        };
    }

    returnedBorrower.address.addressIndex = 0;
    returnedBorrower.employments.currentEmploymentIndex = 0;
    returnedBorrower.personalLoans.personalLoanActiveIndex = 0;
    returnedBorrower.creditCards.creditCardActiveIndex = 0;
    returnedBorrower.motorVehicles.motorVehicleActiveIndex = 0;
    returnedBorrower.realEstates.realEstateActiveIndex = 0;

    // BE Would otherwise overwrite FE vehicle / seller set by FE params which is incorrect
    if (isAssetKnownViaParams) {
        // Only use new vehicle if it's present
        returnedBorrower.seller = { ...seller };
        returnedBorrower.vehicle = { ...vehicle };
    }

    // Do not overwrite the vehicle asset type if it is known via params
    if (isAssetTypeKnownViaParams || !returnedBorrower.vehicle?.assetType) {
        returnedBorrower.vehicle.assetType = currentAssetType;
        returnedBorrower.vehicle.assetCategory = currentAssetCategory;
    }

    // only update loan if it is not coming from the parameters
    returnedBorrower.loan.deposit =
        loan.deposit || returnedBorrower.loan.deposit;
    returnedBorrower.loan.loanTerm =
        loan.loanTerm || returnedBorrower.loan.loanTerm;
    returnedBorrower.loan.balloon =
        loan.balloon || returnedBorrower.loan.balloon;
    returnedBorrower.loan.tradeIn =
        loan.tradeIn || returnedBorrower.loan.tradeIn;

    returnedBorrower.loan.loanUsage =
        loan.loanUsage ?? returnedBorrower.loan.loanUsage;

    // Partner Id overwrite to always be set to the current value
    returnedBorrower.application.partnerOverwriteId = currentLeadPartnerId;
    // Always use the value passed in the params, or undefined otherwise
    returnedBorrower.vehicle.stockNumber = currentStockNumber;

    store.dispatch(setBorrower(returnedBorrower));
    const returnedFirstName = response.data.borrower.personalDetails.firstName;
    if (returnedFirstName) {
        setLocalStorageItem(
            ELocalStorageVariables.FIRST_NAME,
            returnedFirstName
        );
    }

    store.dispatch(
        setAgreedToTermsAndConditions(
            returnedBorrower.personalDetails.termsAndConditions
        )
    );

    // ***** Update Display *****
    if (display === EDisplays.SERVICING_SUCCESS) return;

    if (returnedBorrower.application.isSubmitted) {
        store.dispatch(setModal(EModals.APPLICATION_IN_PROGRESS));
        return;
    } else if (returnedBorrower.application.isPreApproved) {
        store.dispatch(setDisplay(EDisplays.OCR_SUMMARY));
    } else {
        store.dispatch(setDisplay(EDisplays.BASIC));
    }
}
