import { DateInput } from '@/components/DateInput';
import { selectGstFrom, setGstFrom } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'GstFrom';

export function GstFrom({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectGstFrom);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    const handleChange = (date: Date): void => {
        const formattedDate = formatDateToString(date);
        dispatch(setGstFrom(formattedDate));
    };

    // ***** Render *****
    return (
        <div data-testid={testId} className='w-full'>
            <DateInput
                id={`${baseId}-input-gstFrom`}
                title='GST Registered From'
                placeholder='DD/MM/YYYY'
                onChange={handleChange}
                value={parseDate(currentState)}
                showErrors={showErrors && !currentState}
                maxDate={new Date()}
                tooltipContent='This is the date you registered for GST. If you are not registered for GST, skip this.'
            />
        </div>
    );
}

// todo: move this to utils?
function formatDateToString(date: Date | null): string {
    if (date == null) return '';
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    };

    return date.toLocaleDateString('en-GB', options);
}
function parseDate(dateString: string | undefined | null): Date | undefined {
    if (dateString === undefined || dateString === null) return undefined;
    const [day, month, year] = dateString.split('/');
    const parsedDate = new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day)
    );
    return parsedDate;
}
