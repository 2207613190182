import { store } from '@/context/store';

import { isMotorVehicleAssetComplete } from '../isMotorVehicleComplete';

export function isAllMotorVehicleAssetsComplete(canBeEmpty = false): boolean {
  const state = store.getState();
  const motorVehicles = state.borrowerReducer.motorVehicles.motorVehicles;

  // Early Exit
  if (
    motorVehicles?.length === 0 &&
    motorVehicles[0]?.assetValue === undefined
  ) {
    return canBeEmpty;
  }

  let isComplete = true;
  motorVehicles.map((motorVehicle) => {
    if (!isMotorVehicleAssetComplete(motorVehicle)) {
      isComplete = false;
    }
  });

  return isComplete;
}
