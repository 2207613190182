import { Input } from '@/components/Input';
import {
    selectPlantandEquipment,
    setPlantandEquipment,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'PlantandEquipment';

export function PlantandEquipment({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectPlantandEquipment);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        // MAXIMUM
        const MAXIMUM = 999999999;
        const intValue: number | undefined = getNumber(value, MAXIMUM);
        if (intValue && intValue > MAXIMUM) return;
        if (intValue === currentState) return;
        dispatch(setPlantandEquipment(intValue));
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Plant and Equipment'
                placeholder='$1234'
                showErrors={showErrors}
                onChange={handleChange}
                id={`${baseId}-input-plantAndEquipment`}
                value={currentState?.toLocaleString() ?? ''}
                prefix='$'
                showNumpad={true}
                tooltipContent='Machinery, equipment, appliances, containers, tools, anything like that.'
            />
        </div>
    );
}
