import { PropsWithChildren } from 'react';

export function ModalHead({
    children,
}: PropsWithChildren): JSX.Element {

    // ***** Render *****
    return (
        <div className='_flex flex-col justify-start mb-2'>
            {children}
        </div>
    );
}
