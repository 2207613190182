import { useEffect, useState } from 'react';

import { getMake } from '@/apiCalls/glassGuideVehicleLookup';
import { Combobox, IComboboxOption } from '@/components/Combobox';
import {
    selectVehicleAssetType,
    selectVehicleBuildYear,
    selectVehicleMake,
    setVehicleMake,
} from '@/context/borrower';
import {
    IMakeApiResults,
    selectVehicleMakeApiResults,
    setVehicleMakeApiResults,
    setVehicleMakeCodeForApiCall,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'VehicleMake';

export function VehicleMake({ showErrors, baseId }: IProps): JSX.Element {
    // ** Redux State **

    const make = useAppSelector(selectVehicleMake);
    const dispatch = useAppDispatch();
    const buildYear = useAppSelector(selectVehicleBuildYear);
    const partner = useAppSelector((state) => state.displayReducer.partner.id);
    const assetType = useAppSelector(selectVehicleAssetType);
    const vehicleMakeApiResults = useAppSelector(selectVehicleMakeApiResults);

    // ** Local State **
    const [query, setQuery] = useState<undefined | string>(make ?? ''); // Keep track of search
    const [loading, setLoading] = useState(false);

    // *****Functions*****
    // Handle the onChange event for the input.
    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        setQuery(value);
        if (typeof value === 'boolean') return;
        if (value === make) return;
        if (value.length > 4) return;
    }

    // When the user selects an option
    const handleSelect = (option: IComboboxOption): void => {
        if (option.value === make) return;
        dispatch(setVehicleMake(option.value));
        dispatch(setVehicleMakeCodeForApiCall(option.code));
    };

    const helperFunction = (
        apiResult: IMakeApiResults[] | undefined
    ): IComboboxOption[] | undefined => {
        const newArrayName = apiResult?.map((item, index) => ({
            id: index + 1,
            value: item.name,
            code: item.code,
        }));
        return newArrayName;
    };

    useEffect(() => {
        // make API call and update state with returned data
        if (buildYear) {
            if (make) return;
            // make API call for vehicle make options
            const apiCall = async (): Promise<void> => {
                setLoading(true);
                await getMake(partner, buildYear, assetType).then((response) => {
                    dispatch(setVehicleMakeApiResults(response));
                    setLoading(false);
                });
            };
            apiCall();
        }
    }, [buildYear]);

    useEffect(() => {
        setQuery(make);
    }, [make]);

    return (
        <div data-testid={testId}>
            <Combobox
                options={helperFunction(vehicleMakeApiResults) ?? []}
                placeholder='Select make'
                heading='Vehicle Make Options'
                onChange={handleChange}
                query={query ?? ''}
                onSelect={handleSelect}
                showErrors={showErrors && !make}
                loading={loading}
                baseId={baseId}
                tooltipContent='The brand of vehicle'
            />
        </div>
    );
}
