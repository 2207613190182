import { Input } from '@/components/Input';
import { selectTradeIn, setTradeIn } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'TradeIn';

export function TradeIn({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectTradeIn);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        // MAXIMUM
        const MAXIMUM = 999999;
        const intValue = getNumber(value, MAXIMUM);
        if (intValue === currentState) return;
        console.log(currentState);
        dispatch(setTradeIn(intValue));
    }

    // ***** Render *****
    return (
        <div data-testid={testId} className='w-full'>
            <Input
                title='Trade In'
                placeholder='Optional'
                showErrors={showErrors}
                onChange={handleChange}
                id={`${baseId}-input-tradeIn`}
                value={currentState?.toLocaleString() ?? ''}
                prefix='$'
                showNumpad={true}
                // tooltipContent='Only use if buying from a dealer. What the dealer will pay for your current car.'
            />
        </div>
    );
}
