import { store } from '@/context/store';

export function getTotalEmploymentYears(): number {
  const state = store.getState();
  const employments = state.borrowerReducer.employments.employments;
  let years = 0;
  let months = 0;
  employments.forEach((employment) => {
    const empYears = employment?.years ?? 0;
    const empMonths = employment?.months ?? 0;
    years += empYears;
    months += empMonths;
  });
  if (months >= 12) {
    const newYears = Math.floor(months / 12);
    years += newYears;
    months -= newYears * 12;
  }

  return years;
}
