import { EPartners } from '@/partners/partnerTypes';

import { EState } from './commonTypes';

export interface IVehicle {
    vin?: string | undefined;
    nvic?: string | undefined;
    buildYear?: number | undefined;
    make?: string | undefined;
    model?: string | undefined;
    variant?: string | undefined;
    description?: string | undefined;
    isVehicleKnown: boolean;
    kilometers?: number | undefined;
    registrationNumber?: string | undefined;
    registrationState?: EState | undefined;
    isRegistrationKnown: boolean;
    assetType: EVehicleAssetType;
    assetCategory: string;
    sourcePrice?: number | undefined;
    url?: string | undefined;
    stockNumber?: string | undefined;
    id?: string | undefined;
    glassValuation?: number | undefined;
    source?: EPartners | undefined;
    condition?: ECondition | undefined;
    sourceYardId?: string | undefined;
    isAssetTypeKnownViaParams?: boolean;
}

export enum ECondition {
    NEW = 'NEW',
    USED = 'USED',
    DEMO = 'DEMO',
}

export enum EVehicleAssetType {
    PASSENGER_VEHICLE = 'PASSENGER_VEHICLE',
    MOTORCYCLE = 'MOTORCYCLE',
    BOAT = 'BOAT',
    CARAVAN = 'CARAVAN',
    TRUCK_GT_4_5 = 'TRUCK_GT_4_5',
    PLANT_EQUIPMENT = 'PLANT_EQUIPMENT',
    TRUCK_LT_4_5 = 'TRUCK_LT_4_5',
    PERSONAL_LOAN = 'PERSONAL_LOAN',
    BUSINESS_LOAN = 'BUSINESS_LOAN',
    GREEN_POLICY = 'GREEN_POLICY',
    GREY_IMPORT = 'GREY_IMPORT',
    BUSINESS_LOAN_LOC = 'BUSINESS_LOAN_LOC',
    PASSENGER_VEHICLE_LUX = 'PASSENGER_VEHICLE_LUX',
    AG_BIKES_ATV = 'AG_BIKES_ATV',
    AIRCRAFT = 'AIRCRAFT',
    MEDICAL_EQUIP = 'MEDICAL_EQUIP',
    BEAUTY_EQUIP = 'BEAUTY_EQUIP',
    BOATS = 'BOATS',
    CARAVANS = 'CARAVANS',
    CNC = 'CNC',
    COFFEE_MACHINE = 'COFFEE_MACHINE',
    COMM_VEH_LT_4_5 = 'COMM_VEH_LT_4_5',
    CONCRETE_CUTTERS = 'CONCRETE_CUTTERS',
    COOL_ROOMS = 'COOL_ROOMS',
    DEBT_CONSOLIDATION = 'DEBT_CONSOLIDATION',
    DESKTOPS = 'DESKTOPS',
    DRONES = 'DRONES',
    EARTH_MOV_EQUIP = 'EARTH_MOV_EQUIP',
    EDUCATION = 'EDUCATION',
    ENGG_LATHES = 'ENGG_LATHES',
    FARM_MACH = 'FARM_MACH',
    FOOD_MANUFAC_EQUIP = 'FOOD_MANUFAC_EQUIP',
    FOOD_PROCESS_PLANT = 'FOOD_PROCESS_PLANT',
    FOOD_TRUCKS = 'FOOD_TRUCKS',
    GENERATORS_COMPRES = 'GENERATORS_COMPRES',
    GOLF_CARTS = 'GOLF_CARTS',
    GREY_IMPORTS = 'GREY_IMPORTS',
    HOME_IMPROVEMENT = 'HOME_IMPROVEMENT',
    INVESTMENT = 'INVESTMENT',
    JET_SKIES = 'JET_SKIES',
    LEGAL = 'LEGAL',
    LINE_OF_CREDIT = 'LINE_OF_CREDIT',
    MATERIAL_HAND_EQUIP = 'MATERIAL_HAND_EQUIP',
    MEDICAL = 'MEDICAL',
    MOBILE_CRANES = 'MOBILE_CRANES',
    MORTGAGE_DEPOSIT = 'MORTGAGE_DEPOSIT',
    MOTOR_VEHICLES = 'MOTOR_VEHICLES',
    MOTORBIKES_REG = 'MOTORBIKES_REG',
    MOTORBIKES_UNREG = 'MOTORBIKES_UNREG',
    OFFICE_FIT_OUT = 'OFFICE_FIT_OUT',
    OTHER = 'OTHER',
    OUTBOARD_ENGINES = 'OUTBOARD_ENGINES',
    OVENS = 'OVENS',
    MOTOR_VEHICLES_SEC = 'MOTOR_VEHICLES_SEC',
    PLASTIC_INJEC_MACH = 'PLASTIC_INJEC_MACH',
    POS_EQUIP = 'POS_EQUIP',
    PRIME_MOVERS = 'PRIME_MOVERS',
    PRINTING_EQUIP = 'PRINTING_EQUIP',
    RECREATIONAL = 'RECREATIONAL',
    SECURITY_SYS = 'SECURITY_SYS',
    SOLAR_PANELS = 'SOLAR_PANELS',
    TAX_BILL = 'TAX_BILL',
    TEL_SYSTEM = 'TEL_SYSTEM',
    TOWER_CRANES = 'TOWER_CRANES',
    TRACTORS = 'TRACTORS',
    TRAILERS = 'TRAILERS',
    TRAVEL = 'TRAVEL',
    TRUCKS_LT_4_5 = 'TRUCKS_LT_4_5',
    VENDING_MACHINES = 'VENDING_MACHINES',
    WEDDING_EVENT = 'WEDDING_EVENT',
    WOODWORKING_EQUIP = 'WOODWORKING_EQUIP',
    WORKING_CAPITAL = 'WORKING_CAPITAL',
    GREEN_VEHICLE = 'GREEN_VEHICLE',
    TRUCKS_RIGID_4_5 = 'TRUCKS_RIGID_4_5',
    COFFEE_MACHINE_UNSEC = 'COFFEE_MACHINE_UNSEC',
    AG_BIKES_ATV_UNSEC = 'AG_BIKES_ATV_UNSEC',
    BEAUTY_EQUIP_UNSEC = 'BEAUTY_EQUIP_UNSEC',
    BOATS_UNSEC = 'BOATS_UNSEC',
    CARAVAN_UNSEC = 'CARAVAN_UNSEC',
    HOME_FURNISHING = 'HOME_FURNISHING',
    RENEWABLE_ENERGY = 'RENEWABLE_ENERGY',
    COMMERCIAL_VEHICLES_LESS_THEN_4_5_TONNES = 'COMM_VEH_LT_4_5', // repeated value
    TRUCK = 'TRUCK_GT_4_5', // repeated value
    PRIME_MOVER = 'PRIME_MOVERS', // repeated value
}

export const initialVehicle: IVehicle = {
    isVehicleKnown: true,
    isRegistrationKnown: true,
    condition: ECondition.NEW,
    assetType: EVehicleAssetType.COMM_VEH_LT_4_5,
    assetCategory: '',
    isAssetTypeKnownViaParams: false,
};
