import { getBorrower } from '@/apiCalls/borrower';
import { getUrl } from '@/apiCalls/fetch';
import { Button, EButtonType } from '@/components/Button';
import { ContactBox } from '@/components/ContactBox';
import { ModalComponent } from '@/components/ModalComponent';
import { RemoteImage } from '@/components/RemoteImage';
import {
    initialBorrowerSlice,
    selectFirstName,
    setBorrower,
} from '@/context/borrower';
import { EDisplays, setDisplay, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { setIsLoggedIn } from '@/context/user';
import {
    ELocalStorageVariables,
    removeLocalStorageItem,
} from '@/utils/localStorage';

export const testId = 'ApplicationInProgress';
const baseId = 'modal-applicationInProgress';

export function ApplicationInProgress(): JSX.Element {
    // ***** Redux State *****
    const firstName = useAppSelector(selectFirstName);
    const dispatch = useAppDispatch();

    function handleClick(): void {
        dispatch(setBorrower(initialBorrowerSlice));
        dispatch(setIsLoggedIn(false));

        removeLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);
        removeLocalStorageItem(ELocalStorageVariables.FIRST_NAME);

        dispatch(setDisplay(EDisplays.QUICK_CALCULATOR));
        dispatch(setModal(null));
    }

    function handleNewApplication(): void {
        // Request to create a new application from previous, and start with the first screen
        getUrl('/api/borrower/new-application').then(() => {
            getBorrower().then(() => {
                dispatch(setDisplay(EDisplays.QUICK_CALCULATOR));
                dispatch(setModal(null));
            });
        });
    }

    // ***** Render *****
    return (
        <ModalComponent baseId={baseId} className=' items-center py-2'>
            <div className='flex-center pt-4 pb-10 relative'>
                <div className='bg-primary h-40 w-40 rounded-full' />
                <RemoteImage
                    fileName='document.svg'
                    className='absolute h-36 w-36'
                />
            </div>
            <h3 className='text-center font-bold text-3xl pb-4 text-primary'>
                Hey, {firstName}
            </h3>
            <p className='pb-4 font-semi-bold text-lg text-center'>
                We&apos;ve got your application and it&apos;s in progress with
                the lender
            </p>
            <ContactBox />
            <div className='pt-4 pb-2 min-w-[250px] flex flex-row justify-between mx-auto gap-[5px]'>
                <Button
                    id={`${baseId}-button-logout`}
                    onClick={handleClick}
                    type={EButtonType.PRIMARY_OUTLINE}
                >
                    Logout
                </Button>
                <Button
                    id={`${baseId}-button-newApplication`}
                    onClick={handleNewApplication}
                    type={EButtonType.PRIMARY}
                >
                    Create New Application
                </Button>
            </div>
        </ModalComponent>
    );
}
