import { ChangeEvent } from 'react';
import moment from 'moment';

import { uniqueIdTransfer } from '@/apiCalls/uniqueIDTransferHelper';
import { trimClassname } from '@/utils/styleHelper';

import { EEventType } from '../eventType';
import { Tooltip } from '../Tooltip';

export interface ITimeInput {
    title: string;
    placeholder: string;
    onChange: (value: Date) => void;
    showErrors: boolean;
    id?: string;
    disabled?: boolean;
    value?: Date;
    minTime?: Date;
    maxTime?: Date;
    timeFormat?: string; // 'HH:mm' for 24-hour format or 'hh:mm A' for 12-hour format
    tooltipContent?: string;
}

export function TimeInput({
    title,
    placeholder,
    id,
    value,
    showErrors,
    disabled = false,
    onChange,
    minTime,
    maxTime,
    timeFormat = 'HH:mm', // Default to 24-hour format
    tooltipContent,
}: ITimeInput): JSX.Element {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        let time = moment(value, timeFormat).toDate();

        if (maxTime && time > maxTime) {
            time = maxTime;
        }

        onChange(time);

        uniqueIdTransfer({
            eventType: EEventType.INPUT,
            applicationData: { id: id },
        });
    };

    function getDisplayValue(): string {
        if (!value) return '';
        if (maxTime && value > maxTime)
            return moment(maxTime).format(timeFormat);
        return moment(value).format(timeFormat);
    }

    return (
        <div data-testid='input' className='py-2 mx-auto w-full max-w-md'>
            <Tooltip content={tooltipContent ?? ''}>
                <h1 className='fieldHeading'>{title}</h1>
            </Tooltip>
            <input
                className={trimClassname(
                    `appearance-none border rounded-[10px] mx-auto w-full max-w-md p-2 text-textPrimary leading-tight h-8 inputButtonFocus placeholderStyle text-center ${
                        showErrors ? errorClass : ''
                    } ${
                        disabled
                            ? 'hover:none cursor-disabled'
                            : 'hover:shadow '
                    }`
                )}
                id={id}
                type='time'
                data-testid='inputField'
                placeholder={placeholder}
                value={getDisplayValue()}
                onChange={handleChange}
                disabled={disabled}
                min={minTime ? moment(minTime).format(timeFormat) : undefined}
                max={maxTime ? moment(maxTime).format(timeFormat) : undefined}
                pattern={timeFormat}
                onInput={handleChange}
                onBlur={handleChange}
            />
        </div>
    );
}
export const errorClass = 'error';
