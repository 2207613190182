import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { submitApplication as submitApplicationToIq } from '@/apiCalls/submitApplication';
import { DisplayButtons } from '@/components/DisplayButtons';
import {
    ELoanUsage,
    EUserFlow,
    selectLoanUsage,
    selectSelectedLender,
    setProgressPercent,
} from '@/context/borrower';
import {
    EDisplays,
    selectUserFlow,
    setDisplay,
    setIsAssetAndLiabilitiesSubmitSkipped,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { canSkipSupportingDocs } from '@/utils/canSkipSupportingDocs';
import {
    isAllCreditCardsComplete,
    isAllMotorVehicleAssetsComplete,
    isAllPersonalLoansComplete,
    isAllRealEstatesComplete,
} from '@/utils/checkAssetsAndLiabilities';
import { EConversionType, reportConversion } from '@/utils/reportConversion';
import { SetFinalScreen } from '@/utils/SetFinalScreen';

interface IAssetsAndLiabilitiesButtonsProps {
    setShowErrors: (value: boolean) => void;
    baseId?: string;
}

export function AssetsAndLiabilitiesButtons({
    setShowErrors,
    baseId,
}: IAssetsAndLiabilitiesButtonsProps): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const selectedLender = useAppSelector(selectSelectedLender);
    const loanUsage = useAppSelector(selectLoanUsage);
    const skipSupportingDocs = canSkipSupportingDocs();
    const userFlow = useAppSelector(selectUserFlow);

    // ***** Event Handlers *****
    function handleBackClick(): void {
        if (userFlow === EUserFlow.CAMPAIGN) {
            dispatch(setDisplay(EDisplays.OCR_SUMMARY));
            return;
        }
        if (loanUsage === ELoanUsage.BUSINESS) {
            dispatch(setDisplay(EDisplays.OCR_SUMMARY));
            return;
        }
        dispatch(setDisplay(EDisplays.EMPLOYMENT));
    }

    function handleNextClick(): void {
        setShowErrors(true);
        if (!isComplete()) return;
        createOrUpdateBorrower();

        if (!selectedLender) {
            dispatch(setDisplay(EDisplays.INDICATIVELY_REFERRED));
            return;
        }

        if (skipSupportingDocs) {
            submitApplication();
        } else {
            dispatch(setIsAssetAndLiabilitiesSubmitSkipped(true));
            dispatch(setDisplay(EDisplays.SUPPORTING_DOCS));
        }
    }

    // ***** Helpers *****
    function isComplete(): boolean {
        if (!isAllRealEstatesComplete(true)) return false;
        if (!isAllPersonalLoansComplete(true)) return false;
        if (!isAllMotorVehicleAssetsComplete(true)) return false;
        if (!isAllCreditCardsComplete(true)) return false;

        return true;
    }

    function submitApplication(): void {
        dispatch(setProgressPercent(100));
        submitApplicationToIq();
        reportConversion(EConversionType.APPLY_TO_LENDER);
        SetFinalScreen();
    }

    return (
        <DisplayButtons
            idLeftButton={`${baseId}-button-back`}
            idRightButton={`${baseId}-button-next`}
            rightClick={handleNextClick}
            leftClick={handleBackClick}
            rightText={skipSupportingDocs ? 'Submit' : 'Next'}
        />
    );
}
