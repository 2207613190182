import { setFirstName } from '@/context/borrower';
import { store } from '@/context/store';
import { setIsLoggedIn } from '@/context/user';
import {
  ELocalStorageVariables,
  getLocalStorageItem,
  removeLocalStorageItem,
} from '@/utils/localStorage';

export function setInitialUserState(): void {
  const authToken = getLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);
  const firstName = getLocalStorageItem(ELocalStorageVariables.FIRST_NAME);
  // Must have both
  if (authToken && firstName) {
    store.dispatch(setIsLoggedIn(true));
    store.dispatch(setFirstName(firstName));
  } else {
    // If only one, remove it
    if (authToken) removeLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);
    if (firstName) removeLocalStorageItem(ELocalStorageVariables.FIRST_NAME);
  }
}
