import { useSelector } from 'react-redux';

import { Input } from '@/components/Input';
import {
    IAddress,
    selectAddressByIndex,
    setAddressByIndex,
} from '@/context/borrower';
import { RootState } from '@/context/store';
import { useAppDispatch } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'PostCode';

export function PostCode({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const address = useSelector((state: RootState) =>
        selectAddressByIndex(state, 0)
    );
    const { postcode } = address;
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        if (typeof value !== 'string') return;
        if (value.length > 4) return;
        const newAddress: IAddress = {
            ...address,
            postcode: value,
        };
        dispatch(setAddressByIndex({ index: 0, address: newAddress }));
    }

    // ***** Helpers *****
    function determineShowErrors(): boolean {
        if (!showErrors) return false;
        if (postcode === undefined) return true;
        if (postcode === null) return true;
        if (postcode.toString()?.length !== 4) return true;
        return false;
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Post Code'
                placeholder='2000'
                showErrors={determineShowErrors()}
                onChange={handleChange}
                id={`${baseId}-input-postCode`}
                value={postcode?.toString() ?? ''}
                showNumpad={true}
            />
        </div>
    );
}
