// eslint-disable-next-line simple-import-sort/imports
import { RootState } from '@/context/store';
import { createSlice } from '@reduxjs/toolkit';
import { ILender } from '../lender';
import { initialBorrowerSlice } from './initialState/initialBorrowerSlice';
import { borrowerReducers } from './reducers';
import {
    ECondition,
    ECreditRating,
    EFrequency,
    EGender,
    ESelfAssessedCreditHistory,
    ESellerType,
    ETrusteeType,
    EVehicleAssetType,
    IAddress,
    IAgreements,
    IBorrowerSlice,
    IBusiness,
    IEmployment,
    IScores,
    ISupportingDoc,
    ITestDrive,
    ITestDriveState,
    IVehicle,
} from './types';

import { ICreditCard } from './types/creditCardsTypes';
import { EOtherIncomeSource } from './types/incomeTypes';
import { ELoanType, ELoanUsage } from './types/loanTypes';
import { IMotorVehicle } from './types/motorVehicleAssetTypes';
import { IPersonalLoan } from './types/personalLoanTypes';
import {
    EBorrowerCitizenshipStatus,
    IPersonalDetails,
} from './types/personalTypes';
import { IRealEstate } from './types/realEstateAssetTypes';

export const borrowerSlice = createSlice({
    name: 'borrower',
    initialState: initialBorrowerSlice,
    reducers: borrowerReducers,
});

export const { setIsPreapproved } = borrowerSlice.actions;
export const { addShownLenders } = borrowerSlice.actions;

export const { setBorrower } = borrowerSlice.actions;
export const { setMobile } = borrowerSlice.actions;
export const { setEmail } = borrowerSlice.actions;
export const { setGender } = borrowerSlice.actions;
export const { setAddressByIndex } = borrowerSlice.actions;
export const { setResidencyStatus } = borrowerSlice.actions;
export const { setVisaNumber } = borrowerSlice.actions;
export const { setVisaExpiryDate } = borrowerSlice.actions;
export const { setHasChildren } = borrowerSlice.actions;
export const { setNumberOfChildren } = borrowerSlice.actions;
export const { setPaidFrequency } = borrowerSlice.actions;
export const { setPaidAmount } = borrowerSlice.actions;
export const { setOtherIncome } = borrowerSlice.actions;
export const { setOtherIncomeSource } = borrowerSlice.actions;
export const { setEmploymentStatusByIndex } = borrowerSlice.actions;
export const { setEmploymentStatus } = borrowerSlice.actions;
export const { setOccupation } = borrowerSlice.actions;
export const { setEmployerName } = borrowerSlice.actions;
export const { setEmployerPhone } = borrowerSlice.actions;
export const { setEmploymentYears } = borrowerSlice.actions;
export const { setEmploymentMonths } = borrowerSlice.actions;
export const { setEmploymentIndex } = borrowerSlice.actions;
export const { setEmployments } = borrowerSlice.actions;

// Application
export const { setProgressPercent } = borrowerSlice.actions;
export const { setPartnerOverwriteId } = borrowerSlice.actions;

// Expenses
export const { setExpensesShared } = borrowerSlice.actions;
export const { setBillsPaidFrequency } = borrowerSlice.actions;
export const { setGeneral } = borrowerSlice.actions;
export const { setTransportVehicle } = borrowerSlice.actions;
export const { setEducation } = borrowerSlice.actions;
export const { setOtherLoans } = borrowerSlice.actions;
export const { setMortgageRent } = borrowerSlice.actions;
export const { setHealthcare } = borrowerSlice.actions;
export const { setNoForseeableChangesToCircumstances } = borrowerSlice.actions;
export const { setForseeableChangesExplanation } = borrowerSlice.actions;

// Loan
export const { setLoanUsage } = borrowerSlice.actions;
export const { setPurchasePrice } = borrowerSlice.actions;
export const { setTradeIn } = borrowerSlice.actions;
export const { setDeposit } = borrowerSlice.actions;
export const { setBalloon } = borrowerSlice.actions;
export const { setLoanTerm } = borrowerSlice.actions;
// Seller
export const { setSellerType } = borrowerSlice.actions;
export const { setSellerName } = borrowerSlice.actions;
export const { setSellerEmail } = borrowerSlice.actions;
export const { setSellerPhone } = borrowerSlice.actions;
export const { setSellerContactName } = borrowerSlice.actions;
export const { setSellerAccountNumber } = borrowerSlice.actions;
export const { setSellerLeadEmail } = borrowerSlice.actions;
export const { setSellerAbn } = borrowerSlice.actions;

// Vehicle
export const { setVehicle } = borrowerSlice.actions;
export const { setVehicleVin } = borrowerSlice.actions;
export const { setVehicleNvic } = borrowerSlice.actions;
export const { setVehicleBuildYear } = borrowerSlice.actions;
export const { setVehicleMake } = borrowerSlice.actions;
export const { setVehicleModel } = borrowerSlice.actions;
export const { setVehicleVariant } = borrowerSlice.actions;
export const { setVehicleDescription } = borrowerSlice.actions;
export const { setIsVehicleKnown } = borrowerSlice.actions;
export const { setVehicleKilometers } = borrowerSlice.actions;
export const { setVehicleRegistrationNumber } = borrowerSlice.actions;
export const { setVehicleRegistrationState } = borrowerSlice.actions;
export const { setIsVehicleRegistrationKnown } = borrowerSlice.actions;
export const { setVehicleAssetType } = borrowerSlice.actions;
export const { setVehicleAssetCategory } = borrowerSlice.actions;
export const { setIsVehicleAssetTypeKnownViaParams } = borrowerSlice.actions;
export const { setVehicleSourcePrice } = borrowerSlice.actions;
export const { setVehicleUrl } = borrowerSlice.actions;
export const { setVehicleSource } = borrowerSlice.actions;
export const { setVehicleStockNumber } = borrowerSlice.actions;
export const { setGlassValuation } = borrowerSlice.actions;
export const { setVehicleCondition } = borrowerSlice.actions;
export const { setVehicleSourceYardId } = borrowerSlice.actions;

// Supporting Docs
export const { setSupportingDocs } = borrowerSlice.actions;
export const { deleteSupportingDoc } = borrowerSlice.actions;
// Personal Details
export const { setPersonalDetails } = borrowerSlice.actions;
export const { setBirthdate } = borrowerSlice.actions;
export const { setLicenseNumber } = borrowerSlice.actions;
export const { setLicenseState } = borrowerSlice.actions;
export const { setLicenseExpiry } = borrowerSlice.actions;
export const { setFirstName } = borrowerSlice.actions;
export const { setMiddleName } = borrowerSlice.actions;
export const { setLastName } = borrowerSlice.actions;
// Selected Lender
export const { setSelectedLender } = borrowerSlice.actions;
// Other assets values
export const { setPlantandEquipment } = borrowerSlice.actions;
export const { setHomeandContents } = borrowerSlice.actions;
export const { setSavings } = borrowerSlice.actions;
export const { setSuperandShares } = borrowerSlice.actions;
export const { setOther } = borrowerSlice.actions;
// Credit Cards
export const { setCreditCardByIndex } = borrowerSlice.actions;
export const { setCreditCardActiveIndex } = borrowerSlice.actions;
export const { setCreditCards } = borrowerSlice.actions;
export const { deleteCreditCard } = borrowerSlice.actions;

// Personal Loans
export const { setPersonalLoanByIndex } = borrowerSlice.actions;
export const { setPersonalLoanActiveIndex } = borrowerSlice.actions;
export const { setPersonalLoans } = borrowerSlice.actions;
export const { deletePersonalLoan } = borrowerSlice.actions;

// Motorvehicles assets/liabilities

export const { setMotorVehicleByIndex } = borrowerSlice.actions;
export const { setMotorVehicleActiveIndex } = borrowerSlice.actions;
export const { setMotorVehicles } = borrowerSlice.actions;
export const { deleteMotorVehicle } = borrowerSlice.actions;

// Business
export const { setManualEntry } = borrowerSlice.actions;
export const { setBusiness } = borrowerSlice.actions;
export const { setBusinessName } = borrowerSlice.actions;
export const { setIndustryType } = borrowerSlice.actions;
export const { setAbn } = borrowerSlice.actions;
export const { setAcn } = borrowerSlice.actions;
export const { setAbnActiveFrom } = borrowerSlice.actions;
export const { setGstFrom } = borrowerSlice.actions;
export const { setEntityType } = borrowerSlice.actions;
export const { setNumberOfDirectors } = borrowerSlice.actions;
export const { setTrusteeName } = borrowerSlice.actions;
export const { setTrusteeType } = borrowerSlice.actions;

// Previous Business
export const { setPreviousBusiness } = borrowerSlice.actions;
export const { setPreviousBusinessName } = borrowerSlice.actions;
export const { setPreviousIndustryType } = borrowerSlice.actions;
export const { setPreviousAbn } = borrowerSlice.actions;
export const { setPreviousAcn } = borrowerSlice.actions;
export const { setPreviousAbnActiveFrom } = borrowerSlice.actions;
export const { setPreviousGstFrom } = borrowerSlice.actions;
export const { setPreviousEntityType } = borrowerSlice.actions;
export const { setPreviousNumberOfDirectors } = borrowerSlice.actions;
export const { setPreviousTrusteeName } = borrowerSlice.actions;
export const { setPreviousTrusteeType } = borrowerSlice.actions;

// Real Estates

export const { setRealEstateActiveIndex } = borrowerSlice.actions;
export const { setRealEstateByIndex } = borrowerSlice.actions;
export const { setRealEstates } = borrowerSlice.actions;
export const { deleteRealEstate } = borrowerSlice.actions;

// Solar Details
export const { setSelfAssessedCreditRating } = borrowerSlice.actions;
export const { setProvideBankStatements } = borrowerSlice.actions;
export const { setPreviousAssetFinance } = borrowerSlice.actions;
export const { setSolarRenewableEnergyEquipment } = borrowerSlice.actions;
export const { setLoanType } = borrowerSlice.actions;

// lender sepecific questions
export const { setAnnualRevenue } = borrowerSlice.actions;
export const { setPrimaryIndustry } = borrowerSlice.actions;
export const { setPrimaryBusinessUnitNumber } = borrowerSlice.actions;
export const { setPrimaryBusinessStreetNumber } = borrowerSlice.actions;
export const { setPrimaryBusinessPostCode } = borrowerSlice.actions;
export const { setPrimaryBusinessStateName } = borrowerSlice.actions;
export const { setPrimaryBusinessStreetName } = borrowerSlice.actions;
export const { setPrimaryBusinessSuburbName } = borrowerSlice.actions;

// Scores
export const { setScores } = borrowerSlice.actions;
export const { setSelfAssessedCreditHistory } = borrowerSlice.actions;

// Agreements
export const { setAgreements } = borrowerSlice.actions;
export const { setApplicationId } = borrowerSlice.actions;

// ***** Test Drive *****
export const {
    setTestDrives,
    setTestDriveByIndex,
    setTestDriveActiveIndex,
    setTestDriveDate,
    setTestDriveTime,
    setAgreedToTestDriveAgreement,
    setTestDriveProceededToFinance,
} = borrowerSlice.actions;

export const selectAgreements = (state: RootState): IAgreements[] =>
    state.borrowerReducer.agreements;

export const selectBorrower = (state: RootState): IBorrowerSlice =>
    state.borrowerReducer;
export const selectMobile = (state: RootState): string | undefined =>
    state.borrowerReducer?.personalDetails?.mobilePhone;
export const selectEmail = (state: RootState): string | undefined =>
    state.borrowerReducer.personalDetails.email;
export const selectGender = (state: RootState): EGender | undefined =>
    state.borrowerReducer.personalDetails.gender;
export const selectAddresses = (state: RootState): IAddress[] =>
    state.borrowerReducer.address.address;
export const selectAddressByIndex = (
    state: RootState,
    index: number
): IAddress => state.borrowerReducer.address.address[index];
export const selectResidencyStatus = (
    state: RootState
): EBorrowerCitizenshipStatus | undefined =>
    state.borrowerReducer.personalDetails.citizenshipStatus;
export const selectVisaNumber = (state: RootState): number | undefined =>
    state.borrowerReducer.personalDetails?.visaNumber;
export const selectVisaExpiryDate = (state: RootState): Date | undefined =>
    state.borrowerReducer.personalDetails?.visaExpiryDate;
export const selectHasChildren = (state: RootState): boolean | undefined =>
    state.borrowerReducer.personalDetails?.hasChildren;
export const selectNumberOfChildren = (state: RootState): number | undefined =>
    state.borrowerReducer.personalDetails?.numberOfChildren;
export const selectPaidFrequency = (state: RootState): EFrequency | undefined =>
    state.borrowerReducer.income?.paidFrequency;
export const selectPaidAmount = (state: RootState): number | undefined =>
    state.borrowerReducer.income?.paidAmount;
export const selectOtherIncome = (state: RootState): number | undefined =>
    state.borrowerReducer.income?.otherIncome;
export const selectOtherIncomeSource = (
    state: RootState
): EOtherIncomeSource | undefined =>
    state.borrowerReducer.income?.otherIncomeSource;
export const selectEmploymentStatusByIndex = (
    state: RootState,
    index: number
): IEmployment => state.borrowerReducer.employments.employments[index];
export const selectEmployments = (state: RootState): IEmployment[] =>
    state.borrowerReducer.employments.employments;
export const selectEmploymentIndex = (state: RootState): number =>
    state.borrowerReducer.employments.currentEmploymentIndex;
export const selectOccupation = (state: RootState): string | undefined =>
    state.borrowerReducer.employments.employments[
        state.borrowerReducer.employments.currentEmploymentIndex
    ]?.occupation;
export const selectEmployerName = (state: RootState): string | undefined =>
    state.borrowerReducer.employments.employments[
        state.borrowerReducer.employments.currentEmploymentIndex
    ]?.employerName;
export const selectEmployerPhone = (state: RootState): string | undefined =>
    state.borrowerReducer.employments.employments[
        state.borrowerReducer.employments.currentEmploymentIndex
    ]?.employerPhone;
export const selectEmploymentYears = (state: RootState): number | undefined =>
    state.borrowerReducer.employments.employments[
        state.borrowerReducer.employments.currentEmploymentIndex
    ]?.years;
export const selectEmploymentMonths = (state: RootState): number | undefined =>
    state.borrowerReducer.employments.employments[
        state.borrowerReducer.employments.currentEmploymentIndex
    ]?.months;

// Expenses
export const selectExpensesShared = (state: RootState): boolean | undefined =>
    state.borrowerReducer.expenses.expensesShared;
export const selectBillsPaidFrequency = (
    state: RootState
): EFrequency | undefined => state.borrowerReducer.expenses.billsPaidFrequency;
export const selectGeneral = (state: RootState): number | undefined =>
    state.borrowerReducer.expenses.general;
export const selectTransportVehicle = (state: RootState): number | undefined =>
    state.borrowerReducer.expenses.transportVehicle;
export const selectEducation = (state: RootState): number | undefined =>
    state.borrowerReducer.expenses.education;
export const selectOtherLoans = (state: RootState): number | undefined =>
    state.borrowerReducer.expenses.otherLoans;
export const selectMortgageRent = (state: RootState): number | undefined =>
    state.borrowerReducer.expenses.mortgageRent;
export const selectHealthcare = (state: RootState): number | undefined =>
    state.borrowerReducer.expenses.healthcare;
export const selectNoForseeableChangesToCircumstances = (
    state: RootState
): boolean | undefined =>
    state.borrowerReducer.expenses.noForseeableChangesToCircumstances;
export const selectForseeableChangesExplanation = (
    state: RootState
): string | undefined =>
    state.borrowerReducer.expenses.forseeableChangesExplanation;

// Loan
export const selectLoanUsage = (state: RootState): ELoanUsage =>
    state.borrowerReducer.loan.loanUsage;
export const selectPurchasePrice = (state: RootState): number | undefined =>
    state.borrowerReducer.loan.purchasePrice;

export const selectTradeIn = (state: RootState): number | undefined =>
    state.borrowerReducer.loan.tradeIn;
export const selectDeposit = (state: RootState): number | undefined =>
    state.borrowerReducer.loan.deposit;
export const selectBalloon = (state: RootState): number | undefined =>
    state.borrowerReducer.loan.balloon;
export const selectLoanTerm = (state: RootState): number =>
    state.borrowerReducer.loan.loanTerm;
// Seller
export const selectSellerType = (state: RootState): ESellerType | null =>
    state.borrowerReducer.seller.type;
export const selectSellerName = (state: RootState): string | undefined =>
    state.borrowerReducer.seller.name;
export const selectSellerEmail = (state: RootState): string | undefined =>
    state.borrowerReducer.seller.email;
export const selectSellerPhone = (state: RootState): string | undefined =>
    state.borrowerReducer.seller.phone;
export const selectSellerContactName = (state: RootState): string | undefined =>
    state.borrowerReducer.seller.contactName;
export const selectSellerAccountNumber = (
    state: RootState
): string | undefined => state.borrowerReducer.seller.accountNumber;
export const selectSellerAbn = (state: RootState): string | undefined =>
    state.borrowerReducer.seller.abn;
export const selectSellerLeadEmail = (state: RootState): string | undefined =>
    state.borrowerReducer.seller.leadEmail;

// Vehicle
export const selectIsAssetTypeKnownViaParams = (
    state: RootState
): boolean | undefined =>
    state.borrowerReducer.vehicle.isAssetTypeKnownViaParams;
export const selectVehicle = (state: RootState): IVehicle =>
    state.borrowerReducer.vehicle;
export const selectVehicleVin = (state: RootState): string | undefined =>
    state.borrowerReducer.vehicle.vin;
export const selectVehicleNvic = (state: RootState): string | undefined =>
    state.borrowerReducer.vehicle.nvic;
export const selectVehicleBuildYear = (state: RootState): number | undefined =>
    state.borrowerReducer.vehicle.buildYear;
export const selectVehicleMake = (state: RootState): string | undefined =>
    state.borrowerReducer.vehicle.make;
export const selectVehicleModel = (state: RootState): string | undefined =>
    state.borrowerReducer.vehicle.model;
export const selectVehicleVariant = (state: RootState): string | undefined =>
    state.borrowerReducer.vehicle.variant;
export const selectVehicleSource = (state: RootState): string | undefined =>
    state.borrowerReducer.vehicle.source;
export const selectVehicleDescription = (
    state: RootState
): string | undefined => state.borrowerReducer.vehicle.description;
export const selectIsVehicleKnown = (state: RootState): boolean =>
    state.borrowerReducer.vehicle.isVehicleKnown;
export const selectRegistrationNumber = (
    state: RootState
): string | undefined => state.borrowerReducer.vehicle.registrationNumber;
export const selectVehicleKilometres = (state: RootState): number | undefined =>
    state.borrowerReducer.vehicle.kilometers;
export const selectVehicleRegistrationNumber = (
    state: RootState
): string | undefined => state.borrowerReducer.vehicle.registrationNumber;
export const selectVehicleRegistrationState = (
    state: RootState
): string | undefined => state.borrowerReducer.vehicle.registrationState;
export const selectIsVehicleRegistrationKnown = (
    state: RootState
): boolean | undefined => state.borrowerReducer.vehicle.isRegistrationKnown;
export const selectVehicleAssetType = (
    state: RootState
): EVehicleAssetType | undefined => state.borrowerReducer.vehicle.assetType;
export const selectVehicleAssetCategory = (
    state: RootState
): string | undefined => state.borrowerReducer.vehicle.assetCategory;
export const selectIsVehicleAssetTypeKnownViaParams = (
    state: RootState
): boolean | undefined =>
    state.borrowerReducer.vehicle.isAssetTypeKnownViaParams;
export const selectVehicleSourcePrice = (
    state: RootState
): number | undefined => state.borrowerReducer.vehicle.sourcePrice;
export const selectVehiclePrice = (state: RootState): number | undefined =>
    state.borrowerReducer.vehicle.sourcePrice;
export const selectVehicleUrl = (state: RootState): string | undefined =>
    state.borrowerReducer.vehicle?.url;
export const selectVehicleStockNumber = (
    state: RootState
): string | undefined => state.borrowerReducer.vehicle.stockNumber;
export const selectGlassValuation = (state: RootState): number | undefined =>
    state.borrowerReducer.vehicle.glassValuation;
export const selectVehicleCondition = (
    state: RootState
): ECondition | undefined => state.borrowerReducer.vehicle.condition;
export const selectVehicleSourceYardId = (
    state: RootState
): string | undefined => state.borrowerReducer.vehicle.sourceYardId;

// Supporting Docs
export const selectSupportingDocs = (
    state: RootState
): { [code: string]: ISupportingDoc[] } => state.borrowerReducer.supportingDocs;
// Personal Details
export const selectPersonalDetails = (state: RootState): IPersonalDetails =>
    state.borrowerReducer.personalDetails;
export const selectBirthdate = (state: RootState): Date | undefined => {
    const stateDate = state.borrowerReducer.personalDetails.birthdate;
    if (typeof stateDate === 'string') {
        return new Date(stateDate);
    }
    return stateDate;
};
export const selectLicenseNumber = (state: RootState): string | undefined =>
    state.borrowerReducer.personalDetails.licenseNumber;
export const selectLicenseState = (state: RootState): string | undefined =>
    state.borrowerReducer.personalDetails.licenseState;
export const selectLicenseExpiry = (state: RootState): Date | undefined => {
    const stateDate = state.borrowerReducer.personalDetails.licenseExpiry;
    if (typeof stateDate === 'string') {
        return new Date(stateDate);
    }
    return stateDate;
};
export const selectFirstName = (state: RootState): string | undefined =>
    state.borrowerReducer.personalDetails.firstName;
export const selectMiddleName = (state: RootState): string | undefined =>
    state.borrowerReducer.personalDetails.middleName;
export const selectLastName = (state: RootState): string | undefined =>
    state.borrowerReducer.personalDetails.lastName;
// Selected Lender
export const selectSelectedLender = (state: RootState): ILender | undefined =>
    state.borrowerReducer.selectedLender;

// Other assets values
export const selectPlantandEquipment = (state: RootState): number | undefined =>
    state.borrowerReducer.otherAssets.plantandEquipment;
export const selectHomeandContents = (state: RootState): number | undefined =>
    state.borrowerReducer.otherAssets.homeandContents;
export const selectSavings = (state: RootState): number | undefined =>
    state.borrowerReducer.otherAssets.savings;
export const selectSuperandShares = (state: RootState): number | undefined =>
    state.borrowerReducer.otherAssets.superandShares;
export const selectOther = (state: RootState): number | undefined =>
    state.borrowerReducer.otherAssets.other;

// Credit cards
export const selectCreditCardByIndex = (
    state: RootState,
    index: number
): ICreditCard => {
    return state.borrowerReducer.creditCards?.creditCards[index];
};

export const selectCreditCardActiveIndex = (state: RootState): number => {
    return state.borrowerReducer.creditCards.creditCardActiveIndex;
};
export const selectCreditCards = (state: RootState): ICreditCard[] => {
    return state.borrowerReducer.creditCards.creditCards;
};
// Personal Loans
export const selectPersonalLoanByIndex = (
    state: RootState,
    index: number
): IPersonalLoan => {
    return state.borrowerReducer.personalLoans?.personalLoans[index];
};

export const selectPersonalLoanActiveIndex = (state: RootState): number => {
    return state.borrowerReducer.personalLoans.personalLoanActiveIndex;
};
export const selectPersonalLoans = (state: RootState): IPersonalLoan[] => {
    return state.borrowerReducer.personalLoans.personalLoans;
};
// MOTOR VEHICLE ASSET
export const selectMotorVehiclebyIndex = (
    state: RootState,
    index: number
): IMotorVehicle => {
    return state.borrowerReducer.motorVehicles?.motorVehicles[index];
};
export const selectMotorVehicleActiveIndex = (state: RootState): number => {
    return state.borrowerReducer.motorVehicles.motorVehicleActiveIndex;
};
export const selectMotorVehicles = (state: RootState): IMotorVehicle[] => {
    return state.borrowerReducer.motorVehicles.motorVehicles;
};

// Business
export const selectBusiness = (state: RootState): IBusiness => {
    return state.borrowerReducer.business;
};
export const selectBusinessManualEntry = (
    state: RootState
): boolean | undefined => {
    return state.borrowerReducer.business.manualEntry;
};
export const selectBusinessName = (state: RootState): string | undefined => {
    return state.borrowerReducer.business.name;
};
export const selectIndustryType = (state: RootState): string | undefined => {
    return state.borrowerReducer.business.industry;
};
export const selectAbn = (state: RootState): string | undefined => {
    return state.borrowerReducer.business.abn;
};
export const selectAcn = (state: RootState): string | undefined => {
    return state.borrowerReducer.business.acn;
};
export const selectAbnActiveFrom = (state: RootState): string | undefined => {
    return state.borrowerReducer.business.abnStatusEffectiveFrom;
};
export const selectGstFrom = (state: RootState): string | undefined => {
    return state.borrowerReducer.business.gst;
};
export const selectEntityType = (state: RootState): string | undefined => {
    return state.borrowerReducer.business.entityTypeName;
};
export const selectNumberOfDirectors = (
    state: RootState
): number | undefined => {
    return state.borrowerReducer.business.numberOfDirectors;
};
export const selectTrusteeName = (state: RootState): string | undefined => {
    return state.borrowerReducer.business.trusteeName;
};
export const selectTrusteeType = (
    state: RootState
): ETrusteeType | undefined => {
    return state.borrowerReducer.business.trusteeType;
};
// previous business
export const selectPreviousBusiness = (
    state: RootState
): IBusiness | undefined => {
    return state.borrowerReducer.business.previousBusiness;
};
export const selectPreviousBusinessName = (
    state: RootState
): string | undefined => {
    if (state.borrowerReducer.business.previousBusiness === undefined) return;
    return state.borrowerReducer.business.previousBusiness.name;
};
export const selectPreviousIndustryType = (
    state: RootState
): string | undefined => {
    if (state.borrowerReducer.business.previousBusiness === undefined) return;

    return state.borrowerReducer.business.previousBusiness.industry;
};
export const selectPreviousAbn = (state: RootState): string | undefined => {
    if (state.borrowerReducer.business.previousBusiness === undefined) return;

    return state.borrowerReducer.business.previousBusiness.abn;
};
export const selectPreviousAcn = (state: RootState): string | undefined => {
    if (state.borrowerReducer.business.previousBusiness === undefined) return;

    return state.borrowerReducer.business.previousBusiness.acn;
};
export const selectPreviousAbnActiveFrom = (
    state: RootState
): string | undefined => {
    if (state.borrowerReducer.business.previousBusiness === undefined) return;

    return state.borrowerReducer.business.previousBusiness
        .abnStatusEffectiveFrom;
};
export const selectPreviousGstFrom = (state: RootState): string | undefined => {
    if (state.borrowerReducer.business.previousBusiness === undefined) return;

    return state.borrowerReducer.business.previousBusiness.gst;
};
export const selectPreviousEntityType = (
    state: RootState
): string | undefined => {
    if (state.borrowerReducer.business.previousBusiness === undefined) return;

    return state.borrowerReducer.business.previousBusiness.entityTypeName;
};
export const selectPreviousNumberOfDirectors = (
    state: RootState
): number | undefined => {
    if (state.borrowerReducer.business.previousBusiness === undefined) return;

    return state.borrowerReducer.business.previousBusiness.numberOfDirectors;
};
export const selectPreviousTrusteeName = (
    state: RootState
): string | undefined => {
    if (state.borrowerReducer.business.previousBusiness === undefined) return;

    return state.borrowerReducer.business.previousBusiness.trusteeName;
};
export const selectPreviousTrusteeType = (
    state: RootState
): ETrusteeType | undefined => {
    if (state.borrowerReducer.business.previousBusiness === undefined) return;

    return state.borrowerReducer.business.previousBusiness.trusteeType;
};

// Real Estate
export const selectRealEstatebyIndex = (
    state: RootState,
    index: number
): IRealEstate => {
    return state.borrowerReducer.realEstates?.realEstates[index];
};
export const selectRealEstateActiveIndex = (state: RootState): number => {
    return state.borrowerReducer.realEstates.realEstateActiveIndex;
};
export const selectRealEstates = (state: RootState): IRealEstate[] => {
    return state.borrowerReducer.realEstates.realEstates;
};

// Solar Details
export const selectPreviousAssetFinance = (
    state: RootState
): boolean | undefined => state.borrowerReducer.solar?.previousAssetFinance;
export const selectCanProvideBankStatements = (
    state: RootState
): boolean | undefined => state.borrowerReducer.solar?.canProvideBankStatements;

export const selectSelfAssessedCreditRating = (
    state: RootState
): ECreditRating | undefined =>
    state.borrowerReducer.solar?.selfAssessedCreditRating;
export const selectSolarRenewableEnergyEquipment = (
    state: RootState
): string | undefined => {
    if (
        state.borrowerReducer.solar?.solarRenewableEnergyEquipment === undefined
    )
        return;
    return state.borrowerReducer.solar?.solarRenewableEnergyEquipment;
};
export const selectLoanType = (state: RootState): ELoanType =>
    state.borrowerReducer.loan?.loanType;

// lender specific questions
export const selectAnnualRevenue = (state: RootState): number | undefined => {
    if (state.borrowerReducer.business === undefined) return undefined;

    return state.borrowerReducer.business?.annualRevenue;
};
export const selectPrimaryIndustry = (state: RootState): string | undefined => {
    if (state.borrowerReducer.business === undefined) return undefined;

    return state.borrowerReducer.business?.primaryIndustry;
};
//primary business address
export const selectPrimaryBusinessUnitNumber = (
    state: RootState
): string | undefined => {
    return state.borrowerReducer.business.primaryBusinessUnitNumber;
};
export const selectPrimaryBusinessStreetNumber = (
    state: RootState
): string | undefined => {
    return state.borrowerReducer.business.primaryBusinessStreetNumber;
};
export const selectPrimaryBusinessStreetName = (
    state: RootState
): string | undefined => {
    return state.borrowerReducer.business.primaryBusinessStreetName;
};
export const selectPrimaryBusinessSuburbName = (
    state: RootState
): string | undefined => {
    return state.borrowerReducer.business.primaryBusinessSuburbName;
};
export const selectPrimaryBusinessPostCode = (
    state: RootState
): number | undefined => {
    return state.borrowerReducer.business.primaryBusinessPostCode;
};
export const selectPrimaryBusinessStateName = (
    state: RootState
): string | undefined => {
    return state.borrowerReducer.business.primaryBusinessStateName;
};

// Scores
export const selectScores = (state: RootState): IScores => {
    return state.borrowerReducer.scores;
};
export const selectSelfAssessedCreditHistory = (
    state: RootState
): ESelfAssessedCreditHistory | undefined => {
    return state.borrowerReducer.scores.selfAssessedCreditHistory;
};

// Application
export const selectIsPreapproved = (state: RootState): boolean | undefined => {
    return state.borrowerReducer.application.isPreApproved;
};
export const selectProgressPercent = (state: RootState): number | undefined => {
    return state.borrowerReducer.application.progressPercent;
};

// reducer
const borrowerReducer = borrowerSlice.reducer;
export { borrowerReducer };

// ***** Test Drive *****
export const selectTestDrives = (state: RootState): ITestDriveState => {
    return state.borrowerReducer.testDrives;
};
export const selectAgreedToTestDriveAgreement = (
    state: RootState
): boolean | undefined => {
    return state.borrowerReducer.testDrives.testDrives[
        state.borrowerReducer.testDrives.currentTestDriveIndex
    ]?.agreedToTestDriveAgreement;
};
export const selectActiveTestDriveIndex = (state: RootState): number => {
    return state.borrowerReducer.testDrives.currentTestDriveIndex;
};
export const selectTestDriveByIndex = (
    state: RootState,
    index: number
): ITestDrive => {
    return state.borrowerReducer.testDrives.testDrives[index];
};
export const selectActiveTestDrive = (state: RootState): ITestDrive => {
    return state.borrowerReducer.testDrives.testDrives[
        state.borrowerReducer.testDrives.currentTestDriveIndex
    ];
};
export const selectTestDriveDate = (state: RootState): Date | undefined => {
    return state.borrowerReducer.testDrives.testDrives[
        state.borrowerReducer.testDrives.currentTestDriveIndex
    ]?.date;
};
export const selectTestDriveTime = (state: RootState): Date | undefined => {
    return state.borrowerReducer.testDrives.testDrives[
        state.borrowerReducer.testDrives.currentTestDriveIndex
    ]?.time;
};
