import { setVehicleMake } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialVehicleMake(make: IParameter): void {
  if (!make) {
    return;
  }

  const stateMake = store.getState().borrowerReducer.vehicle.make;

  if (stateMake !== make) {
    store.dispatch(setVehicleMake(make));
  }
}
