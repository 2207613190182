import { EFrequency } from './borrowerSliceTypes';

export interface IExpenses {
  expensesShared?: boolean | undefined;
  billsPaidFrequency?: EFrequency | undefined;
  general?: number | undefined;
  transportVehicle?: number | undefined;
  education?: number | undefined;
  otherLoans?: number | undefined;
  mortgageRent?: number | undefined;
  healthcare?: number | undefined;
  noForseeableChangesToCircumstances?: boolean | undefined;
  forseeableChangesExplanation?: string | undefined;
}

export const initialExpenses: IExpenses = {};
