import { useState } from 'react';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button, EButtonType } from '@/components/Button';
import { ModalComponent } from '@/components/ModalComponent';
import { ModalHeader } from '@/components/ModalHeader';
import { MultipleUserInput } from '@/components/MultipleUserInput';
import {
    selectEntityType,
    selectPreviousAbn,
    selectPreviousIndustryType,
    selectPreviousNumberOfDirectors,
} from '@/context/borrower';
import { EDisplays, setDisplay, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { PreviousAbn } from '@/UserInput/PreviousAbn';
import { PreviousAbnActiveFrom } from '@/UserInput/PreviousAbnActiveFrom';
import { PreviousAcn } from '@/UserInput/PreviousAcn';
import { PreviousBusinessIndustry } from '@/UserInput/PreviousBusinessIndustry';
import { PreviousBusinessLookup } from '@/UserInput/PreviousBusinessLookup';
import { PreviousGstFrom } from '@/UserInput/PreviousGstFrom';
import { PreviousNumberOfDirectors } from '@/UserInput/PreviousNumberOfDirectors';

export const testId = 'PreviousBusinessCheck';

export function PreviousBusinessCheck(): JSX.Element {
    // ***** CONSTANTS *****
    const baseId = 'modal-previousBusiness';
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const entityType = useAppSelector(selectEntityType);
    const dispatch = useAppDispatch();
    const previousIndustryType = useAppSelector(selectPreviousIndustryType);
    const previousAbn = useAppSelector(selectPreviousAbn);
    const previousNumberOfDirectors = useAppSelector(
        selectPreviousNumberOfDirectors
    );

    function handleSaveClick(): void {
        setShowErrors(true);
        if (!isComplete()) return;
        createOrUpdateBorrower();
        const nextScreen = getNextScreen();
        dispatch(setDisplay(nextScreen));
        dispatch(setModal(null));
    }
    function handleSkipClick(): void {
        dispatch(setModal(null));
        const nextScreen = getNextScreen();
        dispatch(setDisplay(nextScreen));
    }

    function getNextScreen(): EDisplays {
        return EDisplays.BASIC;
    }

    // ***** Helpers *****
    function isComplete(): boolean {
        if (!previousIndustryType) return false;
        if (!previousAbn) return false;
        if (!previousNumberOfDirectors) return false;

        return true;
    }

    return (
        <>
            <ModalComponent baseId={baseId}>
                <ModalHeader
                    title='Did you previously own a business?'
                    baseId={baseId}
                />
                <PreviousBusinessIndustry
                    baseId={baseId}
                    showErrors={showErrors}
                />
                <PreviousBusinessLookup
                    baseId={baseId}
                    showErrors={showErrors}
                />
                <MultipleUserInput>
                    <PreviousAbn baseId={baseId} showErrors={false} />
                    {entityType === 'COMPANY' ? (
                        <PreviousAcn baseId={baseId} showErrors={false} />
                    ) : (
                        <div className='hidden' />
                    )}
                </MultipleUserInput>
                <MultipleUserInput>
                    <PreviousAbnActiveFrom baseId={baseId} showErrors={false} />
                    {entityType === 'COMPANY' ? (
                        <PreviousGstFrom baseId={baseId} showErrors={false} />
                    ) : (
                        <div className='hidden' />
                    )}
                </MultipleUserInput>
                <MultipleUserInput>
                    <PreviousNumberOfDirectors
                        baseId={baseId}
                        showErrors={showErrors}
                    />
                    <div className='hidden'></div>
                </MultipleUserInput>
                <div className='_flex space-x-[25px] pt-4'>
                    <Button
                        id={`${baseId}-button-save`}
                        onClick={handleSaveClick}
                        type={EButtonType.SECONDARY_OUTLINE}
                    >
                        <p className='text-xs'>Save</p>
                    </Button>
                    <Button
                        id={`${baseId}-button-skip`}
                        onClick={handleSkipClick}
                        type={EButtonType.PRIMARY}
                    >
                        <p className='text-xs'>Skip</p>
                    </Button>
                </div>
            </ModalComponent>
        </>
    );
}
