import { setPartnerNumber } from '@/context/display';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialDisplaySellerPhone(
  parameterPhone: IParameter,
  partnerPhone: string | null
): void {
  const statePhone = store.getState().displayReducer.partnerNumber;

  const phoneToSave = parameterPhone ?? partnerPhone;

  if (phoneToSave && statePhone !== phoneToSave) {
    store.dispatch(setPartnerNumber(phoneToSave));
  }
}
