import { EDisplays } from '@/context/display';
import { ILender } from '@/context/lender';

export const initialShownLenders: IShownLenders[] = [];

export interface IShownLenders {
    shownLenders: ILender[];
    screenShown: EDisplays;
    vehicleUrl: string;
    passedToIQ?: boolean;
}
