import { useSelector } from 'react-redux';

import { SummarySection } from '@/components/SummarySection';
import {
  selectAbnActiveFrom,
  selectBusinessName,
  selectEntityType,
} from '@/context/borrower';
import { EDisplays, setDisplay } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';
import { getStringFromState } from '@/utils/getStringFromState';

export const testId = 'BusinessInformationSolar';

export interface IProps {
  showErrors: boolean;
  baseId: string;
}

export function BusinessInformationSolar({
  showErrors,
  baseId,
}: IProps): JSX.Element {
  // ***** Redux State *****
  const dispatch = useAppDispatch();

  const businessName = useSelector(selectBusinessName);
  const entityType = useSelector(selectEntityType);
  const activeFrom = useSelector(selectAbnActiveFrom);

  // ***** Event Handlers *****
  function handleClick(): void {
    dispatch(setDisplay(EDisplays.SOLAR_LOAN_DETAILS));
  }

  // ***** Helpers *****
  function isComplete(): boolean {
    if (businessName === null || businessName === undefined) return false;
    if (entityType === null || entityType === undefined) return false;
    if (activeFrom === null || activeFrom === undefined) return false;

    return true;
  }

  function fullText(): string {
    return `My business is ${getStringFromState(
      businessName
    )}. The entity is ${getStringFromState(
      entityType
    )} and has been active since ${getStringFromState(activeFrom)}`;
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <SummarySection
        onClick={handleClick}
        id={`${baseId}-button-addressInformationEdit`}
        text={fullText()}
        heading='Business Details'
        showErrors={showErrors && !isComplete()}
      />
    </div>
  );
}
