import { store } from '@/context/store';

import { isPersonalLoanComplete } from '../isPersonalLoanComplete';

export function isAllPersonalLoansComplete(canBeEmpty = false): boolean {
  const state = store.getState();
  const personalLoans = state.borrowerReducer.personalLoans.personalLoans;

  // Early Exit
  if ( !personalLoans ||
    (personalLoans?.length === 0 &&
    personalLoans[0]?.lenderName === undefined)
  ) {
    return canBeEmpty;
  }

  let isComplete = true;
  personalLoans?.map((personalLoan) => {
    if (!isPersonalLoanComplete(personalLoan)) {
      isComplete = false;
    }
  });

  return isComplete;
}
