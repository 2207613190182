import { useState } from 'react';

import { DisplayComponent } from '@/components/DisplayComponent';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import { AmountPaid } from '@/UserInput/AmountPaid';
import { EmploymentStatus } from '@/UserInput/EmploymentStatus';
import { OtherIncome } from '@/UserInput/OtherIncome';
import { PaidFrequency } from '@/UserInput/PaidFrequency';

import { IncomeButtons } from './IncomeButtons';

export const testId = 'Income';

function Income(): JSX.Element {
    const baseId = 'screen-income';
    // ***** Local State *****
    const [showErrors, setShowErrors] = useState(false);

    return (
        <DisplayComponent baseId={baseId} data-testid={testId}>
            <Header />
            <ProgressBar />
            <DisplayHeading
                title='Income Details'
                infoText='This section looks at your source of income and employment. Hover on each field for more info.'
            />
            <EmploymentStatus showErrors={showErrors} baseId={baseId} />
            <PaidFrequency showErrors={showErrors} baseId={baseId} />
            <AmountPaid showErrors={showErrors} baseId={baseId} />
            <OtherIncome showErrors={showErrors} baseId={baseId} />
            <IncomeButtons setShowErrors={setShowErrors} baseId={baseId} />
        </DisplayComponent>
    );
}

export { Income };
