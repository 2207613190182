import { ECondition, setVehicleCondition } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialVehicleCondition(
    params: IParameter,
    partner: ECondition | undefined
): void {
    const stateCondition = store.getState().borrowerReducer.vehicle.condition;
    let paramsCondition: ECondition | undefined = undefined;
    const partnerCondition = partner;

    if (params) {
        // Check if the "params" condition is one of the enums
        const paramsConditionEnum =
            ECondition[params.toUpperCase() as keyof typeof ECondition];
        if (paramsConditionEnum) {
            paramsCondition = paramsConditionEnum;
        }
    }

    if (!paramsCondition && !partnerCondition) {
        return;
    }

    if (paramsCondition && stateCondition !== paramsCondition) {
        store.dispatch(setVehicleCondition(paramsCondition));
        return;
    }

    if (partnerCondition && stateCondition !== partnerCondition) {
        store.dispatch(setVehicleCondition(partnerCondition));
        return;
    }
}
