import { PayloadAction } from '@reduxjs/toolkit';

import { ESellerType } from '../types';

export const sellerReducers = {
  setSellerType: (state, action: PayloadAction<ESellerType>): void => {
    state.seller.type = action.payload;
  },
  setSellerName: (state, action: PayloadAction<string | undefined>): void => {
    state.seller.name = action.payload;
  },
  setSellerEmail: (state, action: PayloadAction<string | undefined>): void => {
    state.seller.email = action.payload;
  },
  setSellerPhone: (state, action: PayloadAction<string | undefined>): void => {
    state.seller.phone = action.payload;
  },
  setSellerContactName: (
    state,
    action: PayloadAction<string | undefined>
  ): void => {
    state.seller.contactName = action.payload;
  },
  setSellerAccountNumber: (
    state,
    action: PayloadAction<string | undefined>
  ): void => {
    state.seller.accountNumber = action.payload;
  },
  setSellerAbn: (state, action: PayloadAction<string | undefined>): void => {
    state.seller.abn = action.payload;
  },
  setSellerLeadEmail: (
    state,
    action: PayloadAction<string | undefined>
  ): void => {
    state.seller.leadEmail = action.payload;
  },
};
