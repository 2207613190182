import { ModalComponent } from '@/components/ModalComponent';
import { ModalHeader } from '@/components/ModalHeader';
import { selectPartner } from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';

export function Disclaimer(): JSX.Element {
    // ***** Constants *****
    const baseId = 'modal-Disclaimer';

    const partner = useAppSelector(selectPartner);
    const { quickQuotePrivacyPolicy } = partner;

    console.log('quickQuotePrivacyPolicy:', quickQuotePrivacyPolicy);

    // ***** Render *****
    return (
        <ModalComponent
            baseId={baseId}
            className=' w-full h-full max-h-[90vh] '
        >
            <ModalHeader title='Disclaimer' baseId={baseId} />

            <p className='text-sm pb-4'>
                {quickQuotePrivacyPolicy.split('\n').map((line, index) => (
                    <span key={index}>
                        {line}
                        <br />
                    </span>
                ))}
            </p>
        </ModalComponent>
    );
}
