import { useState } from 'react';

import { Combobox, IComboboxOption } from '@/components/Combobox';
import {
    EState,
    selectPrimaryBusinessStateName,
    setPrimaryBusinessStateName,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'PrimaryBusinessAddressState';

export function PrimaryBusinessAddressState({
    showErrors,
    baseId,
}: IProps): JSX.Element {
    // Initial options
    const options: IComboboxOption[] = [
        { id: 1, value: EState.NSW },
        { id: 2, value: EState.VIC },
        {
            id: 3,
            value: EState.QLD,
        },
        { id: 4, value: EState.ACT },
        { id: 5, value: EState.WA },
        { id: 6, value: EState.NT },
        { id: 7, value: EState.SA },
        { id: 8, value: EState.TAS },
    ];

    const dispatch = useAppDispatch();
    const primaryBusinessStateName = useAppSelector(
        selectPrimaryBusinessStateName
    );
    // *****State*****
    const [query, setQuery] = useState<undefined | string>(); // Keep track of search

    // *****Functions*****
    // Handle the onChange event for the input.
    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        setQuery(value);
        if (typeof value === 'boolean') return;
        if (value?.length > 3) return;
    }

    // When the user selects an option
    function handleSelect(option: IComboboxOption): void {
        dispatch(setPrimaryBusinessStateName(option.value as EState));
    }

    // *****Render*****
    return (
        <div data-testid={testId}>
            <Combobox
                options={options}
                placeholder='State'
                heading='State Name'
                onChange={handleChange}
                query={query ?? ''}
                onSelect={handleSelect}
                showErrors={showErrors && !primaryBusinessStateName}
                baseId={baseId}
            />
        </div>
    );
}
