import {
    EVehicleAssetType,
    setIsVehicleAssetTypeKnownViaParams,
    setVehicleAssetType,
} from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialVehicleType(
    parameterType: IParameter,
    partnerInitialType: EVehicleAssetType
): void {
    const stateCondition = store.getState().borrowerReducer.vehicle.assetType;
    let paramsCondition: EVehicleAssetType | undefined = undefined;
    const partnerCondition = partnerInitialType;

    if (parameterType) {
        // Check if the "params" condition is one of the enums
        const paramsConditionEnum =
            EVehicleAssetType[
                parameterType.toUpperCase() as keyof typeof EVehicleAssetType
            ];
        if (paramsConditionEnum) {
            store.dispatch(setIsVehicleAssetTypeKnownViaParams(true));
            paramsCondition = paramsConditionEnum;
        }
    }

    const conditionToUse = paramsCondition ?? partnerCondition;

    if (stateCondition !== conditionToUse) {
        store.dispatch(setVehicleAssetType(conditionToUse));
    }
}
