import { Input } from '@/components/Input';
import {
  selectPrimaryBusinessStreetName,
  setPrimaryBusinessStreetName,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'PrimaryBusinessStreetName';

export function PrimaryBusinessStreetName({
  baseId,
  showErrors,
}: IProps): JSX.Element {
  // ***** Redux *****
  const primaryBusinessStreetName = useAppSelector(
    selectPrimaryBusinessStreetName
  );
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    if (typeof value !== 'string') return;

    dispatch(setPrimaryBusinessStreetName(value));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Street Name'
        placeholder='George St'
        showErrors={showErrors && !primaryBusinessStreetName}
        onChange={handleChange}
        id={`${baseId}-input-unitNumber`}
        value={primaryBusinessStreetName?.toLocaleString() ?? ''}
      />
    </div>
  );
}
