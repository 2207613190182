
import { CheckBox } from '@/components/CheckBox';
import {
    selectAgreedToTermsAndConditions,
    selectTermsAndConditionsLink,
    setAgreedToTermsAndConditions,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

interface ITermsAndConditionsProps {
    baseId: string;
}

export function TermsAndConditions({ baseId }: ITermsAndConditionsProps): JSX.Element {
    // ***** Redux State *****
    const termsAndConditions = useAppSelector(selectAgreedToTermsAndConditions);
    const termsLink = useAppSelector(selectTermsAndConditionsLink);
    const dispatch = useAppDispatch();

    // ***** Handlers *****
    function handleCheckBoxClick(): void {
        dispatch(
            setAgreedToTermsAndConditions({
                isDefaultPrivacyAgreed: {
                    agreed: !termsAndConditions.isDefaultPrivacyAgreed?.agreed,
                    date: new Date(),
                },
            })
        );
    }

    function handleTermsAndConditionsClick(): void {
        window.open(termsLink);
    }

    return (
        <div className='_flex space-x-2 py-2'>
            <CheckBox
                id={`${baseId}-checkbox-termsAndConditions`}
                onClick={handleCheckBoxClick}
                checked={
                    termsAndConditions.isDefaultPrivacyAgreed?.agreed || false
                }
            />

            <div className='text-xs text-textBody'>
                I have read & agreed to the{' '}
                <span
                    className={`text-secondary underline hover:cursor-pointer hover:text-secondaryDark `}
                >
                    <span onClick={handleTermsAndConditionsClick}>
                        Terms & Conditions
                    </span>
                </span>
            </div>
        </div>
    );
}
