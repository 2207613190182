import { useState } from 'react';

import { getQuickQuote } from '@/apiCalls/matchingEngine';
import { DisplayComponent } from '@/components/DisplayComponent';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';

import { TDContactDetails } from './TDContactDetails';
import { TDOcrSummaryButtons } from './TDOcrSummaryButtons';
import { TDPersonalInformation } from './TDPersonalInformation';

export const testId = 'TestDriveOcrSummary';

export function TestDriveOcrSummary(): JSX.Element {
    // ***** Local State *****
    const [showErrors, setShowErrors] = useState(false);

    // ***** CONSTANTS *****
    const baseId = 'screen-TestDriveOcrSummary';

    getQuickQuote();

    return (
        <DisplayComponent baseId={baseId} data-testid={testId}>
            <Header />
            <ProgressBar />
            <DisplayHeading
                title='Details Summary'
                infoText="Here's what we've got so far. Something missing or not quite right? Click on box or the 'edit' button to fix it up."
                subtitle='Check your details below'
            />
            <TDContactDetails baseId={baseId} showErrors={showErrors} />
            <TDPersonalInformation baseId={baseId} showErrors={showErrors} />
            <TDOcrSummaryButtons
                baseId={baseId}
                setShowErrors={setShowErrors}
            />
        </DisplayComponent>
    );
}
