import { store } from '@/context/store';

import { VISA_EXPIRY_MAX_LIMIT, VISA_EXPIRY_MIN_LIMIT } from '../constants';
import { isDateValid } from '../generalDateHelper';
import { maximumDateHelper } from '../maximumDateHelper';
import { minimumDateHelper } from '../minimumDateHelper';
import { returnDate } from '../returnDate';

export function isVisaExpiryDateValid(): boolean {
  const state = store.getState();
  const visaExpiryDate = state.borrowerReducer.personalDetails.visaExpiryDate;
  if (
    isDateValid(
      returnDate(visaExpiryDate),
      minimumDateHelper(VISA_EXPIRY_MIN_LIMIT),
      maximumDateHelper(VISA_EXPIRY_MAX_LIMIT)
    )
  )
    return true;
  return false;
}
