import { useEffect } from 'react';

import { useAppSelector } from '@/context/storeHooks';
import { selectIsLoggedIn } from '@/context/user';
import {
    ELocalStorageVariables,
    getLocalStorageItem,
} from '@/utils/localStorage';
import { EEvents, subscribeToSseEvent } from '@/utils/subscribeToSseEvent';

interface Props {
    eventName: EEvents;
    onOpen?: () => void;
    onMessage: (message: any) => void;
    onError?: (error: any) => void;
}

export function EventSubscriber({
    eventName,
    onOpen,
    onMessage,
    onError,
}: Props): JSX.Element {
    const isLoggedIn = useAppSelector(selectIsLoggedIn);

    // Subscribe to SSE event
    useEffect(() => {
        const token = getLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);

        if (token) {
            const connection = subscribeToSseEvent(
                token,
                eventName,
                onMessage,
                onError,
                onOpen
            );

            // Close connection on component dismount
            return () => {
                connection.close();
            };
        }
    }, [isLoggedIn]);

    // Empty div to render nothing
    return <></>;
}
