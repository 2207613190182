import { ECreditRating } from './commonTypes';

export interface ISolar {
  previousAssetFinance?: boolean | undefined;
  canProvideBankStatements?: boolean | undefined;
  solarRenewableEnergyEquipment?: string;
  selfAssessedCreditRating?: ECreditRating | undefined;
}

export const initialSolar: ISolar = {};
