import {
  ELenderRepaymentFrequency,
  ELenderSortedBy,
  ILender,
} from '../LenderTypes';

export function sortLenders(
  frequency: ELenderRepaymentFrequency | ELenderSortedBy,
  lenders: ILender[]
): ILender[] {
  switch (frequency) {
    default:
    case ELenderRepaymentFrequency.MONTHLY:
      return lenders.sort(
        (a, b) => a.monthlyRepaymentAmount - b.monthlyRepaymentAmount
      );
    case ELenderRepaymentFrequency.FORTNIGHTLY:
      return lenders.sort(
        (a, b) => a.fortnightlyRepaymentAmount - b.fortnightlyRepaymentAmount
      );
    case ELenderRepaymentFrequency.WEEKLY:
      return lenders.sort(
        (a, b) => a.weeklyRepaymentAmount - b.weeklyRepaymentAmount
      );
    case ELenderSortedBy.ADVERTISED_RATE:
      return lenders.sort(
        (a, b) => a.advertisedRateInPercent - b.advertisedRateInPercent
      );
    case ELenderSortedBy.COMPARISON_RATE:
      return lenders.sort(
        (a, b) => a.comparisonRateInPercent - b.comparisonRateInPercent
      );
    case ELenderSortedBy.ONGOING_FEE:
      return lenders.sort(
        (a, b) => a.monthlyOngoingFeeAmount - b.monthlyOngoingFeeAmount
      );
    case ELenderSortedBy.REPAYMENT:
      return lenders.sort(
        (a, b) => a.monthlyRepaymentAmount - b.monthlyRepaymentAmount
      );
    case ELenderSortedBy.TURNAROUND_TIME:
      return lenders.sort(
        (a, b) => a.turnaroundTimeInDays - b.turnaroundTimeInDays
      );
    case ELenderSortedBy.UPFRONT_FEE:
      return lenders.sort((a, b) => a.upfrontFeeAmount - b.upfrontFeeAmount);
  }
}
