import { useState } from 'react';

import { Input } from '@/components/Input';
import { selectMobile, setMobile } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { isMobile } from '@/utils/isMobile';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'MobileNumber';

export function MobileNumber({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectMobile);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        setErrors(false);
        if (typeof value === 'boolean') return;
        if (value === currentState) return;
        if (value === '') {
            dispatch(setMobile(value));
            return;
        }
        if (value?.length > 10) return;
        const lastDigit = value[value?.length - 1];
        if (isNaN(Number(lastDigit))) {
            return;
        }
        dispatch(setMobile(value));
    }

    const [errors, setErrors] = useState(false); // This is used to show errors after the user has blurred the input

    function getShowErrors(): boolean {
        if (showErrors === true) return true;
        if (errors === true) return true;
        return false;
    }

    function handleBlur(): void {
        if (isMobile(currentState)) {
            return;
        }
        setErrors(true);
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Mobile Number'
                placeholder='04...'
                showErrors={getShowErrors()}
                onChange={handleChange}
                id={`${baseId}-input-mobileNumber`}
                value={currentState?.toLocaleString() ?? ''}
                onBlur={handleBlur}
                showNumpad={true}
            />
        </div>
    );
}
