import { useEffect, useState } from 'react';

import { DisplayComponent } from '@/components/DisplayComponent';
import { Header } from '@/components/Header';
import { MultipleUserInput } from '@/components/MultipleUserInput';
import { ProgressBar } from '@/components/ProgressBar';
import { Slogan } from '@/components/Slogan';
import {
    selectIsAssetTypeKnownViaParams,
    selectIsVehicleKnown,
} from '@/context/borrower';
import {
    EDisplays,
    selectHideVehicleScreen,
    selectIsAssetKnownViaParams,
    selectPreviousDisplay,
} from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';
import { Balloon } from '@/UserInput/Balloon';
import { Deposit } from '@/UserInput/Deposit';
import { LoanUsage } from '@/UserInput/LoanUsage';
import { TradeIn } from '@/UserInput/TradeIn';
import { VehicleAssetType } from '@/UserInput/VehicleAssetType';
import { VehicleCondition } from '@/UserInput/VehicleCondition';
import { VehicleKnown } from '@/UserInput/VehicleKnown';

import { LoanButtons } from './LoanButtons';

export const testId = 'Loan';

export function Loan(): JSX.Element {
    // ***** CONSTANTS *****
    const baseId = 'screen-loanDetails';
    const previousDisplay = useAppSelector(selectPreviousDisplay);
    const hideVehicleScreen = useAppSelector(selectHideVehicleScreen);
    const isAssetKnownViaParams = useAppSelector(selectIsAssetKnownViaParams);
    const isAssetTypeKnownViaParams = useAppSelector(
        selectIsAssetTypeKnownViaParams
    );
    const wasPreviousDisplayIndicativelyApproved =
        previousDisplay === EDisplays.INDICATIVELY_APPROVED;
    const isVehicleKnown = useAppSelector(selectIsVehicleKnown);

    const [showDoYouKnowTheVehicleButtons, setShowDoYouKnowTheVehicleButtons] =
        useState(true);

    useEffect(() => {
        setShowDoYouKnowTheVehicleButtons(showDoYouKnowTheVehicle());
    }, [isAssetKnownViaParams, hideVehicleScreen]);

    function showDoYouKnowTheVehicle(): boolean {
        if (isAssetKnownViaParams) {
            if (!hideVehicleScreen) {
                return true;
            }
            return false;
        }
        return true;
    }

    return (
        <DisplayComponent baseId={baseId} data-testid={testId}>
            <Header />
            <Slogan />
            <ProgressBar />
            <LoanUsage showErrors={false} baseId={baseId} />
            {!isAssetTypeKnownViaParams && (
                <VehicleAssetType showErrors={false} baseId={baseId} />
            )}

            <MultipleUserInput>
                <TradeIn showErrors={false} baseId={baseId} />
                <Deposit showErrors={false} baseId={baseId} />
            </MultipleUserInput>
            <MultipleUserInput>
                <Balloon showErrors={false} baseId={baseId} />
                <div className='w-full' />
            </MultipleUserInput>
            {!wasPreviousDisplayIndicativelyApproved &&
                showDoYouKnowTheVehicleButtons && (
                    <VehicleKnown showErrors={false} baseId={baseId} />
                )}
            {showDoYouKnowTheVehicleButtons && isVehicleKnown && (
                <VehicleCondition showErrors={false} baseId={baseId} />
            )}
            <LoanButtons baseId={baseId} />
        </DisplayComponent>
    );
}
