import { loadScanware } from '@/actions/loadScanware';
import { ECampaign, EUserFlow } from '@/context/borrower';
import {
    EJourneys,
    EModals,
    setModal,
    setScanwareQrId,
    setShowScanwarePopup,
} from '@/context/display';
import { store } from '@/context/store';
import { EPartners } from '@/partners/partnerTypes';
import { IParameter } from '@/utils/setParametersFromParams';

import { getInitialModalScreen } from '../getInitialModalScreen';

export function setInitialModal(
    partnerId: EPartners,
    paramQrScanwareId: IParameter,
    initialJourney: EJourneys,
    userFlow: EUserFlow
): void {
    // hardcoded no modal for test drive journey
    if (initialJourney === EJourneys.TEST_DRIVE) {
        store.dispatch(setModal(null));
        return;
    }
    // hardcoded campaign disclaimer modal for campaign journey
    if (userFlow === EUserFlow.CAMPAIGN) {
        // todo This should be moved to config! Hardcoded cause 4 hours notice =(
        const campaign = store.getState().displayReducer.partner.campaign;
        const url = window.location.href;
        const isLotus = url.includes('lotus');
        if (campaign === ECampaign.YAMAHA) {
            store.dispatch(setModal(null));
            return;
        } else if (isLotus) {
            store.dispatch(setModal(null));
            return;
        } else {
            store.dispatch(setModal(EModals.CAMPAIGN_DISCLAIMER));
            return;
        }
    }

    const state = store.getState();
    const { showWelcomeScreen, modal } = state.displayReducer;
    const { isLoggedIn } = state.userReducer;

    let modalToSet: EModals | null = null;

    if (paramQrScanwareId) {
        store.dispatch(setShowScanwarePopup(true));
        store.dispatch(setScanwareQrId(paramQrScanwareId));

        modalToSet = EModals.IMOTOR_STOCK;
        loadScanware();
    } else if (showWelcomeScreen && isLoggedIn) {
        store.dispatch(setModal(EModals.DEALERSHIP_WELCOME));
        modalToSet = EModals.DEALERSHIP_WELCOME;
        return;
    } else {
        modalToSet = getInitialModalScreen(partnerId);
    }

    if (modalToSet === modal) return;
    store.dispatch(setModal(modalToSet));
}
