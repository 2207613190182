import {
    ELoanUsage,
    EMoneyType,
    EUserFlow,
    EVehicleAssetType,
} from '@/context/borrower';

import { DEFAULT_PARTNER } from './defaultPartner';
import { ELenderSorting, EMatchingEngineLenders } from './matchingEngineEnums';
import { EPartners, IPartnerParams } from './partnerTypes';

export const TRUCKSTOP_PARTNER_NAME = 'Truckstop';

export const TRUCKSTOP_PARTNER: IPartnerParams = {
    ...DEFAULT_PARTNER,
    id: EPartners.TRUCKSTOP,
    whiteLabel: true,
    loanUsage: ELoanUsage.BUSINESS,
    dealerName: TRUCKSTOP_PARTNER_NAME,
    primaryColor: '#1e3a4b',
    secondaryColor: '#ff374f',
    logo: 'odin.png',
    availableVehicleAssetTypes: [
        {
            label: 'Car, Van or Ute',
            type: EVehicleAssetType.COMMERCIAL_VEHICLES_LESS_THEN_4_5_TONNES,
            tooltip: 'Car, van or ute: Excludes all rigid and other trucks.',
        },
        {
            label: 'Truck',
            type: EVehicleAssetType.TRUCK,
            tooltip: 'Truck: Trucks under 4.5t GVM.',
        },
        {
            label: 'Prime Mover',
            type: EVehicleAssetType.PRIME_MOVER,
            tooltip: 'Prime Mover: Prime movers or trucks over 4.5t GVM.',
        },
        {
            label: 'Trailer',
            type: EVehicleAssetType.TRAILERS,
            tooltip: 'Trailer: Trailers.',
        },
        {
            label: 'Other',
            type: EVehicleAssetType.OTHER,
            tooltip: 'Other: Anything not better covered by the above options',
        },
    ],
    lenderSorting: ELenderSorting.LOW_DOC,
    dealerEmail: 'admin@odinfinance.com.au',
    dealerPhone: '1300144542',
    requireGlassGuide: false,
    termsAndConditionsLink: 'https://odinfinance.com.au/privacy-policy',
    illionCodes: {
        threeMonths: 'TS3M',
        sixMonths: 'TS6M',
        twelveMonths: 'TS12M',
    },
    waterfallLenders: [
        {
            lenders: [
                EMatchingEngineLenders.ANGLE_FINANCE, //
                EMatchingEngineLenders.CAPITAL, //
                EMatchingEngineLenders.FLEXI, //
                EMatchingEngineLenders.SCOTTISH_PACIFIC, //
                EMatchingEngineLenders.THORN, //
                EMatchingEngineLenders.WESTPAC, //
                EMatchingEngineLenders.FIRST_MAC, //
                EMatchingEngineLenders.FIRST_MAC_FIXED, //
                EMatchingEngineLenders.PEPPER, //
                EMatchingEngineLenders.WISR_CAR_LOAN, //
                EMatchingEngineLenders.PLENTI, //
            ],
            showLenderDetails: true,
        },
        {
            lenders: [
                EMatchingEngineLenders.AUTOPAY_BROKER, //
                EMatchingEngineLenders.BRANDED_BROKER, //
                EMatchingEngineLenders.FINANCE_ONE, //
                EMatchingEngineLenders.FIRST_MAC_FIXED,
                EMatchingEngineLenders.GEDDA_MONEY, //
                EMatchingEngineLenders.GROW_ASSET_FINANCE, //
                EMatchingEngineLenders.LATITUDE_FINANCE,
                EMatchingEngineLenders.LIBERTY, //
                EMatchingEngineLenders.METRO, //
                EMatchingEngineLenders.MONEY_TECH, //
                EMatchingEngineLenders.PLENTI_COMMERCIAL, //
                EMatchingEngineLenders.RESIMAC, //
                EMatchingEngineLenders.SCOTTISH_PACIFIC,
                EMatchingEngineLenders.SHIFT, //
            ],
            showLenderDetails: true,
        },
    ],
    financeTypeCommission: {
        consumer: {
            value: 0,
            type: EMoneyType.PERCENTAGE,
        },
        commercial: {
            value: 0,
            type: EMoneyType.PERCENTAGE,
        },
    },
    splitTestUserFlow: EUserFlow.TRUCKSTOP_NEW_SCREEN,
    splitTest: true,
    userFlow: EUserFlow.EARLY_SIGNUP,
};
