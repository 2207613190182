import { setDeposit } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialLoanDepositAmount(
    parameterLoanDepositAmount: IParameter
): void {
    const currentDeposit = store.getState().borrowerReducer.loan.deposit;

    const parsedParameter = Number(parameterLoanDepositAmount);
    if (!isNaN(parsedParameter) && parsedParameter > 0) {
        if (currentDeposit !== parsedParameter) {
            store.dispatch(setDeposit(parsedParameter));
            return;
        }
    }
}
