import { RadioGroup } from '@/components/RadioGroup';
import {
    selectHasChildren,
    setHasChildren,
    setNumberOfChildren,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { NumberOfKids } from '@/UserInput/NumberOfKids';

export interface IHaveKidsProps {
    baseId: string;
    showErrors: boolean;
}

export function HaveKids({ baseId, showErrors }: IHaveKidsProps): JSX.Element {
    // ***** Redux *****
    const hasChildren = useAppSelector(selectHasChildren);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean | undefined): void {
        if (value === undefined) {
            dispatch(setNumberOfChildren(undefined));
            return;
        }

        dispatch(setHasChildren(value === 'YES' ? true : false));
    }

    // ***** Render *****
    const options = [
        {
            label: 'Yes',
            value: 'YES',
            id: `${baseId}-button-hasChildren-yes`,
        },
        {
            label: 'No',
            value: 'NO',
            id: `${baseId}-button-hasChildren-no`,
        },
    ];
    return (
        <div data-testid='haveKids'>
            <RadioGroup
                title='do you have kids?'
                showErrors={showErrors && hasChildren === undefined}
                onChange={handleChange}
                options={options}
                value={
                    hasChildren === undefined
                        ? undefined
                        : hasChildren
                        ? 'YES'
                        : 'NO'
                }
                tooltipContent='Only those under 18 years old'
            />
            {hasChildren && (
                <NumberOfKids baseId={baseId} showErrors={showErrors} />
            )}
        </div>
    );
}
