import { setSellerAbn } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialSellerAbn(
  parameterAbn: IParameter,
  partnerAbn: IParameter
): void {
  const sellerAbn = store.getState().borrowerReducer.seller.accountNumber;

  const abnToSave = parameterAbn ?? partnerAbn;

  if (abnToSave && sellerAbn !== abnToSave) {
    store.dispatch(setSellerAbn(abnToSave));
  }
}
