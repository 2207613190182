import { EVehicleAssetType } from '@/context/borrower';

export function getGlassAssetType(assetType?: EVehicleAssetType): string {
    switch (assetType) {
        case EVehicleAssetType.MOTORBIKES_UNREG:
        case EVehicleAssetType.MOTORBIKES_REG:
        case EVehicleAssetType.MOTORCYCLE:
            return 'motorcycle';
        case EVehicleAssetType.PRIME_MOVERS:
        case EVehicleAssetType.TRUCKS_RIGID_4_5:
        case EVehicleAssetType.TRUCKS_LT_4_5:
        case EVehicleAssetType.TRUCK_GT_4_5:
        case EVehicleAssetType.TRUCK_LT_4_5:
            return 'truck';
        case EVehicleAssetType.MOTOR_VEHICLES:
        case EVehicleAssetType.MOTOR_VEHICLES_SEC:
        case EVehicleAssetType.GREY_IMPORTS:
        case EVehicleAssetType.COMM_VEH_LT_4_5:
        default:
            return 'vehicle';
    }
}

export function isCar(assetType?: EVehicleAssetType): boolean {
    switch (assetType) {
        case EVehicleAssetType.MOTOR_VEHICLES:
        case EVehicleAssetType.MOTOR_VEHICLES_SEC:
        case EVehicleAssetType.GREY_IMPORTS:
        case EVehicleAssetType.COMM_VEH_LT_4_5:
            return true;
        default:
            return false;
    }
}
