import { DisplayButtons } from '@/components/DisplayButtons';
import {
    EUserFlow,
    EVehicleAssetType,
    selectAbn,
    selectAbnActiveFrom,
    selectIndustryType,
    selectIsVehicleKnown,
    selectNumberOfDirectors,
    selectVehicleAssetType,
} from '@/context/borrower';
import {
    EDisplays,
    EModals,
    selectHideSellerScreen,
    selectHideVehicleScreen,
    selectUserFlow,
    setDisplay,
    setModal,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { formatStringToDate } from '@/utils/formatStringToDate';
import { getDateYearsBack } from '@/utils/getDateYearsBack';

interface IBusinessButtonsProps {
    setShowErrors: (value: boolean) => void;
    baseId: string;
}

export function BusinessButtons({
    setShowErrors,
    baseId,
}: IBusinessButtonsProps): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const hideSeller = useAppSelector(selectHideSellerScreen);
    const hideVehicle = useAppSelector(selectHideVehicleScreen);
    const industryType = useAppSelector(selectIndustryType);
    const businessAbn = useAppSelector(selectAbn);
    const numberOfDirectors = useAppSelector(selectNumberOfDirectors);
    const isVehicleKnown = useAppSelector(selectIsVehicleKnown);
    const vehicleAssetType = useAppSelector(selectVehicleAssetType);
    const abnActiveFrom = useAppSelector(selectAbnActiveFrom);
    const userFlow = useAppSelector(selectUserFlow);

    // ***** Event Handlers ****
    function handleBackClick(): void {
        const backScreen = getBackScreen();
        dispatch(setDisplay(backScreen));
    }

    function handleNextClick(): void {
        setShowErrors(true);
        if (!isComplete()) return;

        // Check date
        const abnDate = formatStringToDate(abnActiveFrom);
        if (abnDate === undefined) return;

        if (abnDate > getDateYearsBack(2)) {
            dispatch(setModal(EModals.PREVIOUS_BUSINESS_CHECK));
            return;
        }
        const nextScreen = getNextScreen();
        dispatch(setDisplay(nextScreen));
    }

    // ***** Helpers *****
    function isComplete(): boolean {
        if (!industryType) return false;
        if (!businessAbn) return false;
        if (!numberOfDirectors) return false;

        return true;
    }

    function getNextScreen(): EDisplays {
        return EDisplays.BASIC;
    }

    function getBackScreen(): EDisplays {
        if (userFlow === EUserFlow.CAMPAIGN) {
            return EDisplays.CAMPAIGN_LANDING;
        }

        if (!isVehicleKnown) {
            return EDisplays.LOAN;
        }
        if (!hideSeller) {
            return EDisplays.SELLER;
        }
        if (!hideVehicle) {
            if (
                vehicleAssetType ===
                EVehicleAssetType.COMMERCIAL_VEHICLES_LESS_THEN_4_5_TONNES
            ) {
                return EDisplays.VEHICLE;
            }
            return EDisplays.MANUAL_VEHICLE;
        }
        return EDisplays.LOAN;
    }

    return (
        <DisplayButtons
            idLeftButton={`${baseId}-button-back`}
            idRightButton={`${baseId}-button-next`}
            rightClick={handleNextClick}
            leftClick={handleBackClick}
        />
    );
}
