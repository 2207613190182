import { getScanwareById } from '@/apiCalls/scanware';
import { setVehicleStockNumber } from '@/context/borrower';
import { store } from '@/context/store';

export async function loadScanware(): Promise<void> {
    const res = await getScanwareById();
    if (res?.stockNumber) {
        store.dispatch(setVehicleStockNumber(res.stockNumber));
    }
}
