import { displaySlice } from '../displaySlice';

export const { setVehicleBuildYearApiResults } = displaySlice.actions;
export const { setVehicleMakeApiResults } = displaySlice.actions;
export const { setVehicleModelApiResults } = displaySlice.actions;
export const { setVehicleVariantApiResults } = displaySlice.actions;
export const { setGlassOptionsForVehicle } = displaySlice.actions;
export const { setVehicleModelCodeForApiCall } = displaySlice.actions;
export const { setVehicleMakeCodeForApiCall } = displaySlice.actions;
export const { setFullLogoUrl } = displaySlice.actions;
export const { setRequireGlassGuide } = displaySlice.actions;
