export function setCssVariables(variables: {
  [variableName: string]: string | null;
}): void {
  Object.keys(variables).forEach((variableName) => {
    document.documentElement.style.setProperty(
      `--${variableName}`,
      variables[variableName]
    );
  });
}

