import { uniqueIdTransfer } from '@/apiCalls/uniqueIDTransferHelper';

import { EEventType } from '../eventType';

export interface IAssetsNavigationButtonsProps {
    handleDeleteClick: () => void;
    handleNextClick: () => void;
    handlePreviousClick: () => void;
    hasPrevious: boolean;
    hasNext: boolean;
    baseId: string;
}

export function AssetsNavigationButtons({
    handleDeleteClick,
    handleNextClick,
    handlePreviousClick,
    hasPrevious,
    hasNext,
    baseId,
}: IAssetsNavigationButtonsProps): JSX.Element {
    function previousClick(): void {
        uniqueIdTransfer({
            eventType: EEventType.CLICK,
            applicationData: { id: `${baseId}-button-previous` },
        });
        handlePreviousClick();
    }
    function nextOrAddClick(): void {
        uniqueIdTransfer({
            eventType: EEventType.CLICK,
            applicationData: {
                id: `${baseId}-button-${hasNext ? 'next' : 'add'}`,
            },
        });
        handleNextClick();
    }

    function deleteClick(): void {
        uniqueIdTransfer({
            eventType: EEventType.CLICK,
            applicationData: { id: `${baseId}-button-delete` },
        });
        handleDeleteClick();
    }
    return (
        <div className='_flex justify-between'>
            <button
                className='text-secondary'
                onClick={previousClick}
                disabled={hasPrevious === false}
                id={`${baseId}-button-previous`}
            >
                {hasPrevious && (
                    <p className='text-sm text-secondary underline'>Previous</p>
                )}
            </button>
            <button
                className='text-sm text-secondary underline'
                onClick={nextOrAddClick}
                id={`${baseId}-button-${hasNext ? 'next' : 'add'}`}
            >
                {hasNext ? 'Next' : 'Add'}
            </button>
            <button
                className='text-sm text-primary underline'
                onClick={deleteClick}
                id={`${baseId}-button-delete`}
            >
                <p>Delete</p>
            </button>
        </div>
    );
}
