import { DisplayComponent } from '@/components/DisplayComponent';
import { GenericLenderCard } from '@/components/GenericLenderCard';
import { Header } from '@/components/Header';
import { MultipleUserInput } from '@/components/MultipleUserInput';
import { ProgressBar } from '@/components/ProgressBar';
import { Slogan } from '@/components/Slogan';
import { ECampaign } from '@/context/borrower';
import { selectCampaign } from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';
import { Balloon } from '@/UserInput/Balloon';
import { Deposit } from '@/UserInput/Deposit';
import { LoanAmount } from '@/UserInput/LoanAmount';
import { LoanTerm } from '@/UserInput/LoanTerm';
import { LoanUsage } from '@/UserInput/LoanUsage';
import { PurchasePrice } from '@/UserInput/PurchasePrice';

import { CampaignLandingButton } from './CampaignLandingButton';

export const testId = 'CampaignLanding';

export function CampaignLanding(): JSX.Element {
    // ***** CONSTANTS *****
    const baseId = 'screen-CampaignLanding';
    const url = window.location.href;
    const isLotus = url.includes('lotus');

    // ***** STATE *****
    const campaign = useAppSelector(selectCampaign);

    function getMaxLoanTerm(): number {
        if (isLotus) return 7;
        if (campaign === ECampaign.YAMAHA) return 3;
        return 4;
    }

    return (
        <DisplayComponent baseId={baseId} data-testid={testId}>
            <Header />
            <Slogan />
            <ProgressBar />
            <MultipleUserInput>
                <PurchasePrice
                    showErrors={false}
                    baseId={baseId}
                    title='Purchase Price'
                />
                <Balloon showErrors={false} baseId={baseId} />
            </MultipleUserInput>
            <MultipleUserInput>
                <Deposit showErrors={false} baseId={baseId} />
                <LoanAmount baseId={baseId} />
            </MultipleUserInput>
            <LoanTerm
                showErrors={false}
                baseId={baseId}
                maxTerm={getMaxLoanTerm()}
            />
            <LoanUsage showErrors={false} baseId={baseId} />
            <GenericLenderCard />
            <div className='h-4 w-full' />
            <CampaignLandingButton />
            <div />
        </DisplayComponent>
    );
}
