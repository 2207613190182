import {
  CheckIcon,
} from '@heroicons/react/24/solid';

export function CheckIconCircle(): JSX.Element {
  return (
    <div className='h-5 w-5 border rounded-full p-1 border-green-500 text-green-500 transition-transform duration-500 ease-in-out'>
      <CheckIcon className='' />
    </div>
  );
}
