import { Input } from '@/components/Input';
import {
    selectDeposit,
    selectPurchasePrice,
    selectTradeIn,
} from '@/context/borrower';
import { useAppSelector } from '@/context/storeHooks';
import { calculateLoanAmount } from '@/utils/calculateLoanAmount';

export interface IProps {
    baseId: string;
}

export const testId = 'LoanAmount';

export function LoanAmount({ baseId }: IProps): JSX.Element {
    // ***** Redux *****
    const purchasePrice = useAppSelector(selectPurchasePrice);
    const deposit = useAppSelector(selectDeposit);
    const tradeIn = useAppSelector(selectTradeIn);

    const loanAmount = calculateLoanAmount(purchasePrice, deposit, tradeIn);

    // ***** Render *****
    return (
        <div data-testid={testId} className='w-full'>
            <Input
                title={'Amount to Borrow'}
                placeholder=''
                id={`${baseId}-input-LoanAmount`}
                value={loanAmount.toLocaleString()}
                prefix='$'
                showNumpad={true}
                disabled
                tooltipContent='The calculated loan amount based on the purchase price and deposit.'
            />
        </div>
    );
}
