import { RootState } from '@/context/store';

import { EDisplays, EJourneys, EModals } from '../types';

export const selectDisplay = (state: RootState): EDisplays =>
    state.displayReducer.display;
export const selectPreviousDisplay = (
    state: RootState
): EDisplays | undefined => state.displayReducer.previousDisplay;
export const selectModal = (state: RootState): EModals | null =>
    state.displayReducer.modal;
export const selectIsLoaded = (state: RootState): boolean =>
    state.displayReducer.isLoaded;
export const selectIllionCodes = (
    state: RootState
): {
    threeMonths: string;
    sixMonths: string;
    twelveMonths: string;
} => state.displayReducer.illionCodes;

export const selectIsAssetAndLiabilitiesSubmitSkipped = (
    state: RootState
): boolean => state.displayReducer.isAssetAndLiabilitiesSubmitSkipped;

export const selectShowScanwarePopup = (state: RootState): boolean =>
    state.displayReducer.showScanwarePopup;
export const selectScanwareQrId = (state: RootState): string | undefined =>
    state.displayReducer.scanwareQrId;
export const selectInitialJourney = (state: RootState): EJourneys =>
    state.displayReducer.initialJourney;
export const selectVehicleManualEntry = (state: RootState): boolean =>
    state.displayReducer.vehicleManualEntry;

export const selectIsPriceKnownViaExternal = (state: RootState): boolean =>
    state.displayReducer.isPriceKnownViaExternal;
