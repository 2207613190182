import { RadioGroup } from '@/components/RadioGroup';
import {
    EBorrowerCitizenshipStatus,
    selectResidencyStatus,
    setResidencyStatus,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

import { VisaExpiryDate } from '../VisaExpiryDate';
import { VisaNumber } from '../VisaNumber';

export interface IResidencyStatus {
    baseId: string;
    showErrors: boolean;
}

export function ResidencyStatus({
    baseId,
    showErrors,
}: IResidencyStatus): JSX.Element {
    // ***** Redux *****
    const residencyStatus = useAppSelector(selectResidencyStatus);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean | undefined): void {
        dispatch(setResidencyStatus(value as EBorrowerCitizenshipStatus));
        if (value === EBorrowerCitizenshipStatus.VISA_HOLDER) return;
    }

    // ***** Render *****
    const options = [
        {
            label: 'Citizen',
            value: EBorrowerCitizenshipStatus.AUSTRALIAN_CITIZEN,
            id: `${baseId}-button-residencyStatus-citizen`,
        },
        {
            label: 'Permanent Resident',
            value: EBorrowerCitizenshipStatus.PERMANENT_RESIDENT,
            id: `${baseId}-button-residencyStatus-permanentResident`,
        },
        {
            label: 'Visa',
            value: EBorrowerCitizenshipStatus.VISA_HOLDER,
            id: `${baseId}-button-residencyStatus-visaHolder`,
        },
    ];
    return (
        <>
            <div data-testid='residencyStatus'>
                <RadioGroup
                    title='residency status'
                    showErrors={showErrors}
                    onChange={handleChange}
                    options={options}
                    value={residencyStatus}
                    tooltipContent='This should be based on where you currently live.'
                />
            </div>
            {residencyStatus === EBorrowerCitizenshipStatus.VISA_HOLDER && (
                <div className='_flex space-x-2 flex-center'>
                    <div style={{ flex: 1 }}>
                        <VisaNumber showErrors={showErrors} baseId={baseId} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <VisaExpiryDate
                            showErrors={showErrors}
                            baseId={baseId}
                        />
                    </div>
                </div>
            )}
        </>
    );
}
