export function formatStringToDate(
  dateString: string | undefined
): Date | undefined {
  if (dateString === undefined) return undefined;
  const [dd, mm, yyyy] = dateString.split('/');
  const isoDateString = `${yyyy}-${mm}-${dd}T00:00:00.000Z`;

  const date = new Date(Date.parse(isoDateString));
  return date;
}
