import { setSellerName } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialSellerName(
  paramaterPartnerName: IParameter,
  partnerName: IParameter
): void {
  const { name: stateName } = store.getState().borrowerReducer.seller;

  const sellerName = paramaterPartnerName ?? partnerName;

  if (sellerName && stateName !== sellerName) {
    store.dispatch(setSellerName(sellerName));
  }
}
