import { EFrequency } from './borrowerSliceTypes';

export interface IIncome {
  paidFrequency?: EFrequency | undefined;
  paidAmount?: number | undefined;
  otherIncome?: number | undefined;
  otherIncomeSource?: EOtherIncomeSource | undefined;
  verifierIncome?: number | undefined;
}

export enum EOtherIncomeSource {
  RENTAL_OR_INVESMENT = 'RENTAL_OR_INVESMENT',
  GOVERNMENT_BENEFITS = 'GOVERNMENT_BENEFITS',
  OTHER = 'OTHER',
}

export const initialIncome: IIncome = {};
