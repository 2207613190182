import { useState } from 'react';

import { Combobox, IComboboxOption } from '@/components/Combobox';
import { selectIndustryType, setIndustryType } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'BusinessIndustry';

export const BusinessIndustry = ({
    showErrors,
    baseId,
}: IProps): JSX.Element => {
    // Initial options
    const Industries: IComboboxOption[] = [
        { id: 1, value: 'Agriculture' },
        { id: 2, value: 'Basic material wholesaling' },
        {
            id: 3,
            value: 'Building cleaning, pest control and other support services',
        },
        { id: 4, value: 'Construction' },
        { id: 5, value: 'Mining' },
        { id: 6, value: 'Manufacturing' },
        { id: 7, value: 'Food and beverage services' },
        { id: 8, value: 'Removalist' },
        { id: 9, value: 'Earthmoving/Civil' },
        { id: 10, value: 'Transport' },
        { id: 11, value: 'Waste collection and water supply' },
        { id: 12, value: 'Warehousing and storage services' },
        { id: 13, value: 'Other' },
    ];

    // ***** Redux State *****
    const dispatch = useAppDispatch();

    const industry = useAppSelector(selectIndustryType);

    // *****State*****
    const [query, setQuery] = useState<undefined | string>(industry); // Keep track of search
    const [options, setOptions] = useState<IComboboxOption[]>([
        ...Industries,
        { id: 14, value: industry ?? '' },
    ]); // Keep track of options to display

    // *****Functions*****
    // Handle the onChange event for the input.
    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        setQuery(value);
        setOptions([...Industries, { id: 14, value: value }]);
    }

    // When the user selects an option
    function handleSelect(option: IComboboxOption): void {
        dispatch(setIndustryType(option.value));
    }

    // *****Render*****
    return (
        <Combobox
            options={options}
            placeholder='Removalist'
            heading='Industry Type'
            onChange={handleChange}
            query={query ?? ''}
            onSelect={handleSelect}
            showErrors={showErrors && !industry}
            baseId={baseId}
            tooltipContent='Select the industry your business or employer operates in.'
        />
    );
};
