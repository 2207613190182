import { store } from '@/context/store';
import { EPartners } from '@/partners/partnerTypes';

declare global {
    interface Window {
        gtag: (command: string, event: string, config: any) => void;
    }
}

export function reportConversion(eventId: EConversionType): void {
    const partnerId = store.getState().displayReducer.partner.id;
    if (partnerId !== EPartners.TRUCKSTOP) return;

    const url = window.location.href;
    if (!url) return;

    let id = '';

    switch (eventId) {
        case EConversionType.LEAD_CAPTURE:
            id = 'sjy8COD9it0YEOHF7fIp';
            break;
        case EConversionType.SUBMIT_LOAN_REQUEST:
            id = 'pfu-CJqB77oYEOHF7fIp';
            break;
        case EConversionType.INSTANT_PRE_APPROVAL:
            id = 'xlaXCOP9it0YEOHF7fIp';
            break;
        case EConversionType.APPLY_TO_LENDER:
            id = 'k_sPCOb9it0YEOHF7fIp';
            break;
        default:
            return;
    }

    gtag_report_conversion(id);
}

export enum EConversionType {
    LEAD_CAPTURE = 'LEAD_CAPTURE', // sjy8COD9it0YEOHF7fIp
    SUBMIT_LOAN_REQUEST = 'SUBMIT_LOAN_REQUEST', // pfu-CJqB77oYEOHF7fIp
    INSTANT_PRE_APPROVAL = 'INSTANT_PRE_APPROVAL', // xlaXCOP9it0YEOHF7fIp
    APPLY_TO_LENDER = 'APPLY_TO_LENDER', // k_sPCOb9it0YEOHF7fIp
}

function gtag_report_conversion(id: string): void {
    // window.gtag('event', 'conversion', {
    //     // send_to: `AW-11246723809/${id}`,
    // });
}
