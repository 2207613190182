import { TestDriveReadyButton } from '../TestDriveReadyButton';

interface Props {
    baseId: string;
}

function TDRFinance({ baseId }: Props): JSX.Element {
    return (
        <div className='absolute top-[65%] w-full px-4 '>
            <div className='bg-white border w-full rounded-lg py-6 px-4'>
                <h3 className='text-center font-bold text-2xl pb-2'>Looking For Finance?</h3>
                <p className='text-center pb-4'>
                    Enquire or apply through our 24/7
                    <br />
                    customer self-service finance solution
                </p>
                <TestDriveReadyButton baseId={baseId} />
            </div>
        </div>
    );
}

export default TDRFinance;
