import { MultipleUserInput } from '@/components/MultipleUserInput';
import { selectEntityType } from '@/context/borrower';
import { useAppSelector } from '@/context/storeHooks';
import { Abn } from '@/UserInput/Abn';
import { AbnActiveFrom } from '@/UserInput/AbnActiveFrom';
import { Acn } from '@/UserInput/Acn';
import { BusinessLookup } from '@/UserInput/BusinessLookup';
import { GstFrom } from '@/UserInput/GstFrom';
import { NumberOfDirectors } from '@/UserInput/NumberOfDirectors';

interface Props {
    baseId: string;
    showErrors: boolean;
}

export function BusinessLookupSection({
    baseId,
    showErrors,
}: Props): JSX.Element {
    const entityType = useAppSelector(selectEntityType);
    return (
        <>
            <BusinessLookup baseId={baseId} showErrors={showErrors} />
            <MultipleUserInput>
                <Abn baseId={baseId} showErrors={false} />
                {entityType === 'COMPANY' ? (
                    <Acn baseId={baseId} showErrors={false} />
                ) : (
                    <div className='hidden' />
                )}
            </MultipleUserInput>
            <MultipleUserInput>
                <AbnActiveFrom baseId={baseId} showErrors={false} />
                {entityType === 'COMPANY' ? (
                    <GstFrom baseId={baseId} showErrors={false} />
                ) : (
                    <div className='hidden' />
                )}
            </MultipleUserInput>
            <MultipleUserInput>
                <NumberOfDirectors baseId={baseId} showErrors={showErrors} />
                <div className='hidden' />
            </MultipleUserInput>
        </>
    );
}
