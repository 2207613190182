import { useSelector } from 'react-redux';

import { Input } from '@/components/Input';
import {
    selectRealEstateActiveIndex,
    selectRealEstatebyIndex,
    setRealEstateByIndex,
} from '@/context/borrower';
// eslint-disable-next-line no-restricted-imports
import { IRealEstate } from '@/context/borrower/types/realEstateAssetTypes';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
    setShowErrors: (value: boolean) => void;
}

export const testId = 'RealEstateMonthlyRepayment';

export function RealEstateMonthlyRepayment({
    baseId,
    showErrors,
    setShowErrors,
}: IProps): JSX.Element {
    // ***** Redux *****
    const activeRealEstateIndex = useAppSelector(selectRealEstateActiveIndex);

    const realEstate = useSelector((state: RootState) =>
        selectRealEstatebyIndex(state, activeRealEstateIndex)
    );
    const monthlyRepayment = realEstate?.monthlyRepayment;

    const dispatch = useAppDispatch();
    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        setShowErrors(false);

        if (realEstate === null) return;
        if (typeof value !== 'string') return;
        const MAXIMUM = 9999999;
        const intValue: number | undefined = getNumber(value, MAXIMUM);
        // if (intValue && intValue > 9999999) return;
        if (realEstate === null) return;

        const newRealEstate: IRealEstate = {
            ...realEstate,
            monthlyRepayment: intValue,
        };
        if (intValue && isNaN(intValue)) {
            newRealEstate.monthlyRepayment = undefined;
        }
        dispatch(
            setRealEstateByIndex({
                index: activeRealEstateIndex,
                realEstate: newRealEstate,
            })
        );
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Monthly Repayment'
                placeholder='What is your monthly repayment?'
                showErrors={showErrors && !monthlyRepayment}
                onChange={handleChange}
                id={`${baseId}-input-monthlyRepayment`}
                value={monthlyRepayment?.toLocaleString() ?? ''}
                prefix='$'
                showNumpad={true}
            />
        </div>
    );
}
