import { store } from '@/context/store';

import { isCreditCardComplete } from '../isCreditCardComplete';

export function isAllCreditCardsComplete(canBeEmpty = false): boolean {
  const state = store.getState();
  const creditCards = state.borrowerReducer.creditCards.creditCards;

  // Early Exit
  if (!creditCards || (creditCards?.length === 0 && creditCards[0]?.lenderName === undefined)) {
    return canBeEmpty;
  }

  let isComplete = true;
  creditCards?.map((creditCard) => {
    if (!isCreditCardComplete(creditCard)) {
      isComplete = false;
    }
  });

  return isComplete;
}
