import { Input } from '@/components/Input';
import {
  ESellerType,
  selectSellerContactName,
  selectSellerType,
  setSellerContactName,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'DealershipContactName';

export function DealershipContactName({
  baseId,
  showErrors,
}: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectSellerContactName);
  const sellerType = useAppSelector(selectSellerType);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    if (typeof value === 'boolean') return;
    if (value === currentState) return;
    if (value?.length > 20) return;
    dispatch(setSellerContactName(value));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title={`${
          sellerType === ESellerType.DEALERSHIP && 'Dealership '
        }contact name`}
        placeholder='Optional'
        showErrors={showErrors}
        onChange={handleChange}
        id={`${baseId}-input-dealershipContactName`}
        value={currentState?.toLocaleString() ?? ''}
      />
    </div>
  );
}
