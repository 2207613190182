import { Input } from '@/components/Input';
import { selectSuperandShares, setSuperandShares } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'SuperandShares';

export function SuperandShares({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectSuperandShares);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        // MAXIMUM
        const MAXIMUM = 999999999;
        const intValue: number | undefined = getNumber(value, MAXIMUM);

        if (intValue && intValue > MAXIMUM) return;
        if (intValue === currentState) return;
        dispatch(setSuperandShares(intValue));
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Super & shares'
                placeholder='$'
                showErrors={showErrors}
                onChange={handleChange}
                id={`${baseId}-input-superAndShares`}
                value={currentState?.toLocaleString() ?? ''}
                prefix='$'
                showNumpad={true}
                tooltipContent='What would be the value of your super funds and shares?'
            />
        </div>
    );
}
