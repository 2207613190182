import assert from 'assert';

import { EMoneyType } from '@/context/borrower';
// NOTE: If these change, the loan term slider css will have to be updated
export const MAX_LOAN_TERM = 7;
export const MIN_LOAN_TERM = 1;
export const MAXIMUM_BALLOON_PERCENT = 50;
export const MAXIMUM_LOANTERM_FOR_BALLOON = 7;

export const EMAIL = 'lodge@lodge.finance';
export const PHONE = '1300 031 264';

export const SOLAR_LOAN_TERMS: number[] = [3, 4, 5, 6, 7];
export const SOLAR_ORIGINATION_FEE = 440;

export const MIN_EMPLOYMENT_YEAR = 3;

export const CURRENT_YEAR = 2024;

export const NAME_REGEX = /^[^0-9]*$/;

export const DEFAULT_COMMISSION = 3;
export const DEFAULT_COMMISSION_TYPE = EMoneyType.PERCENTAGE;

export const DEMO_NAME_TRIGGER = 'Donald Duck';

// date things
export const VISA_EXPIRY_MAX_LIMIT = 10;
export const VISA_EXPIRY_MIN_LIMIT = 10;
export const LICENSE_EXPIRY_MIN_LIMIT = 10;
export const LICENSE_EXPIRY_MAX_LIMIT = 10;
export const BIRTHDATE_MIN_LIMIT = 100;
export const BIRTHDATE_MAX_LIMIT = new Date();

export const ERROR_MESSAGE_REGO_NOT_FOUND =
    'Sorry, we were unable to find that plate. Please check that the Registration Number and State are correct or select the Not Yet option.';

export const DEFAULT_TERMS_AND_CONDITIONS_LINK =
    'http://www.vroom.finance/privacy';

export const IMAGE_URL =
    'https://driveiq-assets.s3.ap-southeast-2.amazonaws.com/vroom';

// ***** Backend URL *****
assert.ok(process.env.REACT_APP_API_URL, 'REACT_APP_API_URL is not set');
export const API_URL = process.env.REACT_APP_API_URL;

// ***** Environment *****
assert.ok(process.env.REACT_APP_ENV, 'REACT_APP_ENV is not set');
export const REACT_APP_ENV = process.env.REACT_APP_ENV;

// ***** Bank Statements *****
assert.ok(
    process.env.REACT_APP_BANK_STATEMENTS_REFERRER_CODE_3M,
    'REACT_APP_BANK_STATEMENTS_REFERRER_CODE_3M is not set'
);
export const REACT_APP_BANK_STATEMENTS_REFERRER_CODE_3M =
    process.env.REACT_APP_BANK_STATEMENTS_REFERRER_CODE_3M;

assert.ok(
    process.env.REACT_APP_BANK_STATEMENTS_REFERRER_CODE_6M,
    'REACT_APP_BANK_STATEMENTS_REFERRER_CODE_6M is not set'
);
export const REACT_APP_BANK_STATEMENTS_REFERRER_CODE_6M =
    process.env.REACT_APP_BANK_STATEMENTS_REFERRER_CODE_6M;

assert.ok(
    process.env.REACT_APP_BANK_STATEMENTS_REFERRER_CODE_12M,
    'REACT_APP_BANK_STATEMENTS_REFERRER_CODE_12M is not set'
);
export const REACT_APP_BANK_STATEMENTS_REFERRER_CODE_12M =
    process.env.REACT_APP_BANK_STATEMENTS_REFERRER_CODE_12M;

assert.ok(
    process.env.REACT_APP_BANK_STATEMENT_ENDPOINT,
    'REACT_APP_BANK_STATEMENT_ENDPOINT is not set'
);
export const REACT_APP_BANK_STATEMENT_ENDPOINT =
    process.env.REACT_APP_BANK_STATEMENT_ENDPOINT;
