import { useMemo } from 'react';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button, EButtonType } from '@/components/Button';
import { ModalComponent } from '@/components/ModalComponent';
import { ModalHeader } from '@/components/ModalHeader';
import { EDisplays, selectAgreedToTermsAndConditions, selectDisplay, setDisplay, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { ExtendedTermsAndConditions } from '@/UserInput/ExtendedTermsAndConditions';

export function ExpressPrivacy(): JSX.Element {

    const baseId = 'modal-ExpressPrivacy';
    const dispatch = useAppDispatch();
    const display = useAppSelector(selectDisplay);
    const termsAndConditions = useAppSelector(selectAgreedToTermsAndConditions);

    const isReady = useMemo<boolean>(() => {
        if (!termsAndConditions.isDefaultPrivacyAgreed?.agreed
            || !termsAndConditions.isEquifaxVerificationExchangeAgreed?.agreed
            || !termsAndConditions.isMarketingConsentAgreed?.agreed
        ) {
            return false;
        }

        return true;
    }, [termsAndConditions]);

    function handleNextButtonClick(): void {
        if (!isReady) {
            return;
        }

        createOrUpdateBorrower().then(() => {
            dispatch(setModal(null));

            if (display === EDisplays.SERVICING_SUCCESS) {
                dispatch(setDisplay(EDisplays.IDENTIFICATION));
            }
        });
    }

    return (
        <ModalComponent baseId={baseId} className='relative w-full h-full overflow-x-hidden'>
            <ModalHeader title='PRIVACY DISCLOSURE STATEMENT & CONSENT' baseId={baseId} showCloseButton={false} className='text-sm w-[70%]' />

            <div className='_flex flex-col gap-[15px]'>
                <p className='text-sm'>
                    This Privacy Policy is published by Drive IQ Technology Pty Ltd (ABN 14 079 249 451) trading as YOUx. Our Privacy Policy explains how we handle your personal information. Your privacy is very important to us. Our Privacy Policy describes our practices in relation to information collected in connection with our Website and our products and services.Information on Data Protection and our Privacy Policy.
                </p>
                <p className='text-sm'>
                    Definitions used in this Privacy Policy:‘YOUx’, ‘we’, ‘our’ or ‘us’ refers to Drive IQ technology Pty Ltd (ABN 14 079 249 451) trading as YOUx and includes our related and associated entities.‘Website’ means the website located at <a className='font-medium text-blue-600 dark:text-blue-500 hover:underline' target='_blank' href='https://www.youxpowered.com.au' rel="noreferrer">www.youxpowered.com.au</a>
                </p>
                <p className='text-sm'>
                    This Privacy Policy applies to all persons who interact with us (you/your), including everyone who visits or uses our Website, anyone else who uses any of our products and services and our suppliers and service providers.  We have commercially acceptable internal processes and controls in place to store your personal information securely in our various systems across our network. YOUx is committed to user privacy in respect of our products and services, and guards your data carefully. The online environment provides an unprecedented access to information, but the convergence of communication and information technologies also brings a range of information privacy issues to the centre of public concern.
                </p>
                <p className='text-sm'>
                    <span className='font-bold'>1. Introduction</span><br />
                    This Privacy Policy sets out how YOUx collects, stores, uses and discloses personal information in accordance with the Australian Privacy Principles (&quot;Principles&quot;) contained in the Privacy Act 1988.
                    The Principles govern the use of &quot;personal information&quot;, which is information or an opinion about an identified individual or an individual who is reasonably identifiable. It need not matter that the information is correct or not or whether it is recorded in a material form or not.
                </p>
                <p className='text-sm'>
                    This Privacy Policy describes the information that the Privacy Act requires us to communicate to all of our customers. We hope it helps you understand how we handle your personal information and deliver the protection that the Privacy Act affords. Please keep it for future reference.
                </p>
                <p className='text-sm'>
                    <span className='font-bold'>2. Who are we</span><br />
                    In this document ‘YOUx‘ or ‘we’ or ‘us’ refers to Drive IQ Technology Pty Ltd (ABN 14 079 249 451) trading as YOUx. YOUx distributes and sells a range of vehicles, parts and accessories in Australia through its appointed dealer network.
                </p>
                <div className='text-sm'>
                    <span className='font-bold'>3. What information do we collect and hold</span><br />
                    YOUx may hold and collect the following personal information about you, may include:
                    <ul className='_flex flex-col gap-[5px] mt-[10px] list-disc list-inside'>
                        <li>contact information and identification such as your name, date of birth, telephone number(s), email address(es), residential and/or business address(es), ABN, demographic information (such as postcode, age, gender) and driver’s licence details;</li>
                        <li>vehicle warranty and servicing details provided by our dealer network, affiliates or third parties including vehicle registration, vehicle purchase details, name of your selling or servicing YOUx dealer, service appointment bookings, vehicle service and repair history (including in relation to warranty, repairs and recalls, if applicable), data collected as a result of on-board vehicle telematics;</li>
                        <li>information collected from marketing campaigns, product research, customer surveys, your interactions with YOUx including via social media, or publicly available information that you post or publish;</li>
                        <li>Payment details (such as account or credit card details) and payment-related information in connection with your purchase of our products and/or services;</li>
                        <li>employment application information; and</li>
                        <li>any other relevant information.</li>
                        <li>Verification Exchange</li>
                        <li>So that my application can be assessed, I consent to you and your service provider, Equifax Verification Exchange®:</li>
                        <li>collecting, using and disclosing my personal information to my employers (or to payroll or other service providers who might act on behalf of my employers) to identify me; and</li>
                        <li>collecting and using my employment income, history and related information from such parties to allow you to verify those matters.</li>
                        <li>I consent to Equifax Verification Exchange® using and disclosing my personal information in accordance with its Verification Exchange Collection Statement.The Verification Exchange Collection Statement reference is a hyperlink to: <a className='font-medium text-blue-600 dark:text-blue-500 hover:underline' target='_blank' href='https://www.equifax.com.au/hrsolutions/pdf/ve-collection-statement.pdf' rel="noreferrer">https://www.equifax.com.au/hrsolutions/pdf/ve-collection-statement.pdf</a></li>
                        <li>We collect information about you for the purposes you agree to in this Privacy Disclosure Statement and Consent. When you accept the terms and conditions, you agree we can, consistent with Australia’s privacy and credit reporting laws, collect, use and exchange credit and personal information about you for those purposes.</li>
                    </ul>
                </div>
                <p className='text-sm'>
                    If you choose not to provide us with your personal information, we may not be able to provide you with the services you require, or the level of service on which we pride ourselves.
                    Sensitive information: We will only collect sensitive information about you with your consent (unless we are otherwise allowed or required by law to collect that information). Sensitive information includes information about your health, race, ethnic origin and religious beliefs.
                </p>
                <p className='text-sm'>
                    Providing someone else’s personal information: If you provide us with personal information about another person (such as a joint vehicle owner or authorised driver or contact person), please tell them about this Policy so they are aware that you have provided their information to us and that they can read this Policy to understand how their information will be handled.
                    Information we collect automatically whenever you visit or interact with YOUx’s website or other online platforms (“Platforms”), we, as well as any third-party service provider and/or advertiser, may use a variety of technologies that automatically or passively record information about how the Platform is accessed and used (“Usage Information”). Usage Information may include your IP address or other unique identifier for the device used to access a Platform (&quot;Device Identifier&quot;), browser type, “Device” type (computer, mobile phone, tablet or other device), operation system, application version, date and time of visit, pages viewed, preceding page views and your use of features or applications on the Platform such as interactions with connections or groups.
                </p>
                <p className='text-sm'>
                    Usage Information helps us keep our Platforms relevant to users and allows us to tailor content to a user’s interests. Usage Information is generally non-identifying, but if we associate it with you as a specific and identifiable person, we will treat it as personal information.
                </p>
                <p className='text-sm'>
                    We may use Device Identifiers to help us administer Platforms, diagnose problems with our servers, analyse trends, observe Platform usage and activity over time, help identify you and your shopping cart, and gather broad demographic information for aggregate use.
                </p>
                <p className='text-sm'>
                    Cookies<br />
                    We or our external service providers may collect cookies from you (data files placed on a Device when it is used to visit a Platform) for security purposes, to facilitate site navigation and personalise your experience while visiting our Platforms (such as allowing us to select which ads or offers are most likely to appeal to you, based on your interests, preferences, location or demographic information).
                    For more information, please see our Cookie Policy.
                </p>
                <p className='text-sm'>
                    <span className='font-bold'>4. Links to other websites</span><br />
                    The YOUx website (and other materials we provide to you) may contain links to third party websites. The operators of those websites may have privacy policies and practices which differ from our own. YOUx is not responsible for the content of any external website or the privacy practices of any third party.
                </p>
                <div className='text-sm'>
                    <span className='font-bold'>5. How we collect personal information</span><br />
                    YOUx collects personal information in a variety of ways directly from you, including:
                    <ul className='_flex flex-col gap-[5px] mt-[10px] list-disc list-inside'>
                        <li>directly from you, when you interact with us in person or by telephone, electronic messages (including email and SMS) or other forms of communication;directly from you, via our internet site (requests for information);</li>
                        <li>directly from you, via our internet site (requests for information);</li>
                        <li>when you use data-collecting devices, products or systems;</li>
                        <li> from our dealer network when you purchase a vehicle or have your vehicle repaired;</li>
                        <li> from your agents or representatives who act on your behalf;</li>
                        <li> from competition entry forms, surveys or at our promotional activities or sponsored events;</li>
                        <li>directly from you, via our internet site (requests for information);</li>
                        <li> from third parties we contract to collect and/or manage data on our behalf;</li>
                        <li> from third parties who you have asked or permitted to provide your personal information (including those parties from whom you purchase goods and services);</li>
                        <li> from marketing organisations, including through the use of purchased lists;</li>
                        <li> from industry databases;</li>
                        <li> from publicly available sources such as the Internet and telephone directories;</li>
                        <li>and from law enforcement, dispute resolution, statutory and regulatory bodies.</li>
                    </ul>
                </div>
                <div className='text-sm'>
                    <span className='font-bold'>6. How we use your personal information</span><br />
                    Your personal information may be used in order to:
                    <ul className='_flex flex-col gap-[5px] mt-[10px] list-disc list-inside'>
                        <li>provide our products and services and those of our related companies and dealerships (whether directly or through our related companies and dealerships);</li>
                        <li>market our products and services and those of our related companies and dealerships (whether directly or through our related companies and dealerships or third parties, such as search engines or social media);</li>
                        <li>communicate to you in relation to our products, services and our promotional activities (directly or through third parties such as search engines or social media);</li>
                        <li>providing customer assistance and support such as vehicle service reminders, service campaigns and assisting with warranty claims (whether directly or through our dealerships);</li>
                        <li>improving your customer experience and our marketing, including through data analytics, product planning, product development and research;</li>
                        <li> comply with our legal obligations (i.e. in the event of a Vehicle Safety Recall), assisting government and enforcement bodies or regulators, or where otherwise required or authorised by or under law, or an order of a court or tribunal;</li>
                        <li>monitor our performance and to help us manage our services;</li>
                        <li>identify and contact individuals who do business with us;</li>
                        <li>train our employees, agents, dealers and representatives;</li>
                        <li>conduct customer and market research and analytics to improve the quality of our products and services;</li>
                        <li>assess and process employment applications and manage employment issues;</li>
                        <li>and handle complaints and disputes; and</li>
                        <li>detect, investigating and preventing fraud.</li>
                    </ul>
                </div>
                <p className='text-sm'>
                    We may contact and communicate with you either via telephone, post, facsimile, email or SMS.
                    We may publish (including by posting on social media) customer testimonials/ images/videos which may contain personally identifiable information. In each case, we will obtain the customer’s consent prior to publishing any content.
                </p>
                <p className='text-sm'>
                    <span className='font-bold'>7. Direct Marketing</span><br />
                    YOUx (including our dealers, agents and contractors if any acting on behalf of YOUx) may send you direct marketing to inform you about products or services, special offers, promotions and events that may be of interest to you. These marketing communications may include joint promotions with YOUx dealers or other promotion partners, and may be sent to you using any contact details provided by you, such as post, phone, email or SMS.
                </p>
                <p className='text-sm'>
                    Your consent to receive direct marketing communications from YOUx in the above ways will be deemed if you do not opt out when you are offered the opportunity to do so, and will remain current on an ongoing basis unless and until you advise otherwise. If you do not want to receive such offers from us or do not want us to disclose your personal information to our related entities or dealerships for marketing purposes, you can opt out at any time by contacting us using our contact details provided below or by utilising the “unsubscribe” function in electronic communications. In some circumstances we may need to contact you to obtain additional information, verify your identity or to clarify your request, in order to action it.
                </p>
                <p className='text-sm'>
                    If the law requires us to provide you with information about our products or services (for example, product recalls), we will provide that information even if you have elected not to receive information about our products and services generally.
                </p>
                <div className='text-sm'>
                    <span className='font-bold'>8. When we disclose your personal information</span><br />
                    We may disclose your personal information to organisations outside of YOUx. These organisations may include:
                    <ul className='_flex flex-col gap-[5px] mt-[10px] list-disc list-inside'>
                        <li>our appointed dealers;</li>
                        <li>outsourced service providers who manage services we provide to you including:
                            <ul className='_flex flex-col gap-[5px] ml-[10px] mt-[10px] list-[circle] list-inside'>
                                <li>roadside assistance;</li>
                                <li>customer research;</li>
                                <li>financial services and insurance;</li>
                                <li>mailing systems and direct marketing; and</li>
                                <li>market research;</li>
                            </ul></li>
                        <li>government, regularity and law enforcement authorities and organisations, as required or authorised by law;</li>
                        <li>our related body corporates;</li>
                        <li>the agent(s) of any of those entities referred to above; your employer (where applicable); and</li>
                        <li>our advisors (including legal and accounting advisors).</li>
                    </ul>
                </div>
                <p className='text-sm'>
                    Those entities are not authorised by us to use personal information for anything other than the purpose for which we supplied that information to them. We may also disclose personal information where you consent to us doing so. That consent may be written, verbal or implied from your conduct.
                </p>
                <p className='text-sm'>
                    Outstanding service campaigns and recalls may be made available to subsequent owners (if any) and/or prospects. We do not disclose any personally identifying information about you to subsequent owners of your vehicle.
                </p>
                <p className='text-sm'>
                    <span className='font-bold'>9. Disclosure to overseas recipients</span><br />
                    Our related entities and some of our service providers (including information technology service providers) may be located overseas and, as a result, personal information collected and held by YOUx may be transferred overseas. The countries in which these recipients may be located will vary from time to time, but may include the Czech Republic, Germany, India, Singapore, New Zealand, United Kingdom and the United States of America.
                </p>
                <div className='text-sm'>
                    <span className='font-bold'>10. How we hold and store your personal information</span><br />
                    YOUx may hold your personal information in a number of ways, including:
                    <ul className='_flex flex-col gap-[5px] mt-[10px] list-disc list-inside'>
                        <li>in our computer systems or databases, which may involve storing data on storage or computer systems provided by third party suppliers;</li>
                        <li>in paper records; and/or</li>
                        <li>in telephone recordings.</li>
                    </ul>
                </div>
                <p className='text-sm'>
                    Where the information has been collected from our or your agent, or our service providers or dealerships, they may also hold copies of your personal information.
                </p>
                <p className='text-sm'>
                    We may combine personal information we receive about you with other information we hold about you.
                    YOUx will store your personal information in a secure environment. It is protected by a combination of physical and technological measures. We have taken all reasonable steps to carefully protect your data from loss, misuse, unauthorised access or disclosure, alteration or destruction. If you want more information about the way we manage the information we hold please contact us as we would be happy to provide you with further information on our processes. Our contact details can be found at the end of this document.
                </p>
                <div className='text-sm'>
                    Help us to ensure we hold accurate information
                    YOUx takes reasonable precautions to ensure that the personal information we collect, use and disclose is accurate, complete and up-to-date. However, the accuracy of that information depends to a large extent on the information you provide. We recommend that you:
                    <ul className='_flex flex-col gap-[5px] mt-[10px] list-disc list-inside'>
                        <li>let us know if there are any errors in your personal information; and</li>
                        <li>keep us up to date with changes to your personal information such as your name and address.</li>
                    </ul>
                </div>
                <p className='text-sm'>
                    You can access your personal information
                    You have a right to access your personal information, subject to some exceptions allowed by law. If you would like to access and request the correction of information we hold about you by contacting us in any of the ways set out at the bottom of this Privacy Policy. You may be required to put your request in writing for security reasons and in some circumstances we may need to charge a fee to recover the reasonable costs incurred. This charge is only designed to help us reasonably recover the costs associated with providing you with access and does not apply to the making of the request. Before we act on a request, we will provide an estimate of the access fee and ask you to agree to it. Access to your information may be refused in a number of circumstances, such as where the information relates to anticipated legal proceedings or if the request for access is frivolous or vexatious. If we deny or restrict your access, we will write to you to let you know why, unless, having regard to the grounds for the refusal, it would be unreasonable for us to do so. You may make a complaint about a refusal to the Office of the Australian Information Commissioner.
                </p>
                <p className='text-sm'>
                    <span className='font-bold'>11. How to contact us</span><br />
                    If you wish to notify us of your new contact details, update your personal information, opt out of direct marketing, make a complaint about a breach of privacy or have any questions in relation to the accuracy of your information or privacy, please contact our Information and Privacy Officer by:<br />
                    <br />
                    Telephone<br />
                    1300 301 720<br />
                    8:30 am – 5:00 pm (AEST) Monday to Friday (excluding NSW Public Holidays)<br />
                    Mark to the attention of the ’Information and Privacy Officer’
                </p>
                <p className='text-sm'>
                    E-mail<br />
                    hello@youxpowered.com.au
                </p>
                <p className='text-sm'>
                    Mail Information and Privacy Officer<br />
                    PO Box 3760 Rhodes NSW 2138
                </p>
                <p className='text-sm'>
                    <span className='font-bold'>12. Making a Complaint</span><br />
                    If you believe that your privacy has been compromised, or if you feel that we have breached the privacy laws, you are entitled to make a complaint. Complaints can be made by contacting us using our contact details in the “How to Contact Us” section of this Privacy Policy.
                </p>
                <p className='text-sm'>
                    You will need to provide us with details of your complaint and any supporting evidence and/or information. You will receive an acknowledgement from us no later than 7 business days after receipt of your written complaint.
                </p>
                <p className='text-sm'>
                    We will investigate your compliant in accordance with our internal complaints resolution process and determine the steps (if any) that we will undertake to resolve your complaint. We will contact you if we require any additional information from you. You will be sent the final decision in writing no later than 20 business days after you contact us, if we have all the necessary information to make that decision.
                </p>
                <p className='text-sm'>
                    If you disagree with our decision, you may refer your complaint to the Office of the Australian Information Commissioner by visiting <a className='font-medium text-blue-600 dark:text-blue-500 hover:underline' target='_blank' href='www.oaic.gov.au' rel="noreferrer">www.oaic.gov.au</a>, calling 1300 363 992 or emailing enquiries@oaic.gov.au
                </p>
                <p className='text-sm'>
                    <span className='font-bold'>13. Changes to our Privacy Policy</span><br />
                    This Privacy Policy may be amended at any time. The updated version will be available by following the “Privacy Policy” link on YOUx’s website homepage, at <a className='font-medium text-blue-600 dark:text-blue-500 hover:underline' target='_blank' href='www.youxpowered..com.au' rel="noreferrer">www.YOUxpowered..com.au</a>. You should check the Privacy Policy regularly for changes.
                    This Privacy Policy was last updated 30 July 2021
                </p>
            </div>
            <div className='w-full mt-[10px]'>
                <ExtendedTermsAndConditions baseId={baseId} />
                <div className='mx-auto w-[80%] mt-[20px]'>
                    <Button
                        onClick={handleNextButtonClick}
                        title='Continue'
                        type={!isReady ? EButtonType.DISABLED : EButtonType.PRIMARY}
                    />
                </div>
            </div>
            {/* <div className='z-10 absolute w-full blur-1'>
                <BouncingArrowDown />
            </div> */}
        </ModalComponent>
    );
}
