import { useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

import { uniqueIdTransfer } from '@/apiCalls/uniqueIDTransferHelper';
import { DisplayComponent } from '@/components/DisplayComponent';
import { DisplayHeading } from '@/components/DisplayHeading';
import { EEventType } from '@/components/eventType';
import { Header } from '@/components/Header';
import { PlusIconCircle } from '@/components/PlusIconCircle';
import { ProgressBar } from '@/components/ProgressBar';
import {
    selectCreditCards,
    selectHomeandContents,
    selectMotorVehicles,
    selectOther,
    selectPersonalLoans,
    selectPlantandEquipment,
    selectRealEstates,
    selectSavings,
    selectSuperandShares,
} from '@/context/borrower';
import { EModals, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import {
    isAllCreditCardsComplete,
    isAllMotorVehicleAssetsComplete,
    isAllPersonalLoansComplete,
    isAllRealEstatesComplete,
} from '@/utils/checkAssetsAndLiabilities';
import { isMotorVehicleUndefined } from '@/utils/isMotorVehicleUndefined';
import { isRealEstateUndefined } from '@/utils/isRealEstateUndefined';

import { AssetsAndLiabilitiesButtons } from './AssetsAndLiabilitiesButtons';

export function AssetsAndLiabilities(): JSX.Element {
    // ***** Local State *****
    const [showErrors, setShowErrors] = useState(false);

    // ***** CONSTANTS *****
    const baseId = 'screen-assetsAndLiabilities';

    // ***** Redux State *****
    const dispatch = useAppDispatch();

    const realEstates = useAppSelector(selectRealEstates);
    const motorVehicles = useAppSelector(selectMotorVehicles);
    const creditCards = useAppSelector(selectCreditCards);
    const personalLoans = useAppSelector(selectPersonalLoans);

    // Savings and more section
    const plantAndEqu = useAppSelector(selectPlantandEquipment);
    const homeAndContent = useAppSelector(selectHomeandContents);
    const savings = useAppSelector(selectSavings);
    const superAndShares = useAppSelector(selectSuperandShares);
    const others = useAppSelector(selectOther);

    const tickIconSavingsOrMore =
        plantAndEqu || homeAndContent || savings || superAndShares || others
            ? true
            : false;

    const savingsAndMoreArray = [
        plantAndEqu,
        homeAndContent,
        savings,
        superAndShares,
        others,
    ];

    const errorCss = 'error';

    const handleOnClickRealEstate = (): void => {
        uniqueIdTransfer({
            eventType: EEventType.TOGGLE,
            applicationData: {
                id: `${baseId}-toggle-expand-realEstate`,
            },
        });
        dispatch(setModal(EModals.REAL_ESTATES));
    };
    const handleOnClickMotorVehicles = (): void => {
        uniqueIdTransfer({
            eventType: EEventType.TOGGLE,
            applicationData: {
                id: `${baseId}-toggle-expand-motorVehicles`,
            },
        });
        dispatch(setModal(EModals.MOTOR_VEHICLES_ASSETS));
    };
    const handleOnClickSavings = (): void => {
        uniqueIdTransfer({
            eventType: EEventType.TOGGLE,
            applicationData: {
                id: `${baseId}-toggle-expand-savingsAndMore`,
            },
        });
        dispatch(setModal(EModals.SAVINGS_OR_MORE));
    };
    const handleOnClickCreditCard = (): void => {
        uniqueIdTransfer({
            eventType: EEventType.TOGGLE,
            applicationData: {
                id: `${baseId}-toggle-expand-creditCards`,
            },
        });
        dispatch(setModal(EModals.CREDIT_CARDS));
    };
    const handleOnClickPersonalLoans = (): void => {
        uniqueIdTransfer({
            eventType: EEventType.TOGGLE,
            applicationData: {
                id: `${baseId}-toggle-expand-personalLoans`,
            },
        });
        dispatch(setModal(EModals.PERSONAL_LOANS));
    };

    const assetsAndLiabilities = [
        {
            name: 'Real Estate',
            item: realEstates,
            onClick: handleOnClickRealEstate,
            ref: 'realEstate',
            tickIcon: isAllRealEstatesComplete(),
            isUndefined: isRealEstateUndefined(),
        },
        {
            name: 'Motor Vehicle',
            item: motorVehicles,
            onClick: handleOnClickMotorVehicles,
            ref: 'motorVehicle',
            tickIcon: isAllMotorVehicleAssetsComplete(),
            isUndefined: isMotorVehicleUndefined(),
        },
        {
            name: 'Savings and More',
            ref: 'savingsAndMore',
            item: savingsAndMoreArray,
            onClick: handleOnClickSavings,
            tickIcon: tickIconSavingsOrMore,
            isUndefined: true,
        },
        {
            name: 'Credit Cards',
            item: creditCards,
            onClick: handleOnClickCreditCard,
            ref: 'creditCards',
            tickIcon: isAllCreditCardsComplete(),
            isUndefined: creditCards?.length === 0,
        },
        {
            name: 'Personal Loans',
            item: personalLoans,
            onClick: handleOnClickPersonalLoans,
            ref: 'personalLoans',
            tickIcon: isAllPersonalLoansComplete(),
            isUndefined: personalLoans?.length === 0,
        },
    ];

    return (
        <DisplayComponent baseId={baseId} data-testid={baseId}>
            <Header />
            <ProgressBar />
            <DisplayHeading
                title='Assets and Liabilities'
                infoText='Please give information about your assets and liabilities'
            />
            {/* Todo: This shouldn't be here. It's reuseable with supporting docs for a start and secondly it's logic which should be in it's own component */}
            {assetsAndLiabilities.map((item, index) => (
                <div
                    className={`_flex justify-between align-center m-2  p-4 border rounded-lg border-[#BDBDBD] cursor-pointer 1px solid
               ${!item.tickIcon && !item.isUndefined && errorCss}`}
                    onClick={item.onClick}
                    key={index}
                >
                    <div className=''>{item.name}</div>
                    <div className={`flex-center `}>
                        {item.tickIcon ? (
                            <CheckCircleIcon className='h-5 w-5 text-success' />
                        ) : (
                            <div id={`${baseId}-toggle-expand-${item.ref}`}>
                                <PlusIconCircle />
                            </div>
                        )}
                    </div>
                </div>
            ))}
            <AssetsAndLiabilitiesButtons
                baseId={baseId}
                setShowErrors={setShowErrors}
            />
        </DisplayComponent>
    );
}
