import { EMoneyType } from '@/context/borrower';
import { store } from '@/context/store';
import { getFinanceType } from '@/utils/getFinanceType';

export function getCommissionAmount(): number {
    const { borrowerReducer, displayReducer } = store.getState();
    const { purchasePrice } = borrowerReducer.loan;
    const financeType = getFinanceType();
    const { financeTypeCommission } = displayReducer.partner;

    const commissionObj = financeTypeCommission[financeType];
    const commissionAmount =
        commissionObj?.value ?? financeTypeCommission.consumer.value;
    const commissionType =
        commissionObj?.type ?? financeTypeCommission.consumer.type;

    if (commissionType === EMoneyType.DOLLAR) {
        return commissionAmount;
    }

    return (commissionAmount * (purchasePrice ?? 0)) / 100;
}
