// eslint-disable-next-line simple-import-sort/imports
import { useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { getVehicleByPlate } from '@/apiCalls/glassGuideVehicleLookup/getVehicleByPlate';
// eslint-disable-next-line no-restricted-imports
import { Button, EButtonType } from '@/components/Button';
import {
    ESource,
    IBillingLog,
    selectIsVehicleRegistrationKnown,
    selectVehicleDescription,
    selectVehicleRegistrationNumber,
    selectVehicleRegistrationState,
    setIsVehicleRegistrationKnown,
    setVehicleDescription,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { Kilometers } from '@/UserInput/Kilometers';
import { RegistrationNumber } from '@/UserInput/RegistrationNumber';
import { RegistrationState } from '@/UserInput/RegistrationState';
import { ERROR_MESSAGE_REGO_NOT_FOUND } from '@/utils/constants';

// eslint-disable-next-line no-restricted-imports
import { EBillingService } from '@/context/borrower/types/EBillingService';
// eslint-disable-next-line no-restricted-imports
import { DescriptionBox } from '../DisplayVehicleResults/DescriptionBox';
// eslint-disable-next-line simple-import-sort/imports
export const testId = 'VehicleDetailsRegoKnown';

interface IVehicleDetailsRegoKnownProps {
    showErrors: boolean;
}

export function VehicleDetailsRegoKnown({
    showErrors,
}: IVehicleDetailsRegoKnownProps): JSX.Element {
    const baseId = 'screen-vehicleDetailsRegoUnknown';
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const description = useAppSelector(selectVehicleDescription);
    const partner = useAppSelector((state) => state.displayReducer.partner.id);
    const applicationId = useAppSelector(
        (state) => state.borrowerReducer.application.applicationId
    );
    const manageOrganisationId = useAppSelector(
        (state) => state.displayReducer.partner.manageOrganisationId
    );
    // ***** CONSTANTS *****
    const handleOnClickFindVehicle = async (): Promise<void> => {
        setLoading(true);

        //todo:likan
        const billingData: IBillingLog = {
            service: EBillingService.GLASS_GUIDE_PLATE,
            youxAccountUsed: true,
            source: ESource.VROOM,
            externalPartner: partner ?? '',
            externalReferenceId: applicationId ?? '',
            managePartnerToBillId: manageOrganisationId ?? '',
        };

        await getVehicleByPlate(
            vehicleRegistrationNumber,
            vehicleState,
            partner,
            billingData
        )
            .then((response) => {
                console.log('1', response);
                setLoading(false);
                dispatch(setVehicleDescription(response.description));
            })
            .catch(() => {
                console.log('Catch');
                setLoading(false);
                dispatch(setVehicleDescription(ERROR_MESSAGE_REGO_NOT_FOUND));
            });
    };

    const isRegistrationKnown = useAppSelector(
        selectIsVehicleRegistrationKnown
    );
    const vehicleRegistrationNumber = useAppSelector(
        selectVehicleRegistrationNumber
    );
    const vehicleState = useAppSelector(selectVehicleRegistrationState);

    const requiredFieldsPresentInState =
        isRegistrationKnown && vehicleRegistrationNumber && vehicleState
            ? true
            : false;

    function handleClick(): void {
        dispatch(setIsVehicleRegistrationKnown(false));
    }

    return (
        <div>
            <RegistrationNumber showErrors={showErrors} baseId={baseId} />
            <Kilometers showErrors={showErrors} baseId={baseId} />
            <RegistrationState showErrors={showErrors} baseId={baseId} />
            <div className='py-4'>
                {requiredFieldsPresentInState === true && !description ? (
                    <Button onClick={handleOnClickFindVehicle}>
                        Find Vehicle
                    </Button>
                ) : (
                    <Button
                        onClick={handleOnClickFindVehicle}
                        type={EButtonType.FULL_ROUNDED_DISABLED}
                        id={`${baseId}-button-findVehicle`}
                    >
                        Find Vehicle
                    </Button>
                )}
            </div>
            <div>
                {loading && <div className='text-center'>.....Loading</div>}

                {!loading && description !== undefined && (
                    <DescriptionBox
                        key={undefined}
                        selected
                        content={description}
                    />
                )}
            </div>
            <p className='text-lg px-4 text-center pb-4'>
                Can&apos;t find the vehicle? <br />
            </p>
            <Button type={EButtonType.SECONDARY_OUTLINE} onClick={handleClick}>
                Search Manually
            </Button>
        </div>
    );
}
