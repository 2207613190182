export enum EMatchingEngineLenders {
    // Always update together with api/src/features/matchingEngineWaterfall/matchingEngineWaterfallUtils.ts
    AMMF = 'AMMF',
    DYNAMONEY = 'GROW_ASSET_FINANCE',
    MONEY_3 = 'MONEY_3',
    GREENLIGHT_AUTO = 'GREENLIGHT_AUTO',
    RAPID_LOANS = 'RAPID_LOANS',
    MORRIS_FINANCE = 'MORRIS_FINANCE',
    SELFCO_LEASING = 'SELFCO_LEASING',
    MOTORCYCLE_FINANCE = 'ALLIED_MOTORCYCLE_FINANCE',
    YAMAHA_FINANCIAL_SERVICES = 'YAMAHA_FINANCIAL_SERVICES',
    BOQ = 'BOQ_STANDARD',
    YAMAHA_FINANCE_CAMPAIGN = 'YAMAHA_FINANCE_CAMPAIGN',
    BOQ_LEISURE = 'BOQ_LEISURE',
    AMH_DEALER_FINANCE = 'AMH_DEALER_FINANCE',
    ANGLE_AUTO = 'ANGLE_AUTO',
    ANGLE_AUTO_FINANCE = 'ANGLE_AUTO_FINANCE',
    ANGLE_FINANCE = 'ANGLE_FINANCE',
    AUTOPAY_BROKER = 'AUTOPAY',
    AFFORDABLE_CAR_LOANS = 'AFFORDABLE_CAR_LOANS',
    AUTOPAY_INTERNAL = 'AUTOPAY_BROKER_2',
    EARLYPAY = 'CLASSIC_FUNDING',
    GEDDA_MONEY = 'LOANU',
    BRANDED_BROKER = 'BRANDED_FINANCIAL_SERVICES',
    BRANDED_CHERY = 'BRANDED_CHERY',
    BRANDED_DEALER = 'BRANDED_DEALER',
    CAPITAL = 'CAPITAL',
    FINANCE_ONE = 'FINANCE_1',
    FIRST_MAC_FIXED = 'FIRST_MAC_FIXED',
    FIRST_MAC = 'FIRST_MAC',
    FLEXI = 'FLEXI',
    GROW_SOLAR = 'GROW_SOLAR',
    GROW_ASSET_FINANCE = 'GROW_ASSET_FINANCE',
    LATITUDE_FINANCE = 'LATITUDE_FINANCE',
    METRO = 'METRO',
    MONEY_TECH = 'MONEY_TECH',
    NISSAN_FINANCE = 'NISSAN_FINANCE',
    NOW_FINANCE = 'NOW_FINANCE',
    PEPPER = 'PEPPER',
    PLENTI = 'PLENTI',
    PLENTI_COMMERCIAL = 'PLENTI_COMMERCIAL',
    SCOTTISH_PACIFIC = 'SCOTTISH_PACIFIC',
    SHIFT = 'GET_CAPITAL',
    VOLKSWAGEN_FINANCIAL_SERVICES = 'VOLKSWAGEN_FINANCIAL_SERVICES',
    VOLKSWAGEN_DEALER = 'VOLKSWAGEN_DEALER',
    WESTPAC = 'WESTPAC',
    WISR_CAR_LOAN = 'WISR_CAR_LOAN',
    ALLIED_CREDIT = 'ALLIED_CREDIT',
    LIBERTY = 'LIBERTY',
    MACQUARIE_DEALER = 'MACQUARIE_DEALER',
    AUTOMOTIVE_FINANCE_JOHN_HUGHES = 'AUTOMOTIVE_FINANCE', // John Hughes Group in Multi
    AUTOMOTIVE_FINANCE_BOORAN = 'AUTOMOTIVE_FINANCE_BOORAN', // Automotive Fiance
    SUBARU_FINANCE = 'SUBARU_FINANCE',
    RESIMAC = 'RESIMAC',
    MACQUARIE_LEASING = 'MACQUARIE_LEASING',
    THORN = 'THORN',
    VOLKSWAGEN_FINANCE_LODGE = 'VOLKSWAGEN_FINANCIAL_SERVICES_LODGE',
    TOYOTA_FINANCIAL_SERVICES = 'TOYOTA_FINANCIAL_SERVICES',
    VOLKSWAGEN_CAMPAIGN_RATE_1 = 'VOLKSWAGEN_CAMPAIGN_RATE_1',
    AZORA = 'AZORA',
    HARLEY_DAVIDSON_FINANCIAL_SERVICES = 'HARLEY_DAVIDSON_FINANCIAL', // todo: update the code
    AFS = 'AFS', // todo: update the code
    TAURUS_MOTOR_FINANCE = 'TAURUS_MOTOR_FINANCE',
}

export enum ELenderSorting {
    CHEAPEST = 'CHEAPEST',
    LOW_DOC = 'LOW_DOC',
}

export const LODGE_LENDERS: EMatchingEngineLenders[] = [
    EMatchingEngineLenders.AUTOPAY_INTERNAL,
    EMatchingEngineLenders.BRANDED_BROKER,
    EMatchingEngineLenders.FINANCE_ONE,
    EMatchingEngineLenders.FIRST_MAC,
    EMatchingEngineLenders.LATITUDE_FINANCE,
    EMatchingEngineLenders.LIBERTY,
    EMatchingEngineLenders.METRO,
    EMatchingEngineLenders.MONEY_TECH,
    EMatchingEngineLenders.NISSAN_FINANCE,
    EMatchingEngineLenders.PEPPER,
    EMatchingEngineLenders.PLENTI,
    EMatchingEngineLenders.PLENTI_COMMERCIAL,
    EMatchingEngineLenders.VOLKSWAGEN_DEALER,
    EMatchingEngineLenders.WESTPAC,
    EMatchingEngineLenders.WISR_CAR_LOAN,
];

export const ALL_NON_SPECIAL_LENDERS: EMatchingEngineLenders[] = [
    EMatchingEngineLenders.ANGLE_FINANCE,
    EMatchingEngineLenders.AUTOPAY_BROKER,
    EMatchingEngineLenders.AUTOPAY_INTERNAL,
    EMatchingEngineLenders.BRANDED_BROKER,
    EMatchingEngineLenders.BRANDED_CHERY,
    EMatchingEngineLenders.BRANDED_DEALER,
    EMatchingEngineLenders.CAPITAL,
    EMatchingEngineLenders.FINANCE_ONE,
    EMatchingEngineLenders.FIRST_MAC_FIXED,
    EMatchingEngineLenders.FIRST_MAC,
    EMatchingEngineLenders.FLEXI,
    EMatchingEngineLenders.GEDDA_MONEY,
    // EMatchingEngineLenders.GROW_SOLAR,
    EMatchingEngineLenders.GROW_ASSET_FINANCE,
    EMatchingEngineLenders.LATITUDE_FINANCE,
    EMatchingEngineLenders.METRO,
    EMatchingEngineLenders.MONEY_TECH,
    EMatchingEngineLenders.NISSAN_FINANCE,
    EMatchingEngineLenders.NOW_FINANCE,
    EMatchingEngineLenders.PEPPER,
    EMatchingEngineLenders.PLENTI,
    EMatchingEngineLenders.PLENTI_COMMERCIAL,
    EMatchingEngineLenders.SCOTTISH_PACIFIC,
    EMatchingEngineLenders.SHIFT,
    EMatchingEngineLenders.VOLKSWAGEN_FINANCIAL_SERVICES,
    EMatchingEngineLenders.VOLKSWAGEN_DEALER,
    EMatchingEngineLenders.WESTPAC,
    EMatchingEngineLenders.WISR_CAR_LOAN,
    EMatchingEngineLenders.ALLIED_CREDIT,
    EMatchingEngineLenders.LIBERTY,
    EMatchingEngineLenders.MACQUARIE_DEALER,
    // EMatchingEngineLenders.AUTOMOTIVE_FINANCE_JOHN_HUGHES,
    // EMatchingEngineLenders.AUTOMOTIVE_FINANCE_BOORAN,
    // EMatchingEngineLenders.SUBARU_FINANCE,
    EMatchingEngineLenders.RESIMAC,
    EMatchingEngineLenders.MACQUARIE_LEASING,
    EMatchingEngineLenders.THORN,
    EMatchingEngineLenders.VOLKSWAGEN_FINANCE_LODGE,
];
