import { DateInput } from '@/components/DateInput';
import { selectVisaExpiryDate, setVisaExpiryDate } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { isVisaExpiryDateValid } from '@/utils/checkVisaExpiryDate';
import { maximumDateHelper } from '@/utils/maximumDateHelper';
import { minimumDateHelper } from '@/utils/minimumDateHelper';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'VisaExpiryDate';

export const VisaExpiryDate = ({ showErrors, baseId }: IProps): JSX.Element => {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const visaExpiryDate = useAppSelector(selectVisaExpiryDate);

    // *****Functions*****

    const handlOnChange = (date: Date): void => {
        dispatch(setVisaExpiryDate(date));
    };

    // *****Render*****
    return (
        <DateInput
            id={`${baseId}-input-visaExpiryDate`}
            title='Visa Expiry Date'
            placeholder='Visa Expiry'
            onChange={handlOnChange}
            value={visaExpiryDate}
            showErrors={showErrors && !isVisaExpiryDateValid()}
            minDate={minimumDateHelper(10)}
            maxDate={maximumDateHelper(10)}
            tooltipContent='The date your visa expires.'
        />
    );
};
