import { memo } from 'react';

import { uniqueIdTransfer } from '@/apiCalls/uniqueIDTransferHelper';
import {
    initialBorrowerSlice,
    setBorrower,
} from '@/context/borrower';
import {
    EDisplays,
    EModals,
    selectDisplay,
    setDisplay,
    setInitialLoad,
    setModal,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { selectIsLoggedIn, setIsLoggedIn } from '@/context/user';
import {
    ELocalStorageVariables,
    removeLocalStorageItem,
} from '@/utils/localStorage';

import { EEventType } from '../eventType';
import { PartnerLogo } from '../PartnerLogo';

function HeaderComponent(): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const display = useAppSelector(selectDisplay);
    const solar = false; // todo: delete
    const solarOcrAnimation =
        solar &&
        (display === EDisplays.OCR_ANIMATION ||
            display === EDisplays.SOLAR_HOLDING_SCREEN);

    // ***** Event Handlers *****
    function handleClick(): void {
        uniqueIdTransfer({
            eventType: EEventType.CLICK,
            applicationData: {
                id: `allScreens-header-button-${getButtonLabel()}`,
            },
        });
        if (isLoggedIn) {
            const nextDisplay = getNextDisplay();
            const nextModal = getNextModal();
            dispatch(setBorrower(initialBorrowerSlice));
            dispatch(setIsLoggedIn(false));
            dispatch(setInitialLoad(false)); // required to reset the parameters (eg, partner, vehicle from the url)
            if (nextModal !== null) {
                dispatch(setModal(nextModal));
            }
            if (nextDisplay !== null) {
                dispatch(setDisplay(nextDisplay));
            }
            removeLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);
            removeLocalStorageItem(ELocalStorageVariables.FIRST_NAME);

            return;
        }
        if (solar) {
            dispatch(setModal(EModals.SOLAR_LOGIN));
            return;
        }
        dispatch(setModal(EModals.LOGIN));
    }

    function getNextDisplay(): EDisplays | null {
        if (!isLoggedIn) return null;
        if (solar) return EDisplays.SOLAR_LOAN_DETAILS;
        return EDisplays.QUICK_CALCULATOR;
    }

    function getNextModal(): EModals | null {
        if (isLoggedIn) return null;
        if (solar) return EModals.SOLAR_LOGIN;
        return EModals.WELCOME;
    }

    function getButtonLabel(): string {
        return isLoggedIn ? 'Logout' : 'Login';
    }

    // ***** Render *****
    return (
        <div data-testid='header' className='_flex justify-between pt-2'>
            <div className='h-10 _flex justify-start items-center mt-1 flex-shrink-0'>
                <PartnerLogo />
            </div>

            <div
                className={
                    solar
                        ? 'w-48'
                        : 'h-10 flex-center items-center justify-center '
                }
            >
                {!solar && !solarOcrAnimation && (
                    <span
                        onClick={handleClick}
                        className={`text-black text-[11px] uppercase  hover:cursor-pointer hover:font-bold`}
                    >
                        {getButtonLabel()}
                    </span>
                )}
            </div>
        </div>
    );
}

export const Header = memo(HeaderComponent);
