import { ILender } from '@/context/lender';

import { IAddressState } from './addressTypes';
import { IAgreements } from './agreementTypes';
import { IApplication } from './applicationTypes';
import { IBusiness } from './businessTypes';
import { ICredit } from './creditCardsTypes';
import { IEmployment, IEmploymentState } from './employmentTypes';
import { IExpenses } from './expenseTypes';
import { IIncome } from './incomeTypes';
import { ILoan } from './loanTypes';
import { IMotorVehicleAsset } from './motorVehicleAssetTypes';
import { IOtherAssets } from './otherAssetsTypes';
import { IPersonalLoanOptions } from './personalLoanTypes';
import { IPersonalDetails } from './personalTypes';
import { IRealEstateAsset } from './realEstateAssetTypes';
import { IScores } from './scoreTypes';
import { ISeller } from './SellerTypes';
import { IShownLenders } from './shownLenders';
import { ISolar } from './solarTypes';
import { ISupportingDoc } from './supportingDocs';
import { ITestDriveState } from './testDriveTypes';
import { IVehicle } from './VehicleTypes';

export enum EGender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    UNKNOWN = 'UNKNOWN',
}

export enum EFrequency {
    WEEKLY = 'WEEKLY',
    FORTNIGHTLY = 'FORTNIGHTLY',
    MONTHLY = 'MONTHLY',
}

export interface IEmploymentByIndex {
    index: number;
    employment: IEmployment;
}

export interface IBorrowerSlice {
    personalDetails: IPersonalDetails;
    address: IAddressState;
    income: IIncome;
    employments: IEmploymentState;
    expenses: IExpenses;
    loan: ILoan;
    seller: ISeller;
    vehicle: IVehicle;
    supportingDocs: { [code: string]: ISupportingDoc[] };
    selectedLender: ILender | undefined;
    otherAssets: IOtherAssets;
    creditCards: ICredit;
    personalLoans: IPersonalLoanOptions;
    motorVehicles: IMotorVehicleAsset;
    business: IBusiness;
    realEstates: IRealEstateAsset;
    scores: IScores;
    solar: ISolar;
    application: IApplication;
    testDrives: ITestDriveState;
    shownLenders: IShownLenders[];
    agreements: IAgreements[];
}
