export function hexToRgb(hex: string, opacity: number): string {
    // Remove the "#" character if present
    hex = hex.replace(/^#/, '');

    // Parse the hex color values and convert to decimal
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
