import { CheckBox } from '@/components/CheckBox';
import {
    selectAgreedToTermsAndConditions,
    setAgreedToTermsAndConditions,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

import { InsuranceOptIn } from '../InsuranceOptIn';

interface IProps {
    baseId?: string;
}

export function ExtendedTermsAndConditions({ baseId }: IProps): JSX.Element {
    const dispatch = useAppDispatch();
    const termsAndConditions = useAppSelector(selectAgreedToTermsAndConditions);

    function handleDefaultPrivacyClick(): void {
        dispatch(
            setAgreedToTermsAndConditions({
                isDefaultPrivacyAgreed: {
                    agreed: !termsAndConditions.isDefaultPrivacyAgreed?.agreed,
                    date: new Date(),
                },
            })
        );
    }

    function handleMarketingConsentClick(): void {
        dispatch(
            setAgreedToTermsAndConditions({
                isMarketingConsentAgreed: {
                    agreed: !termsAndConditions.isMarketingConsentAgreed
                        ?.agreed,
                    date: new Date(),
                },
            })
        );
    }

    function handleVerificationConsentClick(): void {
        dispatch(
            setAgreedToTermsAndConditions({
                isEquifaxVerificationExchangeAgreed: {
                    agreed: !termsAndConditions
                        .isEquifaxVerificationExchangeAgreed?.agreed,
                    date: new Date(),
                },
            })
        );
    }

    return (
        <div className='_flex flex-col gap-[5px] py-2 text-textBody'>
            <div className='_flex flex-row items-start'>
                <CheckBox
                    id={`${baseId}-input-checkbox-termsAndConditions-privacy`}
                    onClick={handleDefaultPrivacyClick}
                    checked={
                        termsAndConditions.isDefaultPrivacyAgreed?.agreed ||
                        false
                    }
                />

                <div className='ml-[5px] text-xs '>
                    I have read and understand the privacy agreement
                </div>
            </div>
            <div className='_flex flex-row items-start'>
                <CheckBox
                    id={`${baseId}-input-checkbox-termsAndConditions-marketing`}
                    onClick={handleMarketingConsentClick}
                    checked={
                        termsAndConditions.isMarketingConsentAgreed?.agreed ||
                        false
                    }
                />

                <div className='ml-[5px] text-xs'>
                    I have read and understand the marketing consent
                </div>
            </div>
            <div className='_flex flex-row items-start'>
                <CheckBox
                    id={`${baseId}-input-checkbox-termsAndConditions-verification-exchange`}
                    onClick={handleVerificationConsentClick}
                    checked={
                        termsAndConditions.isEquifaxVerificationExchangeAgreed
                            ?.agreed || false
                    }
                />

                <div className='ml-[5px] text-xs'>
                    I have read and understand the Verification Exchange consent
                </div>
            </div>
            <InsuranceOptIn baseId={baseId ?? ''} />
        </div>
    );
}
