import { useState } from 'react';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button } from '@/components/Button';
import { ModalComponent } from '@/components/ModalComponent';
import { ModalHeader } from '@/components/ModalHeader';
import {
    EBorrowerCitizenshipStatus,
    selectLicenseNumber,
    selectLicenseState,
    selectResidencyStatus,
    selectVisaNumber,
} from '@/context/borrower';
import { setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { BirthDate } from '@/UserInput/BirthDate';
import { LicenseExpiry } from '@/UserInput/LicenseExpiry';
import { LicenseNumber } from '@/UserInput/LicenseNumber';
import { LicenseState } from '@/UserInput/LicenseState';
import { ResidencyStatus } from '@/UserInput/ResidencyStatus';
import { isBirthDateValid } from '@/utils/checkBirthDate';
import { isLicenseExpiryDateValid } from '@/utils/checkLicenseExpiryDate';
import { isVisaExpiryDateValid } from '@/utils/checkVisaExpiryDate';

export const testId = 'PersonalInformation';

export function PersonalInformation(): JSX.Element {
    // ***** Constants *****
    const baseId = 'modal-ContactDetails';
    // ***** Local State *****
    const [showErrors, setShowErrors] = useState<boolean>(false);
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    // const gender = useAppSelector(selectGender);
    const licenseNumber = useAppSelector(selectLicenseNumber);
    const licenseState = useAppSelector(selectLicenseState);
    const residencyStatus = useAppSelector(selectResidencyStatus);
    const visaNumber = useAppSelector(selectVisaNumber);

    // ***** Handlers *****
    function handleClick(): void {
        setShowErrors(true);
        if (!isComplete()) return;
        createOrUpdateBorrower();
        dispatch(setModal(null));
    }

    // ***** Helpers *****
    function isComplete(): boolean {
        // if (gender === null || gender === undefined) return false;
        if (!isBirthDateValid()) return false;
        if (licenseNumber === '' || licenseNumber === undefined) return false;
        if (licenseState === '' || licenseState === undefined) return false;
        if (!isLicenseExpiryDateValid()) return false;
        if (residencyStatus === null || residencyStatus === undefined)
            return false;
        if (
            residencyStatus === EBorrowerCitizenshipStatus.VISA_HOLDER &&
            ((visaNumber && visaNumber.toString().length < 3) ||
                !isVisaExpiryDateValid())
        )
            return false;
        return true;
    }

    // ***** Render *****
    return (
        <ModalComponent baseId={baseId}>
            <ModalHeader title='Personal Information' baseId={baseId} />
            {/* <Gender baseId={baseId} showErrors={showErrors} /> */}
            <ResidencyStatus baseId={baseId} showErrors={showErrors} />
            <LicenseNumber baseId={baseId} showErrors={showErrors} />
            <LicenseState baseId={baseId} showErrors={showErrors} />
            <LicenseExpiry baseId={baseId} showErrors={showErrors} />
            <BirthDate baseId={baseId} showErrors={showErrors} />
            <div className='py-2 mx-16'>
                <Button onClick={handleClick} id={`${baseId}-button-close`}>
                    <p>Close</p>
                </Button>
            </div>
        </ModalComponent>
    );
}
