import { IPartnerParams } from '@/partners/partnerTypes';
import {
    EEasycarsParameters,
    EParameters,
} from '@/utils/setParametersFromParams';

import { ISetInitialSeller } from './ISetInitialSeller';

export function getInitialSellerData(
    partner: IPartnerParams,
    params: URLSearchParams
): ISetInitialSeller {
    return {
        partnerName: partner.dealerName,
        paramaterPartnerName:
            params.get(EParameters.DEALER_NAME) ||
            params.get(EEasycarsParameters.DEALER_NAME),
        partnerAccountNumber: partner.dealerAccountNumber,
        parameterAccountNumber:
            params.get(EParameters.DEALER_ACCOUNT_NUMBER) ||
            params.get(EEasycarsParameters.DEALER_ACCOUNT_NUMBER),
        partnerAbn: partner.dealerAbn,
        parameterAbn:
            params.get(EParameters.ABN) || params.get(EEasycarsParameters.ABN),
        partnerEmail: partner.dealerEmail,
        parameterEmail:
            params.get(EParameters.EMAIL) ||
            params.get(EEasycarsParameters.EMAIL),
        partnerPhone: partner.dealerPhone,
        parameterPhone:
            params.get(EParameters.PHONE) ||
            params.get(EEasycarsParameters.PHONE),
        partnerLeadEmail: partner.leadEmail,
        parameterLeadEmail: params.get(EParameters.LEAD_EMAIL),
    };
}
