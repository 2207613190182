import React from 'react';

import {
    selectVehicleBuildYear,
    selectVehicleMake,
    selectVehicleModel,
    selectVehicleVariant,
} from '@/context/borrower';
import { useAppSelector } from '@/context/storeHooks';

interface Props {
    fullScreen: boolean;
    baseId: string;
}

export function TDRVehicleDetails({ fullScreen, baseId }: Props): JSX.Element {
    // ***** Redux *****
    const year = useAppSelector(selectVehicleBuildYear);
    const make = useAppSelector(selectVehicleMake);
    const model = useAppSelector(selectVehicleModel);
    const variant = useAppSelector(selectVehicleVariant);

    // ***** CONSTANTS *****
    const vehicleName = `${year} ${make} ${model} ${variant}`;

    return (
        <div
            className={`absolute left-1/2 transform -translate-x-1/2 w-[90%] rounded text-white text-center ${
                fullScreen ? 'top-[30%]' : 'top-[14%] '
            }`}
        >
            <h1 className='text-4xl font-bold'>
                Ready to Hit
                <br />
                the Road!
            </h1>
            <p className='text-lg px-16 pt-10'>
                Your <span className='font-bold'>{vehicleName}</span> awaits
                <br />
            </p>
        </div>
    );
}
