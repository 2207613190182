import { getUrl } from '../fetch';

import { IGetApplicationIdResponse } from './getApplicationIdTypes';

export async function getApplicationId(): Promise<string> {
  const url = '/api/data/application/getAppId';
  const response = await getUrl<IGetApplicationIdResponse>(url);

  return response.data.applicationId;
}
