import { store } from '@/context/store';

export function isMotorVehicleUndefined(): boolean {
  const state = store.getState();
  const motorVehicles = state.borrowerReducer.motorVehicles.motorVehicles;
  if (motorVehicles.length === 0) return true;

  let isUndefined = false;
  motorVehicles.map((motorVehicle) => {
    if (motorVehicle.assetValue === undefined && !motorVehicle.isLoanPresent) {
      isUndefined = true;
    }
  });
  return isUndefined;
}
