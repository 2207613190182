export interface IApplication {
    id?: string | undefined;
    applicationId?: string | undefined;
    isDeclined?: boolean | undefined;
    declineReason?: string | undefined;
    isServicing?: boolean | undefined;
    isPreApproved?: boolean | undefined;
    isSubmitted?: boolean | undefined;
    skynetOpportunityId?: string | undefined;
    skynetPartnerId?: string | undefined;
    progressPercent?: number | undefined;
    partnerOverwriteId: string | null;
}

export const initialApplication: IApplication = {
    partnerOverwriteId: null,
};
