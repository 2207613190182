import { getUrl } from '@/apiCalls/fetch';
import { EVehicleAssetType } from '@/context/borrower';
import { IModelApiResults } from '@/context/display';
import { getGlassAssetType } from '@/utils/glassGuideHelpers';

import { IGetModelTypesResponse } from './getModelTypes';

export async function getModel(
    partner: string,
    year: number | undefined,
    make: string | undefined,
    assetType?: EVehicleAssetType
): Promise<IModelApiResults[]> {
    const url = `/api/asset/select/${partner}/${getGlassAssetType(assetType)}/${year}/${make}`;
    const response = await getUrl<IGetModelTypesResponse>(url);
    return response.data;
}
