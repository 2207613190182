import { Input } from '@/components/Input';
import { selectAbnActiveFrom } from '@/context/borrower';
import { useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'AbnActiveFrom';

export function AbnActiveFrom({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectAbnActiveFrom);

    // ***** Event Handlers *****
    function handleChange(): void {
        return;
    }

    // ***** Render *****
    return (
        <div data-testid={testId} className='w-full'>
            <Input
                title='Active From'
                placeholder={currentState || '11-11-2011'}
                showErrors={showErrors && !currentState}
                onChange={handleChange}
                id={`${baseId}-input-abnActiveFrom`}
                value={currentState?.toLocaleString() ?? ''}
                disabled
                tooltipContent='We collect this automatically using your ABN.'
            />
        </div>
    );
}
