import { IPartnerParams } from '@/partners/partnerTypes';
import { EParameters } from '@/utils/setParametersFromParams';

import { ISetInitialLoan } from './ISetInitialLoan';

export function getInitialLoanData(
    partner: IPartnerParams,
    params: URLSearchParams
): ISetInitialLoan {
    return {
        partnerLoanUsage: partner.loanUsage,
        parameterLoanUsage: params.get(EParameters.LOAN_USAGE),
        partnerLoanType: partner.initialLoanType,
        partnerLoanTerm: partner.loanTerm,
        parameterLoanTerm: params.get(EParameters.LOAN_TERM),
        parameterDepositAmount: params.get(EParameters.DEPOSIT_AMOUNT),
        parameterTradeInAmount: params.get(EParameters.TRADE_IN_AMOUNT),
        parameterBalloonPercentage: params.get(EParameters.BALLOON_PERCENTAGE),
    };
}
