import { Input } from '@/components/Input';
import { selectVehicleVariant, setVehicleVariant } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { userFacingAssetTitle } from '@/utils/userFacingAssetTitle';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'VariantManual';

export function VariantManual({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectVehicleVariant);
    const dispatch = useAppDispatch();
    const assetTitle = userFacingAssetTitle();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        // MAXIMUM
        const MAXIMUM = 30;
        if (value.toString()?.length > MAXIMUM) return;
        dispatch(setVehicleVariant(value.toString()));
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title={`${assetTitle} Variant`}
                placeholder={`${assetTitle} Variant`}
                showErrors={showErrors}
                onChange={handleChange}
                id={`${baseId}-input-vehicleVariant`}
                value={currentState?.toLocaleString() ?? ''}
                tooltipContent='Check this looks correct but if not, search using the Business Name again.'
            />
        </div>
    );
}
