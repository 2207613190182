import { Input } from '@/components/Input';
import {
    selectNumberOfDirectors,
    setNumberOfDirectors,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'NumberOfDirectors';

export function NumberOfDirectors({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectNumberOfDirectors);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        // MAXIMUM
        const MAXIMUM = 99;
        const intValue = parseInt(value.toString());
        if (intValue > MAXIMUM) return;
        if (isNaN(intValue)) {
            dispatch(setNumberOfDirectors(undefined));
            return;
        }
        dispatch(setNumberOfDirectors(intValue));
    }

    // ***** Render *****
    return (
        <div data-testid={testId} className='w-full'>
            <Input
                title='How Many Directors?'
                placeholder='Directors'
                showErrors={showErrors && !currentState}
                onChange={handleChange}
                id={`${baseId}-input-numberOfDirectors`}
                value={currentState?.toLocaleString() ?? ''}
                tooltipContent='We need to know this because lenders will want to know.'
            />
        </div>
    );
}
