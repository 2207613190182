import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Combobox as HUICombobox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { uniqueIdTransfer } from '@/apiCalls/uniqueIDTransferHelper';
import { EDisplays, selectDisplay } from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';
import { formatSpacedStrings } from '@/utils/formatSpacedStrings';

import { EEventType } from '../eventType';
import { Tooltip } from '../Tooltip';

export interface ICombobox {
    options: IComboboxOption[];
    placeholder: string;
    heading: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    query: string;
    onSelect: (option: IComboboxOption) => void;
    showErrors: boolean;
    loading?: boolean;
    baseId?: string;
    eventType?: EEventType;
    tooltipContent?: string;
}

export interface IComboboxOption {
    id: number;
    value: string;
    code?: string;
}

export function Combobox({
    options,
    loading,
    placeholder,
    query,
    onChange,
    onSelect,
    heading,
    showErrors,
    baseId,
    eventType = EEventType.DROPDOWN,
    tooltipContent,
}: ICombobox): JSX.Element {
    const [selected, setSelected] = useState<IComboboxOption | ''>('');
    const solar = false; // todo delete
    const display = useAppSelector(selectDisplay);
    const vehicleDisplay = display === EDisplays.VEHICLE;

    const filteredOptions =
        query === ''
            ? options
            : options.filter((person) => {
                  if (typeof person.value === 'string') {
                      return person.value
                          .toLowerCase()
                          .replace(/\s+/g, '')
                          .includes(query.toLowerCase().replace(/\s+/g, ''));
                  }
                  return false;
              });

    function chooseOption(option: IComboboxOption): void {
        setSelected(option);
        onSelect(option);
        uniqueIdTransfer({
            eventType: eventType,
            applicationData: {
                label: `${baseId}-dropdown-${formatSpacedStrings(heading)}`,
            },
        });
    }
    useEffect(() => {
        setSelected('');
    }, [query]);

    return (
        <div className='py-2'>
            <Tooltip content={tooltipContent ?? ''}>
                <h1 className='fieldHeading'>{heading}</h1>
            </Tooltip>

            <HUICombobox
                value={selected}
                onChange={chooseOption}
                data-testid='combobox'
            >
                <div className='relative'>
                    <div
                        className={`border-2 relative w-full cursor-default overflow-hidden ${
                            solar ? 'rounded-lg h-10' : 'rounded-[10px] h-8'
                        }  bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm ${
                            showErrors && 'border-errorSolid'
                        }`}
                    >
                        <HUICombobox.Input
                            className={`w-full border-none py-1 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:outline-none text-center ${
                                !solar &&
                                vehicleDisplay &&
                                'placeholderStyle italic'
                            } ${showErrors && 'bg-error'}`}
                            displayValue={(option: IComboboxOption): string =>
                                option?.value ?? query
                            }
                            id={`${baseId}-dropdown-${formatSpacedStrings(
                                heading
                            )}`}
                            placeholder={placeholder}
                            data-testid='combobox-input'
                            onChange={onChange}
                        />
                        <HUICombobox.Button
                            data-testid='combobox-button'
                            className='absolute inset-y-0 right-0 _flex items-center pr-2'
                        >
                            <ChevronDownIcon
                                className='h-5 w-5 text-gray-400'
                                aria-hidden='true'
                            />
                        </HUICombobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <HUICombobox.Options className='absolute max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10'>
                            {filteredOptions?.length === 0 ? (
                                <div className='relative cursor-default select-none py-1 px-4 text-gray-700'>
                                    {loading ? 'Loading...' : 'Nothing found.'}
                                </div>
                            ) : (
                                filteredOptions.map((option) => (
                                    <HUICombobox.Option
                                        data-testid='combobox-option'
                                        key={option.id}
                                        className={({
                                            selected,
                                            active,
                                        }): string =>
                                            `relative cursor-default select-none  pl-10 pr-4 ${
                                                active
                                                    ? 'bg-secondary text-white'
                                                    : selected
                                                    ? 'text-white'
                                                    : 'text-gray-900'
                                            } ${
                                                selected
                                                    ? 'text-white bg-primary'
                                                    : ''
                                            }`
                                        }
                                        value={option}
                                    >
                                        {({
                                            selected,
                                            active,
                                        }): JSX.Element => (
                                            <>
                                                <span
                                                    className={`block  _flex align-middle ${
                                                        selected
                                                            ? 'font-medium'
                                                            : 'font-normal'
                                                    }`}
                                                >
                                                    {option.value}
                                                    {selected ? (
                                                        <span
                                                            className={`relative inset-y-0 _flex items-center pl-3 ${
                                                                active
                                                                    ? 'text-white bg-primary'
                                                                    : 'text-teal-600'
                                                            }`}
                                                        ></span>
                                                    ) : null}
                                                </span>
                                            </>
                                        )}
                                    </HUICombobox.Option>
                                ))
                            )}
                        </HUICombobox.Options>
                    </Transition>
                </div>
            </HUICombobox>
        </div>
    );
}
