import { ModalComponent } from '@/components/ModalComponent';
import { ModalHeader } from '@/components/ModalHeader';
import { ECondition, selectVehicleCondition } from '@/context/borrower';
import { useAppSelector } from '@/context/storeHooks';

export function MchDisclaimer(): JSX.Element {
    // ***** Redux *****
    const condition = useAppSelector(selectVehicleCondition);

    // ***** Constants *****
    const baseId = 'modal-MchDisclaimer';
    // ***** Render *****
    return (
        <ModalComponent
            baseId={baseId}
            className=' w-full h-full max-h-[90vh] '
        >
            <ModalHeader title='Disclaimer' baseId={baseId} />

            <p className='text-sm pb-4'>
                {condition === ECondition.NEW ? (
                    <>
                        Ride away pricing is shown on all products for the base
                        model colour, different colours may be at an additional
                        cost. Ride away pricing includes registration, CTP,
                        stamp duty and dealer delivery fee. Private buyers only.
                        Ride away pricing is based on a motorcycle registered in
                        QLD and is subject to change without notice. Please
                        contact your local dealer to confirm pricing as actual
                        ride away pricing may vary according to your individual
                        circumstances or location. For non-road registerable
                        vehicles, ready to ride pricing includes dealer delivery
                        fee. We reserve the right to correct any mistakes or
                        omissions or vary the ride away pricing at any time.
                    </>
                ) : (
                    <>
                        Pricing excludes government charges and on-road costs.
                        Please contact your local dealer to confirm pricing as
                        actual ride away pricing may vary according to your
                        individual circumstances or location.
                    </>
                )}
            </p>
        </ModalComponent>
    );
}
