import { useSelector } from 'react-redux';

import { RadioGroup } from '@/components/RadioGroup';
import {
  EAddressOwnershipType,
  IAddress,
  selectAddressByIndex,
  setAddressByIndex,
} from '@/context/borrower';
import { RootState } from '@/context/store';
import { useAppDispatch } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'PropertyOwner';

export function PropertyOwner({ baseId, showErrors }: IProps): JSX.Element {
  // ***** Redux *****
  // Not using the redux hooks (eg, useAppSelector) here because we need to use the index - extra args
  const address = useSelector((state: RootState) =>
    selectAddressByIndex(state, 0)
  );
  const ownershipType = address?.ownershipType;
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean | undefined): void {
    const newAddress: IAddress = {
      ...address,
      ownershipType: value as EAddressOwnershipType,
    };
    dispatch(setAddressByIndex({ index: 0, address: newAddress }));
  }
  // ***** Render *****
  const options = [
    {
      label: 'Yes',
      value: EAddressOwnershipType.OWNER,
      id: `${baseId}-button-isPropertyOwner-yes`,
    },
    {
      label: 'No',
      value: EAddressOwnershipType.RENTING,
      id: `${baseId}-button-isPropertyOwner-no`,
    },
  ];
  return (
    <div data-testid={testId}>
      <RadioGroup
        title='Are you a property owner?'
        showErrors={showErrors && !ownershipType}
        onChange={handleChange}
        options={options}
        value={ownershipType}
      />
    </div>
  );
}
