import { v4 as uuidv4 } from 'uuid';

import { getLocalStorageItem } from '../getLocalStorageItem';
import { ELocalStorageVariables } from '../localStorageTypes';
import { setLocalStorageItem } from '../setLocalStorageItem';

export function getSessionId(): string {
    const sessionData = getLocalStorageItem(ELocalStorageVariables.SESSION_ID);

    if (sessionData) {
        const { uuid, expirationTimestamp } = JSON.parse(sessionData);

        const currentTimestamp = Date.now();

        if (currentTimestamp < expirationTimestamp) {
            return uuid;
        }
    }

    const newUuid = uuidv4();
    const expirationTimestamp = Date.now() + 1000 * 60 * 60 * 4; // Expires in 4 hours

    const newSessionData = JSON.stringify({
        uuid: newUuid,
        expirationTimestamp,
    });

    setLocalStorageItem(ELocalStorageVariables.SESSION_ID, newSessionData);

    return newUuid;
}
