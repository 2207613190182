import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { store } from '@/context/store';

export function SetFinalScreen(): void {
    const state = store.getState();
    const partner = state.displayReducer.partner;
    const { earlyReferredScreen } = partner;
    const isApproved = state.borrowerReducer.application.isPreApproved;

    if (!isApproved && earlyReferredScreen) {
        store.dispatch(setDisplay(EDisplays.INDICATIVELY_REFERRED));
    } else {
        store.dispatch(setModal(EModals.APPLICATION_SUBMITTED));
    }
}
