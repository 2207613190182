import { PayloadAction } from '@reduxjs/toolkit';

import { ESelfAssessedCreditHistory, IScores } from '../types';

export const scoresReducers = {
  setScores: (state, action: PayloadAction<IScores>): void => {
    state.scores = action.payload;
  },
  setSelfAssessedCreditHistory: (
    state,
    action: PayloadAction<ESelfAssessedCreditHistory | undefined>
  ): void => {
    state.scores.selfAssessedCreditHistory = action.payload;
  },
};
