import { setLenderSorting } from '@/context/display';
import { store } from '@/context/store';
import { ELenderSorting } from '@/partners/matchingEngineEnums';

export function setInitialLenderSorting(
    lenderSorting: ELenderSorting | undefined
): void {
    if (!lenderSorting) return;
    const stateLenderSorting = store.getState().displayReducer.lenderSorting;

    if (stateLenderSorting === lenderSorting) return;

    store.dispatch(setLenderSorting(lenderSorting));
}
