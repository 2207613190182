export function capitalizeString(string: string | undefined): string {
    if (string === undefined) {
        return '';
    }

    if (string.length === 0) {
        return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}
