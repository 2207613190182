import { PayloadAction } from '@reduxjs/toolkit';

import { EFrequency } from '../types';

export const expensesReducers = {
  setExpensesShared: (
    state,
    action: PayloadAction<boolean | undefined>
  ): void => {
    state.expenses.expensesShared = action.payload;
  },
  setBillsPaidFrequency: (
    state,
    action: PayloadAction<EFrequency | undefined>
  ): void => {
    state.expenses.billsPaidFrequency = action.payload;
  },
  setGeneral: (state, action: PayloadAction<number | undefined>): void => {
    state.expenses.general = action.payload;
  },
  setTransportVehicle: (
    state,
    action: PayloadAction<number | undefined>
  ): void => {
    state.expenses.transportVehicle = action.payload;
  },
  setEducation: (state, action: PayloadAction<number | undefined>): void => {
    state.expenses.education = action.payload;
  },
  setOtherLoans: (state, action: PayloadAction<number | undefined>): void => {
    state.expenses.otherLoans = action.payload;
  },
  setMortgageRent: (state, action: PayloadAction<number | undefined>): void => {
    state.expenses.mortgageRent = action.payload;
  },
  setHealthcare: (state, action: PayloadAction<number | undefined>): void => {
    state.expenses.healthcare = action.payload;
  },
  setNoForseeableChangesToCircumstances: (
    state,
    action: PayloadAction<boolean | undefined>
  ): void => {
    state.expenses.noForseeableChangesToCircumstances = action.payload;
  },
  setForseeableChangesExplanation: (
    state,
    action: PayloadAction<string | undefined>
  ): void => {
    state.expenses.forseeableChangesExplanation = action.payload;
  },
};
