import { XCircleIcon } from '@heroicons/react/24/outline';

import { setModal } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';

export const testId = 'ModalHeader';

export interface IProps {
    baseId: string;
    title: string;
    performCheck?: boolean;
    showCloseButton?: boolean;
    className?: string;
}

export function ModalHeader({
    title,
    baseId,
    performCheck,
    showCloseButton = true,
    className,
}: IProps): JSX.Element {
    // ***** Constants *****
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleClick(): void {
        dispatch(setModal(null));
    }

    // ***** Render *****
    return (
        <div
            data-testid={testId}
            className={`${className} _flex justify-between pb-2`}
            id={`${baseId}-modal`}
        >
            <p className='w-full font-bold'>{title}</p>
            {showCloseButton && <div className='h-6 w-6 cursor-pointer hover:scale-125 transform delay-75 ease-in-out'>
                <XCircleIcon
                    onClick={handleClick}
                    id={`${baseId}-button-xButton`}
                />
            </div>}
        </div>
    );
}
