interface Props {
    fullScreen: boolean;
}

export function TDRImage({ fullScreen }: Props): JSX.Element {
    return (
        <img
            src='/testDriveFinal.png'
            className={`filter brightness-50 w-full ${
                fullScreen ? 'h-full object-cover' : 'h-2/3'
            }`}
            style={
                fullScreen
                    ? { objectFit: 'cover', objectPosition: 'center' }
                    : {}
            }
        />
    );
}
