import { setVehicleVariant } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialVehicleVariant(variant: IParameter): void {
  if (!variant) {
    return;
  }

  const stateMake = store.getState().borrowerReducer.vehicle.variant;

  if (stateMake !== variant) {
    store.dispatch(setVehicleVariant(variant));
  }
}
