import { useState } from 'react';

import { DisplayComponent } from '@/components/DisplayComponent';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';

import { AddressInformation } from './AddressInformation';
import { ContactDetails } from './ContactDetails';
import { OcrSummaryButtons } from './OcrSummaryButtons';
import { PersonalInformation } from './PersonalInformation';

export const testId = 'OcrSummary';

export function OcrSummary(): JSX.Element {
    // ***** Local State *****
    const [showErrors, setShowErrors] = useState(false);

    // ***** CONSTANTS *****
    const baseId = 'screen-OcrSummary';
    return (
        <DisplayComponent baseId={baseId} data-testid={testId}>
            <Header />
            <ProgressBar />
            <DisplayHeading
                title='Details Summary'
                infoText="Here's what we've got so far. Something missing or not quite right? Click on box or the 'edit' button to fix it up."
                subtitle='Check your details below'
            />
            <ContactDetails baseId={baseId} showErrors={showErrors} />
            <AddressInformation baseId={baseId} showErrors={showErrors} />
            <PersonalInformation baseId={baseId} showErrors={showErrors} />
            <OcrSummaryButtons baseId={baseId} setShowErrors={setShowErrors} />
        </DisplayComponent>
    );
}
