import { useSelector } from 'react-redux';
import {
    EnvelopeIcon,
    MapPinIcon,
    PhoneIcon,
    UserIcon,
} from '@heroicons/react/24/outline';

import { TestDriveSummarySection } from '@/components/TestDriveSummarySection';
import {
    selectAddressByIndex,
    selectEmail,
    selectFirstName,
    selectLastName,
    selectMiddleName,
    selectMobile,
} from '@/context/borrower';
import { EModals, setModal } from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { capitalizeString } from '@/utils/capitalizeString';
import { isEmail } from '@/utils/isEmail';
import { isMobile } from '@/utils/isMobile';

export const testId = 'ContactDetails';

export interface IProps {
    showErrors: boolean;
    baseId: string;
}

export function TDContactDetails({ showErrors, baseId }: IProps): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const firstName = useAppSelector(selectFirstName);
    const lastName = useAppSelector(selectLastName);
    const middleName = useAppSelector(selectMiddleName);
    const email = useAppSelector(selectEmail);
    const phone = useAppSelector(selectMobile);
    const address = useSelector((state: RootState) =>
        selectAddressByIndex(state, 0)
    );
    const { unitNumber, streetNumber, streetName, suburb, state, postcode } =
        address;

    // ***** Event Handlers *****
    function handleClick(): void {
        dispatch(setModal(EModals.TEST_DRIVE_CONTACT_DETAILS));
    }

    // ***** Helpers *****
    function isComplete(): boolean {
        if (firstName === null || firstName === undefined) return false;
        if (lastName === null || lastName === undefined) return false;
        if (!isEmail(email)) return false;
        if (!isMobile(phone)) return false;
        return true;
    }

    function getAddress(
        unitNumber: string | undefined,
        streetNumber: string | undefined,
        streetName: string | undefined,
        suburb: string | undefined,
        state: string | undefined,
        postcode: string | undefined
    ): JSX.Element {
        // Create an array of address parts
        const addressParts = [
            unitNumber,
            streetNumber,
            streetName,
            suburb,
            state,
            postcode,
        ];

        // Filter out empty or undefined parts
        const filteredParts = addressParts.filter((part) => part);

        // Join the parts with a comma and space
        const formattedAddress = filteredParts.join(', ');

        return <p>{formattedAddress}</p>;
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <TestDriveSummarySection
                onClick={handleClick}
                id={`${baseId}-button-editPersonalInformation`}
                heading='Personal Information'
                showErrors={showErrors && !isComplete()}
            >
                <div className='flex flex-col space-y-2 pt-2'>
                    <div className='flex space-x-2 items-center'>
                        <UserIcon className='w-5 h-5' />
                        <p>
                            {capitalizeString(firstName)}{' '}
                            {capitalizeString(middleName)}{' '}
                            {capitalizeString(lastName)}
                        </p>
                    </div>
                    <div className='flex space-x-2 items-center'>
                        <PhoneIcon className='w-5 h-5' />
                        <p>{phone}</p>
                    </div>
                    <div className='flex space-x-2 items-center'>
                        <EnvelopeIcon className='w-5 h-5' />
                        <p>{email}</p>
                    </div>
                    <div className='flex space-x-2 items-center'>
                        <MapPinIcon className='w-5 h-5' />
                        <p>
                            {getAddress(
                                unitNumber,
                                streetNumber,
                                streetName,
                                suburb,
                                state,
                                postcode
                            )}
                        </p>
                    </div>
                </div>
            </TestDriveSummarySection>
        </div>
    );
}
