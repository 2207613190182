import { ESellerType, setSellerType } from '@/context/borrower';
import { store } from '@/context/store';

export function setInitialSellerType(
  partnerType: ESellerType | undefined
): void {
  const state = store.getState();
  if (!partnerType) {
    return;
  }
  const { type } = state.borrowerReducer.seller;

  if (partnerType !== type) {
    store.dispatch(setSellerType(partnerType));
  }
}
