export interface LenderState {
  lenders: ILender[] | null;
  lenderRepaymentFrequency: ELenderRepaymentFrequency;
  lenderSortedBy: ELenderSortedBy;
  isLenderLoading: boolean;
}
export interface ILender {
  lenderName: string;
  matchingEngineLenderId: string;
  advertisedRateInPercent: number;
  balloonValueInPercent: number;
  comparisonRateInPercent: number;
  turnaroundTimeInDays: number;
  upfrontFeeAmount: number;
  monthlyOngoingFeeAmount: number;
  weeklyRepaymentAmount: number;
  fortnightlyRepaymentAmount: number;
  monthlyRepaymentAmount: number;
  requiredDocuments: { [key: string]: string };
    loanTerm?: number;
    financeTotal?: number | null;
    otherFee?: number | null;
    lenderEstablishmentFee?: number | null;
    riskGrade?: string | null;
}

export enum ELenderRepaymentFrequency {
  MONTHLY = 'Monthly',
  WEEKLY = 'Weekly',
  FORTNIGHTLY = 'Fortnightly',
}

export enum ELenderSortedBy {
  REPAYMENT = 'Repayment',
  ADVERTISED_RATE = 'Advertised Rate',
  COMPARISON_RATE = 'Comparison Rate',
  UPFRONT_FEE = 'Upfront Fee',
  ONGOING_FEE = 'Ongoing Fee',
  TURNAROUND_TIME = 'Turnaround Time',
}
