import { Button } from '@/components/Button';
import { ContactBox } from '@/components/ContactBox';
import { DisplayComponent } from '@/components/DisplayComponent';
import { Header } from '@/components/Header';
import { RemoteImage } from '@/components/RemoteImage';
import { EDisplays, setDisplay } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';

export const baseId = 'screen-servicingFailure';

export function ServicingFailure(): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleReduceLoanAmount(): void {
        dispatch(setDisplay(EDisplays.LOAN));
    }

    function handleEditIncome(): void {
        dispatch(setDisplay(EDisplays.INCOME));
    }

    return (
        <DisplayComponent baseId={baseId} data-testid={baseId}>
            <Header />
            <div className='flex-center pb-4 relative'>
                <div className='bg-primary h-40 w-40 rounded-full' />
                <RemoteImage fileName='coins.svg' className='absolute' />
            </div>
            <div className='text-center _flex flex-col space-y-2'>
                <h3 className='uppercase text-xl text-primary font-bold '>
                    Check your income
                    <br />
                    and expenses
                </h3>
                <p className='text-secondary '>
                    It looks like your income might not be high <br /> enough
                    for the amount you&apos;d like to borrow
                </p>
                <p className='text-sm'>
                    Check your income and expenses to make sure <br />
                    they&apos;re correct or consider reducing the amount <br />
                    you&apos;d like to borrow.
                </p>
            </div>
            <div className='_flex space-x-2 mx-4 pt-4'>
                <Button
                    id={`${baseId}-button-reduceLoan`}
                    onClick={handleReduceLoanAmount}
                >
                    Reduce Loan
                </Button>
                <Button
                    id={`${baseId}-button-editAnswers`}
                    onClick={handleEditIncome}
                >
                    Edit Answers
                </Button>
            </div>
            <div className='flex-center w-full'>
                <ContactBox />
            </div>
        </DisplayComponent>
    );
}
