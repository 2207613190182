import { postUrl } from '@/apiCalls/fetch';
import { ISupportingDoc, setSupportingDocs } from '@/context/borrower';
import { store } from '@/context/store';

import IDocument from './uploadDocumentTypes';

export async function postDocument(
  code: string,
  files: File[]
): Promise<IDocument[]> {
  // 1. Format data
  const data = new FormData();
  data.append('code', code);
  files.forEach((file: File) => {
    data.append('documents', file, file.name);
  });

  // 2. Post data
  const response = await postUrl<IDocument[]>('/api/documents', data);

  // 3. Save response log to store
  const state = store.getState();

  const supportingDocs = state.borrowerReducer.supportingDocs;

  let newSupportingDocs = response;
  Object.keys(supportingDocs).forEach((key) => {
    if (key === code) {
      newSupportingDocs = supportingDocs[key].concat(response);
    }
  });

  const setSupportingDocsPayload: { code: string; document: ISupportingDoc[] } =
    {
      code,
      document: newSupportingDocs,
    };

  store.dispatch(setSupportingDocs(setSupportingDocsPayload));
  return response;
}
