import { EDisplays } from '@/context/display';
import { store } from '@/context/store';

export function ProgressBar(): JSX.Element {
    return (
        <>
            <div className='w-full _flex mb-1 pt-2'>
                {progressBarSections.map((item, index) => (
                    <div
                        key={index}
                        className={`text-[11px] font-sans w-full _flex justify-around flex-center   ${
                            whichDisplayIsActive() === index
                                ? 'font-bold border-primary border-b-4 text-tertiary'
                                : 'border-grey  border-b-2 text-textBody font-light'
                        }`}
                        style={{ whiteSpace: 'pre-wrap' }}
                    >
                        {item.name}
                    </div>
                ))}
            </div>
        </>
    );
}

// Necessary for unique id
export function getActiveProgressText(): string {
    const activeDisplayIndex = whichDisplayIsActive();
    const section = progressBarSections[activeDisplayIndex].name;
    if (section === undefined) return 'Test Drive';
    const formatted = section.replace(/\n/g, ' ');
    return formatted;
}

const progressBarSections = [
    { name: 'Quick\nCalculator' },
    { name: 'Real\nQuote' },
    { name: 'Your\nResults' },
    { name: 'Apply \nto lender' },
];
function whichDisplayIsActive(): number {
    const state = store.getState();
    const display = state.displayReducer.display;

    switch (display) {
        case EDisplays.QUICK_CALCULATOR:
        case EDisplays.TRUCKSTOP_LANDING_PAGE:
        case EDisplays.NO_LENDER_HOME:
            return 0;
        case EDisplays.LOAN:
        case EDisplays.BASIC:
        case EDisplays.INCOME:
        case EDisplays.EXPENSE:
        case EDisplays.VEHICLE:
        case EDisplays.SELLER:
        case EDisplays.IDENTIFICATION:
        case EDisplays.OCR_SUMMARY:
        case EDisplays.PREPPROVAL_ANIMATION:
        case EDisplays.OCR_ANIMATION:
        case EDisplays.INDICATIVELY_DECLINED:
        case EDisplays.INDICATIVELY_REFERRED:
        case EDisplays.SERVICING_FAILURE:
            return 1;
        case EDisplays.SERVICING_SUCCESS:
        case EDisplays.YOUR_SCORES:
        case EDisplays.INDICATIVELY_APPROVED:
            return 2;
        case EDisplays.EMPLOYMENT:
        case EDisplays.ASSETS_AND_LIABILITIES:
        case EDisplays.SUPPORTING_DOCS:
            return 3;
        case EDisplays.TEST_DRIVE_AGREEMENT:
        case EDisplays.TEST_DRIVE_OCR_SUMMARY:
        case EDisplays.TEST_DRIVE_READY:
            return 4;
        default:
            return 5;
    }
}
