import React from 'react';

import './tooltipCss.css';

interface TooltipV2Props {
    children: React.ReactNode;
    content: string;
    maxWidth?: string;
    isHeading?: boolean
}

export function Tooltip({
    children,
    content,
    maxWidth,
    isHeading = false
}: TooltipV2Props): JSX.Element {
    const tooltipStyle = {
        '--tooltip-max-width': maxWidth ?? '100%',
    } as React.CSSProperties;
    return (
        <div
            className={content !== '' ? 'tooltipSegment' : ''}
            data-tooltip={content}
            style={tooltipStyle}
            id={isHeading ? 'tooltipHeading' : ''}
        >
            {children}
        </div>
    );
}
