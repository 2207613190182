import { SummarySection } from '@/components/SummarySection';
import {
    selectEmail,
    selectFirstName,
    selectLastName,
    selectMiddleName,
    selectMobile,
} from '@/context/borrower';
import { EModals, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getStringFromState } from '@/utils/getStringFromState';
import { isEmail } from '@/utils/isEmail';
import { isMobile } from '@/utils/isMobile';

export const testId = 'ContactDetails';

export interface IProps {
    showErrors: boolean;
    baseId: string;
}

export function ContactDetails({ showErrors, baseId }: IProps): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const firstName = useAppSelector(selectFirstName);
    const lastName = useAppSelector(selectLastName);
    const middleName = useAppSelector(selectMiddleName);
    const email = useAppSelector(selectEmail);
    const phone = useAppSelector(selectMobile);

    // ***** Event Handlers *****
    function handleClick(): void {
        dispatch(setModal(EModals.CONTACT_DETAILS));
    }

    // ***** Helpers *****
    function isComplete(): boolean {
        if (firstName === null || firstName === undefined) return false;
        if (lastName === null || lastName === undefined) return false;
        if (!isEmail(email)) return false;
        if (!isMobile(phone)) return false;
        return true;
    }

    function fullText(): string {
        return `I am <strong>${getStringFromState(
            firstName
        )}</strong> <strong>${getStringFromState(
            middleName,
            true
        )}</strong> <strong>${getStringFromState(
            lastName
        )}</strong>. My phone number is <strong>${getStringFromState(
            phone
        )}</strong>. My email is <strong>${getStringFromState(
            email
        )}</strong>.`;
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <SummarySection
                onClick={handleClick}
                id={`${baseId}-button-editContactDetails`}
                text={fullText()}
                heading='Contact Details'
                showErrors={showErrors && !isComplete()}
            />
        </div>
    );
}
