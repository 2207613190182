export function formatSpacedStrings(inputString: string): string {
    // Convert input string to lowercase and split by spaces
    const words = inputString.toLowerCase().split(' ');

    // Concatenate words with first letter capitalized
    const result = words.reduce((acc, curr) => {
        return acc + curr.charAt(0).toUpperCase() + curr.slice(1);
    }, '');

    // Lowercase the first letter of the result and return
    return result.charAt(0).toLowerCase() + result.slice(1);
}
