import { useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button } from '@/components/Button';
import { DisplayComponent } from '@/components/DisplayComponent';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import {
    selectSelectedLender,
    selectSellerName,
    selectSellerPhone,
    selectVehicleBuildYear,
    selectVehicleMake,
    selectVehicleModel,
    setSelectedLender,
} from '@/context/borrower';
import { EDisplays, setDisplay, setPreviousDisplay } from '@/context/display';
import { selectLenders } from '@/context/lender';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { updateShownLenders } from '@/utils/updateShownLenders';

import { IndicativelyApprovedButtons } from './IndicativelyApprovedButtons';
import { LenderRepayments } from './LenderRepayments';

export const baseId = 'screen-indicativelyApproved';

export function IndicativelyApproved(): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const selectedLender = useAppSelector(selectSelectedLender);
    const lenders = useAppSelector(selectLenders);
    const buildYear = useAppSelector(selectVehicleBuildYear);
    const make = useAppSelector(selectVehicleMake);
    const model = useAppSelector(selectVehicleModel);
    const dealerName = useAppSelector(selectSellerName);
    const contactPhone = useAppSelector(selectSellerPhone);

    const isVehicleKnown = useAppSelector(
        (state) => state.borrowerReducer.vehicle.isVehicleKnown
    );

    function toggleCongrats(): void {
        setCongratsExpanded((congratsExpanded) => !congratsExpanded);
    }
    function handleEditVehicle(): void {
        dispatch(setPreviousDisplay(EDisplays.INDICATIVELY_APPROVED));
        createOrUpdateBorrower();
        dispatch(setDisplay(EDisplays.VEHICLE));
    }

    useEffect(() => {
        const findMatchingLender = lenders?.find(
            (lender) =>
                lender.matchingEngineLenderId ===
                selectedLender?.matchingEngineLenderId
        );
        if (findMatchingLender) {
            dispatch(setSelectedLender(findMatchingLender));
        } else {
            dispatch(setSelectedLender(undefined));
        }
        //TODO: temporary fix
        setTimeout(() => {
            createOrUpdateBorrower();
        }, 500);
    }, []);

    useEffect(() => {
        updateShownLenders(lenders, EDisplays.INDICATIVELY_APPROVED);
    }, [lenders]);

    const [congratsExpanded, setCongratsExpanded] = useState(false);
    return (
        <DisplayComponent baseId={baseId} data-testid={baseId}>
            <Header />
            <ProgressBar />
            {/* Congratulations */}
            <div className=''>
                {/* Congratulations */}
                <div className='_flex flex-col text-center borderOutline  bg-white rounded-md pt-4 pb-2 mt-2'>
                    <h3 className='text-primary font-bold text-2xl'>
                        Congratulations!
                    </h3>
                    <p className='text-secondary text-sm text-textBody text-center'>
                        Your application has been <br /> indicatively approved
                    </p>
                    <p className='text-xs px-4 pt-2'>
                        An indicative approval is the first level of approval
                        from your loan provider. It means that your application
                        has successful
                        {!congratsExpanded && <span>...</span>}
                        {congratsExpanded && <span>ly</span>}
                    </p>

                    {congratsExpanded && (
                        <p className='text-xs px-4 pt-2 -mt-2'>
                            gone through the first screening process where your
                            credit history, personal debt and your income has
                            been reviewed. As your application moves on to the
                            next phase, the lender may require more information
                            before granting formal, or &apos;unconditional&apos;
                            approval.
                        </p>
                    )}
                    <div
                        className='w-full justify-end _flex px-2 items-center group cursor-pointer'
                        onClick={toggleCongrats}
                    >
                        <div className='h-5 w-5 group-hover:scale-125 transform delay-75 ease-in-out'>
                            {congratsExpanded ? (
                                <div
                                    className='border rounded-full border-textPrimary'
                                    id={`${baseId}-toggle-expandCongratulationsCard`}
                                >
                                    <ChevronUpIcon />
                                </div>
                            ) : (
                                <div
                                    className='border rounded-full border-textPrimary'
                                    id={`${baseId}-toggle-expandCongratulationsCard`}
                                >
                                    <ChevronDownIcon />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <DisplayHeading
                title='Loan Details'
                infoText='Based on the information you have provided us, here is a list of lenders that you have qualified with.'
            />
            {/* Lender Repayments */}
            <LenderRepayments baseId={baseId} />
            {/* Show the following if only one lender */}
            {(lenders?.length === 1 || selectedLender !== undefined) && (
                <>
                    <div className='borderOutline bg-white  p-2 my-4 relative'>
                        {isVehicleKnown ? (
                            <>
                                <p className='text-[13px] font-bold text-[#201C1F] text-center'>
                                    Vehicle Details
                                </p>
                                <div className='_flex text-[12px] text-center py-2 mx-14'>
                                    <div
                                        className='text-primary font-bold'
                                        style={{ flex: 1 }}
                                    >
                                        {`${make || ''} ${model || ''} ${
                                            buildYear || ''
                                        } `}
                                    </div>
                                </div>
                                <div className='w-auto mx-4'>
                                    <Button
                                        // type={EButtonType.EDIT_BUTTON_OCR_SUMMARY_SECONDARY_OUTLINE}
                                        id={`${baseId}-button-editVehicle`}
                                        onClick={handleEditVehicle}
                                    >
                                        Edit
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                <p className='text-[11px] text-textBody text-center font-semibold'>
                                    You haven&apos;t selected a vehicle yet.
                                </p>
                                <div className='w-auto mt-4 mx-4'>
                                    <Button
                                        id={`${baseId}-button-chooseVehicle`}
                                        onClick={handleEditVehicle}
                                    >
                                        Choose
                                    </Button>
                                </div>
                            </>
                        )}
                        {dealerName && (
                            <>
                                <p className='text-sm font-bold text-[#201C1F] text-center mt-2'>
                                    Seller&apos;s Details
                                </p>
                                <div className='_flex justify-around py-2'>
                                    <div className='_flex flex-col'>
                                        <p className='fieldHeading'>
                                            Dealership name
                                        </p>
                                        <div
                                            className='text-primary font-bold text-center'
                                            style={{ flex: 1 }}
                                        >
                                            {dealerName}
                                        </div>
                                    </div>

                                    {contactPhone && (
                                        <div className='_flex flex-col'>
                                            <p className='fieldHeading'>
                                                Phone Number
                                            </p>
                                            <a
                                                className='text-primary font-bold text-center'
                                                style={{ flex: 1 }}
                                                href={`tel:${contactPhone}`}
                                            >
                                                {contactPhone}
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
            {selectedLender && <IndicativelyApprovedButtons baseId={baseId} />}
        </DisplayComponent>
    );
}
