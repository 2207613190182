import { useState } from 'react';
import { useSelector } from 'react-redux';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button } from '@/components/Button';
import { ModalComponent } from '@/components/ModalComponent';
import { ModalHeader } from '@/components/ModalHeader';
import {
    selectAddressByIndex,
    selectEmail,
    selectFirstName,
    selectLastName,
} from '@/context/borrower';
import { setModal } from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { AddressState } from '@/UserInput/AddressState';
import { Email } from '@/UserInput/Email';
import { FirstName } from '@/UserInput/FirstName';
import { LastName } from '@/UserInput/LastName';
import { MiddleName } from '@/UserInput/MiddleName';
import { PostCode } from '@/UserInput/PostCode';
import { StreetName } from '@/UserInput/StreetName';
import { StreetNumber } from '@/UserInput/StreetNumber';
import { SuburbName } from '@/UserInput/SuburbName';
import { UnitNumber } from '@/UserInput/UnitNumber';
import { isEmail } from '@/utils/isEmail';

export const testId = 'TDContactDetailsModal';

export function TDContactDetailsModal(): JSX.Element {
    // ***** Constants *****
    const baseId = 'modal-TDContactDetailsModal';
    // ***** Local State *****
    const [showErrors, setShowErrors] = useState<boolean>(false);
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const firstName = useAppSelector(selectFirstName);
    const lastName = useAppSelector(selectLastName);
    const email = useAppSelector(selectEmail);
    const address = useSelector((state: RootState) =>
        selectAddressByIndex(state, 0)
    );
    const { streetNumber, streetName, suburb, state, postcode } = address;

    // ***** Handlers *****
    function handleClick(): void {
        setShowErrors(true);
        if (!isComplete()) return;
        createOrUpdateBorrower();
        dispatch(setModal(null));
    }

    // ***** Helpers *****
    function isComplete(): boolean {
        if (firstName === '' || firstName === undefined) return false;
        if (lastName === '' || lastName === undefined) return false;
        if (email === '' || lastName === undefined) return false;

        if (!isEmail(email)) return false;

        if (streetNumber === '') return false;
        if (streetNumber === undefined) return false;

        if (streetName === '') return false;
        if (streetName === undefined) return false;

        if (suburb === '') return false;
        if (suburb === undefined) return false;

        if (state === null) return false;
        if (state === undefined) return false;

        if (postcode === '') return false;
        if (postcode === undefined) return false;
        if (postcode.toString()?.length !== 4) return false;

        return true;
    }

    // ***** Render *****
    return (
        <ModalComponent baseId={baseId}>
            <ModalHeader title='Contact Details' baseId={baseId} />
            <div className='flex-center space-x-2'>
                <FirstName baseId={baseId} showErrors={showErrors} />
                <MiddleName baseId={baseId} showErrors={showErrors} />
            </div>
            <div className='flex-center space-x-2'>
                <LastName baseId={baseId} showErrors={showErrors} />
                {/* <MobileNumber baseId={baseId} showErrors={showErrors} /> */}
                <Email baseId={baseId} showErrors={showErrors} />
            </div>
            <div className='flex-center space-x-2'>
                <UnitNumber baseId={baseId} showErrors={false} />
                <StreetNumber baseId={baseId} showErrors={showErrors} />
            </div>
            <div className='flex-center space-x-2'>
                <StreetName baseId={baseId} showErrors={showErrors} />
                <SuburbName baseId={baseId} showErrors={showErrors} />
            </div>
            <div className='flex-center space-x-2'>
                <AddressState baseId={baseId} showErrors={showErrors} />
                <PostCode baseId={baseId} showErrors={showErrors} />
            </div>

            <div className='py-2 mx-16'>
                <Button onClick={handleClick} id={`${baseId}-button-close`}>
                    Close
                </Button>
            </div>
        </ModalComponent>
    );
}
