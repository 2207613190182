export interface IUser {
  isLoggedIn: boolean;
  mobileOrEmail: EUserLoginOption;
  loginUrl: string;
}

export enum EUserLoginOption {
  MOBILE = 'MOBILE',
  EMAIL = 'EMAIL',
}
