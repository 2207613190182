import { useEffect, useState } from 'react';

import {
    EPreApprovalVerdicts,
    fetchPreapprovalVerdict,
} from '@/apiCalls/fetchPreapprovalVerdict';
import { AnimationHelper } from '@/components/AnimationHelper';
import { DisplayComponent } from '@/components/DisplayComponent';
import { Header } from '@/components/Header';
import {
    ELoanUsage,
    EUserFlow,
    selectLoanUsage,
    setIsPreapproved,
} from '@/context/borrower';
import {
    EDisplays,
    EModals,
    selectPartner,
    selectPreviousDisplay,
    selectUserFlow,
    setDisplay,
    setModal,
    setPreviousDisplay,
    setShowOcrDisplay,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export const baseId = 'screen-preApprovalAnimation';
export const testId = 'PreApprovalAnimation';

const delayExecution = (mls: number): Promise<unknown> => {
    return new Promise((resolve) => {
        setTimeout(() => resolve('ok'), mls);
    });
};

export function PreApprovalAnimation(): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const previousDisplay = useAppSelector(selectPreviousDisplay);
    const wasPreviousDisplayIndicativelyApproved =
        previousDisplay === EDisplays.INDICATIVELY_APPROVED;
    const partner = useAppSelector(selectPartner);
    const loanUsage = useAppSelector(selectLoanUsage);
    const { earlyReferredScreen, showQuickQuote } = partner;
    const userFlow = useAppSelector(selectUserFlow);

    // ***** Local State *****
    const placeholderText = [
        'CHECKING VEHICLE DETAILS',
        'CHECKING LOAN STRUCTURE',
        'CHECKING INCOME AND EXPENSES',
        'CHECKING IDENTIFICATION',
        'GRABBING CREDIT FILE INFO',
        'CONTACTING LENDER FOR DECISION',
    ];
    const [state, setState] = useState('');

    const changePlaceholder = async (): Promise<void> => {
        for (let i = 0; i < placeholderText?.length; i++) {
            await delayExecution(2000);
            setState(placeholderText[i]);
        }
    };

    useEffect(() => {
        if (userFlow === EUserFlow.CAMPAIGN) {
            fetchPreapprovalVerdict().catch(() => {
                // Error handling if needed
            });
            skipVerdict();
        } else {
            loadVerdict();
            changePlaceholder();
        }
    }, []);

    function loadVerdict(): void {
        fetchPreapprovalVerdict()
            .then((response) => {
                if (response.approvedLenders?.length) {
                    dispatch(setIsPreapproved(true));
                    proceedToNextScreen(response.verdict);
                } else {
                    dispatch(setIsPreapproved(false));
                    proceedToNextScreen(EPreApprovalVerdicts.REFERRED);
                }
            })
            .catch(() => {
                proceedToNextScreen(EPreApprovalVerdicts.REFERRED);
            });
    }

    async function proceedToNextScreen(
        verdict: EPreApprovalVerdicts
    ): Promise<void> {
        // Proceed to next screen
        const nextScreen = getNextScreen(verdict);
        dispatch(setDisplay(nextScreen));
        if (nextScreen === EDisplays.EMPLOYMENT) {
            dispatch(setModal(EModals.WORK_HISTORY));
        }

        dispatch(setShowOcrDisplay(false));
    }

    function skipVerdict(): void {
        const nextScreen = getNextScreenBasedOnLoanUsage();
        dispatch(setDisplay(nextScreen));
        if (nextScreen === EDisplays.EMPLOYMENT) {
            dispatch(setModal(EModals.WORK_HISTORY));
        }

        dispatch(setShowOcrDisplay(false));
    }

    function getNextScreenBasedOnLoanUsage(): EDisplays {
        if (loanUsage === ELoanUsage.BUSINESS) {
            return EDisplays.ASSETS_AND_LIABILITIES;
        } else {
            return EDisplays.EMPLOYMENT;
        }
    }

    function getNextScreen(
        verdict: EPreApprovalVerdicts | undefined
    ): EDisplays {
        switch (verdict) {
            case EPreApprovalVerdicts.APPROVED:
                if (wasPreviousDisplayIndicativelyApproved) {
                    dispatch(setPreviousDisplay(undefined));
                    return EDisplays.INDICATIVELY_APPROVED;
                } else {
                    if (showQuickQuote) return EDisplays.YOUR_SCORES;

                    if (loanUsage === ELoanUsage.BUSINESS) {
                        return EDisplays.ASSETS_AND_LIABILITIES;
                    }

                    return EDisplays.EMPLOYMENT;
                }
            case EPreApprovalVerdicts.DECLINED:
            case EPreApprovalVerdicts.REFERRED:
            case undefined:
            default:
                if (earlyReferredScreen) {
                    return EDisplays.INDICATIVELY_REFERRED;
                }
                if (loanUsage === ELoanUsage.BUSINESS) {
                    return EDisplays.ASSETS_AND_LIABILITIES;
                }

                return EDisplays.EMPLOYMENT;
        }
    }

    return (
        <DisplayComponent baseId={baseId} data-testid={testId}>
            <Header />
            <AnimationHelper array={placeholderText} />
        </DisplayComponent>
    );
}
