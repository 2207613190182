import { Header } from '@/components/Header';

export const baseId = 'screen-expense';

export function None(): JSX.Element {
  return (
    <div data-testid='None'>
      <Header />
    </div>
  );
}
