import { CalendarIcon } from '@heroicons/react/20/solid';
import { MapPinIcon } from '@heroicons/react/24/outline';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button, EButtonType } from '@/components/Button';
import { PartnerLogo } from '@/components/PartnerLogo';
import {
    selectVehicleBuildYear,
    selectVehicleMake,
    selectVehicleModel,
    selectVehicleVariant,
} from '@/context/borrower';
import {
    EDisplays,
    selectAgreedToTermsAndConditions,
    selectPartner,
    setDisplay,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { TermsAndConditions } from '@/UserInput/TermsAndConditions';
import { TestDriveDate } from '@/UserInput/TestDriveDate';
import { TestDriveTime } from '@/UserInput/TestDriveTime';

export const testId = 'QuickCalculator';

export function TestDriveLandingPage(): JSX.Element {
    // ***** Redux *****
    const year = useAppSelector(selectVehicleBuildYear);
    const make = useAppSelector(selectVehicleMake);
    const model = useAppSelector(selectVehicleModel);
    const variant = useAppSelector(selectVehicleVariant);
    const partner = useAppSelector(selectPartner);
    const { locationName, locationAddress, locationAddress2 } = partner;
    const agreedTerms = useAppSelector(selectAgreedToTermsAndConditions);
    const { isDefaultPrivacyAgreed } = agreedTerms;
    const hasAgreed = isDefaultPrivacyAgreed?.agreed;

    const dispatch = useAppDispatch();

    // ***** CONSTANTS *****
    const baseId = 'screen-TestDriveLandingPage';
    const vehicleName = `${year} ${make} ${model} ${variant}`;

    function handleClick(): void {
        if (!hasAgreed) return;
        createOrUpdateBorrower();
        dispatch(setDisplay(EDisplays.IDENTIFICATION));

        return;
    }

    // ***** Render *****
    return (
        <div className='relative w-full h-full'>
            <img src='/testdrive.png' className='filter brightness-50 w-full' />
            <div className='absolute left-1/2 top-[14%] transform -translate-x-1/2 w-[90%] rounded text-white text-center'>
                <h1 className='text-3xl font-bold'>Let&apos;s Go Driving!</h1>
                <p className='text-sm'>
                    To continue, confirm the details below
                    <br />
                    and agree to the terms & conditions.
                </p>
            </div>
            <div className='absolute left-1/2 top-[30%] transform -translate-x-1/2 w-[90%] rounded'>
                <div className='bg-white w-full rounded-lg p-4'>
                    {/* Logo */}
                    <div className='w-full flex-center pb-4'>
                        <PartnerLogo />
                    </div>
                    {/* Car Details */}
                    <div className='flex flex-col space-y-4'>
                        <p className='font-semibold '>Test Drive</p>
                        <div className='flex space-x-4'>
                            <img src='/car.svg' className='bg-white h-6 w-6' />
                            <p>{vehicleName}</p>
                        </div>
                        <div className='flex flex-col'>
                            <div className='flex space-x-4'>
                                <MapPinIcon className='w-6 h-6' />
                                <p>{locationName}</p>
                            </div>
                            <div className='ml-10 italic'>
                                <p>{locationAddress},</p>
                                <p>{locationAddress2}</p>
                            </div>
                        </div>
                    </div>
                    {/* Time Details */}
                    <div className='flex items-center'>
                        <div className='w-full'>
                            <CalendarIcon className='w-4 h-4 z-10' />
                        </div>
                        <div className='w-full mx-2'>
                            <TestDriveDate />
                        </div>
                        <div className='w-full'>
                            <TestDriveTime />
                        </div>
                    </div>
                    <div className='w-full border h-[1px] my-4' />

                    {/* T&C */}
                    <TermsAndConditions baseId={baseId} />
                    {/* Button */}
                    <div className='py-2' />
                    <Button
                        type={
                            hasAgreed
                                ? EButtonType.PRIMARY
                                : EButtonType.FULL_ROUNDED_DISABLED
                        }
                        onClick={handleClick}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
}
