import { useState } from 'react';
import { useSelector } from 'react-redux';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { solarPreapprovalVerdict } from '@/apiCalls/solarPreapprovalVerdict';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { Loading } from '@/components/Loading';
import { SolarNavigationButtons } from '@/components/SolarNavigationButtons';
import { SolarProgressBar } from '@/components/SolarProgressBar';
import {
  selectAddressByIndex,
  selectBirthdate,
  selectEmail,
  selectFirstName,
  selectGender,
  selectLicenseExpiry,
  selectLicenseNumber,
  selectLicenseState,
  selectMobile,
  selectResidencyStatus,
} from '@/context/borrower';
import { EDisplays, setDisplay } from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { isEmail } from '@/utils/isEmail';
import { isMobile } from '@/utils/isMobile';

import { AddressInformation } from './AddressInformation';
import { BusinessInformationSolar } from './BusinessInformationSolar';
import { ContactDetails } from './ContactDetails';
import { PersonalInformation } from './PersonalInformation';

export const testId = 'SolarOcrSummary';

export function SolarOcrSummary(): JSX.Element {
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  // ***** CONSTANTS *****
  const baseId = 'screen-SolarOcrSummary';

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  // Contact Details
  const firstName = useAppSelector(selectFirstName);
  const email = useAppSelector(selectEmail);
  const phone = useAppSelector(selectMobile);
  // Personal Information
  const gender = useAppSelector(selectGender);
  const birthdate = useAppSelector(selectBirthdate);
  const licenseNumber = useAppSelector(selectLicenseNumber);
  const licenseState = useAppSelector(selectLicenseState);
  const licenseExpiry = useAppSelector(selectLicenseExpiry);
  const residencyStatus = useAppSelector(selectResidencyStatus);
  // Address Information
  const address = useSelector((state: RootState) =>
    selectAddressByIndex(state, 0)
  );
  const { ownershipType, streetNumber, streetName, suburb, state, postcode } =
    address;

  // ***** Helpers *****
  function isComplete(): boolean {
    // Contact Details
    if (firstName === null || firstName === undefined) return false;
    if (!isEmail(email)) return false;
    if (!isMobile(phone)) return false;
    // Personal Information
    if (gender === null || gender === undefined) return false;
    if (birthdate === null || birthdate === undefined) return false;
    if (licenseNumber === null || licenseNumber === undefined) return false;
    if (licenseState === null || licenseState === undefined) return false;
    if (licenseExpiry === null || licenseExpiry === undefined) return false;
    if (residencyStatus === null || residencyStatus === undefined) return false;
    // Address Information
    if (residencyStatus === null || residencyStatus === undefined) return false;
    if (ownershipType === null || ownershipType === undefined) return false;
    if (streetNumber === null || streetNumber === undefined) return false;
    if (streetName === null || streetName === undefined) return false;
    if (suburb === null || suburb === undefined) return false;
    if (state === null || state === undefined) return false;
    if (postcode === null || postcode === undefined) return false;

    return true;
  }

  async function getNextScreen(): Promise<EDisplays> {
    await solarPreapprovalVerdict();

    return EDisplays.SOLAR_HOLDING_SCREEN;
  }

  // ***** Event Handlers *****
  function handleBackClick(): void {
    dispatch(setDisplay(EDisplays.SOLAR_IDENTIFICATOIN));
  }

  async function handleNextClick(): Promise<void> {
    setShowErrors(true);
    if (!isComplete()) return;
    setLoading(true);
    createOrUpdateBorrower();
    const nextScreen = await getNextScreen();
    setLoading(false);
    createOrUpdateBorrower();
    dispatch(setDisplay(nextScreen));
  }

  // ***** Render *****
  if (loading) return <Loading />;

  return (
    <>
      <div data-testid={testId} className='px-4 '>
        <Header />
        <SolarProgressBar />

        <div className='px-4'>
          <DisplayHeading title='Check Your Details Below' infoText='Basics' />
          <BusinessInformationSolar baseId={baseId} showErrors={showErrors} />
          <ContactDetails baseId={baseId} showErrors={showErrors} />
          <PersonalInformation baseId={baseId} showErrors={showErrors} />
          <AddressInformation baseId={baseId} showErrors={showErrors} />
        </div>
      </div>

      <SolarNavigationButtons
        handleBackClick={handleBackClick}
        handleNextClick={handleNextClick}
      />
    </>
  );
}
