import { ECampaign, EUserFlow } from '@/context/borrower';
import { ITermsAndConditions } from '@/context/display';
import { RootState } from '@/context/store';
import { EMatchingEngineLenders } from '@/partners/matchingEngineEnums';
import { IPartnerParams, ISignupOptions } from '@/partners/partnerTypes';
import { IVehicleAssetTypeButton } from '@/partners/partnerTypes';

export const selectShowWelcomeScreen = (state: RootState): boolean =>
    state.displayReducer.showWelcomeScreen;
export const selectIsWhiteLabel = (state: RootState): boolean =>
    state.displayReducer.isWhiteLabel;
export const selectLogo = (state: RootState): string =>
    state.displayReducer.logo;
export const selectFullLogoUrl = (state: RootState): boolean =>
    state.displayReducer.fullLogoUrl;
export const selectInitialLoad = (state: RootState): boolean =>
    state.displayReducer.initialLoad;
export const selectShowOcrDisplay = (state: RootState): boolean =>
    state.displayReducer.showOcrDisplay;
export const selectIsLoading = (state: RootState): boolean =>
    state.displayReducer.isLoading;
export const selectAgreedToTermsAndConditions = (
    state: RootState
): ITermsAndConditions => state.displayReducer.agreedToTermsAndConditions;
export const selectTermsAndConditionsLink = (state: RootState): string =>
    state.displayReducer.termsAndConditionsLink;
export const selectHideSellerScreen = (state: RootState): boolean =>
    state.displayReducer.hideSellerScreen;
export const selectHideVehicleScreen = (state: RootState): boolean =>
    state.displayReducer.hideVehicleScreen;
export const selectShowTotalAmountPayable = (state: RootState): boolean =>
    state.displayReducer.showTotalAmountPayable;
export const selectPartnerName = (state: RootState): string | null =>
    state.displayReducer.partnerName;
export const selectPartnerEmail = (state: RootState): string | null =>
    state.displayReducer.partnerEmail;
export const selectPartnerNumber = (state: RootState): string | null =>
    state.displayReducer.partnerNumber;
export const selectSignupOptions = (
    state: RootState
): ISignupOptions | undefined => state.displayReducer.partner.signupOptions;
export const selectSkipExpenseScreen = (
    state: RootState
): boolean | undefined => state.displayReducer.partner.skipExpensesScreen;
export const selectWaterfallLenders = (
    state: RootState
): {
    lenders: EMatchingEngineLenders[];
    showLenderDetails: boolean;
}[] => state.displayReducer.waterfallLenders;
export const selectIsAssetKnownViaParams = (state: RootState): boolean =>
    state.displayReducer.isAssetKnownViaParams;
export const selectAskTermsAndConditions = (state: RootState): boolean | null =>
    state.displayReducer.askTermsAndConditions;
export const selectAvailableVehicleAssetTypes = (
    state: RootState
): IVehicleAssetTypeButton[] => state.displayReducer.availableVehicleAssetTypes;
export const selectUserFlow = (state: RootState): EUserFlow =>
    state.displayReducer.partner.userFlow;
export const selectPartner = (state: RootState): IPartnerParams =>
    state.displayReducer.partner;
export const selectIsDemoMode = (state: RootState): boolean =>
    state.displayReducer.isDemoMode;
export const selectCampaign = (state: RootState): ECampaign | undefined =>
    state.displayReducer.partner.campaign;
