import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getBorrower } from '@/apiCalls/borrower';
import { loginViaCode } from '@/apiCalls/loginViaCode';
import { Loading } from '@/components/Loading';
import { EModals, setDisplay, setModal } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';
import { Vroom } from '@/pages/Vroom';
import { ELocalStorageVariables, setLocalStorageItem } from '@/utils/localStorage';

export function LoginViaCode(): JSX.Element {
    const codeParam: string | undefined = useParams<{ code: string }>().code;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [failed, setFailed] = useState(false);

    useEffect(() => {
        // A fail safe in case the loginViaCode api call fails
        const timeout = setTimeout(() => {
            setFailed(true);
            dispatch(setModal(EModals.LOGIN));
        }, 3000);

        const [code, display] = codeParam?.split('--') || [];
        loginViaCode(code).then((data) => {
            clearTimeout(timeout);

            // Set URL path and query params
            navigate(data?.location || '/');

            setLocalStorageItem(ELocalStorageVariables.FIRST_NAME, data?.name || '');
            setLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN, data?.token || '');

            // Set display
            if (display) {
                getBorrower().then(() => {
                    dispatch(setDisplay(display));
                    dispatch(setModal(null));
                });
            }

            // Ask to login if no token or name
            if (!data?.token || !data.name) {
                setFailed(true);
                dispatch(setModal(EModals.LOGIN));
            }
        });
    }, []);

    return failed ? <Vroom /> : <Loading />;
}
