import { ITermsAndConditions } from '@/context/display';

import { EGender } from './borrowerSliceTypes';
import { EState } from './commonTypes';

export interface IPersonalDetails {
    mobilePhone?: string | undefined;
    email?: string | undefined;
    gender?: EGender | undefined;
    title?: string | undefined;
    firstName?: string | undefined;
    middleName?: string | undefined;
    lastName?: string | undefined;
    birthdate?: Date | undefined;
    licenseNumber?: string | undefined;
    licenseState?: EState | undefined;
    licenseExpiry?: Date | undefined;
    licenseType?: EBorrowerLicenseType | undefined;
    maritalStatus?: EBorrowerMaritalStatus | undefined;
    citizenshipStatus?: EBorrowerCitizenshipStatus | undefined;
    visaNumber?: number | undefined;
    visaExpiryDate?: Date | undefined;
    hasChildren?: boolean | undefined;
    numberOfChildren?: number | undefined;
    termsAndConditions?: ITermsAndConditions | undefined;
}

export enum EBorrowerLicenseType {
    LEARNER = 'LEARNER',
    PROVISIONAL = 'PROVISIONAL',
    FULL = 'FULL',
    HEAVY_VEHICLE = 'HEAVY_VEHICLE',
    NONE = 'NONE',
}

export enum EBorrowerCitizenshipStatus {
    AUSTRALIAN_CITIZEN = 'AUSTRALIAN_CITIZEN',
    PERMANENT_RESIDENT = 'PERMANENT_RESIDENT',
    VISA_HOLDER = 'VISA_HOLDER',
}

export const initialPersonalDetails: IPersonalDetails = {};

export enum EBorrowerMaritalStatus {
    MARRIED = 'MARRIED',
    SINGLE = 'SINGLE',
    DIVORCED = 'DIVORCED',
    SEPARATED = 'SEPARATED',
    WIDOWED = 'WIDOWED',
    DE_FACTO = 'DE_FACTO',
}
