import { PayloadAction } from '@reduxjs/toolkit';

import { ILender } from '@/context/lender';

import { IAgreements, IBorrowerSlice, IShownLenders } from '../types';

import { businessReducers } from './businessReducers';
import { creditCardReducers } from './creditCardReducers';
import { employmentReducers } from './employmentReducers';
import { expensesReducers } from './expensesReducers';
import { incomeReducers } from './incomeReducers';
import { loanReducers } from './loanReducers';
import { motorVehicleReducers } from './motorVehicleReducers';
import { otherAssetsReducers } from './otherAssetsReducers';
import { personalDetailsReducers } from './personalDetailsReducers';
import { personalLoansReducers } from './personalLoansReducers';
import { realEstateReducers } from './realEstateReducers';
import { scoresReducers } from './scoresReducers';
import { sellerReducers } from './sellerReducers';
import { solarReducers } from './solarReducers';
import { supportingDocumentsReducers } from './supportingDocumentsReducers';
import { testDriveReducers } from './testDriveReducers';
import { vehicleReducers } from './vehicleReducers';

export const borrowerReducers = {
    ...personalDetailsReducers,
    ...incomeReducers,
    ...employmentReducers,
    ...expensesReducers,
    ...loanReducers,
    ...sellerReducers,
    ...vehicleReducers,
    ...supportingDocumentsReducers,
    ...otherAssetsReducers,
    ...creditCardReducers,
    ...personalLoansReducers,
    ...motorVehicleReducers,
    ...businessReducers,
    ...realEstateReducers,
    ...solarReducers,
    ...scoresReducers,
    ...testDriveReducers,
    setBorrower: (
        state,
        action: PayloadAction<IBorrowerSlice>
    ): IBorrowerSlice => {
        return action.payload;
    },
    setApplicationId: (state, action: PayloadAction<string>): void => {
        state.application.applicationId = action.payload;
    },
    setIsPreapproved: (
        state,
        action: PayloadAction<boolean | undefined>
    ): void => {
        state.application.isPreApproved = action.payload;
    },
    setProgressPercent: (
        state,
        action: PayloadAction<number | undefined>
    ): void => {
        state.application.progressPercent = action.payload;
    },
    setPartnerOverwriteId: (state, action: PayloadAction<string>): void => {
        state.application.partnerOverwriteId = action.payload;
    },
    setSelectedLender: (
        state,
        action: PayloadAction<ILender | undefined>
    ): void => {
        state.selectedLender = action.payload;
        if (action.payload?.loanTerm) {
            state.loan.loanTerm = action.payload?.loanTerm;
        }
    },
    setAgreements: (state, action: PayloadAction<IAgreements[]>): void => {
        state.agreements = action.payload;
    },
    addShownLenders: (state, action: PayloadAction<IShownLenders[]>): void => {
        const newLenders = action.payload;
        // If there are no old lenders, set the new lenders
        if (!state.shownLenders) {
            state.shownLenders = newLenders;
            return;
        }

        const oldLenders: IShownLenders[] = state.shownLenders || [];

        // Filter out new lenders that are direct matches with old lenders
        // Filter out new lenders that are not unique
        const filteredNewLenders = newLenders.filter(
            (newLender) =>
                !oldLenders.some(
                    (oldLender) =>
                        oldLender.vehicleUrl === newLender.vehicleUrl &&
                        oldLender.screenShown === newLender.screenShown &&
                        newLender.shownLenders.every((newShownLender) =>
                            oldLender.shownLenders.some(
                                (oldShownLender) =>
                                    oldShownLender.lenderName ===
                                        newShownLender.lenderName &&
                                    oldShownLender.monthlyRepaymentAmount ===
                                        newShownLender.monthlyRepaymentAmount
                            )
                        )
                )
        );

        // Combine old lenders with the filtered new lenders
        const updatedLenders = [...oldLenders, ...filteredNewLenders];

        state.shownLenders = updatedLenders;
    },
};
