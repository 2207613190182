import { getUrl } from '@/apiCalls/fetch';
import { ITermsAndConditions } from '@/context/display';
import {
    ELocalStorageVariables,
    getLocalStorageItem,
} from '@/utils/localStorage';

export async function getTermsAndConditionsAgreement(): Promise<ITermsAndConditions | null> {
    const token = getLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);

    if (!token) {
        console.log('No token found in local storage');
        return null;
    }

    const { data } = await getUrl<{ data: ITermsAndConditions | null }>(
        '/api/borrower/terms-and-conditions-agreement'
    );

    return data;
}
