import { PayloadAction } from '@reduxjs/toolkit';

import { ETrusteeType, IBusiness } from '../types';

export const businessReducers = {
    setManualEntry: (
        state,
        action: PayloadAction<boolean | undefined>
    ): void => {
        state.business.manualEntry = action.payload;
    },
    setBusiness: (state, action: PayloadAction<IBusiness>): void => {
        state.business = action.payload;
    },
    setIndustryType: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.business.industry = action.payload;
    },
    setBusinessName: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.business.name = action.payload;
    },
    setAbn: (state, action: PayloadAction<string | undefined>): void => {
        state.business.abn = action.payload;
    },
    setAcn: (state, action: PayloadAction<string | undefined>): void => {
        state.business.acn = action.payload;
    },
    setAbnActiveFrom: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.business.abnStatusEffectiveFrom = action.payload;
    },
    setGstFrom: (state, action: PayloadAction<string | undefined>): void => {
        state.business.gst = action.payload;
    },
    setEntityType: (state, action: PayloadAction<string | undefined>): void => {
        state.business.entityTypeName = action.payload;
    },
    setNumberOfDirectors: (
        state,
        action: PayloadAction<number | undefined>
    ): void => {
        state.business.numberOfDirectors = action.payload;
    },
    setTrusteeName: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.business.trusteeName = action.payload;
    },
    setTrusteeType: (
        state,
        action: PayloadAction<ETrusteeType | undefined>
    ): void => {
        state.business.trusteeType = action.payload;
    },

    //  Previous Business
    setPreviousBusiness: (state, action: PayloadAction<IBusiness>): void => {
        state.business.previousBusiness = action.payload;
    },
    setPreviousIndustryType: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.business.previousBusiness.industry = action.payload;
    },
    setPreviousBusinessName: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.business.previousBusiness.name = action.payload;
    },
    setPreviousAbn: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.business.previousBusiness.abn = action.payload;
    },
    setPreviousAcn: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.business.previousBusiness.acn = action.payload;
    },
    setPreviousAbnActiveFrom: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.business.previousBusiness.abnStatusEffectiveFrom = action.payload;
    },
    setPreviousGstFrom: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.business.previousBusiness.gst = action.payload;
    },
    setPreviousNumberOfDirectors: (
        state,
        action: PayloadAction<number | undefined>
    ): void => {
        state.business.previousBusiness.numberOfDirectors = action.payload;
    },
    setPreviousEntityType: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.business.previousBusiness.entityTypeName = action.payload;
    },
    setPreviousTrusteeName: (
        state,
        action: PayloadAction<string | undefined>
    ): void => {
        state.business.previousBusiness.trusteeName = action.payload;
    },
    setPreviousTrusteeType: (
        state,
        action: PayloadAction<ETrusteeType | undefined>
    ): void => {
        state.business.previousBusiness.trusteeType = action.payload;
    },
};
