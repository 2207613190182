interface IYourScoresInfo {
    color: string;
    title: string;
    description: string;
}
export function YourScoresInfo({
    color,
    title,
    description,
}: IYourScoresInfo): JSX.Element {
  return (
    <div className='_flex items-center'>
      <svg className='h-10 w-10 pt-1'>
        <circle cx='8' cy='8' r='8' fill={color} />
      </svg>
      <div className='_flex flex-col ml-4'>
        <p className='font-bold text-sm'>{title}</p>
        <p className='text-xs'>{description}</p>
      </div>
    </div>
  );
}

