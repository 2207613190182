import { PayloadAction } from '@reduxjs/toolkit';

import { EDisplays, EJourneys, EModals, IDisplaySlice } from '../types';

import { glassGuideReducers } from './glassGuideReducers';
import { partnerReducers } from './partnerReducers';

export interface DisplayReducers {
    [key: string]: (state: IDisplaySlice, action: PayloadAction<any>) => void;
}

export const displayReducers: DisplayReducers = {
    setShowScanwarePopup: (state, action: PayloadAction<boolean>) => {
        state.showScanwarePopup = action.payload;
    },
    setScanwareQrId: (state, action: PayloadAction<string>) => {
        state.scanwareQrId = action.payload;
    },
    setDisplay: (state, action: PayloadAction<EDisplays>) => {
        state.display = action.payload;
    },
    setIsAssetAndLiabilitiesSubmitSkipped: (
        state,
        action: PayloadAction<boolean>
    ) => {
        state.isAssetAndLiabilitiesSubmitSkipped = action.payload;
    },
    setPreviousDisplay: (
        state,
        action: PayloadAction<EDisplays | undefined>
    ) => {
        state.previousDisplay = action.payload;
    },
    setModal: (state, action: PayloadAction<EModals | null>) => {
        state.modal = action.payload;
    },
    setIsLoaded: (state, action: PayloadAction<boolean>) => {
        state.isLoaded = action.payload;
    },
    setSendLeadEmail: (state, action: PayloadAction<boolean>) => {
        state.sendLeadEmail = action.payload;
    },
    setIllionCodes: (
        state,
        action: PayloadAction<{
            threeMonths: string;
            sixMonths: string;
            twelveMonths: string;
        }>
    ) => {
        state.illionCodes = action.payload;
    },
    setInitialJourney: (state, action: PayloadAction<string>) => {
        state.initialJourney = action.payload as EJourneys;
    },
    setVehicleManualEntry: (state, action: PayloadAction<boolean>) => {
        state.vehicleManualEntry = action.payload;
    },
    setIsPriceKnownViaExternal: (state, action: PayloadAction<boolean>) => {
        state.isPriceKnownViaExternal = action.payload;
    },
    ...partnerReducers,
    ...glassGuideReducers,
};
