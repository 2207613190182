import { useState } from 'react';

import { updateScanware } from '@/actions/updateScanware';
import { Input } from '@/components/Input';
import {
    selectVehicleStockNumber,
    setVehicleStockNumber,
} from '@/context/borrower';
import { EModals, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'StockNumber';

export function StockNumber({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectVehicleStockNumber);
    const dispatch = useAppDispatch();
    const [error, setError] = useState(false);

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        if (typeof value === 'boolean') return;
        if (value === currentState) return;
        if (value?.length > 10) return;
        dispatch(setVehicleStockNumber(value.toLocaleUpperCase()));
    }

    async function handleEnter(): Promise<void> {
        const valid = await updateScanware(true);
        if (!valid) {
            setError(true);
            dispatch(setVehicleStockNumber(''));
        } else {
            dispatch(setModal(EModals.SCANWARE_CHECK_ASSET));
        }
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                placeholder={
                    !error ? `Enter the stock number` : "Oops, that didn't work"
                }
                showErrors={error}
                onChange={handleChange}
                id={`${baseId}-input-stockNumber`}
                value={currentState?.toLocaleString() ?? ''}
                onArrow={handleEnter}
                height='large'
            />
        </div>
    );
}
