import styles from './Ring.module.css';

const MAX_PROGRESS = 100;
const RADIUS = 130; // the radius of the chart in pixels
const CIRCUMFERENCE = 2.5 * Math.PI * RADIUS; // the circumference of the chart in pixels
interface Props {
  width: string;
  height: string;
  path: string;
  progress?: number;
  backgroundColor?: string;
  progressColor?: string;
  strokeWidth: number;
  viewBox?: string;
}

function Ring({
  width,
  height,
  path,
  progress = 0,
  backgroundColor = '#C4C4C4',
  progressColor = '#000000',
  strokeWidth,
  viewBox = '0 0 100 100',
}: Props): JSX.Element {
  // check that the progress is not greater than the maxProgress, set to maxProgress if it is
  const progressToRender = progress > MAX_PROGRESS ? MAX_PROGRESS : progress;

  return (
    <div className='absolute'>
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d={path}
          fill='none'
          stroke={backgroundColor}
          strokeWidth={strokeWidth}
          strokeLinecap='round'
        />
        {progressToRender && (
          <path
            className={styles.progressRing}
            d={path}
            fill='none'
            stroke={progressColor}
            strokeLinecap='round'
            strokeWidth={strokeWidth}
            strokeDasharray={`${progressToRender} ${
              MAX_PROGRESS - progressToRender + MAX_PROGRESS
            } `}
            pathLength={MAX_PROGRESS}
          />
        )}
      </svg>
    </div>
  );
}

export default Ring;
