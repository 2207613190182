import { Button } from '@/components/Button';
import {
    ELoanUsage,
    selectLoanUsage,
    selectPurchasePrice,
} from '@/context/borrower';
import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { selectIsLoggedIn } from '@/context/user';

export function CampaignLandingButton(): JSX.Element {
    const purchasePrice = useAppSelector(selectPurchasePrice);
    const dispatch = useAppDispatch();
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const loanUsage = useAppSelector(selectLoanUsage);

    async function handleClick(): Promise<void> {
        if (!purchasePrice) {
            return;
        }

        if (!isLoggedIn) {
            dispatch(setModal(EModals.REGISTER));
            return;
        }

        if (loanUsage === ELoanUsage.BUSINESS) {
            dispatch(setDisplay(EDisplays.BUSINESS));
            return;
        }
        dispatch(setDisplay(EDisplays.BASIC));
    }

    return (
        <Button
            onClick={handleClick}
            title='Apply Now'
            id='screen-CampaignLanding-button-applyNow'
        />
    );
}
