import { setIsDemoMode } from '@/context/display';
import { store } from '@/context/store';

import { DEMO_NAME_TRIGGER } from '../constants';

export function setDemoMode(firstName: string): void {
    const state = store.getState();
    const isDemoMode = state.displayReducer.partner.isDemoMode;

    const isNameMatch =
        firstName.toLocaleLowerCase() === DEMO_NAME_TRIGGER.toLocaleLowerCase();

    if (isNameMatch && !isDemoMode) {
        store.dispatch(setIsDemoMode(true));
    }

    if (!isNameMatch && isDemoMode) {
        store.dispatch(setIsDemoMode(false));
    }
}
