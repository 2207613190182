import { setSellerLeadEmail } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialSellerLeadEmail(
  parameterLeadEmail: IParameter,
  partnerLeadEmail: IParameter
): void {
    const sellerLeadEmail = store.getState().borrowerReducer.seller.leadEmail;

    const leadEmailToSave = parameterLeadEmail ?? partnerLeadEmail;

  if (leadEmailToSave && sellerLeadEmail !== leadEmailToSave) {
    store.dispatch(setSellerLeadEmail(leadEmailToSave));
  }
}
