import { useSelector } from 'react-redux';

import { Input } from '@/components/Input';
import {
    selectRealEstateActiveIndex,
    selectRealEstatebyIndex,
    setRealEstateByIndex,
} from '@/context/borrower';
// eslint-disable-next-line no-restricted-imports
import { IRealEstate } from '@/context/borrower/types/realEstateAssetTypes';
// eslint-disable-next-line no-restricted-imports
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
    setShowErrors: (value: boolean) => void;
}

export const testId = 'RealEstateLoanLenderName';

export function RealEstateLoanLenderName({
    baseId,
    showErrors,
    setShowErrors,
}: IProps): JSX.Element {
    // ***** Redux *****
    const activeRealEstateIndex = useAppSelector(selectRealEstateActiveIndex);

    const realEstate = useSelector((state: RootState) =>
        selectRealEstatebyIndex(state, activeRealEstateIndex)
    );
    const lendername = realEstate?.lenderName;
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        setShowErrors(false);

        if (typeof value !== 'string') return;
        const newRealEstate: IRealEstate = {
            ...realEstate,
            lenderName: value,
        };
        dispatch(
            setRealEstateByIndex({
                index: activeRealEstateIndex,
                realEstate: newRealEstate,
            })
        );
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Lender name'
                placeholder='What lender is your mortgage with?'
                showErrors={showErrors && !lendername}
                onChange={handleChange}
                id={`${baseId}-input-lenderName`}
                value={lendername?.toLocaleString() ?? ''}
            />
        </div>
    );
}
