import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Combobox, IComboboxOption } from '@/components/Combobox';
import {
    EState,
    IAddress,
    selectAddressByIndex,
    selectLicenseState,
    setAddressByIndex,
    setLicenseState,
} from '@/context/borrower';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'AddressState';

export function AddressState({ showErrors, baseId }: IProps): JSX.Element {
    // Initial options
    const options: IComboboxOption[] = [
        { id: 1, value: EState.NSW },
        { id: 2, value: EState.VIC },
        {
            id: 3,
            value: EState.QLD,
        },
        { id: 4, value: EState.ACT },
        { id: 5, value: EState.WA },
        { id: 6, value: EState.NT },
        { id: 7, value: EState.SA },
        { id: 8, value: EState.TAS },
    ];

    const address = useSelector((state: RootState) =>
        selectAddressByIndex(state, 0)
    );
    const dispatch = useAppDispatch();
    const licenseState = useAppSelector(selectLicenseState);

    // *****State*****
    const [query, setQuery] = useState<undefined | string>(address.state ?? ''); // Keep track of search -> Initially set to currentState

    // *****Functions*****
    // Handle the onChange event for the input.
    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        setQuery(value);
        if (typeof value === 'boolean') return;
        if (value?.length > 3) return;
    }

    // When the user selects an option
    function handleSelect(option: IComboboxOption): void {
        const newAddress: IAddress = {
            ...address,
            state: option.value as EState,
        };
        dispatch(setAddressByIndex({ index: 0, address: newAddress }));
    }

    // dispatch same state for license as well if license state not yet chosen
    if (!licenseState || licenseState === undefined) {
        dispatch(setLicenseState(address.state));
    }
    // *****Render*****
    return (
        <div data-testid={testId}>
            <Combobox
                options={options}
                placeholder='State'
                heading='State Name'
                onChange={handleChange}
                query={query ?? ''}
                onSelect={handleSelect}
                showErrors={showErrors && !address.state}
                baseId={baseId}
            />
        </div>
    );
}
