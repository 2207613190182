export enum EDisplays {
    ASSETS_AND_LIABILITIES = 'ASSETS_AND_LIABILITIES',
    BASIC = 'BASIC',
    INCOME = 'INCOME',
    EXPENSE = 'EXPENSE',
    LOAN = 'LOAN',
    VEHICLE = 'VEHICLE',
    SELLER = 'SELLER',
    SERVICING_SUCCESS = 'SERVICING_SUCCESS',
    SERVICING_FAILURE = 'SERVICING_FAILURE',
    IDENTIFICATION = 'IDENTIFICATION',
    OCR_SUMMARY = 'OCR_SUMMARY',
    INDICATIVELY_APPROVED = 'APPROVED',
    INDICATIVELY_REFERRED = 'REFERRED',
    INDICATIVELY_DECLINED = 'DECLINED',
    SUPPORTING_DOCS = 'SUPPORTING_DOCS',
    EMPLOYMENT = 'EMPLOYMENT',
    NONE = 'NONE',
    MANUAL_VEHICLE = 'MANUAL_VEHICLE',
    BUSINESS = 'BUSINESS',
    SOLAR_YOUR_DETAILS = 'SOLAR_YOUR_DETAILS',
    SOLAR_INDICATIVELY_APPROVED = 'SOLAR_INDICATIVELY_APPROVED',
    OCR_ANIMATION = 'OCR_ANIMATION',
    PREPPROVAL_ANIMATION = 'PREPPROVAL_ANIMATION',
    SOLAR_LOAN_DETAILS = 'SOLAR_LOAN_DETAILS',
    SOLAR_LENDERS_LIST = 'SOLAR_LENDERS_LIST',
    SOLAR_OCR_SUMMARY = 'SOLAR_OCR_SUMMARY',
    SOLAR_HOLDING_SCREEN = 'SOLAR_HOLDING_SCREEN',
    SOLAR_LENDER_SPECIFIC_QUESTIONS = 'SOLAR_LENDER_SPECIFIC_QUESTIONS',
    YOUR_SCORES = 'YOUR_SCORES',
    SOLAR_IDENTIFICATOIN = 'SOLAR_IDENTIFICATOIN',
    SOLAR_ASSETS_AND_LIABILITIES = 'SOLAR_ASSETS_AND_LIABILITIES',
    SOLAR_SUPPORTING_DOCS = 'SOLAR_SUPPORTING_DOCS',
    QUICK_CALCULATOR = 'QUICK_CALCULATOR',
    TEST_DRIVE_LANDING_PAGE = 'TEST_DRIVE_LANDING_PAGE',
    TRUCKSTOP_LANDING_PAGE = 'TRUCKSTOP_LANDING_PAGE',
    NO_LENDER_HOME = 'NO_LENDER_HOME',
    TEST_DRIVE_AGREEMENT = 'TEST_DRIVE_AGREEMENT',
    TEST_DRIVE_OCR_SUMMARY = 'TEST_DRIVE_OCR_SUMMARY',
    TEST_DRIVE_READY = 'TEST_DRIVE_READY',
    CAMPAIGN_LANDING = 'CAMPAIGN_LANDING',
}
