import { setFullLogoUrl, setLogo } from '@/context/display';
import { store } from '@/context/store';
import { REACT_APP_ENV } from '@/utils/constants';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialSellerLogo(
    parameterLogo: IParameter,
    partnerLogo: string | null
): void {
    // If production, do not allow parameter logo to be used at all
    // If not production, allow parameter logo to override partner logo
    const { logo } = store.getState().displayReducer;
    let newLogo = logo;

    if (REACT_APP_ENV !== 'production') {
        console.log('not prod');
        console.log('parameterLogo', parameterLogo);
        if (parameterLogo) {
            console.log('setFullLogoUrl');
            newLogo = parameterLogo;
            store.dispatch(setFullLogoUrl(true));
        } else if (partnerLogo) {
            newLogo = partnerLogo;
        }
    } else {
        if (partnerLogo) {
            newLogo = partnerLogo;
        }
    }

    if (newLogo !== logo) {
        store.dispatch(setLogo(newLogo));
    }
}
