import { useState } from 'react';
import { useSelector } from 'react-redux';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { AssetsNavigationButtons } from '@/components/AssetsNavigationButtons';
import { Button } from '@/components/Button';
import { ModalComponent } from '@/components/ModalComponent';
import { ModalHeader } from '@/components/ModalHeader';
import {
    deleteRealEstate,
    selectRealEstateActiveIndex,
    selectRealEstates,
    setRealEstateActiveIndex,
} from '@/context/borrower';
import { setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { RealEstateAssetValue } from '@/UserInput/RealEstateAssetValue';
import {
    isAllRealEstatesComplete,
    isRealEstateAssetComplete,
} from '@/utils/checkAssetsAndLiabilities';
import { titleHelper } from '@/utils/titleHelper';

export const testId = 'RealEstateAsset';

export const RealEstateAsset = (): JSX.Element => {
    // ***** Constants *****
    const baseId = 'modal-realEstates';
    const modaltitle = 'Real Estate';

    // ***** Redux *****

    const dispatch = useAppDispatch();
    const activeRealEstateIndex = useAppSelector(selectRealEstateActiveIndex);
    const titleNumber = activeRealEstateIndex + 1;
    const realEstates = useSelector(selectRealEstates);
    const hasPreviousRealEstateAsset =
        realEstates?.length > 0 && activeRealEstateIndex !== 0;
    const hasNextRealEstate = realEstates?.length - 1 > activeRealEstateIndex;

    // ***** Local State *****
    const [showErrors, setShowErrors] = useState<boolean>(false);

    // ***** Handlers *****

    function handleSaveClick(): void {
        setShowErrors(true);
        if (!isAllRealEstatesComplete()) return;
        createOrUpdateBorrower();
        dispatch(setModal(null));
    }

    function handleNextClick(): void {
        if (!isRealEstateAssetComplete(realEstates[activeRealEstateIndex]))
            return;
        dispatch(setRealEstateActiveIndex(activeRealEstateIndex + 1));
    }

    function handleDeleteClick(): void {
        // Delete Current Real Estate
        dispatch(deleteRealEstate());
        // Change Active Index
        if (
            realEstates?.length >= 1 &&
            realEstates?.length > activeRealEstateIndex - 1
        ) {
            dispatch(setRealEstateActiveIndex(activeRealEstateIndex - 1));
        }
    }

    function handlePreviousClick(): void {
        if (
            realEstates?.length >= 1 &&
            realEstates?.length > activeRealEstateIndex - 1
        ) {
            dispatch(setRealEstateActiveIndex(activeRealEstateIndex - 1));
        }
    }

    // ****** Render ******
    return (
        <ModalComponent baseId={baseId}>
            <ModalHeader
                title={`${modaltitle} ${titleNumber} ${
                    titleNumber <= realEstates.length
                        ? titleHelper(realEstates)
                        : ''
                }`}
                baseId={baseId}
            />
            <p className='text-xs -mt-2 mb-4'>
                Add all of your real estate assets and liabilities here
            </p>

            <AssetsNavigationButtons
                baseId={baseId}
                handleDeleteClick={handleDeleteClick}
                handlePreviousClick={handlePreviousClick}
                handleNextClick={handleNextClick}
                hasPrevious={hasPreviousRealEstateAsset}
                hasNext={hasNextRealEstate}
            />
            <div className='mt-2'>
                <RealEstateAssetValue
                    baseId={baseId}
                    showErrors={showErrors}
                    setShowErrors={setShowErrors}
                />
            </div>

            <div className='pt-6  mx-2'>
                <Button onClick={handleSaveClick} id={`${baseId}-button-save`}>
                    Save
                </Button>
            </div>
        </ModalComponent>
    );
};
