import { store } from '@/context/store';

import { putUrl } from '../fetch';

import { ScanwareWithId } from './scanwareTypes';

export async function updateScanwareById(): Promise<ScanwareWithId | null> {
    try {
        const { scanwareQrId } = store.getState().displayReducer;
        const stockNumber =
            store.getState().borrowerReducer.vehicle.stockNumber;
        const url = `/api/scanware/id/${scanwareQrId}`;

        const body = { stockNumber };

        return await putUrl<ScanwareWithId>(url, body);
    } catch (error) {
        console.error(error);
        return null;
    }
}
