import { Button, EButtonType } from '../Button';

export interface IProps {
    onClick: () => void;
    heading: string;
    id: string;
    showErrors: boolean;
    children: JSX.Element;
}

export const testId = 'SummarySection';

export function TestDriveSummarySection({
    onClick,
    heading,
    id,
    showErrors,
    children,
}: IProps): JSX.Element {
    return (
        <div
            data-testid={testId}
            className={`border  bg-white  p-4 rounded-lg my-1 ${
                showErrors && 'bg-error'
            }`}
        >
            <div className='_flex justify-between items-center'>
                <h3 className='w-full font-bold text-xl'>{heading}</h3>
                <div>
                    <Button
                        type={EButtonType.PRIMARY}
                        onClick={onClick}
                        id={id}
                    >
                        Edit
                    </Button>
                </div>
            </div>
            {children}
        </div>
    );
}
