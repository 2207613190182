import { useState } from 'react';

import { IListboxOption, Linkbox } from '@/components/Linkbox';
import {
    ELenderRepaymentFrequency,
    selectLenderRepaymentFrequency,
    setLenderRepaymentFrequency,
} from '@/context/lender';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

interface IRepaymentProps {
    baseId?: string;
    valueOfElement?: string;
}

export function RepaymentSelect({
    baseId,
    valueOfElement,
}: IRepaymentProps): JSX.Element {
    // ***** Redux *****
    const dispatch = useAppDispatch();
    const lenderRepaymentFrequency = useAppSelector(
        selectLenderRepaymentFrequency
    );

    const options: IListboxOption[] = [
        {
            value: 'Monthly',

            id: 1,
        },
        {
            value: 'Fortnightly',

            id: 2,
        },
        {
            value: 'Weekly',
            id: 3,
        },
    ];

    const [selected, setSelected] = useState(findActive());

    function handleChange(value: string): void {
        const selected = options.find((options) => options.value === value);
        if (!selected) return;

        setSelected(selected?.id);

        dispatch(
            setLenderRepaymentFrequency(value as ELenderRepaymentFrequency)
        );
    }

    function findActive(): number {
        switch (lenderRepaymentFrequency) {
            case ELenderRepaymentFrequency.MONTHLY:
                return options[0]?.id;
            case ELenderRepaymentFrequency.FORTNIGHTLY:
                return options[1]?.id;
            case ELenderRepaymentFrequency.WEEKLY:
                return options[2]?.id;
        }
    }

    return (
        <Linkbox
            valueOfElement={valueOfElement}
            baseId={baseId}
            options={options}
            selected={selected}
            handleChange={handleChange}
        />
    );
}

export default RepaymentSelect;
