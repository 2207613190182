import { getUrl } from '@/apiCalls/fetch';
import { EVehicleAssetType } from '@/context/borrower';
import { IGlassOptionsApiResults } from '@/context/display';
import { getGlassAssetType } from '@/utils/glassGuideHelpers';

import { IGetGlassOptionsTypesResponse } from './getGlassOptionsTypes';

export async function getGlassOptions(
    partner: string,
    year: number | undefined,
    make: string | undefined,
    model: string | undefined,
    variant: string | undefined,
    assetType?: EVehicleAssetType
): Promise<IGlassOptionsApiResults[]> {
    const url = `/api/asset/select/${partner}/${getGlassAssetType(assetType)}/${year}/${make}/${model}/${variant}`;
    const response = await getUrl<IGetGlassOptionsTypesResponse>(url);
    return response.data;
}
