import { IScores, setScores } from '@/context/borrower';
import { setAndSortLenders } from '@/context/lender';
import { store } from '@/context/store';
import { getCommissionAmount } from '@/utils/getCommissionAmount';

import { postUrl } from '../fetch';

import { IPreApprovalResponse } from './fetchPreapprovalVerdict.types';

export async function fetchPreapprovalVerdict(): Promise<IPreApprovalResponse> {
    // ***** Send the Request *****
    const url = '/api/submit/preApproval';

    const state = store.getState();

    // Get Pre Approval parameters
    const lowestMonthlyRepayment =
        state.lenderReducer.lenders?.[0]?.monthlyRepaymentAmount; // TODO: filter to actually get the lowest one

    const sendLeadEmail = state.displayReducer.sendLeadEmail;

    let waterfallLenders: string[][] | undefined = undefined;
    if (state.displayReducer.waterfallLenders) {
        waterfallLenders = [];
        for (let i = 0; i < state.displayReducer.waterfallLenders.length; i++) {
            waterfallLenders.push(
                state.displayReducer.waterfallLenders[i].lenders
            );
        }
    }
    const isDemoMode = state.displayReducer.partner.isDemoMode;

    // Get partner commission amount
    const commissionAmount = getCommissionAmount();

    const response = await postUrl<IPreApprovalResponse>(url, {
        monthlyRepayment: lowestMonthlyRepayment,
        waterfallLenders,
        sendLeadEmail,
        commissionAmount,
        isDemoMode,
    });

    // ***** Dispatch response to state *****
    const stateScores = state.borrowerReducer.scores;

    // response returns number | null but FE saves number | undefined
    const scores: IScores = {
        onePointOneScore: response.onePointOneScore
            ? response.onePointOneScore
            : undefined,
        comprehensiveScore: response.comprehensiveScore
            ? response.comprehensiveScore
            : undefined,
        creditScore: response.creditScore ? response.creditScore : undefined,
        creditScorePercentage: response.creditScorePercentage,
        eligibilityScorePercentage: response.eligibilityScorePercentage,
        accuracyScorePercentage: response.accuracyScorePercentage,
    };

    // set scores to state
    store.dispatch(
        setScores({
            ...stateScores, // other scores that aren't returned by this API
            ...scores,
        })
    );

    // Set approved lenders to state
    store.dispatch(setAndSortLenders(response.approvedLenders));

    return response;
}
