import { Input } from '@/components/Input';
import {
    selectOtherIncome,
    setOtherIncome,
    setOtherIncomeSource,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

import { OtherIncomeSource } from '../OtherIncomeSource';

export interface IOtherIncome {
    baseId: string;
    showErrors: boolean;
}

export function OtherIncome({ baseId, showErrors }: IOtherIncome): JSX.Element {
    // ***** Redux *****
    const otherIncome = useAppSelector(selectOtherIncome);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        // MAXIMUM
        const MAXIMUM = 99999;
        const intValue = getNumber(value, MAXIMUM);
        if (intValue === otherIncome) return;
        dispatch(setOtherIncome(intValue));
        if (intValue !== 0) return;
        dispatch(setOtherIncomeSource(undefined));
    }

    // ***** Render *****
    return (
        <>
            <div data-testid='OtherIncome'>
                <Input
                    title='Any Other Income Paid to Your Bank Account'
                    placeholder='Income not from your job'
                    showErrors={false} // Not mandatory field
                    onChange={handleChange}
                    id={`${baseId}-input-otherIncome`}
                    value={otherIncome?.toLocaleString()}
                    prefix='$'
                    showNumpad={true}
                    tooltipContent='Got another revenue stream or a side hustle? Enter this extra monthly income here.'
                />
            </div>
            {otherIncome !== undefined && otherIncome > 0 && (
                <OtherIncomeSource baseId={baseId} showErrors={showErrors} />
            )}
        </>
    );
}
