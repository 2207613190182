import { useEffect } from 'react';

import { Input } from '@/components/Input';
import {
    EUserFlow,
    selectDeposit,
    selectPurchasePrice,
    setDeposit,
} from '@/context/borrower';
import { selectUserFlow } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'Deposit';

export function Deposit({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectDeposit);
    const dispatch = useAppDispatch();
    const userFlow = useAppSelector(selectUserFlow);
    const isCampaign = userFlow === EUserFlow.CAMPAIGN;
    const purchasePrice = useAppSelector(selectPurchasePrice);
    const url = window.location.href;
    const isLotus = url.includes('lotus');

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        // MAXIMUM
        const MAXIMUM = 999999;
        const intValue = getNumber(value, MAXIMUM);
        if (intValue === currentState) return;
        dispatch(setDeposit(intValue));
    }

    // ***** Campaign UseEffects *****
    // Initialize the deposit to 10% of the purchase price on initial render
    useEffect(() => {
        if (!isCampaign) return;
        if (!purchasePrice) return;
        if (isLotus) return;

        const minDeposit = purchasePrice * 0.1;
        if (!currentState || currentState < minDeposit) {
            dispatch(setDeposit(minDeposit));
        }
    }, []);
    // Initialize the deposit to 10% of the purchase price on purchase price change
    useEffect(() => {
        if (!isCampaign) return;
        if (!purchasePrice) return;
        if (isLotus) return;

        const minDeposit = purchasePrice * 0.1;
        if (!currentState || currentState < minDeposit) {
            dispatch(setDeposit(minDeposit));
        }
    }, [purchasePrice]);

    // Set the deposit to 10% of the purchase price if the deposit is less than 10% of the purchase price
    function onBlur(): void {
        if (!isCampaign) return;
        if (!purchasePrice) return;
        if (isLotus) return;

        const minDeposit = purchasePrice * 0.1;
        if (!currentState || currentState < minDeposit) {
            dispatch(setDeposit(minDeposit));
        }
    }

    // ***** Render *****
    return (
        <div data-testid={testId} className='w-full'>
            <Input
                title='Deposit'
                placeholder='Optional'
                showErrors={showErrors}
                onChange={handleChange}
                id={`${baseId}-input-deposit`}
                value={currentState?.toLocaleString() ?? ''}
                prefix='$'
                showNumpad={true}
                // tooltipContent='The amount of money that you are willing to pay upfront'
                onBlur={onBlur}
            />
        </div>
    );
}
