import { displaySlice } from '../displaySlice';

export const { setDisplay } = displaySlice.actions;
export const { setModal } = displaySlice.actions;
export const { setIsLoaded } = displaySlice.actions;
export const { setPreviousDisplay } = displaySlice.actions;
export const { setIsModalDisplayed } = displaySlice.actions;
export const { setIllionCodes } = displaySlice.actions;
export const { setIsAssetAndLiabilitiesSubmitSkipped } = displaySlice.actions;
export const { setInitialJourney } = displaySlice.actions;
export const { setShowScanwarePopup } = displaySlice.actions;
export const { setScanwareQrId } = displaySlice.actions;
export const { setVehicleManualEntry } = displaySlice.actions;
export const { setIsPriceKnownViaExternal } = displaySlice.actions;
