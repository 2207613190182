import { RadioGroup } from '@/components/RadioGroup';
import { selectIsVehicleKnown, setIsVehicleKnown } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { userFacingAssetTitle } from '@/utils/userFacingAssetTitle';
import { getBoolean } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'VehicleKnown';

export function VehicleKnown({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectIsVehicleKnown);
    const dispatch = useAppDispatch();
    const assetTitle = userFacingAssetTitle();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean | undefined): void {
        const booleanValue = getBoolean(value);
        dispatch(setIsVehicleKnown(booleanValue));
        if (!booleanValue) dispatch(setIsVehicleKnown(false));
    }

    // ***** Render *****
    const options = [
        {
            label: 'Yes',
            value: 'true',
            id: `${baseId}-button-vehicleKnown-yes`,
        },
        {
            label: 'Not Yet',
            value: 'false',
            id: `${baseId}-button-vehicleKnown-no`,
        },
    ];
    return (
        <div data-testid={testId}>
            <RadioGroup
                title={`Do you know the ${assetTitle} you want?`}
                showErrors={showErrors}
                onChange={handleChange}
                options={options}
                value={`${currentState}`}
            />
        </div>
    );
}
