import { Input } from '@/components/Input';
import { selectPreviousAbnActiveFrom } from '@/context/borrower';
import { useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'PreviousAbnActiveFrom';

export function PreviousAbnActiveFrom({
  baseId,
  showErrors,
}: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectPreviousAbnActiveFrom);

  // ***** Event Handlers *****
  function handleChange(): void {
    return;
  }

  // ***** Render *****
  return (
    <div data-testid={testId} className='w-full'>
      <Input
        title='Active From'
        placeholder={currentState || '11-11-2011'}
        showErrors={showErrors && !currentState}
        onChange={handleChange}
        id={`${baseId}-input-abnActiveFrom`}
        value={currentState?.toLocaleString() ?? ''}
        disabled
      />
    </div>
  );
}
