import { setIsPreapproved } from '@/context/borrower';
import { store } from '@/context/store';

import { postUrl } from '../fetch';

import {
  ESolarPreapprovalVerdict,
  ISolarPreapprovalRequest,
  ISolarPreapprovalReturn,
} from './solarPreapprovalVerdictTypes';

export async function solarPreapprovalVerdict(): Promise<void> {
  const state = store.getState();
  const ownershipType = state.borrowerReducer.address.address[0].ownershipType;
  const selfAssessedCreditRating =
    state.borrowerReducer.solar.selfAssessedCreditRating;
  const previousAssetFinance = state.borrowerReducer.solar.previousAssetFinance;
  const citizenshipStatus =
    state.borrowerReducer.personalDetails.citizenshipStatus;

  if (
    !ownershipType ||
    !selfAssessedCreditRating ||
    !previousAssetFinance ||
    !citizenshipStatus
  ) {
    store.dispatch(setIsPreapproved(false));
    return;
  }

  const url = '/api/solarPreapproval';

  const body: ISolarPreapprovalRequest = {
    ownershipType,
    selfAssessedCreditRating,
    previousAssetFinance,
    citizenshipStatus,
  };

  const response = await postUrl<ISolarPreapprovalReturn>(url, body);

  if (response.verdict === ESolarPreapprovalVerdict.REFERRED) {
    store.dispatch(setIsPreapproved(false));
  } else {
    store.dispatch(setIsPreapproved(true));
  }
}
