import { PayloadAction } from '@reduxjs/toolkit';

import { EVehicleAssetType } from '@/context/borrower';
import { ITermsAndConditions } from '@/context/display';
import { ELenderSorting } from '@/partners/matchingEngineEnums';
import { IPartnerParams, ISignupOptions } from '@/partners/partnerTypes';

export const partnerReducers = {
    setShowWelcomeScreen: (state, action: PayloadAction<boolean>): void => {
        state.showWelcomeScreen = action.payload;
    },
    setIsWhiteLabel: (state, action: PayloadAction<boolean>): void => {
        state.isWhiteLabel = action.payload;
    },
    setLogo: (state, action: PayloadAction<string>): void => {
        state.logo = action.payload;
    },
    setFullLogoUrl: (state, action: PayloadAction<boolean>): void => {
        state.fullLogoUrl = action.payload;
    },
    setInitialLoad: (state, action: PayloadAction<boolean>): void => {
        state.initialLoad = action.payload;
    },
    setShowOcrDisplay: (state, action: PayloadAction<boolean>): void => {
        state.showOcrDisplay = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>): void => {
        state.isLoading = action.payload;
    },
    setAgreedToTermsAndConditions: (
        state,
        action: PayloadAction<ITermsAndConditions>
    ): void => {
        if (state.agreedToTermsAndConditions) {
            state.agreedToTermsAndConditions = {
                ...state.agreedToTermsAndConditions,
                ...action.payload,
            };
        } else {
            state.agreedToTermsAndConditions = action.payload;
        }
    },
    setTermsAndConditionsLink: (state, action: PayloadAction<string>): void => {
        state.termsAndConditionsLink = action.payload;
    },
    setHideSellerScreen: (state, action: PayloadAction<boolean>): void => {
        state.hideSellerScreen = action.payload;
    },
    setHideVehicleScreen: (state, action: PayloadAction<boolean>): void => {
        state.hideVehicleScreen = action.payload;
    },
    setShowTotalAmountPayable: (
        state,
        action: PayloadAction<boolean>
    ): void => {
        state.showTotalAmountPayable = action.payload;
    },
    setPartnerName: (state, action: PayloadAction<string | null>): void => {
        state.partnerName = action.payload;
    },
    setSignupOptions: (state, action: PayloadAction<ISignupOptions>): void => {
        state.partner.signupOptions = action.payload;
    },
    setPartnerNumber: (state, action: PayloadAction<string | null>): void => {
        state.partnerNumber = action.payload;
    },
    setPartnerEmail: (state, action: PayloadAction<string | null>): void => {
        state.partnerEmail = action.payload;
    },
    setWaterfallLenders: (state, action: PayloadAction<string[][]>): void => {
        state.waterfallLenders = action.payload;
    },
    setIsAssetKnownViaParams: (state, action: PayloadAction<boolean>): void => {
        state.isAssetKnownViaParams = action.payload;
    },
    setPartner: (state, action: PayloadAction<IPartnerParams>): void => {
        state.partner = action.payload;
    },
    setAskTermsAndConditions: (
        state,
        action: PayloadAction<boolean | null>
    ): void => {
        state.askTermsAndConditions = action.payload;
    },
    setAvailableVehicleAssetTypes: (
        state,
        action: PayloadAction<EVehicleAssetType[]>
    ): void => {
        state.availableVehicleAssetTypes = action.payload;
    },
    setLenderSorting: (state, action: PayloadAction<ELenderSorting>): void => {
        state.lenderSorting = action.payload;
    },
    setIsDemoMode: (state, action: PayloadAction<boolean>): void => {
        state.partner.isDemoMode = action.payload;
    },

};
