export enum EEmploymentStatus {
  PERMANENT = 'PERMANENT',
  CASUAL = 'CASUAL',
  OTHER = 'OTHER',
}
export interface IEmploymentState {
  currentEmploymentIndex: number;
  employments: IEmployment[];
}
export interface IEmployment {
  status?: EEmploymentStatus | undefined;
  occupation?: string | undefined;
  employerName?: string | undefined;
  employerPhone?: string | undefined;
  years?: number | undefined;
  months?: number | undefined;
}

export const initialEmployment: IEmployment[] = [];
