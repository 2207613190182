import { ChangeEvent } from 'react';
import {
    ArrowRightCircleIcon,
    InformationCircleIcon,
} from '@heroicons/react/20/solid';

import { uniqueIdTransfer } from '@/apiCalls/uniqueIDTransferHelper';
import { trimClassname } from '@/utils/styleHelper';

import { EEventType } from '../eventType';
import { Tooltip } from '../Tooltip';

export interface IInput {
    title?: string;
    placeholder: string;
    id: string;
    onChange?: (value: string) => void;
    onBlur?: () => void;
    showErrors?: boolean;
    disabled?: boolean;
    value?: string;
    prefix?: string;
    showNumpad?: boolean;
    type?: string;
    eventType?: EEventType;
    tooltipContent?: string;
    onArrow?: () => void;
    height?: 'normal' | 'large';
    disclaimer?: boolean;
    disclaimerClick?: () => void;
}

export function Input({
    title,
    placeholder,
    id,
    value,
    showErrors,
    disabled = false,
    onChange,
    onBlur,
    prefix,
    showNumpad,
    type = 'text',
    eventType = EEventType.INPUT,
    tooltipContent,
    height = 'normal',
    onArrow,
    disclaimer,
    disclaimerClick,
}: IInput): JSX.Element {
    function handleChange(event: ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        let protectedValue = value;
        if (prefix) {
            protectedValue = value.replace(prefix, '');
        }

        onChange && onChange(protectedValue);
    }

    function handleKeyPress(
        event: React.KeyboardEvent<HTMLInputElement>
    ): void {
        // Check if Enter key is pressed
        if (event.key === 'Enter' && onArrow) {
            onArrow();
        }
    }

    function handleArrowClick(): void {
        if (onArrow) {
            onArrow();
        }
    }

    function handleBlurWrapper(): void {
        // Call the existing onBlur function from props, if available
        onBlur && onBlur();
        // Call new function
        uniqueIdTransfer({
            eventType: eventType,
            applicationData: { id: id },
        });
    }

    function getDisplayValue(): string {
        if (value === null) return '';
        if (value === undefined) return '';
        if (value === '') return '';
        if (prefix) return `${prefix}${value}`;
        return value;
    }

    return (
        <div
            data-testid='input'
            className='py-2 mx-auto w-full max-w-md relative'
        >
            <div
                className={`w-full flex space-x-1 items-center ju ${
                    disclaimer ? 'pb-1' : ''
                }`}
            >
                {title && (
                    <Tooltip content={tooltipContent ?? ''}>
                        <h1 className={`fieldHeading ${disclaimer && 'pb-0'}`}>
                            {title}
                        </h1>
                    </Tooltip>
                )}
                {disclaimer && (
                    <InformationCircleIcon
                        height={20}
                        width={20}
                        className='text-quaternary cursor-pointer'
                        onClick={disclaimerClick}
                    />
                )}
            </div>
            <input
                className={trimClassname(
                    `appearance-none border  rounded-[10px] mx-auto w-full max-w-md p-2 text-textPrimary leading-tight inputButtonFocus placeholderStyle text-center h-8 z-40 ${
                        showErrors ? errorClass : ''
                    } ${
                        disabled
                            ? 'hover:none cursor-disabled'
                            : 'hover:border-secondary '
                    } ${height === 'large' ? 'h-12' : ''}`
                )}
                id={id}
                type={type}
                data-testid='inputField'
                placeholder={placeholder}
                value={getDisplayValue()}
                onChange={handleChange}
                disabled={disabled}
                onKeyPress={handleKeyPress}
                onBlur={handleBlurWrapper}
                inputMode={showNumpad ? 'numeric' : undefined}
            />
            {onArrow && (
                <div
                    className='absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer z-50'
                    onClick={handleArrowClick}
                >
                    <ArrowRightCircleIcon className='w-5 h-5 text-primary' />
                </div>
            )}
        </div>
    );
}

export const errorClass = 'error';
