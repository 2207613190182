import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { store } from '@/context/store';

import { REACT_APP_ENV } from './utils/constants';
import App from './App';

import '@/styles/global.css';

// replace console.* for disable log on production
if (REACT_APP_ENV === 'production') {
    console.log = (): void => {
        return;
    };
    console.error = (): void => {
        return;
    };
    console.debug = (): void => {
        return;
    };
}

const root = ReactDOM.createRoot(
    document.getElementById('vroomroottag') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

export default root;
