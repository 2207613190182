import { useEffect, useState } from 'react';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import {
    ESupportingDocCodes,
    selectLoanUsage,
    selectSupportingDocs,
} from '@/context/borrower';
import { EDisplays, selectShowOcrDisplay, setDisplay } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

interface IskipProps {
    baseId?: string;
}

export function IdentificationSkipButton({
    baseId,
}: IskipProps): JSX.Element | null {
    // ***** Local State *****
    const [bothSidesUploaded, setBothSidesUploaded] = useState<boolean>(false);

    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const showOcr = useAppSelector(selectShowOcrDisplay);
    const loanUsage = useAppSelector(selectLoanUsage);
    const supportingDocs = useAppSelector(selectSupportingDocs);
    const areDocsPresent =
        (supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_BACK] &&
            supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_BACK].length ===
                0) ||
        (supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_FRONT] &&
            supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_FRONT].length ===
                0);

    function isComplete(): boolean {
        if (
            !supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_BACK] ||
            (supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_BACK] &&
                supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_BACK]
                    .length === 0)
        ) {
            return false;
        }
        if (
            !supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_FRONT] ||
            (supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_FRONT] &&
                supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_FRONT]
                    .length === 0)
        ) {
            return false;
        }
        setBothSidesUploaded(true);

        return true;
    }

    useEffect(() => {
        isComplete();
        if (areDocsPresent) {
            setBothSidesUploaded(false);
        }
    }, [supportingDocs]);

    useEffect(() => {
        isComplete();
        if (areDocsPresent) {
            setBothSidesUploaded(false);
        }
    }, []);

    function handleSkip(): void {
        const nextScreen = getNextScreen();
        createOrUpdateBorrower();
        dispatch(setDisplay(nextScreen));
    }

    function getNextScreen(): EDisplays {
        if (showOcr) {
            return EDisplays.OCR_ANIMATION;
        }
        return EDisplays.OCR_SUMMARY;
    }

    if (!bothSidesUploaded)
        return (
            <div
                className=' text-sm w-full _flex justify-center text-center w-f'
                id={`${baseId}-button-illDoItLater`}
            >
                <span className='text-secondary underline hover:cursor-pointer hover:text-secondaryDark'>
                    <span onClick={handleSkip}>I&apos;ll do it later</span>
                </span>
            </div>
        );

    return null;
}
