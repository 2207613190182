import { useState } from 'react';

import { DisplayComponent } from '@/components/DisplayComponent';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { Loading } from '@/components/Loading';
import { MultipleUserInput } from '@/components/MultipleUserInput';
import { ProgressBar } from '@/components/ProgressBar';
import { Education } from '@/UserInput/Education';
import { ForseeableChangesToCircumstances } from '@/UserInput/ForseeableChangesToCircumstances';
import { GeneralLiving } from '@/UserInput/GeneralLiving';
import { Healthcare } from '@/UserInput/Healthcare';
import { MortgageRent } from '@/UserInput/MortgageRent';
import { OtherLoans } from '@/UserInput/OtherLoans';
import { PayBillsFrequency } from '@/UserInput/PayBillsFrequency';
import { ShareExpenses } from '@/UserInput/ShareExpenses';
import { TransportVehicle } from '@/UserInput/TransportVehicle';

import { ExpenseButtons } from './ExpenseButtons';

export const testId = 'Expense';

function Expense(): JSX.Element {
    // ***** Local State *****
    const [showErrors, setShowErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const baseId = 'screen-expense';

    if (loading) {
        return (
            <DisplayComponent baseId={baseId} data-testid={testId}>
                <Header />
                <ProgressBar />
                <Loading />
            </DisplayComponent>
        );
    }

    // ***** Render *****
    return (
        <DisplayComponent baseId={baseId} data-testid={testId}>
            <Header />
            <ProgressBar />

            <DisplayHeading
                title='Expense Details'
                infoText='This section looks at your expenses to determine the best deal for you. Hover on each field for more info.'
            />
            <ShareExpenses showErrors={showErrors} baseId={baseId} />
            <PayBillsFrequency showErrors={showErrors} baseId={baseId} />
            <MultipleUserInput>
                <GeneralLiving showErrors={showErrors} baseId={baseId} />
                <MortgageRent showErrors={showErrors} baseId={baseId} />
            </MultipleUserInput>
            <MultipleUserInput>
                <TransportVehicle showErrors={showErrors} baseId={baseId} />
                <Healthcare showErrors={showErrors} baseId={baseId} />
            </MultipleUserInput>
            <MultipleUserInput>
                <Education showErrors={showErrors} baseId={baseId} />
                <OtherLoans showErrors={showErrors} baseId={baseId} />
            </MultipleUserInput>
            <div>
                <ForseeableChangesToCircumstances
                    showErrors={showErrors}
                    baseId={baseId}
                />
            </div>
            <ExpenseButtons
                setShowErrors={setShowErrors}
                setLoading={setLoading}
                baseId={baseId}
            />
        </DisplayComponent>
    );
}

export { Expense };
