import { postUrl } from '@/apiCalls/fetch';
import { setApplicationId } from '@/context/borrower';
import {
    EDisplays,
    EModals,
    setAgreedToTermsAndConditions,
    setModal,
} from '@/context/display';
import { store } from '@/context/store';
import { getProgressPercent } from '@/utils/getProgressPercent';
import {
    ELocalStorageVariables,
    getLocalStorageItem,
    setLocalStorageItem,
} from '@/utils/localStorage';
import { setDemoMode } from '@/utils/setDemoMode';

import {
    IGetBorrowerPostBody,
    IGetBorrowerReturn,
} from './createOrUpdateBorrowerTypes';

export async function createOrUpdateBorrower(): Promise<void> {
    // ***** Redux *****
    const state = store.getState();
    const borrower = { ...state.borrowerReducer }; // Copy borrower to avoid mutating state
    const isLoggedIn = state.userReducer.isLoggedIn;
    const token = getLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);
    const vehicle = state.borrowerReducer.vehicle;
    const seller = state.borrowerReducer.seller;
    const display = state.displayReducer.display;

    if (!token) {
        console.log('No token found in local storage');
        return;
    }
    if (!isLoggedIn) return;

    // Add progress percentage to borrower
    const currentProgressPercent = getProgressPercent();
    const savedProgressPercent = borrower.application.progressPercent;

    if (
        !savedProgressPercent ||
        currentProgressPercent > savedProgressPercent
    ) {
        borrower.application = {
            ...borrower.application,
            progressPercent: currentProgressPercent,
        }; // Copy application to avoid mutating state
    }

    borrower.personalDetails = {
        ...borrower.personalDetails,
        termsAndConditions: state.displayReducer.agreedToTermsAndConditions,
    };

    const sendToDealerSolutions =
        state.displayReducer.partner.sendToDealerSolutions;

    // ***** Request *****
    const url = '/api/borrower/createOrUpdate';

    const body: IGetBorrowerPostBody = {
        borrower,
        sendToDealerSolutions,
    };

    //   const body = {};
    const response = await postUrl<IGetBorrowerReturn>(url, body);
    // ***** Update Redux *****
    const returnedBorrower = response.borrower;
    const returnedFirstName = response.borrower.personalDetails?.firstName; // To save to local storage
    const returnedApplication = response.borrower.application;

    if (returnedApplication && returnedApplication.id) {
        store.dispatch(setApplicationId(returnedApplication.id));
    }

    if (returnedFirstName) {
        setLocalStorageItem(
            ELocalStorageVariables.FIRST_NAME,
            returnedFirstName
        );
    }

    // BE does not save these values
    if (!returnedBorrower.address) {
        returnedBorrower.address = {
            addressIndex: 0,
            address: [],
        };
    }
    if (!returnedBorrower.employments) {
        returnedBorrower.employments = {
            currentEmploymentIndex: 0,
            employments: [],
        };
    }
    if (
        returnedBorrower.personalLoans === null ||
        returnedBorrower.personalLoans === undefined
    ) {
        returnedBorrower.personalLoans = {
            personalLoanActiveIndex: 0,
            personalLoans: [],
        };
    }
    if (
        returnedBorrower.creditCards === null ||
        returnedBorrower.creditCards === undefined
    ) {
        returnedBorrower.creditCards = {
            creditCardActiveIndex: 0,
            creditCards: [],
        };
    }
    if (
        returnedBorrower.motorVehicles === null ||
        returnedBorrower.motorVehicles === undefined
    ) {
        returnedBorrower.motorVehicles = {
            motorVehicleActiveIndex: 0,
            motorVehicles: [],
        };
    }
    if (
        returnedBorrower.realEstates === null ||
        returnedBorrower.realEstates === undefined
    ) {
        returnedBorrower.realEstates = {
            realEstateActiveIndex: 0,
            realEstates: [],
        };
    }

    returnedBorrower.address.addressIndex = 0;
    returnedBorrower.employments.currentEmploymentIndex = 0;
    if (
        returnedBorrower.personalLoans === null ||
        returnedBorrower.personalLoans === undefined
    ) {
        returnedBorrower.personalLoans = {
            personalLoanActiveIndex: 0,
            personalLoans: [],
        };
    }

    returnedBorrower.personalLoans.personalLoanActiveIndex = 0;
    returnedBorrower.creditCards.creditCardActiveIndex = 0;
    returnedBorrower.motorVehicles.motorVehicleActiveIndex = 0;
    returnedBorrower.realEstates.realEstateActiveIndex = 0;

    if (returnedBorrower.personalLoans.personalLoans === undefined) {
        returnedBorrower.personalLoans.personalLoans = [];
    }

    if (returnedBorrower.creditCards.creditCards === undefined) {
        returnedBorrower.creditCards.creditCards = [];
    }
    if (returnedBorrower.personalLoans.personalLoans === undefined) {
        returnedBorrower.personalLoans.personalLoans = [];
    }

    if (returnedBorrower.creditCards.creditCards === undefined) {
        returnedBorrower.creditCards.creditCards = [];
    }

    if (returnedFirstName) {
        setLocalStorageItem(
            ELocalStorageVariables.FIRST_NAME,
            returnedFirstName
        );

        setDemoMode(returnedFirstName);
    }

    if (returnedBorrower.creditCards.creditCards === undefined) {
        returnedBorrower.creditCards.creditCards = [];
    }

    // BE Would otherwise overwrite FE vehicle / seller set by FE params which is incorrect
    returnedBorrower.seller = seller;
    returnedBorrower.vehicle = vehicle;

    store.dispatch(
        setAgreedToTermsAndConditions(
            returnedBorrower.personalDetails.termsAndConditions
        )
    );

    // store.dispatch(setBorrower(returnedBorrower));

    // When a application is submitted for the first time, they are on the supporting doc screen and call this function to save isSubmitted to the DB, we don't want to force the modal to change because they should see the Congratulations modal instead.
    if (
        returnedBorrower.application.isSubmitted &&
        display !== EDisplays.SUPPORTING_DOCS &&
        display !== EDisplays.SOLAR_SUPPORTING_DOCS
    ) {
        store.dispatch(setModal(EModals.APPLICATION_IN_PROGRESS));
    }

    return;
}
