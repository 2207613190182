import { Input } from '@/components/Input';
import { selectAbn, setAbn } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
    disabled?: boolean;
}

export const testId = 'Abn';

export function Abn({
    baseId,
    showErrors,
    disabled = true,
}: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectAbn);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    const handleChange = (value: string): void => {
        dispatch(setAbn(value));
    };

    // ***** Render *****
    return (
        <div className='w-full'>
            <Input
                title='ABN'
                placeholder={currentState || 'Required'}
                showErrors={showErrors && !currentState}
                id={`${baseId}-input-abn`}
                value={currentState ?? ''}
                disabled={disabled}
                data-testid={testId}
                onChange={handleChange}
                tooltipContent='Check this looks correct but if not, search using the Business Name again.'
            />
        </div>
    );
}
