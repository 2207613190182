import { RadioGroup } from '@/components/RadioGroup';
import { ELoanUsage, selectLoanUsage, setLoanUsage } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'LoanUsage';

export function LoanUsage({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectLoanUsage);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean | undefined): void {
        dispatch(setLoanUsage(value as ELoanUsage));
    }

    // ***** Render *****
    const options = [
        {
            label: 'Personal',
            value: ELoanUsage.PERSONAL,
            id: `${baseId}-button-loanUsage-personal`,
        },
        {
            label: 'Business',
            value: ELoanUsage.BUSINESS,
            id: `${baseId}-button-loanUsage-business`,
        },
    ];
    return (
        <div data-testid={testId}>
            <RadioGroup
                title="What's the purpose of the loan?"
                showErrors={showErrors}
                onChange={handleChange}
                options={options}
                value={currentState}
                tooltipContent='Select Business if more than 50% business use. Can also apply to employees.'
            />
        </div>
    );
}
