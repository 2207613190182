import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { DisplayButtons } from '@/components/DisplayButtons';
import {
    EDisplays,
    selectPartner,
    selectPreviousDisplay,
    setDisplay,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { loanGetNextScreen } from '@/utils/loanGetNextScreen';

interface LoanButtonsPropsInterface {
    baseId?: string;
}

export function LoanButtons({
    baseId,
}: LoanButtonsPropsInterface): JSX.Element {
    const dispatch = useAppDispatch();
    const { showQuickQuote } = useAppSelector(selectPartner);

    const previousDisplay = useAppSelector(selectPreviousDisplay);
    const wasPreviousDisplayIndicativelyApproved =
        previousDisplay === EDisplays.INDICATIVELY_APPROVED;

    function handleRightClick(): void {
        createOrUpdateBorrower();
        if (wasPreviousDisplayIndicativelyApproved) {
            dispatch(setDisplay(EDisplays.PREPPROVAL_ANIMATION));
            return;
        }
        const nextScreen = loanGetNextScreen();
        dispatch(setDisplay(nextScreen));
    }

    function handleLeftClick(): void {
        createOrUpdateBorrower();
        const backScreen = getBackScreen();
        dispatch(setDisplay(backScreen));
    }

    function getBackScreen(): EDisplays {
        if (showQuickQuote) return EDisplays.QUICK_CALCULATOR;
        return EDisplays.NO_LENDER_HOME;
    }

    return (
        <DisplayButtons
            idLeftButton={`${baseId}-button-back`}
            idRightButton={`${baseId}-button-next`}
            rightClick={handleRightClick}
            leftClick={handleLeftClick}
        />
    );
}
