import { IPartnerParams } from '@/partners/partnerTypes';
import {
    EEasycarsParameters,
    EParameters,
} from '@/utils/setParametersFromParams';

import { ISetInitialVehicle } from './ISetInitialVehicle';

export function getInitialVehicleData(
    partner: IPartnerParams,
    params: URLSearchParams
): ISetInitialVehicle {
    console.log('* getInitialVehicleData', partner);
    return {
        buildYear:
            params.get(EParameters.BUILD_YEAR) ||
            params.get(EEasycarsParameters.BUILD_YEAR) ||
            params.get('buildyear'),
        make: params.get(EParameters.MAKE),
        model: params.get(EParameters.MODEL),
        nvic: params.get(EParameters.NVIC),
        sourceYardId: params.get(EParameters.SOURCE_YARD_ID),
        stockNumber:
            params.get(EParameters.STOCK_NUMBER) ||
            params.get(EEasycarsParameters.STOCK_NUMBER),
        variant: params.get(EParameters.VARIANT),
        vehicleKilometers: params.get(EParameters.KILOMETERS),
        vehiclePrice: params.get(EParameters.AMOUNT),
        vin: params.get(EParameters.VIN),
        partnerId: partner.id,
        originalUrl: params.get(EParameters.ORIGINAL_URL),
        vehicleCondition: {
            params: params.get(EParameters.CONDITION),
            partner: partner.defaultVehicleCondition,
        },
        initialVehicleAssetType: {
            params:
                params.get(EParameters.VEHICLE_TYPE) ||
                params.get(EParameters.ASSET_TYPE),
            partner: partner.defaultVehicleAssetType,
        },
        initialVehicleAssetCategory: {
            params: params.get(EParameters.ASSET_CATEGORY),
            partner: partner.defaultVehicleAssetCategory,
        },
    };
}
