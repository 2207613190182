import { getUrl } from '@/apiCalls/fetch';
import { setVehicle } from '@/context/borrower';
import { store } from '@/context/store';
import { getGlassAssetType } from '@/utils/glassGuideHelpers';

import { IGetNvicReturn } from './lookupNvicTypes';

export async function lookupNvic(partnerId: string): Promise<void> {
    const state = store.getState();
    const stateNvic = state.borrowerReducer.vehicle.nvic;
    const stateVehicle = state.borrowerReducer.vehicle;
    const assetType = state.borrowerReducer.vehicle.assetType;

    if (!stateNvic) return;

    const url = `/api/asset/select/${partnerId}/${getGlassAssetType(
        assetType
    )}/nvic/${stateNvic}`;

    const { data } = await getUrl<IGetNvicReturn>(url);

    if (!data) return;

    const {
        make,
        model,
        vin,
        nvic,
        variant,
        buildYear,
        glassValuation,
        description,
    } = data;

    const isVehicleKnown = glassValuation ? true : false;

    store.dispatch(
        setVehicle({
            ...stateVehicle,
            make,
            model,
            vin,
            nvic,
            variant,
            buildYear,
            glassValuation,
            description,
            isVehicleKnown,
        })
    );
}
