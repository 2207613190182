import { useState } from 'react';

import { IListboxOption, Linkbox } from '@/components/Linkbox';
import {
    ELenderSortedBy,
    selectLenderSortedBy,
    setLenderSortedBy,
} from '@/context/lender';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

interface ILenderSortByProps {
    baseId?: string;
    valueOfElement?: string;
}

export function LenderSortBySelect({
    baseId,
    valueOfElement,
}: ILenderSortByProps): JSX.Element {
    // ***** Redux *****
    const dispatch = useAppDispatch();
    const lenderSortedBy = useAppSelector(selectLenderSortedBy);

    const options: IListboxOption[] = [
        {
            value: ELenderSortedBy.REPAYMENT,

            id: 1,
        },
        {
            value: ELenderSortedBy.ADVERTISED_RATE,

            id: 2,
        },
        {
            value: ELenderSortedBy.COMPARISON_RATE,
            id: 3,
        },
        {
            value: ELenderSortedBy.UPFRONT_FEE,
            id: 4,
        },
        {
            value: ELenderSortedBy.ONGOING_FEE,

            id: 5,
        },
        {
            value: ELenderSortedBy.TURNAROUND_TIME,
            id: 6,
        },
    ];

    const [selected, setSelected] = useState(findActive());

    function handleChange(value: string): void {
        const selected = options.find((options) => options.value === value);
        if (!selected) return;

        setSelected(selected?.id);

        dispatch(setLenderSortedBy(value as ELenderSortedBy));
    }

    function findActive(): number {
        switch (lenderSortedBy) {
            default:
            case ELenderSortedBy.REPAYMENT:
                return options[0]?.id;
            case ELenderSortedBy.ADVERTISED_RATE:
                return options[1]?.id;
            case ELenderSortedBy.COMPARISON_RATE:
                return options[2]?.id;
            case ELenderSortedBy.UPFRONT_FEE:
                return options[3]?.id;
            case ELenderSortedBy.ONGOING_FEE:
                return options[4]?.id;
            case ELenderSortedBy.TURNAROUND_TIME:
                return options[5]?.id;
        }
    }

    return (
        <Linkbox
            valueOfElement={valueOfElement}
            baseId={baseId}
            options={options}
            selected={selected}
            handleChange={handleChange}
        />
    );
}
