import { setPartnerName } from '@/context/display';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialDisplaySellerName(
  parameterPartnerName: IParameter,
  partnerName: string | null
): void {
  const stateName = store.getState().displayReducer.partnerName;

  const nameToSave = parameterPartnerName ?? partnerName;

  if (stateName !== nameToSave) {
    store.dispatch(setPartnerName(nameToSave));
  }
}
