import { IPartnerParams } from '@/partners/partnerTypes';
import { EParameters } from '@/utils/setParametersFromParams';

import { ISetInitialBorrower } from './ISetInitialBorrower';

export function getInitialBorrowerData(
    partner: IPartnerParams,
    params: URLSearchParams
): ISetInitialBorrower {
    return {
        leadToken: params.get(EParameters.LEAD_TOKEN),
        testDriveDate: params.get(EParameters.TEST_DRIVE_DATE),
        testDriveTime: params.get(EParameters.TEST_DRIVE_TIME),
    };
}
