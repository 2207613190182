import { useSelector } from 'react-redux';

import { Input } from '@/components/Input';
import {
    IAddress,
    selectAddressByIndex,
    setAddressByIndex,
} from '@/context/borrower';
import { RootState } from '@/context/store';
import { useAppDispatch } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'StreetNumber';

export function StreetNumber({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const address = useSelector((state: RootState) =>
        selectAddressByIndex(state, 0)
    );
    const { streetNumber } = address;
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        if (typeof value !== 'string') return;
        const newAddress: IAddress = {
            ...address,
            streetNumber: value,
        };
        dispatch(setAddressByIndex({ index: 0, address: newAddress }));
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Street Number'
                placeholder='12'
                showErrors={showErrors && !streetNumber}
                onChange={handleChange}
                id={`${baseId}-input-streetNumber`}
                value={streetNumber?.toLocaleString() ?? ''}
            />
        </div>
    );
}
