import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { fetchServicing } from '@/apiCalls/fetchServicing';
import { DisplayButtons } from '@/components/DisplayButtons';
import {
    selectBillsPaidFrequency,
    selectEducation,
    selectExpensesShared,
    selectGeneral,
    selectHealthcare,
    selectMortgageRent,
    selectOtherLoans,
    selectTransportVehicle,
} from '@/context/borrower';
import { EDisplays, setDisplay } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

interface IExpenseButtonsProps {
    setShowErrors: (value: boolean) => void;
    setLoading: (value: boolean) => void;
    baseId: string;
}

export function ExpenseButtons({
    setShowErrors,
    setLoading,
    baseId,
}: IExpenseButtonsProps): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const shareExpenses = useAppSelector(selectExpensesShared);
    const billsFrequency = useAppSelector(selectBillsPaidFrequency);
    const generalLiving = useAppSelector(selectGeneral);
    const mortgageRent = useAppSelector(selectMortgageRent);
    const transportVehicle = useAppSelector(selectTransportVehicle);
    const education = useAppSelector(selectEducation);
    const healthcare = useAppSelector(selectHealthcare);
    const otherLoans = useAppSelector(selectOtherLoans);

    // ***** Event Handlers *****
    function handleBackClick(): void {
        const backScreen = getBackScreen();
        dispatch(setDisplay(backScreen));
    }

    async function handleNextClick(): Promise<void> {
        setShowErrors(true);
        if (!isComplete()) return;
        createOrUpdateBorrower();
        const nextScreen = await getNextScreen();
        dispatch(setDisplay(nextScreen));
    }

    // ***** Helpers ******
    function isComplete(): boolean {
        if (shareExpenses === undefined) return false;
        if (billsFrequency === undefined) return false;
        if (generalLiving === undefined) return false;
        if (mortgageRent === undefined) return false;
        if (transportVehicle === undefined) return false;
        if (education === undefined) return false;
        if (healthcare === undefined) return false;
        if (otherLoans === undefined) return false;
        return true;
    }

    async function getNextScreen(): Promise<EDisplays> {
        setLoading(true);
        const servicingMet = await fetchServicing();
        if (servicingMet) {
            return EDisplays.SERVICING_SUCCESS;
        }
        return EDisplays.SERVICING_FAILURE;
    }

    function getBackScreen(): EDisplays {
        return EDisplays.INCOME;
    }

    return (
        <DisplayButtons
            idLeftButton={`${baseId}-button-back`}
            idRightButton={`${baseId}-button-next`}
            rightClick={handleNextClick}
            leftClick={handleBackClick}
        />
    );
}
