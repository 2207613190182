import { setVehicleKilometers } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialVehicleKilometers(
    vehicleKilometers: IParameter
): void {
    if (!vehicleKilometers || vehicleKilometers === 'undefined') {
        return;
    }

    const intVehicleKilometers = parseInt(vehicleKilometers, 10);
    if (isNaN(intVehicleKilometers)) {
        return;
    }

    const stateKilometers = store.getState().borrowerReducer.vehicle.kilometers;

    if (stateKilometers !== intVehicleKilometers) {
        store.dispatch(setVehicleKilometers(intVehicleKilometers));
    }
}
