import { useLocation } from 'react-router-dom';

export function getParameters(): URLSearchParams {
  let params: URLSearchParams;

  // code snippet logic
  try {
    // Global const from outside of vroom on the host website

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const csParams = VroomCodeSnippetQueryStringParams;

    params = new URLSearchParams(csParams || useLocation().search);
  } catch {
    params = new URLSearchParams(useLocation().search);
  }

  return params;
}
