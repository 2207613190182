import { TimeInput } from '@/components/TimeInput';
import { selectTestDriveTime, setTestDriveTime } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export function TestDriveTime(): JSX.Element {
    const time = useAppSelector(selectTestDriveTime);
    const dispatch = useAppDispatch();

    function handleChange(value: Date): void {
        dispatch(setTestDriveTime(value));
    }

    return (
        <TimeInput
            title=''
            showErrors={false}
            onChange={handleChange}
            placeholder='DD/MM/YYYY'
            value={time}
        />
    );
}

export default TestDriveTime;
