export const testId = 'DescriptionBox';

interface Props {
  key: number | null | undefined;
  content: string | null | undefined;
  onClick?: () => void;
  selected?: boolean;
  vehicleUnknown?: boolean;
}

function DescriptionBox({
  content,
  onClick,
  selected,
  vehicleUnknown,
}: Props): JSX.Element {
  const generalClassName = `italic text-xs border rounded-[10px]`;
  const className = `${generalClassName} ${
    selected
      ? `border border-primary text-center p-2 m-2 
       cursor-pointer bg-primary text-white`
      : `border border-secondary text-center p-2 m-2 ${
          vehicleUnknown ? 'cursor-pointer' : ''
        }`
  }`.trim();

  return (
    <div className={className} onClick={onClick}>
      {content}
    </div>
  );
}

export { DescriptionBox };
