import { useState } from 'react';

import { DisplayComponent } from '@/components/DisplayComponent';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import { ELoanUsage, EUserFlow, selectLoanUsage } from '@/context/borrower';
import { selectUserFlow } from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';
import { LivingStatus } from '@/UserInput';
import { CampaignVehicleDropdown } from '@/UserInput/CampaignVehicleDropdown';
import { HaveKids } from '@/UserInput/HaveKids';
import { ResidencyStatus } from '@/UserInput/ResidencyStatus';

import { BasicDetailsButtons } from './BasicDetailsButtons';

export function BasicDetails(): JSX.Element {
    // ***** Local State *****
    const [showErrors, setShowErrors] = useState(false);
    const loanUsage = useAppSelector(selectLoanUsage);
    const userFlow = useAppSelector(selectUserFlow);

    // ***** CONSTANTS *****
    const baseId = 'screen-basicDetails';

    return (
        <DisplayComponent baseId={baseId}>
            <Header />
            <ProgressBar />
            <DisplayHeading
                title='Basic Details'
                infoText='We just need to ask some basic details about you'
            />
            {/* <Gender showErrors={showErrors} baseId={baseId} /> */}
            {userFlow === EUserFlow.CAMPAIGN && (
                <CampaignVehicleDropdown
                    baseId={baseId}
                    showErrors={showErrors}
                />
            )}

            <LivingStatus showErrors={showErrors} baseId={baseId} />
            <ResidencyStatus showErrors={showErrors} baseId={baseId} />
            {loanUsage !== ELoanUsage.BUSINESS && (
                <HaveKids showErrors={showErrors} baseId={baseId} />
            )}
            <BasicDetailsButtons
                setShowErrors={setShowErrors}
                baseId={baseId}
            />
        </DisplayComponent>
    );
}
