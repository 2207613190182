import { IMAGE_URL } from "@/utils/constants";

export function TruckstopQuickCalculatorIcons(): JSX.Element {
    return (
        <div className='flex w-full justify-between text-xs font-semibold items-center text-center uppercase '>
            <div className={`flex-center flex-col w-full py-4 `}>
                <p className='pb-'>
                    No impact on
                    <br />
                    credit score
                </p>
                <img
                    src={`${IMAGE_URL}/odometer.svg`}
                    alt={'odometer'}
                    className='h-12 w-10 text-secondary'
                />
            </div>
            <div className={`flex-center flex-col w-full py-4 `}>
                <p className='pb-'>
                    Compare with <br />
                    {'>'} 40 lenders
                </p>
                <img
                    src={`${IMAGE_URL}/puzzle.svg`}
                    alt={'puzzle'}
                    className='h-12 w-10 text-secondary'
                />
            </div>
            <div className={`flex-center flex-col w-full py-4 `}>
                <p className='pb-'>
                    No hidden <br />
                    fees
                </p>
                <img
                    src={`${IMAGE_URL}/hearts.svg`}
                    alt={'hearts'}
                    className='h-12 w-10 text-secondary'
                />
            </div>
        </div>
    );
}
