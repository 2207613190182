import React, { useEffect } from 'react';

import { uniqueIdTransfer } from '@/apiCalls/uniqueIDTransferHelper';

import { EEventType } from '../eventType';

interface IModalComponentProps {
    baseId: string;
    children: React.ReactNode;
    className?: string; // Add className to accept the prop
    [key: string]: unknown; // add an index signature to accept any other props
}

export function ModalComponent({
    baseId,
    children,
    className, // Destructure className
    ...rest
}: IModalComponentProps): JSX.Element {
    useEffect(() => {
        uniqueIdTransfer({
            eventType: EEventType.VISIBLE_ON_SCREEN,
            applicationData: {
                id: `${baseId}-modal`,
            },
        });
    }, []);
    return (
        <div
            id={`${baseId}-modal`}
            className={`_flex flex-col justify-between  mx-auto ${className}`} // Use the className prop here
            {...rest}
        >
            {React.Children.map(children, (child, index) => {
                return <div className=''>{child}</div>;
            })}
        </div>
    );
}
