import { useState } from 'react';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button } from '@/components/Button';
import { ModalComponent } from '@/components/ModalComponent';
import { ModalHeader } from '@/components/ModalHeader';
import { selectLoanUsage } from '@/context/borrower';
import { setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { HomeandContents } from '@/UserInput/HomeandContents';
import { OtherAssets } from '@/UserInput/OtherAssets';
import { PlantandEquipment } from '@/UserInput/PlantandEquipment';
import { Savings } from '@/UserInput/Savings';
import { SuperandShares } from '@/UserInput/SuperandShares';

export const testId = 'OtherAssetsSavingsDetails';

export function OtherAssetsSavingsDetails(): JSX.Element {
    // ***** Constants *****
    const baseId = 'modal-savingsOrMore';
    // ***** Local State *****
    const [showErrors, setShowErrors] = useState<boolean>(false);
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const commercialFlow = useAppSelector(selectLoanUsage) === 'BUSINESS';
    function handleClick(): void {
        createOrUpdateBorrower();
        dispatch(setModal(null));
    }

    // ***** Render *****
    return (
        <ModalComponent baseId={baseId}>
            <ModalHeader title='Savings and other assets' baseId={baseId} />
            <p className='text-xs -mt-2 mb-2'>
                List the value of your other assets below
            </p>
            {commercialFlow && (
                <PlantandEquipment baseId={baseId} showErrors={showErrors} />
            )}
            <HomeandContents baseId={baseId} showErrors={showErrors} />
            <Savings baseId={baseId} showErrors={showErrors} />
            <SuperandShares baseId={baseId} showErrors={showErrors} />
            <OtherAssets baseId={baseId} showErrors={showErrors} />
            <div className='pt-10 mx-2'>
                <Button onClick={handleClick} id={`${baseId}-button-save`}>
                    Save
                </Button>
            </div>
        </ModalComponent>
    );
}
