import { setVehicleAssetCategory } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialVehicleCategory(
    parameterType: IParameter,
    partnerInitialType: string
): void {
    const category = parameterType || partnerInitialType;

    if (category) {
        const stateCategory =
            store.getState().borrowerReducer.vehicle.assetType;

        if (stateCategory !== category) {
            store.dispatch(setVehicleAssetCategory(category));
        }
    }
}
