import { Button, EButtonType } from '@/components/Button';
import { setPurchasePrice } from '@/context/borrower';
import { setIsPriceKnownViaExternal, setModal } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';

export function ScanwareCheckAssetButton(): JSX.Element {
    const dispatch = useAppDispatch();

    function handleClick(): void {
        dispatch(setModal(null));
    }

    function handleNo(): void {
        dispatch(setPurchasePrice(0));
        dispatch(setIsPriceKnownViaExternal(false));
        dispatch(setModal(null));
    }

    return (
        <div className='flex space-x-4'>
            <div className='w-full'>
                <Button
                    onClick={handleNo}
                    type={EButtonType.ROUNDED_FULL_SECONDARY_OUTLINE}
                >
                    Edit
                </Button>
            </div>
            <div className='w-full'>
                <Button onClick={handleClick}>Confirm</Button>
            </div>
        </div>
    );
}
