import { Fragment, useEffect, useState } from 'react';
import { RadioGroup as HUIRadioGroup } from '@headlessui/react';
import { trimClassname } from 'src/utils/styleHelper';

import { uniqueIdTransfer } from '@/apiCalls/uniqueIDTransferHelper';
import { selectIsLoading } from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';

import { EEventType } from '../eventType';
import { Tooltip } from '../Tooltip';

interface IOption {
    label: string;
    value: string | boolean | undefined;
    id: string;
}

export interface IRadioGroup {
    title: string | null;
    options: IOption[];
    showErrors: boolean;
    onChange: (value: string | boolean | undefined) => void;
    eventType?: EEventType;
    value: string | boolean | number | undefined | null;
    tooltipContent?: string;
}

export function RadioGroup({
    title,
    options,
    value,
    showErrors,
    onChange,
    eventType = EEventType.CLICK,
    tooltipContent,
}: IRadioGroup): JSX.Element {
    // const componentOptions: string[] = [];
    const [optionsRows, setOptionsRows] = useState<IOption[][]>([]);
    const [flexWrap, setFlexWrap] = useState<boolean>(false);
    const [useIncreasedHeight, setUseIncreasedHeight] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<string | boolean | number | undefined>(value || undefined);
    const isLoading = useAppSelector(selectIsLoading);

    useEffect(() => {
        // Chose the list style based on the number of options
        const optionsLength = options.length;
        let size: [number, number] | null = null; // [columns, rows]

        if (optionsLength <= 3) {
            size = [optionsLength, 1];
        } else if (optionsLength === 4) {
            size = [2, 2];
            setUseIncreasedHeight(true);
        } else if (optionsLength === 6) {
            size = [3, 2];
            setUseIncreasedHeight(true);
        } else if (optionsLength === 8) {
            size = [2, 4];
            setUseIncreasedHeight(true);
        } else if (optionsLength === 9) {
            size = [3, 3];
            setUseIncreasedHeight(true);
        } else {
            setFlexWrap(true);
        }

        // Separate options into rows and columns
        const rows: IOption[][] = [];

        if (size) {
            for (let ri = 0; ri < size[1]; ri++) {
                const row: IOption[] = [];
                for (let ci = ri * size[0]; ci < ri * size[0] + size[0]; ci++) {
                    row.push(options[ci]);
                }
                rows.push(row);
            }
        } else {
            rows[0] = options; // First row to have all columns
        }

        setOptionsRows(rows);
    }, []);

    function haveErrors(): boolean {
        return showErrors && !selectedValue;
    }

    function handleChange(clickedOption: IOption): void {
        setSelectedValue(clickedOption.value);

        // Callback
        onChange(clickedOption.value);

        // Tracking
        uniqueIdTransfer({
            eventType: eventType,
            applicationData: {
                id: clickedOption.id,
            },
        });
    }

    return (
        <div data-testid='radioGroup' className='py-2'>
            <HUIRadioGroup value={selectedValue} onChange={handleChange as any}>
                {/* Title */}
                {title && (
                    <Tooltip content={tooltipContent ?? ''}>
                        <HUIRadioGroup.Label className='fieldHeading ' as='p'>
                            {title}
                        </HUIRadioGroup.Label>
                        <HUIRadioGroup.Label className='sr-only'>
                            {title}
                        </HUIRadioGroup.Label>
                    </Tooltip>
                )}
                {optionsRows.map((columns, ri) => {
                    const isFirstRow = ri === 0;
                    const isLastRow = ri === optionsRows.length - 1;

                    return (
                        <div
                            data-testid='radioGroupOptionDiv'
                            className={`_flex justify-center items-center w-full ${flexWrap && 'flex-wrap space-y-2'}`}
                            key={ri}
                        >
                            {columns.map((option, ci) => {
                                const isFirstColumn = ci === 0;
                                const isLastColumn = ci === columns.length - 1;

                                return (
                                    // Outside Card
                                    <Fragment key={ri * columns.length + ci}>
                                        <HUIRadioGroup.Option
                                            data-googleid={option.id}
                                            value={option}
                                            data-testid='radioGroupOptionCard'
                                            disabled={isLoading ? true : false}
                                            className={`
                                                ${!flexWrap && isFirstRow && isFirstColumn ? 'rounded-tl-[10px] ' : ''}
                                                ${!flexWrap && isFirstRow && isLastColumn ? 'rounded-tr-[10px] ' : ''}
                                                ${!flexWrap && isLastRow && isFirstColumn ? 'rounded-bl-[10px] ' : ''}
                                                ${!flexWrap && isLastRow && isLastColumn ? 'rounded-br-[10px] ' : ''}
                                                ${flexWrap ? 'rounded-[10px]' : ''}
                                                ${haveErrors() ? errorClass : ''}
                                                relative _flex
                                                ${!isLoading ? 'cursor-pointer hover:border-secondary' : ''}
                                                hover:z-10
                                                hover:box-border
                                                w-full
                                                transition
                                                ease-in-out
                                                ${useIncreasedHeight ? 'h-10' : 'h-8'}
                                                border
                                                -mx-[0.3px]
                                                ${selectedValue === option.value ? '!bg-secondary text-white' : ''}
                                            `}

                                        >
                                            {({ checked }): JSX.Element => (
                                                <HUIRadioGroup.Label
                                                    as='p'
                                                    data-testid='radioGroupOptionText'
                                                    className={trimClassname(`fieldHeading centerFull text-center pb-0 px-3 ${selectedValue === option.value && 'text-white'}`)}
                                                >
                                                    {option.label}
                                                </HUIRadioGroup.Label>
                                            )}
                                        </HUIRadioGroup.Option>
                                    </Fragment>
                                );
                            })}
                        </div>
                    );
                })}
            </HUIRadioGroup>
        </div>
    );
}

// For testing purposes, constants and exported instead of being inlined
export const checkedClass = 'text-white';
export const uncheckedClass = 'text-gray-900';
export const errorClass = 'error';
