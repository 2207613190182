import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button } from '@/components/Button';
import { ModalComponent } from '@/components/ModalComponent';
import { ModalHeader } from '@/components/ModalHeader';
import {
    EBorrowerCitizenshipStatus,
    selectAddressByIndex,
    selectEmail,
    selectFirstName,
    selectLastName,
    selectLicenseNumber,
    selectLicenseState,
    selectMobile,
    selectResidencyStatus,
    selectVisaNumber,
} from '@/context/borrower';
import { EDisplays, selectDisplay, setModal } from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { isBirthDateValid } from '@/utils/checkBirthDate';
import { isLicenseExpiryDateValid } from '@/utils/checkLicenseExpiryDate';
import { isVisaExpiryDateValid } from '@/utils/checkVisaExpiryDate';
import { getOcrFields } from '@/utils/getOcrFields';
import { isEmail } from '@/utils/isEmail';

export const testId = 'OcrCheckIncomplete';
export function OcrCheckIncomplete(): JSX.Element {
    // ***** Constants *****
    const baseId = 'modal-ocrSummaryCheck';
    // ***** Local State *****
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [missingFields, setMissingFields] = useState<JSX.Element[]>([]);
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    // Contact Details
    const firstName = useAppSelector(selectFirstName);
    const lastName = useAppSelector(selectLastName);
    const email = useAppSelector(selectEmail);
    // Personal Information
    const licenseNumber = useAppSelector(selectLicenseNumber);
    const licenseState = useAppSelector(selectLicenseState);
    const visaNumber = useAppSelector(selectVisaNumber);
    const phone = useAppSelector(selectMobile);
    const residencyStatus = useAppSelector(selectResidencyStatus);

    // Address Information
    const address = useSelector((state: RootState) =>
        selectAddressByIndex(state, 0)
    );
    const { ownershipType, streetNumber, streetName, suburb, state, postcode } =
        address;

    const display = useAppSelector(selectDisplay);

    // ***** UseEffect *****
    useEffect(() => {
        checkMissingFields();
    }, []);

    // ***** Handlers *****
    function handleClick(): void {
        setShowErrors(true);
        if (!isComplete()) return;
        createOrUpdateBorrower();
        dispatch(setModal(null));
    }

    // ***** Helpers *****
    function isComplete(): boolean {
        // Contact Details
        if (firstName === '' || firstName === undefined) return false;
        if (lastName === '' || lastName === undefined) return false;
        if (!isEmail(email)) return false;

        // Not required for test drive flow
        if (display !== EDisplays.TEST_DRIVE_OCR_SUMMARY) {
            if (phone && (phone === '' || phone?.length < 10)) return false;
            if (ownershipType === null || ownershipType === undefined)
                return false;

            if (
                residencyStatus === EBorrowerCitizenshipStatus.VISA_HOLDER &&
                ((visaNumber && visaNumber.toString().length < 3) ||
                    !isVisaExpiryDateValid())
            )
                return false;

            if (residencyStatus === null || residencyStatus === undefined)
                return false;
        }
        // Personal Information
        if (!isBirthDateValid()) return false;

        if (licenseNumber === '' || licenseNumber === undefined) return false;
        if (licenseState === '' || licenseState === undefined) return false;

        if (!isLicenseExpiryDateValid()) return false;
        // Address Information
        if (streetNumber === '' || streetNumber === undefined) return false;
        if (streetName === '' || streetName === undefined) return false;
        if (suburb === '' || suburb === undefined) return false;
        if (state === null || state === undefined) return false;
        if (
            postcode === '' ||
            postcode === undefined ||
            postcode.toString().length < 4
        )
            return false;

        return true;
    }

    const checkMissingFields = (): void => {
        const missing: JSX.Element[] = [];
        getOcrFields(baseId, showErrors).map((item, index) => {
            missing.push(item);
        });
        setMissingFields(missing);
    };

    // ***** Render *****
    return (
        <>
            <ModalComponent
                baseId={baseId}
                className='overflow-y-scroll w-full h-full'
            >
                <ModalHeader title='Please fill to proceed' baseId={baseId} />
                <div
                    data-testid={testId}
                    className={`${
                        missingFields.length > 1 ? 'grid grid-cols-2 gap-4' : ''
                    }`}
                >
                    {missingFields.map((item, index) => {
                        return (
                            <div key={index} className=''>
                                {React.cloneElement(item, {
                                    showErrors: showErrors,
                                })}
                            </div>
                        );
                    })}
                </div>
                <div className='mt-4 mx-16'>
                    <Button onClick={handleClick} id={`${baseId}-button-close`}>
                        Close
                    </Button>
                </div>
            </ModalComponent>
        </>
    );
}
