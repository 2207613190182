import { DisplayComponent } from '@/components/DisplayComponent';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import { RemoteImage } from '@/components/RemoteImage';
import Ring from '@/components/Ring';
import { selectScores } from '@/context/borrower';
import { useAppSelector } from '@/context/storeHooks';

import { YourScoresButtons } from './YourScoresButtons';
import { YourScoresInfo } from './YourScoresInfo';

export const baseId = 'screen-yourScores';

export function YourScores(): JSX.Element {
    // **** Redux Store ****
    const scores = useAppSelector(selectScores);

    return (
        <DisplayComponent baseId={baseId} data-testid={baseId}>
            <Header />
            <ProgressBar />
            {/* Scores */}
            <>
                <p className='font-bold text-primary text-lg text-center pt-2 -mb-4'>
                    YOUR SCORES
                </p>
                <div className='h-full w-full overflow-x-hidden'>
                    <div className='relative w-full'>
                        {/* Inner Ring */}
                        <div className='w-full flex-center h-full absolute bottom-1 left-9'>
                            <Ring
                                width='75%'
                                height='75%'
                                path='M 44.6527 70.2904 C 40.7006 68.9843 37.2287 66.4802 34.7375 63.0975 C 31.7755 59.0755 30.4107 54.0978 30.9072 49.1274 C 31.4037 44.1571 33.7264 39.5476 37.4256 36.1911 C 41.1247 32.8345 45.9375 30.9694 50.9325 30.9568 C 55.9275 30.9441 60.7497 32.7848 64.4659 36.1226 C 68.182 39.4604 70.5278 44.058 71.0497 49.0258 C 71.5713 53.9936 70.2317 58.9783 67.2899 63.015 C 64.8161 66.4103 61.357 68.9321 57.4117 70.2581'
                                progress={scores.creditScorePercentage}
                                progressColor='#066BFC'
                                strokeWidth={10}
                                backgroundColor='rgba(6,107,252,0.1)'
                            />
                        </div>
                        {/* Middle Ring */}
                        <div className='w-full flex-center h-full absolute bottom-1 left-9'>
                            <Ring
                                width='75%'
                                height='75%'
                                path='M 43.2808 80.6699 C 36.8548 78.9733 31.1272 75.22 26.9994 69.9434 C 22.2676 63.8946 19.9845 56.2873 20.6037 48.6326 C 21.2229 40.9781 24.699 33.8365 30.3415 28.6269 C 35.9839 23.4174 43.3797 20.5212 51.0592 20.5138 C 58.7388 20.5063 66.1403 23.3882 71.7928 28.5868 C 77.4452 33.7854 80.9353 40.9202 81.5692 48.5737 C 82.2034 56.2271 79.9349 63.839 75.2148 69.8967 C 71.0973 75.1811 65.377 78.9457 58.9543 80.6548'
                                progress={scores.eligibilityScorePercentage}
                                progressColor='#8C2DBB'
                                backgroundColor='rgba(140, 45, 187, 0.1)'
                                strokeWidth={10}
                            />
                        </div>
                        {/* Outer Ring */}
                        <div className='w-full flex-center h-full absolute bottom-1 left-9'>
                            <Ring
                                width='75%'
                                height='75%'
                                path='M 42.5059 91.426 C 33.4532 89.485 25.2872 84.5278 19.3777 77.3179 C 12.7766 69.2641 9.4849 59.0017 10.1699 48.6109 C 10.8548 38.22 15.4651 28.4785 23.0661 21.3608 C 30.6672 14.2432 40.6903 10.2821 51.1036 10.2805 C 61.5169 10.2789 71.5414 14.237 79.1446 21.3523 C 86.7478 28.4676 91.3612 38.2078 92.0491 48.5983 C 92.7373 58.9888 89.4487 69.2524 82.8502 77.308 C 76.9429 84.52 68.7784 89.4796 59.7262 91.4236'
                                progress={scores.accuracyScorePercentage}
                                progressColor='#EB018D'
                                backgroundColor='rgba(235, 1, 141, 0.1)'
                                strokeWidth={10}
                            />
                        </div>
                        {/* Label Ring */}
                        <div className='relative w-full flex-center h-1/2'>
                            <RemoteImage
                                fileName='yourscores.svg'
                                className='h-[260px] w-[260px]'
                            />
                        </div>
                    </div>
                </div>
            </>
            {/* Explanation box */}
            <div className='w-full bg-white borderOutline rounded-xl p-4 mb-6'>
                <div className='_flex flex-col space-y-2'>
                    <YourScoresInfo
                        color='#066BFC'
                        title='CREDIT SCORE'
                        description='How likely you are to get a great Interest Rate based on your credit score.'
                    />
                    <YourScoresInfo
                        color='#8C2DBB'
                        title='ELIGIBILITY'
                        description='The likelihood of getting approved based on the application data you provided.'
                    />
                    <YourScoresInfo
                        color='#EB018D'
                        title='ACCURACY'
                        description='Our confidence level based on the information we were able to find out.'
                    />
                </div>
            </div>
            <YourScoresButtons baseId={baseId} />
        </DisplayComponent>
    );
}
