import { CheckBox } from '@/components/CheckBox';
import {
    EAgreements,
    IAgreements,
    selectAgreements,
    setAgreements,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

interface IInsuranceOptInProps {
    baseId: string;
}

export function InsuranceOptIn({ baseId }: IInsuranceOptInProps): JSX.Element {
    // ***** Redux State *****
    const agreements = useAppSelector(selectAgreements);
    const insuranceAgreement = agreements?.find(
        (agreement) => agreement.name === EAgreements.INSURANCE_OPT_IN
    );
    const dispatch = useAppDispatch();

    // ***** Handlers *****
    function handleCheckBoxClick(): void {
        const agreement: IAgreements = {
            name: EAgreements.INSURANCE_OPT_IN,
            version: '1.0',
            clauses: [
                {
                    clauseId: 'General Insurance Opt In',
                    agreed: !insuranceAgreement?.clauses[0].agreed,
                    date: new Date(),
                    agreementType: 'Checkbox',
                },
            ],
        };
        const newAgreements = agreements.filter(
            (agreement) => agreement.name !== EAgreements.INSURANCE_OPT_IN
        );

        newAgreements.push(agreement);

        dispatch(setAgreements(newAgreements));
    }

    return (
        <div className='_flex space-x-2 py-2'>
            <CheckBox
                id={`${baseId}-checkbox-insuranceOptIn`}
                onClick={handleCheckBoxClick}
                checked={insuranceAgreement?.clauses[0].agreed ?? false}
            />

            <div className='text-xs text-textBody'>
                I consent to be contacted in relation to vehicle and loan
                protection insurance and vehicle warranties.
            </div>
        </div>
    );
}
