import { Tooltip } from '@/components/Tooltip';
import { selectLoanTerm, setBalloon, setLoanTerm } from '@/context/borrower';
import { selectIsLoading } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
    maxTerm?: number;
}

export const testId = 'LoanTerm';

export function LoanTerm({
    baseId,
    showErrors,
    maxTerm = 7,
}: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectLoanTerm);
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoading);

    // ***** Event Handlers *****
    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const value = event.target.value;
        const intValue = getNumber(value, maxTerm);
        if (intValue === currentState) return;
        dispatch(setLoanTerm(intValue));
    }

    if (currentState > maxTerm) {
        dispatch(setBalloon(0));
    }

    const currentPercent = ((currentState - 1) / (maxTerm - 1)) * 100;

    // ***** Render *****
    return (
        <div className='pb-2'>
            <Tooltip content='The longer the term the lower the repayments.'>
                <h1 className='fieldHeading'>
                    What&apos;s your preferred loan term?
                </h1>
            </Tooltip>

            <div className='flex-center flex-col'>
                <label
                    className='font-bold text-lg pb-1'
                    htmlFor={`${baseId}-input-loanTerm`}
                >
                    {currentState} years
                </label>
                <div className='relative w-full h-1 rounded-full'>
                    <div
                        className='absolute top-0 h-full bg-primary rounded-full'
                        style={{ width: `${currentPercent}%` }}
                    ></div>
                    <div
                        className='absolute top-0 h-full bg-gray-300 rounded-full'
                        style={{
                            width: `${100 - currentPercent}%`,
                            left: `${currentPercent}%`,
                        }}
                    ></div>
                    <input
                        id={`${baseId}-input-loanTerm`}
                        type='range'
                        min={1}
                        max={maxTerm}
                        value={currentState}
                        className='absolute w-full h-full cursor-pointer range-sm top-1'
                        step='1'
                        onChange={handleChange}
                        disabled={isLoading ? true : false}
                        style={{
                            WebkitAppearance: 'none',
                            background: 'transparent',
                            outline: 'none',
                            height: '20px', // increase the height to match the height of the thumb
                            left: 0,
                        }}
                    />
                    <style>
                        {`
                        #${baseId}-input-loanTerm::-webkit-slider-thumb {
                            -webkit-appearance: none;
                            width: 20px;
                            height: 20px;
                            border-radius: 10px;
                            background: var(--primaryColor);
                            cursor: pointer;
                            margin-top: -8px;
                            position: relative;
                            z-index: 0;
                        }

                        #${baseId}-input-loanTerm::-moz-range-thumb {
                            width: 20px;
                            height: 20px;
                            border-radius: 10px;
                            background: var(--primaryColor);
                            cursor: pointer;
                            z-index: 2;
                        }
                    `}
                    </style>
                </div>
            </div>
        </div>
    );
}
