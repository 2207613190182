import { store } from '@/context/store';

export function isRealEstateUndefined(): boolean {
  const state = store.getState();
  const realEstates = state.borrowerReducer.realEstates.realEstates;
  if (realEstates.length === 0) return true;

  let isUndefined = false;
  realEstates.map((realEstate) => {
    if (realEstate.assetValue === undefined && !realEstate.isLoanPresent) {
      isUndefined = true;
    }
  });

  return isUndefined;
}
