export interface ISeller {
  type: ESellerType;
  name?: string | undefined;
  email?: string | undefined;
  phone?: string | undefined;
  contactName?: string | undefined;
  accountNumber?: string | undefined;
  abn?: string | undefined;
  leadEmail?: string | undefined;
  dealershipId?: string | undefined;
}

export enum ESellerType {
  DEALERSHIP = 'DEALERSHIP',
  PRIVATE = 'PRIVATE',
  UNKNOWN = 'UNKNOWN',
  SOLAR = 'SOLAR',
}

export const initialSellerState: ISeller = {
  type: ESellerType.DEALERSHIP,
};
