import { Input } from '@/components/Input';
import { selectOther, setOther } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'OtherAssets';

export function OtherAssets({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectOther);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        // MAXIMUM
        const MAXIMUM = 999999999;
        const intValue: number | undefined = getNumber(value, MAXIMUM);
        if (intValue && intValue > MAXIMUM) return;
        if (intValue === currentState) return;
        dispatch(setOther(intValue));
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Other'
                placeholder='$'
                showErrors={showErrors}
                onChange={handleChange}
                id={`${baseId}-input-otherSavings`}
                value={currentState?.toLocaleString() ?? ''}
                prefix='$'
                showNumpad={true}
                tooltipContent="The value of any other asset type that hasn't been accounted for"
            />
        </div>
    );
}
