import { DisplayButtons } from '@/components/DisplayButtons';
import { ELoanUsage, selectLoanUsage } from '@/context/borrower';
import {
    EDisplays,
    EModals,
    selectAgreedToTermsAndConditions,
    selectSkipExpenseScreen,
    setDisplay,
    setModal,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { selectIsLoggedIn } from '@/context/user';
import { EConversionType, reportConversion } from '@/utils/reportConversion';

interface servicingSuccessProps {
    baseId: string;
}

export function ServicingSuccessButtons({
    baseId,
}: servicingSuccessProps): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const loanUsage = useAppSelector(selectLoanUsage);
    const agreedToTermsAndConditions = useAppSelector(
        selectAgreedToTermsAndConditions
    );
    const skipExpenseScreen = useAppSelector(selectSkipExpenseScreen);

    // ***** Event Handlers *****
    function handleBackClick(): void {
        const backScreen = getBackScreen();
        dispatch(setDisplay(backScreen));
    }

    function handleNextClick(): void {
        const nextModal = getNextModal();
        const nextDisplay = getNextDisplay();
        reportConversion(EConversionType.INSTANT_PRE_APPROVAL);

        if (nextModal) {
            dispatch(setModal(nextModal));
        }
        if (nextDisplay) {
            dispatch(setDisplay(nextDisplay));
        }
    }

    // ***** Helpers ******
    function getBackScreen(): EDisplays {
        if (skipExpenseScreen) return EDisplays.INCOME;
        return EDisplays.EXPENSE;
    }

    function getNextModal(): EModals | void {
        if (!isLoggedIn) {
            return EModals.REGISTER;
        }
        if (
            isLoggedIn &&
            loanUsage === ELoanUsage.PERSONAL &&
            (!agreedToTermsAndConditions.isEquifaxVerificationExchangeAgreed
                ?.agreed ||
                !agreedToTermsAndConditions.isMarketingConsentAgreed?.agreed)
        ) {
            return EModals.EXPRESS_PRIVACY;
        }

        if (
            loanUsage === ELoanUsage.PERSONAL &&
            (!agreedToTermsAndConditions.isEquifaxVerificationExchangeAgreed
                ?.agreed ||
                !agreedToTermsAndConditions.isMarketingConsentAgreed?.agreed)
        ) {
            return EModals.EXPRESS_PRIVACY;
        }
    }

    function getNextDisplay(): EDisplays | void {
        if (isLoggedIn) return EDisplays.IDENTIFICATION;
        return;
    }

    return (
        <DisplayButtons
            idLeftButton={`${baseId}-button-back`}
            idRightButton={`${baseId}-button-next`}
            rightClick={handleNextClick}
            leftClick={handleBackClick}
        />
    );
}
