import { getUrl } from '@/apiCalls/fetch';

import { ILookupName, ILookupResponse } from './lookupBusinessNameTypes';

export async function lookupBusinessName(name: string): Promise<ILookupName[]> {
    const url = `/api/abnLookup/name/${name}`;

    const response = await getUrl<ILookupResponse>(url);

    return response.data;
}
