import { IBusiness } from '@/context/borrower';

import { ILookupAbnOrAcn } from '../commonTypes';
import { ILookupName } from '../lookupBusinessName';

/**
 * Takes the result of two api calls and converts it into one object.
 *
 * @param {ILookupName} nameLookup - The result of the name lookup.
 * @param {ILookupAbnOrAcn} abnLookup - The result of the abn lookup.
 *
 * @returns {IBusiness} - The business object.
 *
 * @comment This could be done in the API call, but I think it's better to keep the API calls as simple as possible. It could be changed into a general helper function if needed.
 *
 * @example const business = convertToBusinessObject(nameLookup, abnLookup);
 *
 * @author Nathan
 * @date 28-11-2022
 * @version 1.0
 */
export function convertAbnAndNameLookup(
  nameLookup: ILookupName,
  abnLookup: ILookupAbnOrAcn
): IBusiness {
  const business: IBusiness = {
    abn: abnLookup.Abn,
    name: nameLookup.Name,
    acn: abnLookup.Acn,
    entityTypeName: abnLookup.EntityTypeName,

    // Date comes in as YYYY-MM-DD format
    // We need to save it as DD-MM-YYYY format

    abnStatusEffectiveFrom: convertDateFormat(abnLookup.AbnStatusEffectiveFrom),
    gst: convertDateFormat(abnLookup.Gst),
  };

  return business;
}

function convertDateFormat(dateString: string): string {
  try {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  } catch (err) {
    return dateString;
  }
}
