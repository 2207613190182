import { useState } from 'react';

import { getBorrower } from '@/apiCalls/borrower';
import { Button, EButtonType } from '@/components/Button';
import { ModalComponent } from '@/components/ModalComponent';
import { RemoteImage } from '@/components/RemoteImage';
import {
    EDisplays,
    EModals,
    selectIsAssetKnownViaParams,
    setDisplay,
    setModal,
} from '@/context/display';
import { selectLenders } from '@/context/lender';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { selectIsLoggedIn } from '@/context/user';
import { getLowestWeeklyRepayment } from '@/utils/lenderUtils';

export const baseId = 'modal-Welcome';

export function Welcome(): JSX.Element {
    // ***** Redux *****
    const lenders = useAppSelector(selectLenders);
    const dispatch = useAppDispatch();
    const isVehicleKnown = useAppSelector(selectIsAssetKnownViaParams);
    const isLoggedIn = useAppSelector(selectIsLoggedIn);

    // ***** Variables *****
    const lowestPrice = getLowestWeeklyRepayment(lenders);

    // ***** Local State *****
    const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);

    // ***** Event Handlers *****
    function handleLeftButtonClick(): void {
        // Return User -> Login Modal
        dispatch(setModal(EModals.LOGIN));
    }

    function handleRightButtonClick(): void {
        // Get Finance -> Close Modal
        dispatch(setModal(null));
        const nextScreen = getNextScreen();
        getBorrower();
        dispatch(setDisplay(nextScreen));
    }

    // ***** Helper Functions ******
    function handleToggleDisclaimer(): void {
        setShowDisclaimer(!showDisclaimer);
    }

    function getNextScreen(): EDisplays {
        if (isVehicleKnown) {
            return EDisplays.BASIC;
        }
        return EDisplays.LOAN;
    }

    // ***** Render *****
    return (
        <ModalComponent baseId={baseId}>
            {/* Header Text */}
            <div className='_flex flex-col text-center'>
                <h3 className='font-bold text-2xl'>NEED FINANCE?</h3>
                <p className='text-xl'>
                    Get approved in 60 seconds
                    <br />
                    No impact on credit file
                </p>
            </div>
            {/* Keys */}
            <div className='w-full flex-center py-10'>
                <div className='h-40 w-40 bg-primary rounded-full relative '>
                    <RemoteImage
                        fileName='coins.svg'
                        className='absolute left-4 top-2'
                    />
                </div>
            </div>
            {/* Amount */}
            {lowestPrice && (
                <div
                    className='w-full flex-center text-center pb-4 relative'
                    onMouseEnter={handleToggleDisclaimer}
                    onMouseLeave={handleToggleDisclaimer}
                >
                    <h3 className='text-primary text-xl font-semibold'>
                        Own this vehicle for <br />
                        <span className='font-bold'>
                            ${lowestPrice} per week*
                        </span>
                    </h3>
                    <div
                        className={`${
                            showDisclaimer ? 'absolute' : 'hidden'
                        } bg-secondary -top-2 text-white p-2 rounded-lg w-60`}
                    >
                        <div className='absolute border-solid border-t-secondary border-t-8 border-x-transparent border-x-8 border-b-0 left-32 -bottom-1'></div>
                        <h3 className='text-xs'>
                            Indicated repayments are estimates only and are
                            subject to change.
                        </h3>
                    </div>
                </div>
            )}
            {/* Buttons */}
            <div className='w-full py-2 '>
                <div
                    className={`mx-auto w-full max-w-md _flex space-x-1 ${
                        isLoggedIn && 'px-10'
                    }`}
                >
                    {!isLoggedIn && (
                        <Button
                            onClick={handleLeftButtonClick}
                            type={EButtonType.OUTLINE}
                        >
                            <p>RETURN USER</p>
                        </Button>
                    )}
                    <Button onClick={handleRightButtonClick}>
                        <p>GET FINANCE</p>
                    </Button>
                </div>
            </div>
            {/* Images */}
            <div className='w-full text-center flex-center justify-between px-5 pt-5'>
                <div className='flex-center flex-col'>
                    <RemoteImage fileName='arrow.svg' className='' />
                    <p>
                        Direct to
                        <br />
                        lender
                    </p>
                </div>

                <div className='flex-center flex-col'>
                    <RemoteImage fileName='rackets.svg' className='' />
                    <p>
                        No hidden
                        <br />
                        fees
                    </p>
                </div>

                <div className='flex-center flex-col'>
                    <RemoteImage fileName='creditcard.svg' className='' />
                    <p>
                        Credit file
                        <br />
                        safe
                    </p>
                </div>
            </div>
        </ModalComponent>
    );
}
