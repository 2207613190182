import { IMotorVehicle } from '@/context/borrower';

export function isMotorVehicleAssetComplete(
  motorVehicle: IMotorVehicle
): boolean {
  if (motorVehicle.assetValue && !motorVehicle.isLoanPresent) return true;
  if (motorVehicle.assetValue && motorVehicle.isLoanPresent === undefined)
    return true;
  if (
    motorVehicle.assetValue === undefined &&
    motorVehicle.isLoanPresent === undefined
  )
    return true;
  if (
    motorVehicle.assetValue &&
    motorVehicle.isLoanPresent &&
    motorVehicle.lenderName &&
    motorVehicle.monthlyRepayment &&
    motorVehicle.loanBalance
  )
    return true;

  return false;
}
