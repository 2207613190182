import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/solid';
import QRCode from 'qrcode.react';

import { deleteDocument, postDocument } from '@/apiCalls/documents';
import { ocrLookup } from '@/apiCalls/ocrLookup';
import { uniqueIdTransfer } from '@/apiCalls/uniqueIDTransferHelper';
import {
    deleteSupportingDoc,
    ESupportingDocCodes,
    ISupportingDoc,
    setSupportingDocs,
} from '@/context/borrower';
import { setShowOcrDisplay } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';
import { selectLoginUrl } from '@/context/user';
import { formatSpacedStrings } from '@/utils/formatSpacedStrings';

import { CheckIconCircle } from '../CheckIconCircle';
import { EEventType } from '../eventType';
import { PlusIconCircle } from '../PlusIconCircle';
import { RemoteImage } from '../RemoteImage';

export interface IProps {
    baseId: string;
    showErrors: boolean;
    document: ISupportingDoc[] | null;
    code: string;
    value: string;
    expanded?: boolean;
    handleExpanded: () => void;
}

export const testId = 'DocumentUpload';

export function DocumentUpload({
    baseId,
    showErrors,
    document,
    code,
    value,
    expanded,
    handleExpanded,
}: IProps): JSX.Element {
    const firstDocument = document?.[0];

    // ***** Redux State *****
    const dispatch = useAppDispatch();

    const loginUrl = useSelector(selectLoginUrl);

    // ***** Local State *****
    const [uploadError, setUploadError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);

    // ***** Event Handlers *****
    function handleChange(value: React.ChangeEvent<HTMLInputElement>): void {
        const file: File | null = value.target?.files
            ? value.target.files[0]
            : null;

        if (file === null) return;

        setUploadError(false);

        uploadFile(file);
    }

    // ***** Helpers *****
    async function uploadFile(file: File): Promise<void> {
        setLoading(true);
        postDocument(code, [file])
            .then((response) => {
                dispatch(
                    setSupportingDocs({
                        code,
                        document: response,
                    })
                ); // Save Url to redux
                if (code === ESupportingDocCodes.DRIVERS_LICENSE_FRONT) {
                    dispatch(setShowOcrDisplay(true)); // Show OCR Display
                    ocrLookup(); // Call OCR API
                }
            })
            .catch((error) => {
                console.log(error);
                setUploadError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function handleDeleteClick(): void {
        setUploadError(false);
        const documentId = firstDocument?.id;
        if (!documentId) return;

        // Disable the document at the backend
        setDeleting(true);

        deleteDocument(documentId)
            .then(() => {
                dispatch(deleteSupportingDoc(documentId));
            })
            .catch((error: unknown) => {
                console.log(error);
            })
            .finally(() => {
                // Document delete sync need to be delayed to allow the SSE event to reconnect
                setTimeout(() => setDeleting(false), 500);
            });

        if (code === ESupportingDocCodes.DRIVERS_LICENSE_FRONT) {
            dispatch(setShowOcrDisplay(false)); // Show OCR Display
        }
    }

    function determineSymbol(): JSX.Element {
        if (firstDocument?.filename) {
            return <CheckIconCircle />;
        }

        if (expanded) {
            return (
                <div
                    className='h-5 w-5 border rounded-full p-1 border-[#BDBDBD] 1px solid transition-transform duration-500 ease-in-out'
                    id={`${baseId}-toggle-${formatSpacedStrings(value)}`}
                >
                    <ChevronUpIcon />
                </div>
            );
        }

        return (
            <div id={`${baseId}-toggle-${formatSpacedStrings(value)}`}>
                <PlusIconCircle />
            </div>
        );
    }

    function handleExpandedClick(): void {
        handleExpanded();
        uniqueIdTransfer({
            eventType: EEventType.TOGGLE,
            applicationData: {
                id: `${baseId}-toggle-${formatSpacedStrings(value)}`,
            },
        });
    }

    function handleClickDelete(): void {
        console.log(`${baseId}-remove-${formatSpacedStrings(value)}`);
        handleDeleteClick();
        uniqueIdTransfer({
            eventType: EEventType.TOGGLE,
            applicationData: {
                id: `${baseId}-remove-${formatSpacedStrings(value)}`,
            },
        });
    }

    // As the width is static, we trim by character length
    function trimLongFilename(name: string): string {
        if (name.length < 32) return name;
        return `${name.substring(0, 15)}...${name.substring(name.length - 15, name.length)}`;
    }

    // ***** Render *****
    return (
        <div data-testid={testId} className={`pt-4 ${(deleting || loading) && 'pointer-events-none'}`}>
            <div className='top-0 w-full _flex bg-opacity-10 border border-[#BDBDBD]  1px solid  rounded-lg'>
                <div
                    className={`${showErrors && 'error'} p-4 w-full m-auto rounded-lg`}
                >
                    <div
                        className='_flex justify-between items-center hover:cursor-pointer'
                        onClick={handleExpandedClick}
                    >
                        <h3 className='capitalize text-sm'>{value}</h3>
                        {determineSymbol()}
                    </div>
                    {expanded && firstDocument?.filename && (
                        <div className='_flex items-center justify-between space-x-2 w-full pt-3'>
                            <p title={firstDocument.filename} className='text-sm text-center text-secondary'>
                                {trimLongFilename(firstDocument.filename)}
                            </p>
                            <div
                                className='border rounded-full p-1 hover:cursor-pointer hover:scale-110'
                                onClick={handleClickDelete}
                            >
                                <XMarkIcon
                                    className='h-3 w-3 '
                                    id={`${baseId}-remove-${formatSpacedStrings(
                                        value
                                    )}`}
                                />
                            </div>
                        </div>
                    )}
                    {!firstDocument?.filename && expanded && (
                        <>
                            <div className='_flex gap-0.5 flex-row w-max mx-auto text-center hover:cursor-pointer'>
                                <label className='hover:cursor-pointer p-4'>
                                    <input
                                        className='text-sm cursor-pointer w-36 hidden'
                                        type='file'
                                        onChange={handleChange}
                                    />
                                    <div
                                        id={`${baseId}-upload-${formatSpacedStrings(
                                            value
                                        )}`}
                                    >
                                        <RemoteImage
                                            fileName='feather_upload.svg'
                                            className='w-20 h-20'
                                        />
                                    </div>

                                    <p className='font-bold'>Select file</p>
                                </label>
                                {!isMobile && loginUrl && (
                                    <label className='p-4'>
                                        <QRCode
                                            value={loginUrl}
                                            renderAs='svg'
                                            fgColor='#447CF9'
                                        />
                                    </label>
                                )}
                            </div>
                            {uploadError && (
                                <p className='text-sm text-center text-red-500'>
                                    Error, please try again
                                </p>
                            )}
                            {loading && (
                                <p className='text-sm text-center '>
                                    Uploading...
                                </p>
                            )}
                            {deleting && (
                                <p className='text-sm text-center '>
                                    Deleting...
                                </p>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
