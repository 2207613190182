import {
  EAddressOwnershipType,
  EBorrowerCitizenshipStatus,
  ECreditRating,
} from '@/context/borrower';

export interface ISolarPreapprovalRequest {
  ownershipType: EAddressOwnershipType;
  selfAssessedCreditRating: ECreditRating;
  previousAssetFinance: boolean;
  citizenshipStatus: EBorrowerCitizenshipStatus;
}

export interface ISolarPreapprovalReturn {
  verdict: ESolarPreapprovalVerdict;
}

export enum ESolarPreapprovalVerdict {
  APPROVED = 'APPROVED',
  REFERRED = 'REFERRED',
}
