import { Input } from '@/components/Input';
import { selectEducation, setEducation } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'Education';

export function Education({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectEducation);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        // MAXIMUM
        const MAXIMUM = 99999;
        const intValue = getNumber(value, MAXIMUM);
        if (intValue === currentState) return;
        dispatch(setEducation(intValue));
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Education'
                placeholder='Required'
                showErrors={
                    showErrors &&
                    (currentState === undefined || currentState === null)
                }
                onChange={handleChange}
                id={`${baseId}-input-education`}
                value={currentState?.toLocaleString() ?? ''}
                prefix='$'
                showNumpad={true}
                tooltipContent='This includes school fees, books, uniforms, stationery, tutoring, etc.'
            />
        </div>
    );
}
