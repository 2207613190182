import { PayloadAction } from '@reduxjs/toolkit';

import { ECreditRating, ELoanType, EState } from '../types';

export const solarReducers = {
  setPreviousAssetFinance: (
    state,
    action: PayloadAction<boolean | undefined>
  ): void => {
    state.solar.previousAssetFinance = action.payload;
  },
  setProvideBankStatements: (
    state,
    action: PayloadAction<boolean | undefined>
  ): void => {
    state.solar.canProvideBankStatements = action.payload;
  },

  setSelfAssessedCreditRating: (
    state,
    action: PayloadAction<ECreditRating | undefined>
  ): void => {
    state.solar.selfAssessedCreditRating = action.payload;
  },
  // Solar product details
  setSolarRenewableEnergyEquipment: (
    state,
    action: PayloadAction<string>
  ): void => {
    state.solar.solarRenewableEnergyEquipment = action.payload;
  },
  setLoanType: (state, action: PayloadAction<ELoanType>): void => {
    state.loan.loanType = action.payload;
  },

  // solar lender specific questions
  setAnnualRevenue: (
    state,
    action: PayloadAction<number | undefined>
  ): void => {
    state.business.annualRevenue = action.payload;
  },
  setPrimaryIndustry: (
    state,
    action: PayloadAction<string | undefined>
  ): void => {
    state.business.primaryIndustry = action.payload;
  },
  setPrimaryBusinessUnitNumber: (
    state,
    action: PayloadAction<string | undefined>
  ): void => {
    state.business.primaryBusinessUnitNumber = action.payload;
  },

  setPrimaryBusinessStreetNumber: (
    state,
    action: PayloadAction<string | undefined>
  ): void => {
    state.business.primaryBusinessStreetNumber = action.payload;
  },
  setPrimaryBusinessStreetName: (
    state,
    action: PayloadAction<string | undefined>
  ): void => {
    state.business.primaryBusinessStreetName = action.payload;
  },
  setPrimaryBusinessSuburbName: (
    state,
    action: PayloadAction<string | undefined>
  ): void => {
    state.business.primaryBusinessSuburbName = action.payload;
  },
  setPrimaryBusinessPostCode: (
    state,
    action: PayloadAction<number | undefined>
  ): void => {
    state.business.primaryBusinessPostCode = action.payload;
  },
  setPrimaryBusinessStateName: (
    state,
    action: PayloadAction<EState | undefined>
  ): void => {
    state.business.primaryBusinessStateName = action.payload;
  },
};
