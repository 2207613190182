import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { ocrLookup } from '@/apiCalls/ocrLookup';
import { DisplayButtons } from '@/components/DisplayButtons';
import {
    ELoanUsage,
    ESupportingDocCodes,
    selectLicenseNumber,
    selectLoanUsage,
    selectSupportingDocs,
} from '@/context/borrower';
import {
    EDisplays,
    EJourneys,
    selectInitialJourney,
    selectShowOcrDisplay,
    selectSkipExpenseScreen,
    setDisplay,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

interface IIdentificationButtonsProps {
    setShowErrors: (value: boolean) => void;
    baseId?: string;
}

export function IdentificationButtons({
    baseId,
    setShowErrors,
}: IIdentificationButtonsProps): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const showOcr = useAppSelector(selectShowOcrDisplay);
    const loanUsage = useAppSelector(selectLoanUsage);
    const supportingDocs = useAppSelector(selectSupportingDocs);
    const initialJourney = useAppSelector(selectInitialJourney);
    const licenseNumber = useAppSelector(selectLicenseNumber);
    const skipExpenseScreen = useAppSelector(selectSkipExpenseScreen);

    // ***** Event Handlers *****
    function handleBackClick(): void {
        const backScreen = getBackScreen();
        dispatch(setDisplay(backScreen));
    }

    function handleNextClick(): void {
        setShowErrors(true);
        if (!isComplete()) return;
        createOrUpdateBorrower();
        const nextScreen = getNextScreen();
        dispatch(setDisplay(nextScreen));
    }

    function isComplete(): boolean {
        if (
            !supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_BACK] ||
            (supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_BACK] &&
                supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_BACK]
                    .length === 0)
        ) {
            return false;
        }
        if (
            !supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_FRONT] ||
            (supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_FRONT] &&
                supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_FRONT]
                    .length === 0)
        ) {
            return false;
        }

        return true;
    }

    function getNextScreen(): EDisplays {
        if (
            // Front licence uploaded, no licence number = OCR has not been run
            // TODO: This is a hack to get around uploading from phone / qr session because showOcr is display state which is separate for web and phone sessions
            supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_FRONT] &&
            supportingDocs[ESupportingDocCodes.DRIVERS_LICENSE_FRONT].length >
                0 &&
            !licenseNumber
        ) {
            ocrLookup(); // Call OCR API
            return EDisplays.OCR_ANIMATION;
        }

        if (showOcr) {
            return EDisplays.OCR_ANIMATION;
        }

        if (initialJourney === EJourneys.TEST_DRIVE) {
            return EDisplays.TEST_DRIVE_OCR_SUMMARY;
        }

        return EDisplays.OCR_SUMMARY;
    }

    function getBackScreen(): EDisplays {
        if (initialJourney === EJourneys.TEST_DRIVE) {
            return EDisplays.TEST_DRIVE_LANDING_PAGE;
        }

        if (loanUsage === ELoanUsage.BUSINESS) {
            return EDisplays.BASIC;
        }

        if (skipExpenseScreen) return EDisplays.INCOME;

        return EDisplays.EXPENSE;
    }

    return (
        <DisplayButtons
            idLeftButton={`${baseId}-button-back`}
            idRightButton={`${baseId}-button-next`}
            rightClick={handleNextClick}
            leftClick={handleBackClick}
        />
    );
}
