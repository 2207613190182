// eslint-disable-next-line simple-import-sort/imports
import moment from 'moment';
import { ChangeEvent } from 'react';

import { trimClassname } from '@/utils/styleHelper';
import { uniqueIdTransfer } from '@/apiCalls/uniqueIDTransferHelper';
import { EEventType } from '../eventType';
import { Tooltip } from '../Tooltip';

export interface IDateInput {
    title: string;
    placeholder: string;
    onChange: (value: Date) => void;
    showErrors: boolean;
    id?: string;
    disabled?: boolean;
    value?: Date;
    minDate?: Date;
    maxDate?: Date;
    dateFormat?: string;
    tooltipContent?: string;
}

export function DateInput({
    title,
    placeholder,
    id,
    value,
    showErrors,
    disabled = false,
    onChange,
    minDate,
    maxDate,
    dateFormat = 'YYYY-MM-DD',
    tooltipContent,
}: IDateInput): JSX.Element {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        let date = new Date(value);

        if (maxDate && date > maxDate) {
            date = maxDate;
        }

        onChange(date);

        uniqueIdTransfer({
            eventType: EEventType.INPUT,
            applicationData: { id: id },
        });
    };

    function getDisplayValue(): string {
        if (!value) return '';
        if (maxDate && value > maxDate)
            return moment(maxDate).format(dateFormat);
        return moment(value).format(dateFormat);
    }

    return (
        <div data-testid='input' className='py-2 mx-auto w-full max-w-md'>
            <Tooltip content={tooltipContent ?? ''}>
                <h1 className='fieldHeading'>{title}</h1>
            </Tooltip>
            <input
                className={trimClassname(
                    `appearance-none border rounded-[10px] mx-auto w-full max-w-md p-2 text-textPrimary leading-tight h-8 inputButtonFocus placeholderStyle text-center ${
                        showErrors ? errorClass : ''
                    } ${
                        disabled
                            ? 'hover:none cursor-disabled'
                            : 'hover:shadow '
                    }`
                )}
                id={id}
                type='date'
                data-testid='inputField'
                placeholder={placeholder}
                value={getDisplayValue()}
                onChange={handleChange}
                disabled={disabled}
                min={minDate ? moment(minDate).format(dateFormat) : undefined}
                max={maxDate ? moment(maxDate).format(dateFormat) : undefined}
                pattern={dateFormat}
            />
        </div>
    );
}
export const errorClass = 'error';
