import { useState } from 'react';

import { DisplayComponent } from '@/components/DisplayComponent';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import {
    selectIsVehicleRegistrationKnown,
    selectVehicleAssetType,
} from '@/context/borrower';
import { useAppSelector } from '@/context/storeHooks';
import { RegoKnown } from '@/UserInput/RegoKnown';
import { isCar } from '@/utils/glassGuideHelpers';
import { userFacingAssetTitle } from '@/utils/userFacingAssetTitle';

import { VehicleButtons } from './VehicleButtons';
import { VehicleDetailsRegoKnown } from './VehicleDetailsRegoKnown';
import { VehicleDetailsRegoUnKnown } from './VehicleDetailsRegoUnknown';

export const testId = 'Vehicle';

export function Vehicle(): JSX.Element {
    // ***** CONSTANTS *****
    const baseId = 'screen-vehicleDetails';
    const isRegistrationKnown = useAppSelector(
        selectIsVehicleRegistrationKnown
    );
    const assetType = useAppSelector(selectVehicleAssetType);
    const [showErrors, setShowErrors] = useState(false);
    const assetTitle = userFacingAssetTitle();

    return (
        <DisplayComponent baseId={baseId} data-testid={testId}>
            <Header />
            <ProgressBar />
            <DisplayHeading
                title={`Select ${assetTitle}`}
                infoText='Select your vehicle below to get an accurate quote'
            />
            {isCar(assetType) && (
                <RegoKnown showErrors={false} baseId={baseId} />
            )}
            {isRegistrationKnown ? (
                <VehicleDetailsRegoKnown showErrors={showErrors} />
            ) : (
                <VehicleDetailsRegoUnKnown showErrors={showErrors} />
            )}
            <VehicleButtons setShowErrors={setShowErrors} baseId={baseId} />
        </DisplayComponent>
    );
}
