export interface ILoan {
    loanUsage: ELoanUsage;
    purchasePrice?: number | undefined;
    tradeIn?: number | undefined;
    deposit?: number | undefined;
    balloon?: number | undefined;
    loanTerm: number;
    financeType: EQuickQuoteFinanceType;
    saleType: EApplicationAssetSaleType;
    assetSituation: EApplicationAssetSituation;
    loanType: ELoanType;
}

export enum EApplicationAssetSaleType {
    PRIVATE = 'PRIVATE',
    DEALERSHIP = 'DEALERSHIP',
    ASSET_OWNER = 'ASSET_OWNER',
    BALLOON_REFINANCE = 'BALLOON_REFINANCE',
    MID_TERM_FINANCE = 'MID_TERM_FINANCE',
}

export enum EApplicationAssetSituation {
    ASSET_KNOWN = 'ASSET_KNOWN',
    PRE_APPROVAL = 'PRE_APPROVAL',
    ASSET_OWNER = 'ASSET_OWNER',
}

export enum EQuickQuoteFinanceType {
    CONSUMER_SECURED = 'CONSUMER', // Value is as ME Expects
    COMMERCIAL_LOW_DOC = 'COMMERCIAL_LOW_DOC',
    COMMERCIAL_FULL_DOC = 'COMMERCIAL_FULL_DOC',
    COMMERCIAL_ALT_DOC = 'COMMERCIAL_ALT_DOC',
    COMMERCIAL_LOW_DOC_LEASE = 'COMMERCIAL_LEASE', // Value is as ME Expects
    COMMERCIAL_FULL_DOC_LEASE = 'COMMERCIAL_LEASE_FULL_DOC',
    COMMERCIAL_ALT_DOC_LEASE = 'COMMERCIAL_LEASE_ALT_DOC',
    PERSONAL_USE = 'PERSONAL_USE',
    PAYG_CHATTEL_MORTGAGE = 'PAYG_CHATTEL_MORTGAGE',
    CONSUMER_UNSECURED = 'CONSUMER_UNSECURED',
    BUSINESS_USE = 'BUSINESS_USE',
    COMMERCIAL_START_UP = 'COMMERCIAL_START_UP',
    COMMERCIAL_REP_LEND = 'COMMERCIAL_REP_LEND',
}

export enum EMoneyType {
    DOLLAR = 'DOLLAR',
    PERCENTAGE = 'PERCENTAGE',
}

export enum ELoanType {
    LEASE_RENTAL = 'LEASE_RENTAL',
    CHATTEL_MORTGAGE = 'CHATTEL_MORTGAGE',
}

export enum ELoanUsage {
    PERSONAL = 'PERSONAL',
    BUSINESS = 'BUSINESS',
}

export const initialLoan: ILoan = {
    loanUsage: ELoanUsage.PERSONAL,
    loanTerm: 5,
    financeType: EQuickQuoteFinanceType.CONSUMER_SECURED,
    saleType: EApplicationAssetSaleType.DEALERSHIP,
    assetSituation: EApplicationAssetSituation.ASSET_KNOWN,
    loanType: ELoanType.CHATTEL_MORTGAGE,
    purchasePrice: 0,
};
