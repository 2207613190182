import { PayloadAction } from '@reduxjs/toolkit';

import { ITestDrive } from '../types';

export const testDriveReducers = {
    setTestDrives: (state, action: PayloadAction<ITestDrive[]>): void => {
        state.testDrives.testDrives = action.payload;
    },
    setTestDriveByIndex: (
        state,
        action: PayloadAction<{ index: number; testDrive: ITestDrive }>
    ): void => {
        state.testDrives.testDrives[action.payload.index] =
            action.payload.testDrive;
    },
    setTestDriveActiveIndex: (
        state,
        action: PayloadAction<number | undefined>
    ): void => {
        state.testDrives.currentTestDriveIndex = action.payload;
    },
    setTestDriveDate: (state, action: PayloadAction<Date>): void => {
        state.testDrives.testDrives[
            state.testDrives.currentTestDriveIndex
        ].date = action.payload;
    },
    setTestDriveTime: (state, action: PayloadAction<Date>): void => {
        state.testDrives.testDrives[
            state.testDrives.currentTestDriveIndex
        ].time = action.payload;
    },
    setAgreedToTestDriveAgreement: (
        state,
        action: PayloadAction<boolean>
    ): void => {
        state.testDrives.testDrives[
            state.testDrives.currentTestDriveIndex
        ].agreedToTestDriveAgreement = action.payload;
    },
    setTestDriveProceededToFinance: (
        state,
        action: PayloadAction<boolean>
    ): void => {
        state.testDrives.testDrives[
            state.testDrives.currentTestDriveIndex
        ].proceededToFinance = action.payload;
    }
};
