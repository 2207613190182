import { getUrl } from '../fetch';

import {
  IGetLoginViaCode,
  IGetLoginViaCodeResponse,
} from './loginViaCodeTypes';

export async function loginViaCode(
  code: string | undefined
): Promise<IGetLoginViaCode | undefined> {
  const url = `/api/urlShortener/login/${code}`;
  return (await getUrl<IGetLoginViaCodeResponse>(url)).data;
}
