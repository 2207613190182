import { selectPartner, selectPartnerName } from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';
import { TRUCKSTOP_PARTNER_NAME } from '@/partners/truckstop';

export function Slogan(): JSX.Element {
    const { showQuickQuote } = useAppSelector(selectPartner);
    const partnerName = useAppSelector(selectPartnerName);
    const isTruckstop = partnerName === TRUCKSTOP_PARTNER_NAME;

    if (isTruckstop) {
        return (
            <p className='text font-bold uppercase text-center pt-2'>
                Conditional approval in {'<'} 2 mins
                <br />
                for free
            </p>
        );
    }

    return (
        <p className='text font-bold text-sm'>
            Instant Finance Tailored For You.
            <br />
            Try It Now!
        </p>
    );
}
