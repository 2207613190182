import { PayloadAction } from '@reduxjs/toolkit';

import { IPersonalLoan } from '../types';

export const personalLoansReducers = {
  setPersonalLoanByIndex: (
    state,
    action: PayloadAction<{ index: number; personalLoan: IPersonalLoan }>
  ): void => {
    if (state.personalLoans.personalLoans === null) return;

    state.personalLoans.personalLoans[action.payload.index] =
      action.payload.personalLoan;
  },
  setPersonalLoanActiveIndex: (state, action: PayloadAction<number>): void => {
    let length = action.payload;
    const min = 0;
    if (length < min) {
      length = min;
    }
    state.personalLoans.personalLoanActiveIndex = length;
  },

  setPersonalLoans: (
    state,
    action: PayloadAction<{
      personalLoans: IPersonalLoan[];
      personalLoanActiveIndex: number;
    }>
  ): void => {
    state.personalLoans.personalLoans = action.payload.personalLoans;
    state.personalLoans.personalLoanActiveIndex =
      action.payload.personalLoanActiveIndex;
  },
  deletePersonalLoan: (state): void => {
    if (
      state.personalLoans.personalLoans?.length === 1 &&
      state.personalLoans.personalLoanActiveIndex === 0
    ) {
      state.personalLoans.personalLoans = [];
      return;
    }
    state.personalLoans.personalLoans?.splice(
      state.personalLoans.personalLoanActiveIndex,
      1
    );
  },
};
