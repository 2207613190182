import { IMAGE_URL } from '@/utils/constants';

export interface RemoteImageProps {
  className?: string;
  fileName: string;
}

export function RemoteImage({
  className,
  fileName,
}: RemoteImageProps): JSX.Element {
  return <img className={className} src={`${IMAGE_URL}/${fileName}`} />;
}
