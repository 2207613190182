import { Input } from '@/components/Input';
import { selectFirstName, setFirstName } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { NAME_REGEX } from '@/utils/constants';
import { setDemoMode } from '@/utils/setDemoMode';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'FirstName';

export function FirstName({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectFirstName);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        if (typeof value === 'boolean') return;
        if (value === currentState) return;
        if (value?.length > 12) return;
        if (!NAME_REGEX.test(value)) return;

        setDemoMode(value);

        dispatch(setFirstName(value));
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='First Name'
                placeholder='Type your first name'
                showErrors={showErrors && !currentState}
                onChange={handleChange}
                id={`${baseId}-input-firstName`}
                value={currentState?.toLocaleString() ?? ''}
            />
        </div>
    );
}
