import { setTradeIn } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialLoanTradeInAmount(
    parameterTradeIn: IParameter
): void {
    const currentTradeIn = store.getState().borrowerReducer.loan.tradeIn;

    const parsedParameter = Number(parameterTradeIn);
    if (!isNaN(parsedParameter) && parsedParameter > 0) {
        if (currentTradeIn !== parsedParameter) {
            store.dispatch(setTradeIn(parsedParameter));
            return;
        }
    }
}
