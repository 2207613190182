import { getUrl } from '@/apiCalls/fetch';
import { EVehicleAssetType } from '@/context/borrower';
import { getGlassAssetType } from '@/utils/glassGuideHelpers';

import { IBuildYear } from './getBuildYearTypes';

export async function getBuildYear(
    partner: string,
    assetType?: EVehicleAssetType
): Promise<string[]> {
    const url = `/api/asset/select/${partner}/${getGlassAssetType(assetType)}`;
    const response = await getUrl<IBuildYear>(url);
    return response.data;
}
