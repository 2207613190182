import { useSelector } from 'react-redux';

import { Input } from '@/components/Input';
import {
    IPersonalLoan,
    selectPersonalLoanActiveIndex,
    selectPersonalLoanByIndex,
    setPersonalLoanByIndex,
} from '@/context/borrower';
// eslint-disable-next-line no-restricted-imports
// eslint-disable-next-line no-restricted-imports
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
    setShowErrors: (value: boolean) => void;
}

export const testId = 'PersonalLoanMonthlyRepayment';

export function PersonalLoanMonthlyRepayment({
    baseId,
    showErrors,
    setShowErrors,
}: IProps): JSX.Element {
    // ***** Redux *****
    const activeCardIndex = useAppSelector(selectPersonalLoanActiveIndex);
    const personalLoan = useSelector((state: RootState) =>
        selectPersonalLoanByIndex(state, activeCardIndex)
    );
    const monthlyRepayment = personalLoan?.monthlyRepayment;
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        setShowErrors(false);
        if (typeof value !== 'string') return;
        const MAXIMUM = 999999;

        let intValue: number | undefined = getNumber(value, MAXIMUM);
        if (intValue === 0) intValue = undefined;
        const newPersonalLoan: IPersonalLoan = {
            ...personalLoan,
            monthlyRepayment: intValue,
        };

        if (intValue && isNaN(intValue)) {
            newPersonalLoan.monthlyRepayment = undefined;
        }
        dispatch(
            setPersonalLoanByIndex({
                index: activeCardIndex,
                personalLoan: newPersonalLoan,
            })
        );
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Monthly Repayment'
                placeholder='$2000'
                showErrors={showErrors && !monthlyRepayment}
                onChange={handleChange}
                id={`${baseId}-input-monthlyRepayment`}
                value={monthlyRepayment?.toLocaleString() ?? ''}
                prefix='$'
                showNumpad={true}
            />
        </div>
    );
}
