import { EState } from './commonTypes';

export interface IAddressState {
  address: IAddress[];
  addressIndex: number;
}

export interface IAddress {
  unitNumber?: string | undefined;
  streetNumber?: string | undefined;
  streetName?: string | undefined;
  suburb?: string | undefined;
  state?: EState | undefined;
  postcode?: string | undefined;
  ownershipType?: EAddressOwnershipType | undefined;
  years?: number | undefined;
  months?: number | undefined;
}

export enum EAddressOwnershipType {
  OWNER = 'OWNER',
  MORTGAGE = 'MORTGAGE',
  RENTING = 'RENTING',
  BOARDING = 'BOARDING',
  LIVING_WITH_RELATIVES = 'LIVING_WITH_RELATIVES',
  OTHER = 'OTHER',
}

export const initialAddress: IAddress[] = [{}];
