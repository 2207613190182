import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { fetchLead } from '@/apiCalls/lead';
import {
    EState,
    setAddressByIndex,
    setEmail,
    setFirstName,
    setLastName,
    setMobile,
} from '@/context/borrower';
import { store } from '@/context/store';
import {
    ELocalStorageVariables,
    getLocalStorageItem,
    setLocalStorageItem,
} from '@/utils/localStorage';

export function setLead(leadToken: string | null): void {
    if (!leadToken) return;

    // Fetch the lead data from the backend
    fetchLead(leadToken).then((data) => {
        if (!data) return;
        store.dispatch(setFirstName(data.firstName));
        store.dispatch(setLastName(data.lastName));
        store.dispatch(setEmail(data.email));
        store.dispatch(setMobile(data.mobilePhone));
        store.dispatch(
            setAddressByIndex({
                index: 0,
                address: {
                    unitNumber: data.unitNumber?.toString(),
                    streetNumber: data.streetNumber?.toString(),
                    streetName: data.streetName,
                    suburb: data.suburb,
                    state: data.state as EState,
                    postcode: data.postcode?.toString(),
                },
            })
        );

        setLocalStorageItem(
            ELocalStorageVariables.FIRST_NAME,
            data.firstName || ''
        );

        if (getLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN)) {
            createOrUpdateBorrower();
        }
    });
}
