import { Input } from '@/components/Input';
import { selectMiddleName, setMiddleName } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'MiddleName';

export function MiddleName({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectMiddleName);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        if (typeof value === 'boolean') return;
        if (value === currentState) return;
        if (value?.length > 12) return;
        dispatch(setMiddleName(value));
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Middle Name'
                placeholder='Type your middle name'
                showErrors={showErrors && !currentState}
                onChange={handleChange}
                id={`${baseId}-input-middleName`}
                value={currentState?.toLocaleString() ?? ''}
            />
        </div>
    );
}
