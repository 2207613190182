export interface IBusiness {
    manualEntry?: boolean | undefined;
    abn?: string | undefined;
    abnStatus?: string | undefined;
    isCurrent?: boolean | undefined;
    name?: string | undefined;
    nameType?: string | undefined;
    postcode?: string | undefined;
    state?: string | undefined;
    abnStatusEffectiveFrom?: string | undefined;
    acn?: string | undefined;
    addressDate?: string | undefined;
    addressPostcode?: string | undefined;
    addressState?: string | undefined;
    entityName?: string | undefined;
    entityTypeCode?: string | undefined;
    entityTypeName?: string | undefined;
    gst?: string | undefined;
    message?: string | undefined;
    numberOfDirectors?: number | undefined;
    trusteeName?: string | undefined;
    previousAbn?: boolean | undefined;
    previousBusiness?: IBusiness | undefined;
    industry?: string | undefined;
    annualRevenue?: number | undefined;
    primaryIndustry?: string | undefined;
    primaryBusinessUnitNumber?: string | undefined;
    primaryBusinessStreetNumber?: string | undefined;
    primaryBusinessStreetName?: string | undefined;
    primaryBusinessSuburbName?: string | undefined;
    primaryBusinessPostCode?: number | undefined;
    primaryBusinessStateName?: string | undefined;
    trusteeType?: ETrusteeType | undefined;
}

export const initialBusiness: IBusiness = {};

export enum ETrusteeType {
    COMPANY = 'COMPANY',
    INDIVIDUAL = 'INDIVIDUAL',
}
