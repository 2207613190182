export function calculateLoanAmount(
    purchasePrice: number | undefined,
    deposit: number | undefined,
    tradeIn: number | undefined,
): number {
    console.log('purchasePrice', purchasePrice);
    console.log('deposit', deposit);
    console.log('tradeIn', tradeIn);
        const loanAmount = purchasePrice
        ? purchasePrice - (deposit || 0) - (tradeIn || 0)
        : 0;

    return loanAmount;
}
