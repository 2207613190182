import { ELoanUsage } from '@/context/borrower';
import { EDisplays } from '@/context/display';
import { store } from '@/context/store';

import { isGlassLookupPossible } from '../isGlassLookupPossible';

// This is a helper function because the logic is the same for the next button on both no lender home screen and loan screen
export function loanGetNextScreen(): EDisplays {
    const state = store.getState();
    const {
        isAssetKnownViaParams,
        hideVehicleScreen,
        hideSellerScreen,
        vehicleManualEntry,
    } = state.displayReducer;
    const { isVehicleKnown } = state.borrowerReducer.vehicle;
    const { loanUsage } = state.borrowerReducer.loan;

    if (isAssetKnownViaParams && hideVehicleScreen) {
        if (!hideSellerScreen) {
            return EDisplays.SELLER;
        }
        return loanUsage === ELoanUsage.BUSINESS
            ? EDisplays.BUSINESS
            : EDisplays.BASIC;
    }

    if (isVehicleKnown) {
        if (vehicleManualEntry) {
            // User has gone KNOWN VEHICLE -> Do not know rego -> Cannot find it on glass guide -> Manual entry
            // This will only occur if user goes through above process and then clicks back to loan screen
            return EDisplays.MANUAL_VEHICLE;
        }

        const glassLookupPossible = isGlassLookupPossible();

        if (glassLookupPossible) {
            return EDisplays.VEHICLE;
        }

        return EDisplays.MANUAL_VEHICLE;
    }

    return loanUsage === ELoanUsage.BUSINESS
        ? EDisplays.BUSINESS
        : EDisplays.BASIC;
}
