import { IEmployment } from '@/context/borrower';

export function isEmploymentComplete(employment: IEmployment): boolean {
  const {
    occupation,
    employerName,
    employerPhone,
    years: employmentYears,
    months: employmentMonths,
  } = employment;

  console.log(employerPhone, 'employerPhone');
  if (occupation === null || occupation === undefined) return false;
  if (employerName === null || employerName === undefined) return false;
  if (
    employerPhone === null ||
    employerPhone === undefined ||
    employerPhone.length < 10
  )
    return false;

  if (
    employmentYears === null ||
    (employmentYears === undefined &&
      (employmentMonths === null || employmentMonths === undefined))
  )
    return false;
  return true;
}
