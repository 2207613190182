import { postUrl } from '@/apiCalls/fetch';
import { EModals } from '@/context/display';
import { store } from '@/context/store';

import { EVerificationResponseStatus } from '../verificationCommonTypes';

import { ISendSmsResponse } from './sendSmsCodeTypes';

export async function sendSmsCode(
    mobilePhone: string,
    signUp?: boolean
): Promise<EVerificationResponseStatus | undefined> {
    try {
        const state = store.getState();

        const url = '/api/verify/requestVerification';
        const sellerName = state.borrowerReducer.seller.name ?? '';
        const sellerNameFromVehicle = state.borrowerReducer.vehicle.source;

        let partner: string | null = null;
        if (sellerName === 'Truckstop') {
            partner = 'ODIN_FINANCE';
        } else {
            partner = sellerNameFromVehicle
                ? sellerNameFromVehicle
                : sellerName;
        }

        let modal = state.displayReducer.modal;
        if (signUp) {
            modal = EModals.REGISTER;
        }

        const body = { mobilePhone, partner, modal };

        const response = await postUrl<ISendSmsResponse>(url, body);

        return response.status;
    } catch (error) {
        return undefined;
    }
}
