import { ILender } from '@/context/lender';

export interface IPreApprovalResponse extends Response {
    verdict: EPreApprovalVerdicts;
    approvedLenders: ILender[];
    creditScore: number | null;
    creditScorePercentage: number;
    eligibilityScorePercentage: number;
    accuracyScorePercentage: number;
    onePointOneScore: number | null;
    comprehensiveScore: number | null;
    oneScore: number | null;
    illionScore: number | null;
}

export enum EPreApprovalVerdicts {
    APPROVED = 'APPROVED',
    REFERRED = 'REFERRED',
    DECLINED = 'DECLINED',
}
