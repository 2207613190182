import { useRef } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/solid';

import { Tooltip } from '../Tooltip';

interface IDisplayHeading {
    title: string;
    subtitle?: string;
    infoText?: string;
}

function DisplayHeading({
    title,
    infoText,
    subtitle,
}: IDisplayHeading): JSX.Element {
    const headingRef = useRef<HTMLDivElement>(null);
    const solar = false; // todo

    return (
        <>
            <div
                ref={headingRef}
                className={`px-4 pt-2 max-w-md _flex items-center ${
                    !solar ? 'justify-center' : 'justify-between'
                }  relative`}
            >
                <div />
                <h3 className='fieldHeading text-[18px] font-bold text-[#201C1F] text-left pt-2'>
                    {title}
                </h3>

                <Tooltip content={infoText ?? ''} maxWidth='1000%' isHeading>
                    <InformationCircleIcon height={20} width={20} />
                </Tooltip>
            </div>
            {subtitle && (
                <div
                    className={`text-[12px] text-gray-200 font-semibold text-center`}
                >
                    {subtitle}
                </div>
            )}
        </>
    );
}

export { DisplayHeading };
