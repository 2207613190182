import { setPartner } from '@/context/display';
import { store } from '@/context/store';
import { IPartnerParams } from '@/partners/partnerTypes';

export function setInitialPartner(partner: IPartnerParams | null): void {
  if (!partner) {
    return;
  }

  const currentState = store.getState().displayReducer.partner;
  if (partner !== currentState) {
    store.dispatch(setPartner(partner));
  }
}
