import { RadioGroup } from '@/components/RadioGroup';
import {
  selectCanProvideBankStatements,
  setProvideBankStatements,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getBoolean } from '@/utils/valueFormat';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'ProvideBankStatements';

export function ProvideBankStatements({
  baseId,
  showErrors,
}: IProps): JSX.Element {
  // ***** Redux *****
  const currentState = useAppSelector(selectCanProvideBankStatements);
  const dispatch = useAppDispatch();

  // ***** Event Handlers *****
  function handleChange(value: string | boolean | undefined): void {
    const booleanValue = getBoolean(value);
    dispatch(setProvideBankStatements(booleanValue));
    if (!booleanValue) dispatch(setProvideBankStatements(false));
  }

  // ***** Render *****
  const options = [
    {
      label: 'Yes',
      value: true,
      id: `${baseId}-button-provideBankStatements-yes`,
    },
    {
      label: 'No',
      value: false,
      id: `${baseId}-button-provideBankStatements-no`,
    },
  ];
  return (
    <div data-testid={testId}>
      <RadioGroup
        title='Can you provide bank statements?'
        showErrors={showErrors}
        onChange={handleChange}
        options={options}
        value={currentState}
      />
    </div>
  );
}
