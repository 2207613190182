import { useState } from 'react';

import { DisplayComponent } from '@/components/DisplayComponent';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import { selectBusinessManualEntry } from '@/context/borrower';
import { useAppSelector } from '@/context/storeHooks';
import { BusinessLookupSection } from '@/DisplaySections/BusinessLookupSection';
import { BusinessManualSection } from '@/DisplaySections/BusinessManualSection';
import { BusinessIndustry } from '@/UserInput/BusinessIndustry';
import { BusinessManualLookup } from '@/UserInput/BusinessManualLookup';

import { BusinessButtons } from './BusinessButtons';

export const testId = 'Business';

export function Business(): JSX.Element {
    // ***** CONSTANTS *****p
    const baseId = 'screen-business';
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const manualEntry = useAppSelector(selectBusinessManualEntry);
    const stateToShow = manualEntry === undefined ? true : manualEntry; // select business manual entry = false = use smart lookup true

    return (
        <DisplayComponent baseId={baseId} data-testid={testId}>
            <Header />
            <ProgressBar />
            <DisplayHeading
                title='Business Details'
                infoText='We need some details about your business'
            />
            <BusinessIndustry baseId={baseId} showErrors={showErrors} />

            <BusinessManualLookup baseId={baseId} showErrors={showErrors} />
            {stateToShow ? (
                <BusinessLookupSection
                    baseId={baseId}
                    showErrors={showErrors}
                />
            ) : (
                <BusinessManualSection
                    baseId={baseId}
                    showErrors={showErrors}
                />
            )}
            <BusinessButtons baseId={baseId} setShowErrors={setShowErrors} />
        </DisplayComponent>
    );
}
