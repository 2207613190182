import { TestDriveSummarySection } from '@/components/TestDriveSummarySection';
import {
    selectBirthdate,
    selectLicenseExpiry,
    selectLicenseNumber,
    selectLicenseState,
} from '@/context/borrower';
import { EModals, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export const testId = 'PersonalInformation';

export interface IProps {
    showErrors: boolean;
    baseId: string;
}

export function TDPersonalInformation({
    showErrors,
    baseId,
}: IProps): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    // const gender = useAppSelector(selectGender);
    const birthdate = useAppSelector(selectBirthdate);
    const day = birthdate?.getDate(); // Day of the month
    const month = birthdate ? birthdate?.getMonth() + 1 : undefined; // Month is 0-indexed, so add 1
    const year = birthdate?.getFullYear();

    const licenseNumber = useAppSelector(selectLicenseNumber);
    const licenseState = useAppSelector(selectLicenseState);
    const licenseExpiry = useAppSelector(selectLicenseExpiry);
    const licenseDay = licenseExpiry?.getDate(); // Day of the month
    const licenseMonth = licenseExpiry
        ? licenseExpiry?.getMonth() + 1
        : undefined; // Month is 0-indexed, so add 1
    const licenseYear = licenseExpiry?.getFullYear();

    // ***** Event Handlers *****
    function handleClick(): void {
        dispatch(setModal(EModals.TEST_DRIVE_PERSONAL_INFORMATION));
    }

    // ***** Helpers *****
    function isComplete(): boolean {
        // if (gender === null || gender === undefined) return false;
        if (birthdate === null || birthdate === undefined) return false;
        if (licenseNumber === '' || licenseNumber === undefined) return false;
        if (licenseState === '' || licenseState === undefined) return false;
        if (licenseExpiry === null || licenseExpiry === undefined) return false;
        if (
            licenseExpiry === null ||
            licenseExpiry === undefined ||
            licenseExpiry.toString() === 'Invalid Date'
        )
            return false;
        if (
            birthdate === null ||
            birthdate === undefined ||
            birthdate.toString() === 'Invalid Date'
        )
            return false;
        return true;
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <TestDriveSummarySection
                onClick={handleClick}
                id={`${baseId}-button-editPersonalInformation`}
                heading='License Details'
                showErrors={showErrors && !isComplete()}
            >
                <div className='flex flex-col space-y-2 pt-2'>
                    <div className='flex space-x-2 items-center justify-between w-full'>
                        <p>Date of Birth</p>
                        <p>
                            {
                                // If all parts of the date are defined, return the date in the format DD/MM/YYYY
                                day && month && year
                                    ? `${day}/${month}/${year}`
                                    : // Otherwise, return an empty string
                                      ''
                            }
                        </p>
                    </div>
                    <div className='flex space-x-2 items-center justify-between w-full'>
                        <p>License Number</p>
                        <p>{licenseNumber}</p>
                    </div>
                    <div className='flex space-x-2 items-center justify-between w-full'>
                        <p>State</p>
                        <p>{licenseState} </p>
                    </div>
                    <div className='flex space-x-2 items-center justify-between w-full'>
                        <p>Expiry</p>
                        <p>
                            {
                                // If all parts of the date are defined, return the date in the format DD/MM/YYYY
                                licenseDay &&
                                licenseMonth &&
                                licenseYear &&
                                licenseYear !== 0
                                    ? `${licenseDay}/${licenseMonth}/${licenseYear}`
                                    : // Otherwise, return an empty string
                                      ''
                            }
                        </p>
                    </div>
                </div>
            </TestDriveSummarySection>
        </div>
    );
}
