import { ESupportingDocCodes, ISeller, ISupportingDoc, IVehicle } from '.';

export const initialTestDrives: ITestDriveState = {
    currentTestDriveIndex: 0,
    testDrives: [{}],
};

export interface ITestDriveState {
    currentTestDriveIndex: number;
    testDrives: ITestDrive[];
}
export interface ITestDrive {
    date?: Date; // DD-MM-YYYY
    time?: Date; // HH:MM
    vehicle?: IVehicle;
    seller?: ISeller;
    supportingDocs?: { [key in ESupportingDocCodes]?: ISupportingDoc }[];
    agreedToTestDriveAgreement?: boolean;
    proceededToFinance?: boolean;
}
