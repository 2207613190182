import { useEffect } from 'react';

import { selectLoanTerm } from '@/context/borrower';
import { EDisplays } from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';
import { getCheapestLender } from '@/utils/getCheapestLender';
import { updateShownLenders } from '@/utils/updateShownLenders';

import { TestDriveReadyButton } from '../TestDriveReadyButton';

interface Props {
    baseId: string;
}

export function TDRLenderDetails({ baseId }: Props): JSX.Element {
    // ***** Redux *****
    const loanTerm = useAppSelector(selectLoanTerm);

    // ***** CONSTANTS *****
    const lender = getCheapestLender();

    // ***** useEffects *****
    useEffect(() => {
        updateShownLenders(lender, EDisplays.TEST_DRIVE_READY, false);
    }, [lender]);

    return (
        <div className='absolute left-1/2 top-[55%] transform -translate-x-1/2 w-[90%] rounded'>
            <div className='bg-gray-100 w-full rounded-lg p-4'>
                <div className='flex flex-col space-y-4'>
                    <div>
                        <p className='font-bold '>
                            You can own this car from only
                        </p>
                        <p className='font-bold'>
                            <span className='text-7xl'>
                                ${lender?.weeklyRepaymentAmount}{' '}
                            </span>
                            /weekly
                        </p>
                    </div>
                    <div className='flex font-bold space-x-4'>
                        <div className='flex flex-col w-full'>
                            <p className='text-[10px]'>Loan Term</p>
                            <p className='text-xl'>{loanTerm} years</p>
                        </div>
                        <div className='flex flex-col w-full'>
                            <p className='text-[10px]'>Advertised Rate</p>
                            <p className='text-xl'>
                                {lender?.advertisedRateInPercent}%
                            </p>
                        </div>
                        <div className='flex flex-col w-full'>
                            <p className='text-[10px]'>Comparison Rate</p>
                            <p className='text-xl'>
                                {lender?.comparisonRateInPercent}%
                            </p>
                        </div>
                    </div>
                    <p className='text-xs'>
                        Rates are indicative. No impact on credit score.
                    </p>
                </div>
            </div>
            <div className='py-2' />
            <TestDriveReadyButton baseId={baseId} />
        </div>
    );
}
