import { store } from '@/context/store';

import { isRealEstateAssetComplete } from '../isRealEstateComplete';

export function isAllRealEstatesComplete(canBeEmpty = false): boolean {
  const state = store.getState();
  const realEstates = state.borrowerReducer.realEstates.realEstates;

  // Early Exit
  if (realEstates?.length === 0 && realEstates[0]?.assetValue === undefined) {
    return canBeEmpty;
  }

  let isComplete = true;
  realEstates.map((realEstate) => {
    if (!isRealEstateAssetComplete(realEstate)) {
      isComplete = false;
    }
  });

  return isComplete;
}
