import { useCallback } from 'react';

import { Input } from '@/components/Input';
import { selectPurchasePrice, setPurchasePrice } from '@/context/borrower';
import {
    EModals,
    selectIsLoading,
    selectIsPriceKnownViaExternal,
    selectPartner,
    setModal,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
    title?: string;
}

export const testId = 'PurchasePrice';

export function PurchasePrice({ baseId, showErrors, title }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectPurchasePrice);
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoading);
    const isKnownViaExternal = useAppSelector(selectIsPriceKnownViaExternal);
    const partner = useAppSelector(selectPartner);

    // We only send to dealer solutions if the partner is MCH so for now we will use this as a flag
    const isMCH = partner.sendToDealerSolutions;

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        // MAXIMUM
        if (isLoading === true) return;
        const MAXIMUM = 999999;
        const intValue = getNumber(value, MAXIMUM);
        if (intValue === currentState) return;
        // if (intValue < MINIMUM) return;
        dispatch(setPurchasePrice(intValue));
    }

    let showDisclaimer = false;

    if (currentState === 0 || currentState === undefined) {
        if (!isKnownViaExternal) {
            showDisclaimer = true;
        }
    }

    // ***** Callbacks *****
    const handleClick = useCallback(() => {
        dispatch(setModal(EModals.MCH_DISCLAIMER));
    }, [dispatch]);

    // ***** Render *****
    return (
        <div data-testid={testId} className='w-full'>
            <Input
                title={title ? title : "What's the purchase price?"}
                placeholder='Enter'
                showErrors={showDisclaimer || (showErrors && !currentState)}
                onChange={handleChange}
                id={`${baseId}-input-purchasePrice`}
                value={showDisclaimer ? '0' : currentState?.toLocaleString()}
                prefix='$'
                showNumpad={true}
                tooltipContent='Purchase price - What do you expect to pay?'
                disclaimer={isMCH}
                disclaimerClick={handleClick}
            />
        </div>
    );
}
