export const initialAgreements: IAgreements[] = [];

export enum EAgreements {
    INSURANCE_OPT_IN = 'INSURANCE_OPT_IN',
}

export interface IAgreements {
    name: EAgreements; // eg termsAndConditionsVroom
    version: string; // eg 1.0.0
    clauses: IAgreementClause[];
}

export interface IAgreementClause {
    clauseId: string; // Unique identifier for each clause, e.g., "dataCollection"
    agreed: boolean; // Whether the user agreed to this specific clause
    date: Date; // Date the user agreed to this specific clause
    ipAddress?: string; // IP address of the user when they agreed to this specific clause
    agreementType: string; // eg checkbox, signature, etc
}
