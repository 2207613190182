import { useEffect, useState } from 'react';

import { createLoginUrl } from '@/apiCalls/createLoginUrl';
import { DisplayComponent } from '@/components/DisplayComponent';
import { DisplayHeading } from '@/components/DisplayHeading';
import { DocumentUpload } from '@/components/DocumentUpload';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import {
    ESupportingDocCodes,
    selectFirstName,
    selectSupportingDocs,
} from '@/context/borrower';
import { EJourneys, selectInitialJourney } from '@/context/display';
import { EDisplays, setShowOcrDisplay } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { selectLoginUrl, setLoginUrl } from '@/context/user';
import { getQrPushLocation } from '@/utils/getQrPushLocation';

import { IdentificationButtons } from './IdentificationButtons';
import { IdentificationSkipButton } from './IdentificationSkipButton';

export const baseId = 'screen-identification';

export function Identification(): JSX.Element {
    const [showErrors, setShowErrors] = useState(false);
    const dispatch = useAppDispatch();
    const loginUrl = useAppSelector(selectLoginUrl);
    const firstName = useAppSelector(selectFirstName);
    const initialJourney = useAppSelector(selectInitialJourney);

    // ***** Redux State *****
    const supportingDocs = useAppSelector(selectSupportingDocs);
    const frontLicense = supportingDocs?.app_drivers_licence_front
        ? supportingDocs?.app_drivers_licence_front
        : null;
    const backLicense = supportingDocs?.app_drivers_licence_back
        ? supportingDocs?.app_drivers_licence_back
        : null;

    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

    function getExpandedIndex(): number | null {
        if (!frontLicense) return 0;
        if (!backLicense) return 1;
        return null;
    }

    // Get login url for the QR code
    useEffect(() => {
        const setUrl = (url: string): void => {
            dispatch(setLoginUrl(`${url}--${EDisplays.IDENTIFICATION}`));
        };

        if (!loginUrl) {
            createLoginUrl(getQrPushLocation(), firstName).then((url) => {
                setUrl(url);
            });
        }

        const [url, display] = loginUrl.split('--');
        if (display !== EDisplays.IDENTIFICATION) {
            setUrl(url);
        }
    }, []);

    // Probably will work??
    useEffect(() => {
        const number = getExpandedIndex();
        setExpandedIndex(number);
        if (frontLicense) {
            setExpandedIndex(1);
        }
        setShowOcrDisplay(true);
    }, [supportingDocs]);

    function documentUpload(index: number): void {
        if (expandedIndex === index) {
            setExpandedIndex(null);
            return;
        }
        setExpandedIndex(index);
    }

    // ***** Render *****
    return (
        <DisplayComponent baseId={baseId} data-testid={baseId}>
            <Header />
            <ProgressBar />

            <DisplayHeading
                title='Identification'
                subtitle=" Upload your driver's license"
                infoText='Upload clear photos or files. Doing this now shortens the application and saves you doing it later.'
            />
            {/* TODO: Document Uploads below should be user input components for driverslicensefront and driverslicenseback - They are the same for supporting docs */}
            <DocumentUpload
                baseId={baseId}
                showErrors={
                    showErrors &&
                    (!supportingDocs[
                        ESupportingDocCodes.DRIVERS_LICENSE_FRONT
                    ] ||
                        (supportingDocs[
                            ESupportingDocCodes.DRIVERS_LICENSE_FRONT
                        ] &&
                            supportingDocs[
                                ESupportingDocCodes.DRIVERS_LICENSE_FRONT
                            ].length === 0))
                }
                code={ESupportingDocCodes.DRIVERS_LICENSE_FRONT}
                document={frontLicense}
                value="Driver's License Front (Card Only)"
                expanded={expandedIndex === 0}
                handleExpanded={(): void => documentUpload(0)}
            />
            <DocumentUpload
                baseId={baseId}
                showErrors={
                    showErrors &&
                    (!supportingDocs[
                        ESupportingDocCodes.DRIVERS_LICENSE_BACK
                    ] ||
                        (supportingDocs[
                            ESupportingDocCodes.DRIVERS_LICENSE_BACK
                        ] &&
                            supportingDocs[
                                ESupportingDocCodes.DRIVERS_LICENSE_BACK
                            ].length === 0))
                }
                code={ESupportingDocCodes.DRIVERS_LICENSE_BACK}
                document={backLicense}
                value="Driver's License Back (Card Only)"
                expanded={expandedIndex === 1}
                handleExpanded={(): void => documentUpload(1)}
            />
            <p className='my-4 text-center text-sm text-primary'>
                Once uploaded, we will pre-fill your Full Name, Birthday,
                Address and Drivers License Number
            </p>
            {initialJourney !== EJourneys.TEST_DRIVE && (
                <IdentificationSkipButton baseId={baseId} />
            )}
            <IdentificationButtons
                baseId={baseId}
                setShowErrors={setShowErrors}
            />
        </DisplayComponent>
    );
}
