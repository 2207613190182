import { ISetInitialBorrower } from './ISetInitialBorrower';
import { setInitialTestDriveDate } from './setInitialTestDriveDate';
import { setInitialTestDriveTime } from './setInitialTestDriveTime';
import { setLead } from './setLead';

export function setInitialBorrower({
    leadToken,
    testDriveDate,
    testDriveTime,
}: ISetInitialBorrower): void {
    setLead(leadToken);
    setInitialTestDriveDate(testDriveDate);
    setInitialTestDriveTime(testDriveTime);
}
