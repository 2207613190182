import { getUrl } from '@/apiCalls/fetch';

import ILead from '../ILead';

const URL = '/flow/v1/lead';

export async function fetchLead(leadToken: string): Promise<ILead | null> {
    const data = (
        await getUrl<{ data: ILead & { message?: string } }>(
            `${URL}/${leadToken}`
        )
    ).data;

    // Check if error message
    if (data.message) {
        return null;
    }

    // Else, return the fetched data
    return data;
}

export default fetchLead;
