import { setProgressPercent } from '@/context/borrower';
import { EDisplays } from '@/context/display';
import { store } from '@/context/store';

export function getProgressPercent(): number {
    const state = store.getState();
    const display = state.displayReducer.display;
    const currentProgressPercent =
        state.borrowerReducer.application.progressPercent;

    let newProgressPercent = 0;

    switch (display) {
        case EDisplays.LOAN:
            newProgressPercent = 5;
            break;
        case EDisplays.MANUAL_VEHICLE:
        case EDisplays.VEHICLE:
            // Mutually exclusive displays, same progress
            newProgressPercent = 20;
            break;
        case EDisplays.SELLER:
            newProgressPercent = 25;
            break;
        case EDisplays.BUSINESS:
            newProgressPercent = 45;
            break;
        case EDisplays.BASIC:
            newProgressPercent = 35;
            break;
        case EDisplays.INCOME:
            newProgressPercent = 45;
            break;
        case EDisplays.EXPENSE:
        case EDisplays.SERVICING_SUCCESS:
            // Same progress
            newProgressPercent = 55;
            break;
        case EDisplays.IDENTIFICATION:
            newProgressPercent = 65;
            break;
        case EDisplays.OCR_SUMMARY:
            newProgressPercent = 70;
            break;
        case EDisplays.YOUR_SCORES:
            newProgressPercent = 75;
            break;
        case EDisplays.INDICATIVELY_APPROVED:
        case EDisplays.INDICATIVELY_REFERRED:
        case EDisplays.INDICATIVELY_DECLINED:
            // Same progress
            newProgressPercent = 80;
            break;
        case EDisplays.EMPLOYMENT:
            newProgressPercent = 85;
            break;
        case EDisplays.ASSETS_AND_LIABILITIES:
            newProgressPercent = 90;
            break;
        case EDisplays.SUPPORTING_DOCS:
            newProgressPercent = 95;
            break;
        case EDisplays.QUICK_CALCULATOR:
        default:
            newProgressPercent = 0;
            break;
    }

    // Update highestProgressPercent if currentProgressPercent is greater
    if (
        !currentProgressPercent ||
        currentProgressPercent < newProgressPercent
    ) {
        store.dispatch(setProgressPercent(newProgressPercent));
    }

    return newProgressPercent;
}
