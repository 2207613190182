import { useState } from 'react';

import { getApplicationId } from '@/apiCalls/getApplicationId';
import { CheckIconCircle } from '@/components/CheckIconCircle';
import { PlusIconCircle } from '@/components/PlusIconCircle';
import { selectSupportingDocs } from '@/context/borrower';
import { useAppSelector } from '@/context/storeHooks';
import { REACT_APP_BANK_STATEMENT_ENDPOINT } from '@/utils/constants';

export const testId = 'VariantManual';

export interface IUploadBankStatementsProps {
  code: string;
}

export function UploadBankStatements({
  code,
}: IUploadBankStatementsProps): JSX.Element {
  // ***** Local State *****
  const [bankStatementsWindow, setBankStatementsWindow] =
    useState<Window | null>(null);

  // ***** Redux State *****
  const supportingDocs = useAppSelector(selectSupportingDocs);

  // ***** Helpers *****

  // No idea what this is, copied from old Vroom directly
  const navigation = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    openWindoworTab(url: string): any {
      return window.open(
        url,
        '_blank',
        'popup, width=400, height=600, screenX=300, screenY=100'
      );
    },
  };

  function determineSymbol(): JSX.Element {
    // todo: Evaluate timeframe of supporting docs, not just whether they exist (eg, if it was last updated a year ago, they need to redo it)
    if (supportingDocs[code]) return <CheckIconCircle />;
    return <PlusIconCircle />;
  }

  // ***** Event Handler *****
  async function handleClick(): Promise<void> {
    const appId = await getApplicationId();

    if (!appId) return;

    const window = navigation.openWindoworTab(
      `${REACT_APP_BANK_STATEMENT_ENDPOINT}${code}-${appId}`
    );
    setBankStatementsWindow(window);

    //TODO: trigger window close on bank statement update and set opened window to null
  }

  return (
    <div data-testid={testId} className='pt-4'>
      <div className='top-0 w-full h-full _flex bg-opacity-10 border border-gray-500 rounded-lg'>
        <div className='p-4 bg-white w-full bg-whtie m-auto rounded-lg'>
          <div
            className='_flex justify-between items-center hover:cursor-pointer'
            onClick={handleClick}
          >
            <h3 className='capitalize text-sm'>Bank Statements</h3>
            {determineSymbol()}
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
