import { IPartnerParams } from '@/partners/partnerTypes';

import { getUrl } from '../fetch';

// note: This doesn't return IPartnerParams but PartnerConfig
export async function lookupVroomCode(code: string | undefined): Promise<IPartnerParams | void> {
    try {
        const url = `/api/partner/partnerConfigFromVroomCode/${code}`;
        return (await getUrl<any>(url)).data;
    } catch (err) {
        console.log(err);
        return;
    }
}
