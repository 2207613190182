import { Button } from '@/components/Button';
import { selectPurchasePrice } from '@/context/borrower';
import { setDisplay } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { loanGetNextScreen } from '@/utils/loanGetNextScreen';

interface Props {
    baseId: string;
}

export function NoLenderHomeButtons({ baseId }: Props): JSX.Element {
    const dispatch = useAppDispatch();
    const purchasePrice = useAppSelector(selectPurchasePrice);

    async function handleClick(): Promise<void> {
        if (!purchasePrice) {
            return;
        }
        const nextScreen = loanGetNextScreen();
        dispatch(setDisplay(nextScreen));
    }

    return (
        <Button
            onClick={handleClick}
            title='Apply Now'
            id={`${baseId}button-getQuoteNow`}
        />
    );
}
