import * as helpers from './helpers';
import { ISetInitialDisplay } from './ISetInitialDisplay';

export function setInitialDisplay(settings: ISetInitialDisplay): void {
    helpers.setInitialShowWelcomeScreen(
        settings.showWelcomeScreen.parameter,
        settings.showWelcomeScreen.partner
    );
    helpers.setInitialIsWhiteLabel(settings.isWhiteLabel);
    helpers.setInitialSellerLogo(
        settings.logo.parameter,
        settings.logo.partner
    );
    helpers.setInitialSellerType(settings.type);
    helpers.setInitialSellerTermsLink(settings.termsAndConditionsLink);
    helpers.setInitialHideVehicleScreen(settings.hideVehicleScreen);
    helpers.setInitialHideSellerScreen(settings.hideSellerScreen);
    helpers.setInitialWaterfallLenders(
        settings.waterfallLenders,
        settings.paramCustomWaterfallId,
        settings.partnerMakeWaterfallIdOverwrite
    );
    helpers.setInitialShowTotalAmountPayable(settings.showTotalAmountPayable);
    helpers.setInitialDisplaySellerName(
        settings.name.parameter,
        settings.name.partner
    );
    helpers.setInitialDisplaySellerEmail(
        settings.email.parameter,
        settings.email.partner
    );
    helpers.setInitialDisplaySellerPhone(
        settings.phone.parameter,
        settings.phone.partner
    );
    helpers.setInitialColors(settings.colors);
    helpers.setInitialDisplayScreen(
        settings.partner,
        settings.display,
        settings.initialJourney,
        settings.userFlow
    );
    helpers.setInitialModal(
        settings.partnerId,
        settings.paramQrScanwareId,
        settings.initialJourney,
        settings.userFlow
    );
    helpers.setInitialPartner(settings.partner);
    helpers.getInitialAskTermsAndConditions(settings.askTermsAndConditions);
    helpers.setInitialLenderSorting(settings.lenderSorting);
    helpers.setInitialSendLeadEmail(settings.sendLeadEmail);
    helpers.setInitialAvailableVehicleAssetTypes(
        settings.availableVehicleAssetTypes
    );
    helpers.setInitialRequireGlassGuide(settings.requireGlassGuide);
}
