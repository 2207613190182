import { PayloadAction } from '@reduxjs/toolkit';

import { IMotorVehicle } from '../types';

export const motorVehicleReducers = {
  setMotorVehicleByIndex: (
    state,
    action: PayloadAction<{ index: number; motorVehicle: IMotorVehicle }>
  ): void => {
    if (state.motorVehicles.motorVehicles === null) return;
    state.motorVehicles.motorVehicles[action.payload.index] =
      action.payload.motorVehicle;
  },
  setMotorVehicleActiveIndex: (state, action: PayloadAction<number>): void => {
    let length = action.payload;
    const min = 0;
    if (length < min) {
      length = min;
    }
    state.motorVehicles.motorVehicleActiveIndex = length;
  },

  setMotorVehicles: (
    state,
    action: PayloadAction<{
      motorVehicles: IMotorVehicle[];
      motorVehicleActiveIndex: number;
    }>
  ): void => {
    state.motorVehicles.motorVehicles = action.payload.motorVehicles;
    state.motorVehicles.motorVehicleActiveIndex =
      action.payload.motorVehicleActiveIndex;
  },
  deleteMotorVehicle: (state): void => {
    if (
      state.motorVehicles.motorVehicles?.length === 1 &&
      state.motorVehicles.motorVehicleActiveIndex === 0
    ) {
      state.motorVehicles.motorVehicles = [];
      return;
    }
    state.motorVehicles.motorVehicles?.splice(
      state.motorVehicles.motorVehicleActiveIndex,
      1
    );
  },
};
