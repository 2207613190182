import { useSelector } from 'react-redux';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { DisplayButtons } from '@/components/DisplayButtons';
import {
    EBorrowerCitizenshipStatus,
    selectAddressByIndex,
    selectEmail,
    selectFirstName,
    selectLastName,
    selectLicenseNumber,
    selectLicenseState,
    selectMobile,
    selectResidencyStatus,
    selectVisaNumber,
} from '@/context/borrower';
import {
    EDisplays,
    EJourneys,
    EModals,
    selectInitialJourney,
    setDisplay,
    setModal,
} from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { isBirthDateValid } from '@/utils/checkBirthDate';
import { isLicenseExpiryDateValid } from '@/utils/checkLicenseExpiryDate';
import { isVisaExpiryDateValid } from '@/utils/checkVisaExpiryDate';
import { isEmail } from '@/utils/isEmail';

interface IOcrSummaryButtonsProps {
    setShowErrors: (value: boolean) => void;
    baseId?: string;
}

export function OcrSummaryButtons({
    setShowErrors,
    baseId,
}: IOcrSummaryButtonsProps): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    // Contact Details
    const firstName = useAppSelector(selectFirstName);
    const lastName = useAppSelector(selectLastName);
    const email = useAppSelector(selectEmail);
    const phone = useAppSelector(selectMobile);
    // Personal Information
    // const gender = useAppSelector(selectGender);
    const licenseNumber = useAppSelector(selectLicenseNumber);
    const licenseState = useAppSelector(selectLicenseState);
    const residencyStatus = useAppSelector(selectResidencyStatus);
    const visaNumber = useAppSelector(selectVisaNumber);
    // Address Information
    const address = useSelector((state: RootState) =>
        selectAddressByIndex(state, 0)
    );
    const { ownershipType, streetNumber, streetName, suburb, state, postcode } =
        address;

    const initialJourney = useAppSelector(selectInitialJourney);

    function isComplete(): boolean {
        // Contact Details
        if (firstName === '' || firstName === undefined) return false;
        if (lastName === '' || lastName === undefined) return false;
        if (phone && (phone === '' || phone?.length < 10)) return false;
        if (!isEmail(email)) return false;

        if (
            residencyStatus === EBorrowerCitizenshipStatus.VISA_HOLDER &&
            ((visaNumber && visaNumber.toString().length < 3) ||
                !isVisaExpiryDateValid())
        )
            return false;
        // Personal Information
        // if (gender === null || gender === undefined) return false;
        if (!isBirthDateValid()) return false;

        if (licenseNumber === '' || licenseNumber === undefined) return false;
        if (licenseState === '' || licenseState === undefined) return false;

        if (!isLicenseExpiryDateValid()) return false;

        if (residencyStatus === null || residencyStatus === undefined)
            return false;

        // Address Information
        if (ownershipType === null || ownershipType === undefined) return false;
        if (streetNumber === '' || streetNumber === undefined) return false;
        if (streetName === '' || streetName === undefined) return false;
        if (suburb === '' || suburb === undefined) return false;
        if (state === null || state === undefined) return false;
        if (
            postcode === '' ||
            postcode === null ||
            postcode === undefined ||
            postcode.toString().length < 4
        ) {
            return false;
        }

        return true;
    }
    function handleBackClick(): void {
        dispatch(setDisplay(EDisplays.IDENTIFICATION));
    }

    function handleNextClick(): void {
        setShowErrors(true);
        if (!isComplete()) {
            dispatch(setModal(EModals.OCR_CHECK_INCOMPLETE));
            return;
        }
        // Test Drive
        if (initialJourney === EJourneys.TEST_DRIVE) {
            dispatch(setDisplay(EDisplays.TEST_DRIVE_AGREEMENT));
            createOrUpdateBorrower();
            return;
        }

        // Normal
        dispatch(setDisplay(EDisplays.PREPPROVAL_ANIMATION));
        createOrUpdateBorrower();
    }

    return (
        <DisplayButtons
            idLeftButton={`${baseId}-button-back`}
            idRightButton={`${baseId}-button-next`}
            rightClick={handleNextClick}
            leftClick={handleBackClick}
        />
    );
}
