import { useEffect, useState } from 'react';

import { getGlassOptions } from '@/apiCalls/glassGuideVehicleLookup';
import {
    selectVehicleAssetType,
    selectVehicleBuildYear,
    selectVehicleDescription,
    selectVehicleMake,
    selectVehicleModel,
    selectVehicleVariant,
    setGlassValuation,
    setVehicleDescription,
    setVehicleNvic,
} from '@/context/borrower';
import {
    IGlassOptionsApiResults,
    selectGlassOptionsForVehicle,
    selectVehicleMakeCodeForApiCall,
    selectVehicleModelCodeForApiCall,
    setGlassOptionsForVehicle,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

import { DescriptionBox } from '../DescriptionBox';

export const testId = 'DisplayGlassOptions';

export function DisplayGlassOptions(): JSX.Element {
    // * Local State
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [loadingGlassOptions, setLoadingGlassOptions] = useState(false);

    // * Redux State
    const currentMake = useAppSelector(selectVehicleMake);
    const currentModel = useAppSelector(selectVehicleModel);
    const dispatch = useAppDispatch();
    const variant = useAppSelector(selectVehicleVariant);
    const glassOptions = useAppSelector(selectGlassOptionsForVehicle);
    const buildYear = useAppSelector(selectVehicleBuildYear);
    const makeCodeForApiCall = useAppSelector(selectVehicleMakeCodeForApiCall);
    const modelCodeForApiCall = useAppSelector(
        selectVehicleModelCodeForApiCall
    );
    const description = useAppSelector(selectVehicleDescription);
    const partner = useAppSelector((state) => state.displayReducer.partner.id);
    const assetType = useAppSelector(selectVehicleAssetType);

    const handleOnClick = (
        glassOption: IGlassOptionsApiResults | undefined,
        index: number | null
    ): void => {
        if (glassOption === undefined) return;
        dispatch(setVehicleDescription(glassOption.description));
        dispatch(
            setGlassValuation(
                parseFloat(glassOption.glassValuation) || undefined
            )
        );
        dispatch(setVehicleNvic(glassOption.nvic));
        //   dispatch(setIsVehicleKnown(true));
        setSelectedIndex(index);
    };

    // useEffect
    useEffect(() => {
        if (variant) {
            if (description) {
                glassOptions?.map((item, index) => {
                    if (item.description === description) {
                        setSelectedIndex(index);
                    }
                });
                return;
            }
            setSelectedIndex(null);
            const apiCall = async (): Promise<void> => {
                setLoadingGlassOptions(true);
                await getGlassOptions(
                    partner,
                    buildYear,
                    makeCodeForApiCall,
                    modelCodeForApiCall,
                    variant,
                    assetType
                ).then((response) => {
                    if (response === undefined) return;
                    dispatch(setGlassOptionsForVehicle(response));
                    if (response.length === 1) {
                        // Emulate a click on the only option
                        handleOnClick(response[0], 0);
                    }
                    setLoadingGlassOptions(false);
                });
            };
            apiCall();
        }
    }, [variant]);

    if (!buildYear || !currentMake || !variant || !currentModel) {
        return <div></div>;
    }

    return (
        <div className='pt-4'>
            <h1 className='fieldHeading text-center mb-2'>
                select vehicle match
            </h1>
            <div className='bg-white   mb-5 border rounded-[10px] border-grey'>
                {loadingGlassOptions ? (
                    <p className='text-center'>...Loading</p>
                ) : (
                    glassOptions?.map((value, index) => (
                        <DescriptionBox
                            key={index}
                            content={value.description}
                            onClick={(): void => handleOnClick(value, index)}
                            selected={index === selectedIndex}
                            vehicleUnknown={true}
                        />
                    ))
                )}
            </div>
        </div>
    );
}
