import { EVehicleAssetType } from '@/context/borrower';
import { store } from '@/context/store';

export function isGlassLookupPossible(): boolean {
    const vehicleAssetType = store.getState().borrowerReducer.vehicle.assetType;

    switch (vehicleAssetType) {
        case EVehicleAssetType.JET_SKIES:
        case EVehicleAssetType.PRIME_MOVER:
        case EVehicleAssetType.PLANT_EQUIPMENT:
        case EVehicleAssetType.TRAILERS:
        case EVehicleAssetType.OTHER:
        case EVehicleAssetType.TRUCK_GT_4_5:
        case EVehicleAssetType.BOATS:
        case EVehicleAssetType.BOAT:
            return false;

        case undefined:
        default:
            return true;
    }
}
