import { setVehicleVin } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialVehicleVin(vin: IParameter): void {
  if (!vin || vin === 'undefined') {
    return;
  }

  const stateVin = store.getState().borrowerReducer.vehicle.vin;
  if (vin && stateVin !== vin) {
    store.dispatch(setVehicleVin(vin));
  }
}
