import { postUrl } from '@/apiCalls/fetch';

import { EVerificationResponseStatus } from '../verificationCommonTypes';

import { ISendEmailCodeResponse } from './sendEmailCodeTypes';

export async function sendEmailCode(
  email: string
): Promise<EVerificationResponseStatus> {
  const url = '/api/verify/requestVerificationByEmail';

  const body = { email };

  const response = await postUrl<ISendEmailCodeResponse>(url, body);

  return response.status;
}
