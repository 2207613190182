import { IGlassGuideLookUpVehicle } from '../types';

export const initialGlassGuideLookUpVehicle: IGlassGuideLookUpVehicle = {
  vehicleBuildYearApiResults: undefined,
  vehicleVariantApiResults: undefined,
  glassOptionsForVehicle: undefined,
  vehicleMakeApiResults: undefined,
  vehicleMakeCodeForApiCall: undefined,
  vehicleModelApiResults: undefined,
  vehicleModelCodeForApiCall: undefined,
};
