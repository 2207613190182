import { DisplayComponent } from '@/components/DisplayComponent';
import { GenericLenderCard } from '@/components/GenericLenderCard';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import { Slogan } from '@/components/Slogan';
import { TruckstopQuickCalculatorIcons } from '@/components/TruckstopQuickCalculatorIcons';
import { selectPartnerName } from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';
import { TRUCKSTOP_PARTNER_NAME } from '@/partners/truckstop';
import { LoanTerm } from '@/UserInput/LoanTerm';
import { PurchasePrice } from '@/UserInput/PurchasePrice';
import { SelfAssessedCredit } from '@/UserInput/SelfAssessedCredit';

import { QuickCalculatorButton } from './QuickCalculatorButton';

export const testId = 'QuickCalculator';

export function QuickCalculator(): JSX.Element {
    // ***** CONSTANTS *****
    const baseId = 'screen-quickCalculator';
    const partnerName = useAppSelector(selectPartnerName);
    const isTruckstop = partnerName === TRUCKSTOP_PARTNER_NAME;

    return (
        <DisplayComponent baseId={baseId} data-testid={testId}>
            <Header />
            <Slogan />
            <ProgressBar />
            <PurchasePrice showErrors={false} baseId={baseId} />
            <LoanTerm showErrors={false} baseId={baseId} />
            <SelfAssessedCredit showErrors={false} baseId={baseId} />
            <GenericLenderCard />
            <div className='h-4 w-full' />
            <QuickCalculatorButton />
            <p className='font-semibold text-center mt-2'>
                Get an <span className='text-primary'>instant</span> response.
                <br />
                No impact on <span className='text-primary'>credit</span> score.
            </p>
            {isTruckstop && <TruckstopQuickCalculatorIcons />}
            {/* Empty div below is in place of traditional back/next buttons as display component has extra styling in place for the last element */}
            <div />
        </DisplayComponent>
    );
}
