import { store } from '@/context/store';
import { getNumberOfKids } from '@/utils/getNumberOfKids';
import { getMonthlyAmount } from '@/utils/paymentHelper';

import { postUrl } from '../fetch';

import { IServicingPostBody, IServicingReturn } from './fetchServicingTypes';

export type IServicing = {
  RESULT: boolean;
};

export async function fetchServicing(): Promise<boolean> {
  // ***** Redux State *****
  const state = store.getState();

  // Store
  const shareExpenses = state.borrowerReducer.expenses.expensesShared;
  const billsFrequency = state.borrowerReducer.expenses.billsPaidFrequency;
  const generalLiving = state.borrowerReducer.expenses.general;
  const mortgageRent = state.borrowerReducer.expenses.mortgageRent;
  const transportVehicle = state.borrowerReducer.expenses.transportVehicle;
  const education = state.borrowerReducer.expenses.education;
  const healthcare = state.borrowerReducer.expenses.healthcare;
  const otherLoans = state.borrowerReducer.expenses.otherLoans;
  const paidFrequency = state.borrowerReducer.income.paidFrequency;
  const amountPaid = state.borrowerReducer.income.paidAmount;
  const otherIncome = state.borrowerReducer.income.otherIncome;
  const sourceOfOtherIncome = state.borrowerReducer.income.otherIncomeSource;

  // ***** Constants *****
  const url = '/api/serviceability';
  const amountPaidMonthly = getMonthlyAmount(paidFrequency, amountPaid);
  const otherIncomeMonthly = getMonthlyAmount(paidFrequency, otherIncome);
  const generalLivingMonthly = getMonthlyAmount(billsFrequency, generalLiving);
  const transportVehicleMonthly = getMonthlyAmount(
    billsFrequency,
    transportVehicle
  );
  const educationMonthly = getMonthlyAmount(billsFrequency, education);
  const healthcareMonthly = getMonthlyAmount(billsFrequency, healthcare);
  const otherLoansMonthly = getMonthlyAmount(billsFrequency, otherLoans);
  const mortgageRentalMonthly = getMonthlyAmount(billsFrequency, mortgageRent);
  const numberOfKids = getNumberOfKids();

  // ***** Body *****
  const body: IServicingPostBody = {
    monthlyRepayment: 300, //todo: get this from the store
    amountPaidInBankAccountMonthly: amountPaidMonthly,
    residencyExpenses: mortgageRentalMonthly,
    transportAndVehicleExpenses: transportVehicleMonthly,
    otherOngoingLoansExpenses: otherLoansMonthly,
    generalLivingExpenses: generalLivingMonthly,
    educationExpenses: educationMonthly,
    healthcareExpenses: healthcareMonthly,
    numberOfDependants: numberOfKids,
    amountOfAnyOtherIncomeMonthly: otherIncomeMonthly,
    sourceOfOtherIncome: sourceOfOtherIncome,
    isHouseholdExpensesShared: shareExpenses ? true : false,
  };

  const response = await postUrl<IServicingReturn>(url, body);

      // Check if any servicing lenders are found in the response
  for (const key in response) {
    if (Object.prototype.hasOwnProperty.call(response, key)) {
      const lender = response[key];
      if (lender.isServicing) {
        return true;
      }
    }
  }

  return false;

}
