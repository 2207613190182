import { getPartnerFromUrl } from '@/partners/getPartnerFromUrl';

import { getInitialApplicationData } from './helpers/getInitialApplication/getInitialApplicationData';
import { setInitialApplication } from './helpers/setInitialApplication/setInitialApplicationData';
import {
    getInitialBorrowerData,
    getInitialDisplayData,
    getInitialLoanData,
    getInitialSellerData,
    getInitialVehicleData,
    getParameters,
    setInitialBorrower,
    setInitialDisplay,
    setInitialLoan,
    setInitialSeller,
    setInitialUserState,
    setInitialVehicle,
} from './helpers';

export async function setParametersFromParams(): Promise<void> {
    const params = getParameters();
    const partner = await getPartnerFromUrl();

    // Initial Setup - From Cookies
    setInitialUserState();

    // Setup data
    const sellerData = getInitialSellerData(partner, params);
    const vehicleData = getInitialVehicleData(partner, params);
    const loanData = getInitialLoanData(partner, params);
    const displayData = getInitialDisplayData(partner, params);
    const borrowerData = getInitialBorrowerData(partner, params);
    const applicationData = getInitialApplicationData(partner, params);

    // Set data
    await Promise.all([
        setInitialBorrower(borrowerData),
        setInitialSeller(sellerData),
        setInitialVehicle(vehicleData),
        setInitialLoan(loanData),
        setInitialDisplay(displayData),
        setInitialApplication(applicationData, partner.id),
    ]);
}
