import { useEffect, useState } from 'react';

import {
    convertAbnAndNameLookup,
    ILookupName,
    lookupAbn,
    lookupBusinessName,
} from '@/apiCalls/businessLookup';
import { Combobox, IComboboxOption } from '@/components/Combobox';
import {
    selectPreviousBusinessName,
    selectPreviousIndustryType,
    setPreviousBusiness,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'PreviousBusinessLookup';

export const PreviousBusinessLookup = ({
    showErrors,
    baseId,
}: IProps): JSX.Element => {
    // ***** Redux *****
    const businessName = useAppSelector(selectPreviousBusinessName);
    const industryType = useAppSelector(selectPreviousIndustryType);
    const dispatch = useAppDispatch();

    // *****State*****
    const [query, setQuery] = useState<undefined | string>(businessName); // Keep track of search
    const [options, setOptions] = useState<IComboboxOption[]>([] as any); // Set options for the combobox
    const [data, setData] = useState<ILookupName[]>([] as any); // Full API Data - used to get ABN
    const [loading, setLoading] = useState<boolean>(false);

    // *****Functions*****
    // Handle the onChange event for the input.
    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        setQuery(value);
    }

    // When the query changes, fetch the options after a delay
    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(async () => {
            const newOptions: IComboboxOption[] = [];
            if (query === undefined) return;

            if (query.length === 11 && parseInt(query) > 0) {
                const abnLookupResult = await lookupAbn(query);
                if (!abnLookupResult) return;
                newOptions.push({ id: 0, value: abnLookupResult.EntityName });

                setQuery('');
                setOptions(newOptions);
                setLoading(false);
            } else {
                const lookup = await lookupBusinessName(query);
                lookup.map((item: ILookupName, index) => {
                    return newOptions.push({ id: index, value: item.Name });
                });

                setOptions(newOptions);
                console.log('options', newOptions);
                setLoading(false);
                setData(lookup);
            }
        }, 1000); // 1 second

        return () => clearTimeout(delayDebounceFn);
    }, [query]);

    // When the user selects an option
    async function handleSelect(option: IComboboxOption): Promise<void> {
        const nameLookupResult = data[option.id];
        const abnLookupResult = await lookupAbn(nameLookupResult.Abn);
        if (!abnLookupResult) return;
        const business = convertAbnAndNameLookup(
            nameLookupResult,
            abnLookupResult
        );
        business.industry = industryType; // abn lookup doesn't return industry, so we need to add it from existing state

        dispatch(setPreviousBusiness(business));
    }

    // *****Render*****
    return (
        <Combobox
            options={options}
            loading={loading}
            placeholder='Required'
            heading='Business Name'
            onChange={handleChange}
            query={query ?? businessName ?? ''}
            onSelect={handleSelect}
            showErrors={showErrors && !businessName}
            baseId={baseId}
        />
    );
};
