import { postUrl } from '@/apiCalls/fetch';
import { store } from '@/context/store';
import {
  ELocalStorageVariables,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@/utils/localStorage';

import {
  EVerificationResponseStatus,
  IVerifyCodeReturn,
} from '../verificationCommonTypes';

import {
  IVerifyEmailCodeBody,
  IVerifyEmailResponse,
} from './verifyEmailCodeTypes';

export async function verifyEmailCode(
  email: string,
  code: string
): Promise<IVerifyCodeReturn> {
  // ***** Redux *****
  const state = store.getState();
  const borrower = state.borrowerReducer;

  // ***** Request *****
  const url = '/api/verify/verifyByEmail';
  const body: IVerifyEmailCodeBody = { email, code, borrower };
  const response = await postUrl<IVerifyEmailResponse>(url, body);

  // ***** Use Response *****
  if (
    response.token &&
    response.status === EVerificationResponseStatus.SUCCESSFUL
  ) {
    setLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN, response.token);
  } else {
    removeLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);
  }

  // ***** Return *****
  return {
    status: response.status,
  };
}
