import { EDisplays, selectDisplay } from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';

import { Business } from './Displays/Business';
import { PreApprovalAnimation } from './Displays/PreApprovalAnimation';
import { SolarAssetsAndLiabilities } from './Displays/SolarAssetsAndLiabilities';
import { SolarIdentification } from './Displays/SolarIdentification';
import { SolarLenderSpecificQuestions } from './Displays/SolarLenderSpecificQuestions';
import { SolarSupportingDocs } from './Displays/SolarSupportingDocs';
import { SupportingDocs } from './Displays/SupportingDocs';
import { TestDriveLandingPage } from './Displays/TestDriveLandingPage';
import { YourScores } from './Displays/YourScores/YourScores';
import {
    AssetsAndLiabilities,
    BasicDetails,
    CampaignLanding,
    Employment,
    Expense,
    Identification,
    Income,
    IndicativelyApproved,
    IndicativelyDeclined,
    IndicativelyReferred,
    Loan,
    ManualVehicle,
    NoLenderHome,
    None,
    OcrAnimation,
    OcrSummary,
    QuickCalculator,
    Seller,
    ServicingFailure,
    ServicingSuccess,
    SolarHoldingScreen,
    SolarIndicativelyApproved,
    SolarLendersList,
    SolarLoanDetails,
    SolarOcrSummary,
    SolarYourDetails,
    TestDriveAgreement,
    TestDriveOcrSummary,
    TestDriveReady,
    TruckstopLandingPage,
    Vehicle,
} from './Displays';

function DisplayScreen(): JSX.Element {
    const display = useAppSelector(selectDisplay);

    switch (display) {
        case null:
            return <div />;
        case EDisplays.BASIC:
            return <BasicDetails />;
        case EDisplays.ASSETS_AND_LIABILITIES:
            return <AssetsAndLiabilities />;
        case EDisplays.INCOME:
            return <Income />;
        case EDisplays.EXPENSE:
            return <Expense />;
        case EDisplays.LOAN:
            return <Loan />;
        case EDisplays.SELLER:
            return <Seller />;
        case EDisplays.SERVICING_SUCCESS:
            return <ServicingSuccess />;
        case EDisplays.SERVICING_FAILURE:
            return <ServicingFailure />;
        case EDisplays.IDENTIFICATION:
            return <Identification />;
        case EDisplays.VEHICLE:
            return <Vehicle />;
        case EDisplays.OCR_SUMMARY:
            return <OcrSummary />;
        case EDisplays.INDICATIVELY_REFERRED:
            return <IndicativelyReferred />;
        case EDisplays.INDICATIVELY_DECLINED:
            return <IndicativelyDeclined />;
        case EDisplays.INDICATIVELY_APPROVED:
            return <IndicativelyApproved />;
        case EDisplays.EMPLOYMENT:
            return <Employment />;
        case EDisplays.SUPPORTING_DOCS:
            return <SupportingDocs />;
        case EDisplays.MANUAL_VEHICLE:
            return <ManualVehicle />;
        case EDisplays.BUSINESS:
            return <Business />;
        case EDisplays.SOLAR_YOUR_DETAILS:
            return <SolarYourDetails />;
        case EDisplays.SOLAR_INDICATIVELY_APPROVED:
            return <SolarIndicativelyApproved />;
        case EDisplays.OCR_ANIMATION:
            return <OcrAnimation />;
        case EDisplays.PREPPROVAL_ANIMATION:
            return <PreApprovalAnimation />;
        case EDisplays.SOLAR_LOAN_DETAILS:
            return <SolarLoanDetails />;
        case EDisplays.SOLAR_LENDERS_LIST:
            return <SolarLendersList />;
        case EDisplays.SOLAR_OCR_SUMMARY:
            return <SolarOcrSummary />;
        case EDisplays.SOLAR_HOLDING_SCREEN:
            return <SolarHoldingScreen />;
        case EDisplays.SOLAR_LENDER_SPECIFIC_QUESTIONS:
            return <SolarLenderSpecificQuestions />;
        case EDisplays.YOUR_SCORES:
            return <YourScores />;
        case EDisplays.SOLAR_IDENTIFICATOIN:
            return <SolarIdentification />;
        case EDisplays.SOLAR_ASSETS_AND_LIABILITIES:
            return <SolarAssetsAndLiabilities />;
        case EDisplays.SOLAR_SUPPORTING_DOCS:
            return <SolarSupportingDocs />;
        case EDisplays.QUICK_CALCULATOR:
            return <QuickCalculator />;
        case EDisplays.CAMPAIGN_LANDING:
            return <CampaignLanding />;

        // ***** Test Drive ***** //
        case EDisplays.TEST_DRIVE_LANDING_PAGE:
            return <TestDriveLandingPage />;
        case EDisplays.TRUCKSTOP_LANDING_PAGE:
            return <TruckstopLandingPage />;
        case EDisplays.NO_LENDER_HOME:
            return <NoLenderHome />;
        case EDisplays.TEST_DRIVE_OCR_SUMMARY:
            return <TestDriveOcrSummary />;
        case EDisplays.TEST_DRIVE_AGREEMENT:
            return <TestDriveAgreement />;
        case EDisplays.TEST_DRIVE_READY:
            return <TestDriveReady />;

        default:
        case EDisplays.NONE:
            return <None />;
    }
}

export { DisplayScreen };
