import { ELoanType, EQuickQuoteFinanceType } from '@/context/borrower';
import { store } from '@/context/store';

// Rules are:
// Low Doc: ABN & GST > 24mths registration
// Alt Doc: ABN & GST > 12mths registration
// Full Doc: Remainder
// All enums without _LEASE are chattel
// All enums with _LEASE are lease
// Two step process:
// 1. Calculate doc type
// 2. Chattel or lease
// 3. Find the enum
export function getSolarFinanceType(): EQuickQuoteFinanceType {
  const state = store.getState();
  const abnStatusEffectiveFrom =
    state.borrowerReducer.business.abnStatusEffectiveFrom;
  const gstDate = state.borrowerReducer.business.gst;
  const loanType = state.borrowerReducer.loan.loanType;

  // If there is no gst or abn, then it is a full doc loan
  if (!gstDate || !abnStatusEffectiveFrom) {
    if (loanType === ELoanType.LEASE_RENTAL) {
      return EQuickQuoteFinanceType.COMMERCIAL_FULL_DOC_LEASE;
    } else {
      return EQuickQuoteFinanceType.COMMERCIAL_FULL_DOC;
    }
  }

  // Calculate the time in months since the abn and gst were registered
  const abnRegisteredMonths = getTimeInMonthsFromDate(abnStatusEffectiveFrom);
  const gstRegisteredMonths = getTimeInMonthsFromDate(gstDate);

  console.log('abnRegisteredMonths', abnRegisteredMonths);
  console.log('gstRegisteredMonths', gstRegisteredMonths);
  console.log('loanType', loanType);

  if (abnRegisteredMonths > 24 && gstRegisteredMonths > 24) {
    if (loanType === ELoanType.LEASE_RENTAL) {
      return EQuickQuoteFinanceType.COMMERCIAL_LOW_DOC_LEASE;
    } else {
      return EQuickQuoteFinanceType.COMMERCIAL_LOW_DOC;
    }
  } else if (abnRegisteredMonths > 12 && gstRegisteredMonths > 12) {
    if (loanType === ELoanType.LEASE_RENTAL) {
      return EQuickQuoteFinanceType.COMMERCIAL_ALT_DOC_LEASE;
    } else {
      return EQuickQuoteFinanceType.COMMERCIAL_ALT_DOC;
    }
  } else {
    if (loanType === ELoanType.LEASE_RENTAL) {
      return EQuickQuoteFinanceType.COMMERCIAL_FULL_DOC_LEASE;
    } else {
      return EQuickQuoteFinanceType.COMMERCIAL_FULL_DOC;
    }
  }
}

// todo: separate this function out
function getTimeInMonthsFromDate(dateString: string): number {
  const [day, month, year] = dateString.split('/');
  const inputDate = new Date(`${year}-${month}-${day}`);
  const currentDate = new Date();
  const timeDiffInMs = inputDate.getTime() - currentDate.getTime();
  const timeDiffInMonths = Math.floor(
    timeDiffInMs / (1000 * 60 * 60 * 24 * 30)
  );
  return -timeDiffInMonths;
}
