import { setVehicleSource } from '@/context/borrower';
import { store } from '@/context/store';
import { EPartners } from '@/partners/partnerTypes';

export function setInitialVehicleSource(partnerId: EPartners): void {
    const stateVehicleSource = store.getState().borrowerReducer.vehicle.source;
    if (stateVehicleSource !== partnerId) {
        store.dispatch(setVehicleSource(partnerId));
    }
}
