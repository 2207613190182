import { DisplayComponent } from '@/components/DisplayComponent';
import { Header } from '@/components/Header';
import { MultipleUserInput } from '@/components/MultipleUserInput';
import { Slogan } from '@/components/Slogan';
import {
    selectIsAssetTypeKnownViaParams,
    selectIsVehicleKnown,
} from '@/context/borrower';
import {
    EDisplays,
    selectHideVehicleScreen,
    selectIsAssetKnownViaParams,
    selectPreviousDisplay,
} from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';
import { Balloon } from '@/UserInput/Balloon';
import { Deposit } from '@/UserInput/Deposit';
import { LoanTerm } from '@/UserInput/LoanTerm';
import { LoanUsage } from '@/UserInput/LoanUsage';
import { PurchasePrice } from '@/UserInput/PurchasePrice';
import { TradeIn } from '@/UserInput/TradeIn';
import { VehicleAssetType } from '@/UserInput/VehicleAssetType';
import { VehicleCondition } from '@/UserInput/VehicleCondition';
import { VehicleKnown } from '@/UserInput/VehicleKnown';

import { NoLenderHomeButtons } from './NoLenderHomeButtons';

export const testId = 'Loan';

export function NoLenderHome(): JSX.Element {
    // ***** CONSTANTS *****
    const baseId = 'screen-loanDetails';
    const previousDisplay = useAppSelector(selectPreviousDisplay);
    const hideVehicleScreen = useAppSelector(selectHideVehicleScreen);
    const isAssetKnownViaParams = useAppSelector(selectIsAssetKnownViaParams);
    const isAssetTypeKnownViaParams = useAppSelector(
        selectIsAssetTypeKnownViaParams
    );
    const wasPreviousDisplayIndicativelyApproved =
        previousDisplay === EDisplays.INDICATIVELY_APPROVED;
    const isVehicleKnown = useAppSelector(selectIsVehicleKnown);

    function showDoYouKnowTheVehicle(): boolean {
        if (wasPreviousDisplayIndicativelyApproved) return false;

        if (isAssetKnownViaParams) {
            if (!hideVehicleScreen) {
                return true;
            }
            return false;
        }
        return true;
    }

    return (
        <DisplayComponent baseId={baseId} data-testid={testId}>
            <Header />
            <Slogan />
            <PurchasePrice showErrors={false} baseId={baseId} />
            <LoanTerm showErrors={false} baseId={baseId} />

            <LoanUsage showErrors={false} baseId={baseId} />
            {!isAssetTypeKnownViaParams && (
                <VehicleAssetType showErrors={false} baseId={baseId} />
            )}

            <MultipleUserInput>
                <TradeIn showErrors={false} baseId={baseId} />
                <Deposit showErrors={false} baseId={baseId} />
            </MultipleUserInput>
            <MultipleUserInput>
                <Balloon showErrors={false} baseId={baseId} />
                <div className='w-full' />
            </MultipleUserInput>
            {showDoYouKnowTheVehicle() && (
                <VehicleKnown showErrors={false} baseId={baseId} />
            )}
            {showDoYouKnowTheVehicle() && isVehicleKnown && (
                <VehicleCondition showErrors={false} baseId={baseId} />
            )}
            <NoLenderHomeButtons baseId={baseId} />
        </DisplayComponent>
    );
}
