import { ILender } from '@/context/lender';

export function getLowestWeeklyRepayment(
  lenders: ILender[] | null
): number | null {
  if (lenders === null) {
    return null;
  }
  if (!lenders?.length) {
    return null;
  }

  const lowestLender = lenders.reduce((prev, current): ILender => {
    if (!prev.weeklyRepaymentAmount) {
      return current;
    }
    if (!current.weeklyRepaymentAmount) {
      return prev;
    }

    return prev.weeklyRepaymentAmount < current.weeklyRepaymentAmount
      ? prev
      : current;
  });

  return lowestLender.weeklyRepaymentAmount;
}
