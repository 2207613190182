import { setShowWelcomeScreen } from '@/context/display';
import { store } from '@/context/store';
import { isStringBooleanValue } from '@/utils/isStringBooleanValue';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialShowWelcomeScreen(
  parameterShowWelcomeScreen: IParameter,
  partnerShowWelcomeScreen: boolean
): void {
  const { showWelcomeScreen } = store.getState().displayReducer;

  const paramaterBoolean = isStringBooleanValue(parameterShowWelcomeScreen);

  const valueToSave = paramaterBoolean || partnerShowWelcomeScreen;

  if (showWelcomeScreen !== valueToSave) {
    store.dispatch(setShowWelcomeScreen(valueToSave));
  }
}
