import { setVehicleSourceYardId } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialVehicleSourceYardId(sourceYardId: IParameter): void {
  if (!sourceYardId) {
    return;
  }

  const stateSourceYardId =
    store.getState().borrowerReducer.vehicle.sourceYardId;
  if (sourceYardId !== stateSourceYardId) {
    store.dispatch(setVehicleSourceYardId(sourceYardId));
  }
}
