import { Input } from '@/components/Input';
import { selectAcn } from '@/context/borrower';
import { useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'Acn';

export function Acn({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectAcn);

    // ***** Event Handlers *****
    function handleChange(): void {
        return;
    }

    // ***** Render *****
    return (
        <div data-testid={testId} className='w-full'>
            <Input
                title='ACN'
                placeholder='ACN'
                showErrors={showErrors && !currentState}
                onChange={handleChange}
                id={`${baseId}-input-acn`}
                value={currentState ?? ''}
                disabled
                tooltipContent='We collect this automatically using your ABN.'
            />
        </div>
    );
}
