import { useState } from 'react';

import { Combobox, IComboboxOption } from '@/components/Combobox';
import {
    EState,
    selectVehicleRegistrationState,
    setVehicleRegistrationState,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'RegistrationState';

export function RegistrationState({ showErrors, baseId }: IProps): JSX.Element {
    // Initial options
    const options: IComboboxOption[] = [
        { id: 1, value: EState.NSW },
        { id: 2, value: EState.VIC },
        {
            id: 3,
            value: EState.QLD,
        },
        { id: 4, value: EState.ACT },
        { id: 5, value: EState.WA },
        { id: 6, value: EState.NT },
        { id: 7, value: EState.SA },
        { id: 8, value: EState.TAS },
    ];

    const currentState = useAppSelector(selectVehicleRegistrationState);
    const dispatch = useAppDispatch();

    // *****State*****
    const [query, setQuery] = useState<undefined | string>(currentState ?? ''); // Keep track of search

    // *****Functions*****
    // Handle the onChange event for the input.
    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        setQuery(value);
        if (typeof value === 'boolean') return;
        if (value === currentState) return;
        if (value?.length > 3) return;
    }

    // When the user selects an option
    function handleSelect(option: IComboboxOption): void {
        // logic for selecting an option
        if (option.value === currentState) return;
        dispatch(setVehicleRegistrationState(option.value as EState));
    }

    // *****Render*****
    return (
        <div data-testid={testId}>
            <Combobox
                options={options}
                placeholder='State'
                heading='State Name'
                onChange={handleChange}
                query={query ?? ''}
                onSelect={handleSelect}
                showErrors={showErrors}
                baseId={baseId}
                tooltipContent='The state the vehicle is registered in.'
            />
        </div>
    );
}
