import moment from 'moment';

export function getDateFromState(text: Date | undefined): string {
  if (
    text === null ||
    text === undefined ||
    text.toString() === 'Invalid Date'
  ) {
    return '___';
  }
  const date = moment(text);
  return date.format('D/M/YYYY');
}
