import { useEffect, useRef, useState } from 'react';

import { getQuickQuote } from '@/apiCalls/matchingEngine';
import { DisplayComponent } from '@/components/DisplayComponent';
import { Header } from '@/components/Header';
import { selectPartner } from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';

import { TestDriveAgreementButton } from './TestDriveAgreementButton';
import { testDriveAgreementText } from './TestDriveAgreementText';

export const testId = 'TestDriveAgreement';

export function TestDriveAgreement(): JSX.Element {
    // ***** Redux *****
    const partner = useAppSelector(selectPartner);
    const { locationName } = partner;

    // ***** Local State *****
    const [atBottom, setAtBottom] = useState(false);

    // ***** CONSTANTS *****
    const baseId = 'screen-TestDriveAgreement';

    // ***** Scroll Logic *****
    // Ref for the scrollable container
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);

    // Function to check if the user is at the bottom
    const checkScrollPosition = (): void => {
        if (scrollContainerRef.current) {
            const container = scrollContainerRef.current;
            const isAtBottom =
                container.scrollTop + container.clientHeight ===
                container.scrollHeight;
            setAtBottom(isAtBottom);
        }
    };

    // On this screen, after the applicant has filled in their details from OCR, we want to re run quick quote because on the next screen after this, we show the lowest repayment price.
    useEffect(() => {
        getQuickQuote();
    }, []);

    // Attach scroll event listener when the component mounts
    useEffect(() => {
        if (scrollContainerRef.current) {
            const container = scrollContainerRef.current;
            container.addEventListener('scroll', checkScrollPosition);

            // Remove the event listener when the component unmounts
            return () => {
                container.removeEventListener('scroll', checkScrollPosition);
            };
        }
    }, []);

    // ***** Render *****
    return (
        <DisplayComponent baseId={baseId} data-testid={testId}>
            <Header />
            <div className='relative w-full h-full'>
                <h3 className='text-center text-3xl py-3'>Agreement</h3>
                <h2 className='text-center px-6 pb-4'>
                    Please read and agree to {locationName}&apos;s test drive
                    agreement.
                </h2>

                <div
                    ref={scrollContainerRef}
                    className='w-full bg-gray-100 rounded-lg p-2 max-h-[28rem] overflow-y-scroll pb-4 mb-4'
                >
                    {testDriveAgreementText
                        .split('\n\n')
                        .map((paragraph, index) => (
                            <p key={index}>
                                {paragraph}
                                <br />
                            </p>
                        ))}
                </div>
                <TestDriveAgreementButton
                    baseId={baseId}
                    disabled={!atBottom}
                />
            </div>
        </DisplayComponent>
    );
}
