import { postUrl } from '@/apiCalls/fetch';
import { EAddressOwnershipType } from '@/context/borrower';
import { ILender, setAndSortLenders, toggleLenderLoading } from '@/context/lender';
import { store } from '@/context/store';
import { SOLAR_LOAN_TERMS } from '@/utils/constants';
import { getCommissionAmount } from '@/utils/getCommissionAmount';
import { getSolarFinanceType } from '@/utils/getSolarFinanceType';

import { IQuickQuoteRequest } from './getQuickQuoteTypes';

export async function getQuickQuoteSolar(): Promise<void> {
  const url = '/api/quickQuote2Routes/getSolarQuickQuote';
  console.log('getQuickQuoteSolar()');
  // ***** Redux State *****
  const state = store.getState();

  const amount = state.borrowerReducer.loan.purchasePrice;
  console.log('amount', amount);
  // amount is required
  if (!amount) {
    store.dispatch(setAndSortLenders([]));
    return;
  }

  // deposit is required but 0 is valid value
  const deposit = state.borrowerReducer.loan.deposit ?? 0;

  // Assumption is if not owning, they are renting
  const livingStatus =
    state.borrowerReducer.address[0]?.ownershipType ??
    EAddressOwnershipType.OWNER;

  const abnStatusEffectiveFrom =
    state.borrowerReducer.business.abnStatusEffectiveFrom;
  const gstDate = state.borrowerReducer.business.gst;
  const commissionAmount = getCommissionAmount();

  const financeType = getSolarFinanceType();

  const loanTerms = SOLAR_LOAN_TERMS;

  console.log('adfsafds');
  console.log(commissionAmount);

  const body: IQuickQuoteRequest = {
    amount,
    financeType,
    loanTerms,
    deposit,
    livingStatus,
    establishedDate: abnStatusEffectiveFrom ?? '',
    dateRegisteredForGST: gstDate ?? '',
    commissionAmount,
  };

  console.log('1');
  store.dispatch(toggleLenderLoading());
  console.log('2');
  const response = await postUrl<ILender[]>(url, body);
  console.log(response);
  store.dispatch(setAndSortLenders(response));
  store.dispatch(toggleLenderLoading());

  return;
}
