import { EDisplays, EJourneys } from '@/context/display';
import { IPartnerParams } from '@/partners/partnerTypes';
import { EParameters } from '@/utils/setParametersFromParams';

import { ISetInitialDisplay } from './ISetInitialDisplay';

export function getInitialDisplayData(
    partner: IPartnerParams,
    params: URLSearchParams
): ISetInitialDisplay {
    const paramJourney = params.get(EParameters.INITIAL_JOURNEY);
    let initialJourney = EJourneys.FINANCE;
    if (paramJourney?.toUpperCase() === EJourneys.TEST_DRIVE) {
        initialJourney = EJourneys.TEST_DRIVE;
    }
    return {
        showWelcomeScreen: {
            parameter: params.get(EParameters.SHOW_WELCOME_SCREEN),
            partner: partner.welcomeScreen,
        },
        isWhiteLabel: partner.whiteLabel,
        logo: {
            parameter: params.get(EParameters.LOGO_URL),
            partner: partner.logo,
        },
        type: partner.type,
        termsAndConditionsLink: partner.termsAndConditionsLink,
        askTermsAndConditions: params.get(EParameters.TERMS_AND_CONDITIONS),
        hideVehicleScreen: partner.hideSelectVehicleScreen,
        hideSellerScreen: partner.hideSellerScreen,
        waterfallLenders: partner.waterfallLenders,
        paramCustomWaterfallId: params.get(EParameters.CUSTOM_WATERFALL_ID),
        showTotalAmountPayable: partner.showTotalAmountPayable,
        name: {
            parameter: params.get(EParameters.DEALER_NAME),
            partner: partner.dealerName,
        },
        email: {
            parameter: params.get(EParameters.EMAIL),
            partner: partner.dealerEmail,
        },
        phone: {
            parameter: params.get(EParameters.PHONE),
            partner: partner.dealerPhone,
        },
        colors: {
            parameterPrimaryColor: params.get(EParameters.PRIMARY_COLOR),
            parameterSecondaryColor: params.get(EParameters.SECONDARY_COLOR),
            parameterTertiaryColor: params.get(EParameters.TERTIARY_COLOR),
            parameterQuaternaryColor: params.get(EParameters.QUATERNARY_COLOR),

            partnerPrimaryColor: partner.primaryColor,
            partnerSecondaryColor: partner.secondaryColor,
            partnerTertiaryColor: partner.tertiaryColor,
            partnerQuaternaryColor: partner.quaternaryColor,
        },
        partnerId: partner.id,
        partner: partner,
        display: params.get(EParameters.SCREEN) as EDisplays, // TODO: add check here
        lenderSorting: partner.lenderSorting,
        sendLeadEmail: partner.sendLeadEmail,
        availableVehicleAssetTypes: partner.availableVehicleAssetTypes,
        requireGlassGuide: partner.requireGlassGuide,
        illionCodes: partner.illionCodes,
        initialJourney,
        partnerMakeWaterfallIdOverwrite: partner.makeWaterfallIdOverwrite,
        paramQrScanwareId: params.get(EParameters.QR_SCANWARE_ID),
        userFlow: partner.userFlow,
    };
}
