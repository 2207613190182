import { ECampaign, EUserFlow, setLoanTerm } from '@/context/borrower';
import {
    EDisplays,
    EJourneys,
    setDisplay,
    setInitialJourney,
} from '@/context/display';
import { store } from '@/context/store';
import { IPartnerParams } from '@/partners/partnerTypes';

import { getInitialDisplayScreen } from '../getInitialDisplayScreen';

export function setInitialDisplayScreen(
    partner: IPartnerParams,
    initialScreen: EDisplays | null,
    initialJourney: EJourneys,
    userFlow: EUserFlow
): void {
    if (initialJourney !== EJourneys.FINANCE) {
        store.dispatch(setInitialJourney(initialJourney));

        // Journey has hard coded (here) initial display screen
        store.dispatch(setDisplay(EDisplays.TEST_DRIVE_LANDING_PAGE));
        return;
    }

    if (userFlow === EUserFlow.CAMPAIGN) {
        store.dispatch(setDisplay(EDisplays.CAMPAIGN_LANDING));

        const url = window.location.href;
        const isLotus = url.includes('lotus');
        if (isLotus) return;

        if (
            store.getState().displayReducer.partner.campaign ===
            ECampaign.YAMAHA
        ) {
            store.dispatch(setLoanTerm(3)); //todo
            return;
        }
        store.dispatch(setLoanTerm(4)); //todo
        return;
    }

    if (initialScreen) {
        store.dispatch(setDisplay(initialScreen));
        return;
    }

    const initialDisplay = getInitialDisplayScreen(partner);

    const currentDisplay = store.getState().displayReducer.display;

    if (initialDisplay === currentDisplay) return;
    store.dispatch(setDisplay(initialDisplay));
}
