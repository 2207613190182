import React, { useEffect } from 'react';

import { uniqueIdTransfer } from '@/apiCalls/uniqueIDTransferHelper';

import { EEventType } from '../eventType';

interface IDisplayComponentProps {
    baseId: string;
    children: React.ReactNode;
    className?: string; // Add className to accept the prop
    [key: string]: unknown; // add an index signature to accept any other props
}

export function DisplayComponent({
    baseId,
    children,
    className, // Destructure className
    ...rest
}: IDisplayComponentProps): JSX.Element {
    const numChildren = React.Children.count(children);
    useEffect(() => {
        uniqueIdTransfer({
            eventType: EEventType.VISIBLE_ON_SCREEN,
            applicationData: {
                id: `${baseId}-screen`,
            },
        });
    }, []);
    return (
        <div
            id={`${baseId}-screen`}
            className={`_flex flex-col  h-full min-h-[601px] w-[394px] ${className}`} // Use the className prop here
            {...rest}
        >
            {React.Children.map(children, (child, index) => {
                // This sets the last element (ie, the buttons) at the bottom of the screen
                if (index === numChildren - 1) {
                    return (
                        <div className='flex-1 mt-auto px-8 pb-4 _flex items-end'>
                            {child}
                        </div>
                    );
                } else {
                    return <div className='px-8'>{child}</div>;
                }
            })}
        </div>
    );
}
