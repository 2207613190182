import { SummarySection } from '@/components/SummarySection';
import { selectEmail, selectFirstName, selectMobile } from '@/context/borrower';
import { EModals, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getStringFromState } from '@/utils/getStringFromState';
import { isEmail } from '@/utils/isEmail';
import { isMobile } from '@/utils/isMobile';

export const testId = 'ContactDetails';

export interface IProps {
  showErrors: boolean;
  baseId: string;
}

export function ContactDetails({ showErrors, baseId }: IProps): JSX.Element {
  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const firstName = useAppSelector(selectFirstName);
  const email = useAppSelector(selectEmail);
  const phone = useAppSelector(selectMobile);

  // ***** Event Handlers *****
  function handleClick(): void {
    dispatch(setModal(EModals.CONTACT_DETAILS));
  }

  // ***** Helpers *****
  function isComplete(): boolean {
    if (firstName === null || firstName === undefined) return false;
    if (!isEmail(email)) return false;
    if (!isMobile(phone)) return false;
    return true;
  }

  function fullText(): string {
    return `I am ${getStringFromState(
      firstName
    )}. My phone number is ${getStringFromState(
      phone
    )}. My email is ${getStringFromState(email)}.`;
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <SummarySection
        onClick={handleClick}
        id={`${baseId}-button-contactDetailsEdit`}
        text={fullText()}
        heading='Contact Details'
        showErrors={showErrors && !isComplete()}
      />
    </div>
  );
}
