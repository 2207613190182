import { Input } from '@/components/Input';
import { selectOccupation, setOccupation } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'Occupation';

export function Occupation({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectOccupation);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        if (typeof value === 'boolean') return;
        if (value === currentState) return;
        if (value?.length > 50) return;
        dispatch(setOccupation(value));
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Occupation'
                placeholder='Occupation Title'
                showErrors={showErrors && !currentState}
                onChange={handleChange}
                id={`${baseId}-input-occupation`}
                value={currentState?.toLocaleString() ?? ''}
                tooltipContent='Job profession, role or title'
            />
        </div>
    );
}
