import { ModalComponent } from '@/components/ModalComponent';
import { ModalHeader } from '@/components/ModalHeader';
import {
    selectPurchasePrice,
    selectVehicleDescription,
} from '@/context/borrower';
import { useAppSelector } from '@/context/storeHooks';
import { getPrimaryColor } from '@/utils/getPrimaryColor';
import { hexToRgb } from '@/utils/hexToRgb';

import { ScanwareCheckAssetButton } from './ScanwareCheckAssetButton/ScanwareCheckAssetButton';

export const baseId = 'Welcome';

export function ScanwareCheckAsset(): JSX.Element {
    const description = useAppSelector(selectVehicleDescription);
    const price = useAppSelector(selectPurchasePrice);

    // ***** Render *****
    return (
        <ModalComponent
            baseId={baseId}
            className='py-2 flex flex-col space-y-2'
        >
            <ModalHeader
                title='Are these details correct?'
                baseId={baseId}
                className='text-center'
                showCloseButton={false}
            />
            <div className='flex flex-col justify-center items-center space-y-2'>
                <div className='text-center flex w-full'>
                    <p className='w-full text-center flex-center'>
                        Purchase Price:
                    </p>
                    <p
                        style={{
                            backgroundColor: hexToRgb(getPrimaryColor(), 0.5), // This is the same as bg-green-500/50 but works for primaryColor
                        }}
                        className='w-full flex-center rounded-full py-2'
                    >
                        ${price?.toLocaleString()}
                    </p>
                </div>
                <div className='text-center flex w-full py-4'>
                    <p className='w-full text-center flex-center'>
                        Asset Details:
                    </p>
                    <p
                        style={{
                            backgroundColor: hexToRgb(getPrimaryColor(), 0.5), // This is the same as bg-green-500/50 but works for primaryColor
                        }}
                        className='w-full flex-center rounded-full p-2 text-sm'
                    >
                        {description}
                    </p>
                </div>
            </div>
            <ScanwareCheckAssetButton />
        </ModalComponent>
    );
}
