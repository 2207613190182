import { useEffect, useState } from 'react';

import { getModel } from '@/apiCalls/glassGuideVehicleLookup';
import { Combobox, IComboboxOption } from '@/components/Combobox';
import {
    selectVehicleAssetType,
    selectVehicleBuildYear,
    selectVehicleMake,
    selectVehicleModel,
    setVehicleModel,
} from '@/context/borrower';
import {
    IModelApiResults,
    selectVehicleMakeCodeForApiCall,
    selectVehicleModelApiResults,
    setVehicleModelApiResults,
    setVehicleModelCodeForApiCall,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'VehicleModel';

export function VehicleModel({ showErrors, baseId }: IProps): JSX.Element {
    // ** Redux State **

    const model = useAppSelector(selectVehicleModel);
    const dispatch = useAppDispatch();
    const make = useAppSelector(selectVehicleMake);
    const buildYear = useAppSelector(selectVehicleBuildYear);
    const makeCodeForApiCall = useAppSelector(selectVehicleMakeCodeForApiCall);
    const partner = useAppSelector((state) => state.displayReducer.partner.id);
    const assetType = useAppSelector(selectVehicleAssetType);
    const vehicleModelApiResults = useAppSelector(selectVehicleModelApiResults);
    // ** Local State **

    const [query, setQuery] = useState<undefined | string>(model ?? ''); // Keep track of search
    const [loading, setLoading] = useState(false);

    // *****Functions*****
    // Handle the onChange event for the input.
    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        setQuery(value);
        if (typeof value === 'boolean') return;
        if (value === model) return;
        if (value?.length > 4) return;
    }

    // When the user selects an option
    const handleSelect = (option: IComboboxOption): void => {
        if (option.value === model) return;
        dispatch(setVehicleModel(option.value));
        dispatch(setVehicleModelCodeForApiCall(option.code));
    };

    const helperFunction = (
        apiResult: IModelApiResults[] | undefined
    ): IComboboxOption[] | undefined => {
        const newArrayName = apiResult?.map((item, index) => ({
            id: index + 1,
            value: item.name,
            code: item.code,
        }));
        return newArrayName;
    };

    useEffect(() => {
        if (make) {
            if (model) return;
            // makeCodeLocally API call for vehicle model options
            const apiCall = async (): Promise<void> => {
                setLoading(true);
                await getModel(partner, buildYear, makeCodeForApiCall, assetType).then(
                    (response) => {
                        dispatch(setVehicleModelApiResults(response));
                        setLoading(false);
                    }
                );
            };
            apiCall();
        }
    }, [make]);

    useEffect(() => {
        setQuery(model);
    }, [model]);

    return (
        <div data-testid={testId}>
            <Combobox
                options={helperFunction(vehicleModelApiResults) ?? []}
                placeholder='Select model'
                heading='Vehicle Model Options'
                onChange={handleChange}
                query={query ?? ''}
                onSelect={handleSelect}
                showErrors={showErrors && !model}
                loading={loading}
                baseId={baseId}
                tooltipContent="Impacts the lender's view of how much it is worth."
            />
        </div>
    );
}
