import { setSellerPhone } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialSellerPhone(
  parameterPhone: IParameter,
  partnerPhone: IParameter
): void {
  const sellerPhone = store.getState().borrowerReducer.seller.phone;

  const phoneToSave = parameterPhone ?? partnerPhone;

  if (phoneToSave && sellerPhone !== phoneToSave) {
    store.dispatch(setSellerPhone(phoneToSave));
  }
}
