export enum EModals {
    WELCOME = 'WELCOME',
    LOGIN = 'LOGIN',
    REGISTER = 'REGISTER',
    CONTACT_DETAILS = 'CONTACT_DETAILS',
    PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
    ADDRESS_INFORMATION = 'ADDRESS_INFORMATION',
    WHAT_NEXT = 'WHAT_NEXT',
    SAVINGS_OR_MORE = 'SAVINGS_OR_MORE',
    CREDIT_CARDS = 'CREDIT_CARDS',
    MOTOR_VEHICLES_ASSETS = 'MOTOR_VEHICLES_ASSETS',
    WORK_HISTORY = 'WORK_HISTORY',
    APPLICATION_SUBMITTED = 'CONGRATULATIONS',
    APPLICATION_IN_PROGRESS = 'APPLICATION_IN_PROGRESS',
    REAL_ESTATES = 'REAL_ESTATES',
    PERSONAL_LOANS = 'PERSONAL_LOANS',
    APPLICATION_SUBMITTED_SOLAR = 'APPLICATION_SUBMITTED_SOLAR',
    RETURN_USER = 'RETURN_USER',
    LENDER_SPECIFIC_QUESTION_ONE = 'LENDER_SPECIFIC_QUESTION_ONE',
    LENDER_SPECIFIC_QUESTION_TWO = 'LENDER_SPECIFIC_QUESTION_TWO',
    LENDER_SPECIFIC_QUESTION_THREE = 'LENDER_SPECIFIC_QUESTION_THREE',
    DEALERSHIP_WELCOME = 'DEALERSHIP_WELCOME',
    SOLAR_RETURN_USER = 'SOLAR_RETURN_USER',
    ADD_DEPOSIT = 'ADD_DEPOSIT',
    PREVIOUS_BUSINESS_CHECK = 'PREVIOUS_BUSINESS_CHECK',
    OCR_CHECK_INCOMPLETE = 'OCR_CHECK_INCOMPLETE',
    POSTAPPROVED_PERMISSION_MODAL = 'POSTAPPROVED_PERMISSION_MODAL',
    SOLAR_REGISTER = 'SOLAR_REGISTER',
    SOLAR_LOGIN = 'SOLAR_LOGIN',
    DISCLAIMER = 'DISCLAIMER',
    EXPRESS_PRIVACY = 'EXPRESS_PRIVACY',
    SCANWARE_CHECK_ASSET = 'SCANWARE_CHECK_ASSET',
    TEST_DRIVE_CONTACT_DETAILS = 'TEST_DRIVE_CONTACT_DETAILS',
    TEST_DRIVE_PERSONAL_INFORMATION = 'TEST_DRIVE_PERSONAL_INFORMATION',
    TEST_DRIVE_AGREEMENT = 'TEST_DRIVE_AGREEMENT',
    IMOTOR_STOCK = 'IMOTOR_STOCK',
    MCH_DISCLAIMER = 'MCH_DISCLAIMER',
    CAMPAIGN_DISCLAIMER = 'CAMPAIGN_DISCLAIMER',
}
