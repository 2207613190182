import { useSelector } from 'react-redux';

import { getQuickQuoteSolar } from '@/apiCalls/matchingEngine';
import { Button, EButtonType } from '@/components/Button';
import { Header } from '@/components/Header';
import { Loading } from '@/components/Loading';
import { ELoanType, selectLoanType, setLoanType } from '@/context/borrower';
import { EDisplays, setDisplay } from '@/context/display';
import { selectIsLenderLoading, selectLenders } from '@/context/lender';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

import { SolarLenderCard } from '../SolarLenderCard';

export const testId = 'SolarLendersList';

export function SolarLendersList(): JSX.Element {
  // ***** CONSTANTS *****
  const baseId = 'screen-loanDetails';

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const lenders = useSelector(selectLenders);
  const isLenderLoading = useSelector(selectIsLenderLoading);
  const currentState = useAppSelector(selectLoanType);
  // ***** Event Handlers *****

  // ***** Helpers ******
  function handleLeaseRental(): void {
    if (currentState === ELoanType.LEASE_RENTAL) return;

    dispatch(setLoanType(ELoanType.LEASE_RENTAL));
    getQuickQuoteSolar();
  }

  function handleChattelMortgage(): void {
    if (currentState === ELoanType.CHATTEL_MORTGAGE) return;

    dispatch(setLoanType(ELoanType.CHATTEL_MORTGAGE));
    getQuickQuoteSolar();
  }

  if (isLenderLoading) return <Loading />;
  if (lenders?.length === 0) {
    dispatch(setDisplay(EDisplays.INDICATIVELY_REFERRED));
  }

  return (
    <div data-testid={baseId}>
      <Header />
      <div className='px-4 w-full'>
        <p className='font-bold uppercase mt-4'>Loan Type</p>
        <div className='_flex justify-between w-full space-x-4'>
          <Button
            onClick={handleLeaseRental}
            type={
              currentState === ELoanType.CHATTEL_MORTGAGE
                ? EButtonType.LONGER_ROUNDED_FULL_SECONDARY_OUTLINE
                : EButtonType.LONGER_ROUNDED_FULL_PRIMARY
            }
          >
            Lease Rental
          </Button>

          <Button
            type={
              currentState === ELoanType.LEASE_RENTAL
                ? EButtonType.LONGER_ROUNDED_FULL_SECONDARY_OUTLINE
                : EButtonType.LONGER_ROUNDED_FULL_PRIMARY
            }
            onClick={handleChattelMortgage}
          >
            Chattel Mortgage
          </Button>
        </div>
        <div className='_flex flex-col space-y-4 mt-4'>
          {lenders &&
            lenders.map((lender, index) => (
              <SolarLenderCard key={index} lender={lender} />
            ))}
        </div>
      </div>
    </div>
  );
}
