import { useState } from 'react';

import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { SolarNavigationButtons } from '@/components/SolarNavigationButtons';
import { SolarProgressBar } from '@/components/SolarProgressBar';
import {
  EBorrowerCitizenshipStatus,
  EQuickQuoteFinanceType,
  selectCanProvideBankStatements,
  selectGender,
  selectPreviousAssetFinance,
  selectResidencyStatus,
  selectVisaNumber,
} from '@/context/borrower';
import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { selectIsLoggedIn } from '@/context/user';
import { Gender } from '@/UserInput';
import { CreditRatingForSolar } from '@/UserInput/CreditRatingForSolar';
import { PreviousAssetFinance } from '@/UserInput/PreviousAssetFinance';
import { ProvideBankStatements } from '@/UserInput/ProvideBankStatements';
import { ResidencyStatus } from '@/UserInput/ResidencyStatus';
import { getSolarFinanceType } from '@/utils/getSolarFinanceType';

function SolarYourDetails(): JSX.Element {
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState(false);

  // ***** CONSTANTS *****
  const baseId = 'screen-solarbasic';

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const gender = useAppSelector(selectGender);
  const residencyStatus = useAppSelector(selectResidencyStatus);
  const visaNumber = useAppSelector(selectVisaNumber);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const canProvideBankStatemnts = useAppSelector(
    selectCanProvideBankStatements
  );
  const previousAssetFinance = useAppSelector(selectPreviousAssetFinance);
  const financeType = getSolarFinanceType();
  const showProvideBankStatements =
    financeType === EQuickQuoteFinanceType.COMMERCIAL_ALT_DOC_LEASE ||
    financeType === EQuickQuoteFinanceType.COMMERCIAL_ALT_DOC;

  // ***** Event Handlers *****
  function handleBackClick(): void {
    dispatch(setDisplay(EDisplays.SOLAR_LENDERS_LIST));
  }

  function handleNextClick(): void {
    setShowErrors(true);
    if (!isComplete()) return;
    const nextModal = getNextModal();
    if (nextModal !== null) {
      dispatch(setModal(nextModal));
    }
    const nextDisplay = getNextDisplay();
    if (nextDisplay !== null) {
      dispatch(setDisplay(nextDisplay));
    }
  }

  // ***** Helpers *****
  function isComplete(): boolean {
    if (gender === undefined) return false;
    // if (ownershipType === undefined) return false;
    if (residencyStatus === undefined) return false;
    if (
      residencyStatus === EBorrowerCitizenshipStatus.VISA_HOLDER &&
      visaNumber === undefined
    )
      return false;
    if (showProvideBankStatements && canProvideBankStatemnts === undefined)
      return false;
    if (previousAssetFinance === undefined) return false;

    return true;
  }

  function getNextDisplay(): EDisplays | null {
    if (!isLoggedIn) return null;

    return EDisplays.SOLAR_IDENTIFICATOIN;
  }

  function getNextModal(): EModals | null {
    if (isLoggedIn) return null;
    return EModals.SOLAR_REGISTER;
  }

  return (
    <>
      <div data-testid={baseId} className='px-4 h-screen-92'>
        <Header />
        <SolarProgressBar />

        <div className='px-4'>
          <DisplayHeading
            title='Your Details'
            infoText='We just need to ask some basic details about you'
          />
          <Gender showErrors={showErrors} baseId='screen-basic' />
          <ResidencyStatus showErrors={showErrors} baseId='screen-basic' />
          <PreviousAssetFinance showErrors={showErrors} baseId='screen-basic' />
          {showProvideBankStatements && (
            <ProvideBankStatements
              showErrors={showErrors}
              baseId='screen-basic'
            />
          )}
          <CreditRatingForSolar showErrors={showErrors} baseId='screen-basic' />
        </div>
      </div>{' '}
      <SolarNavigationButtons
        handleBackClick={handleBackClick}
        handleNextClick={handleNextClick}
      />
    </>
  );
}

export { SolarYourDetails };
