import {
    ECampaign,
    ELoanUsage,
    EUserFlow,
    EVehicleAssetType,
} from '@/context/borrower';

import { DEFAULT_PARTNER } from './defaultPartner';
import { EMatchingEngineLenders } from './matchingEngineEnums';
import { EPartners, IPartnerParams } from './partnerTypes';

export const YOU_X_DEMO_YAMAHA_PARTNER: IPartnerParams = {
    ...DEFAULT_PARTNER,
    id: EPartners.YOU_X_DEMO_YAMAHA_PARTNER,
    loanUsage: ELoanUsage.PERSONAL,
    dealerName: 'Youx',
    userFlow: EUserFlow.CAMPAIGN,
    campaign: ECampaign.YAMAHA,
    waterfallLenders: [
        {
            lenders: [EMatchingEngineLenders.YAMAHA_FINANCE_CAMPAIGN],
            showLenderDetails: true,
        },
    ],
    assetType: EVehicleAssetType.MOTORCYCLE,
    defaultVehicleAssetType: EVehicleAssetType.MOTORCYCLE,
    logo: 'yamahafinance.png',
    primaryColor: '#ed1c24',
};
