export function isDateValid(
  date: Date | undefined,
  minDate?: Date,
  maxDate?: Date
): boolean {
  if (
    date === null ||
    date === undefined ||
    date.toString() === 'Invalid Date' ||
    (minDate && date < minDate) ||
    (maxDate && date > maxDate)
  )
    return false;
  return true;
}
