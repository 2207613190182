import { DisplayButtons } from '@/components/DisplayButtons';
import { ELoanUsage, selectLoanUsage, selectVehicleBuildYear } from '@/context/borrower';
import {
    EDisplays,
    selectHideSellerScreen,
    selectPreviousDisplay,
    setDisplay,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

interface IManualVehicleButtonsProps {
    setShowErrors: (value: boolean) => void;
    baseId: string;
}

export function ManualVehicleButtons({
    setShowErrors,
    baseId,
}: IManualVehicleButtonsProps): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const buildYear = useAppSelector(selectVehicleBuildYear);
    const hideSellerScreen = useAppSelector(selectHideSellerScreen);
    const loanUsage = useAppSelector(selectLoanUsage);
    const previousDisplay = useAppSelector(selectPreviousDisplay);

    // ***** Event Handlers *****
    function handleBackClick(): void {
        const backScreen = getBackScreen();
        dispatch(setDisplay(backScreen));
    }

    function handleNextClick(): void {
        setShowErrors(true);
        if (!isComplete()) return;
        const nextScreen = getNextScreen();
        dispatch(setDisplay(nextScreen));
    }

    function isComplete(): boolean {
        return buildYear?.toString().length === 4;
    }

    function getNextScreen(): EDisplays {
        if (previousDisplay === EDisplays.INDICATIVELY_APPROVED) {
            return EDisplays.PREPPROVAL_ANIMATION;
        }
        if (hideSellerScreen) {
            if (loanUsage === ELoanUsage.BUSINESS) {
                return EDisplays.BUSINESS;
            }
            return EDisplays.BASIC;
        }
        return EDisplays.SELLER;
    }

    function getBackScreen(): EDisplays {
        return EDisplays.LOAN;
    }

    return (
        <DisplayButtons
            idLeftButton={`${baseId}-button-back`}
            idRightButton={`${baseId}-button-next`}
            rightClick={handleNextClick}
            leftClick={handleBackClick}
        />
    );
}
