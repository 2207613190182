export function minimumDateHelper(limit: number): Date {
  const today = new Date();
  const currentYear = today.getFullYear(); // get current year
  const minDate = new Date(
    currentYear - limit,
    today.getMonth(),
    today.getDate()
  );
  return minDate;
}
