import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { submitApplication } from '@/apiCalls/submitApplication';
import { DisplayButtons } from '@/components/DisplayButtons';
import {
    ESupportingDocCodes,
    selectSupportingDocs,
    setProgressPercent,
} from '@/context/borrower';
import { EDisplays, selectPartnerName, setDisplay } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { TRUCKSTOP_PARTNER_NAME } from '@/partners/truckstop';
import { EConversionType, reportConversion } from '@/utils/reportConversion';
import { SetFinalScreen } from '@/utils/SetFinalScreen';

interface ISupportingDocsButtonsProps {
    setShowErrors: (value: boolean) => void;
    baseId: string;
}

export function SupportingDocsButtons({
    setShowErrors,
    baseId,
}: ISupportingDocsButtonsProps): JSX.Element {
    const dispatch = useAppDispatch();
    const requiredDocs = useAppSelector(
        (state): Record<string, string> | undefined =>
            state.borrowerReducer.selectedLender?.requiredDocuments
    );
    const supportingDocs = useAppSelector(selectSupportingDocs);
    const partnerName = useAppSelector(selectPartnerName);
    const isTruckstop = partnerName === TRUCKSTOP_PARTNER_NAME;

    function hasAllRequiredDocs(): boolean {
        if (!requiredDocs) return true;
        return Object.keys(requiredDocs).every((key) => {
            // Note: These are things that supporting docs skips => Should be removed after we can upload them
            if (
                key === ESupportingDocCodes.BANK_STATEMENTS_12M ||
                key === ESupportingDocCodes.BANK_STATEMENTS_3M ||
                key === ESupportingDocCodes.BANK_STATEMENTS_6M ||
                key === ESupportingDocCodes.PRIVACY ||
                key === ESupportingDocCodes.LENDER_SPECIFIC_PRIVACY ||
                key === ESupportingDocCodes.RATES_TITLE_SEARCH
            ) {
                return true;
            }

            // Skip driver's license for truckstop
            if (
                isTruckstop &&
                key !== ESupportingDocCodes.DRIVERS_LICENSE_BACK &&
                key !== ESupportingDocCodes.DRIVERS_LICENSE_FRONT
            ) {
                return true;
            }

            return supportingDocs[key];
        });
    }

    // Return straight away if no required documents
    if (!requiredDocs) {
        SetFinalScreen();
        return <div>No additional documents required</div>;
    }

    function handleBackClick(): void {
        dispatch(setDisplay(EDisplays.ASSETS_AND_LIABILITIES));
    }

    async function handleNextClick(): Promise<void> {
        if (!hasAllRequiredDocs()) {
            setShowErrors(true);
            return;
        }
        dispatch(setProgressPercent(100));
        // Keep before submitApplication
        createOrUpdateBorrower();
        submitApplication();
        reportConversion(EConversionType.APPLY_TO_LENDER);

        SetFinalScreen();
    }

    return (
        <DisplayButtons
            idLeftButton={`${baseId}-button-back`}
            idRightButton={`${baseId}-button-submit`}
            rightClick={handleNextClick}
            leftClick={handleBackClick}
            rightText='Submit'
        />
    );
}
