import { setVehicleBuildYear } from '@/context/borrower';
import { store } from '@/context/store';
import { CURRENT_YEAR } from '@/utils/constants';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialVehicleBuildYear(buildYear: IParameter): void {
  if (!buildYear) {
    return;
  }

  const intBuildYear = parseInt(buildYear, 10);
  if (
    isNaN(intBuildYear) ||
    intBuildYear < 1900 ||
    intBuildYear > CURRENT_YEAR
  ) {
    return;
  }

  const stateBuildYear = store.getState().borrowerReducer.vehicle.buildYear;

  if (stateBuildYear !== intBuildYear) {
    store.dispatch(setVehicleBuildYear(intBuildYear));
  }
}
