import { EModals, selectModal } from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';

import { ExpressPrivacy } from './Modals/ExpressPrivacy';
import { LenderSpecificQuestionOne } from './Modals/LenderSpecificQuestionOne';
import { LenderSpecificQuestionThree } from './Modals/LenderSpecificQuestionThree';
import { LenderSpecificQuestionTwo } from './Modals/LenderSpecificQuestionTwo';
import { OcrCheckIncomplete } from './Modals/OcrCheckIncomplete';
import { PostApprovedPermissionModal } from './Modals/PostApprovedPermissionModal';
import { PreviousBusinessCheck } from './Modals/PreviousBusinessCheck';
import { SolarReturnUser } from './Modals/SolarReturnUser';
import {
    AddDeposit,
    AddressInformation,
    ApplicationInProgress,
    ApplicationSubmitted,
    CampaignDisclaimer,
    ContactDetails,
    CreditCards,
    DealershipWelcome,
    Disclaimer,
    IMotorStock,
    Login,
    MchDisclaimer,
    MotorVehiclesAsset,
    OtherAssetsSavingsDetails,
    PersonalInformation,
    PersonalLoans,
    RealEstateAsset,
    Register,
    ReturnUser,
    ScanwareCheckAsset,
    SolarApplicationSubmitted,
    TDContactDetailsModal,
    TDPersonalInformationModal,
    Welcome,
    WhatNext,
    WorkHistory,
} from './Modals';

export function ModalScreen(): JSX.Element {
    const modal = useAppSelector(selectModal);

    switch (modal) {
        case EModals.WELCOME:
            return <Welcome />;
        case EModals.LOGIN:
            return <Login />;
        case EModals.REGISTER:
            return <Register />;
        case EModals.CONTACT_DETAILS:
            return <ContactDetails />;
        case EModals.PERSONAL_INFORMATION:
            return <PersonalInformation />;
        case EModals.ADDRESS_INFORMATION:
            return <AddressInformation />;
        case EModals.WHAT_NEXT:
            return <WhatNext />;
        case EModals.SAVINGS_OR_MORE:
            return <OtherAssetsSavingsDetails />;
        case EModals.CREDIT_CARDS:
            return <CreditCards />;
        case EModals.PERSONAL_LOANS:
            return <PersonalLoans />;
        case EModals.MOTOR_VEHICLES_ASSETS:
            return <MotorVehiclesAsset />;
        case EModals.WORK_HISTORY:
            return <WorkHistory />;
        case EModals.APPLICATION_SUBMITTED:
            return <ApplicationSubmitted />;
        case EModals.APPLICATION_IN_PROGRESS:
            return <ApplicationInProgress />;
        case EModals.REAL_ESTATES:
            return <RealEstateAsset />;
        case EModals.APPLICATION_SUBMITTED_SOLAR:
            return <SolarApplicationSubmitted />;
        case EModals.RETURN_USER:
            return <ReturnUser />;
        case EModals.SOLAR_RETURN_USER:
            return <SolarReturnUser />;
        case EModals.LENDER_SPECIFIC_QUESTION_ONE:
            return <LenderSpecificQuestionOne />;
        case EModals.LENDER_SPECIFIC_QUESTION_TWO:
            return <LenderSpecificQuestionTwo />;
        case EModals.LENDER_SPECIFIC_QUESTION_THREE:
            return <LenderSpecificQuestionThree />;
        case EModals.DEALERSHIP_WELCOME:
            return <DealershipWelcome />;
        case EModals.ADD_DEPOSIT:
            return <AddDeposit />;
        case EModals.PREVIOUS_BUSINESS_CHECK:
            return <PreviousBusinessCheck />;
        case EModals.OCR_CHECK_INCOMPLETE:
            return <OcrCheckIncomplete />;
        case EModals.POSTAPPROVED_PERMISSION_MODAL:
            return <PostApprovedPermissionModal />;
        case EModals.DISCLAIMER:
            return <Disclaimer />;
        case EModals.EXPRESS_PRIVACY:
            return <ExpressPrivacy />;
        case EModals.IMOTOR_STOCK:
            return <IMotorStock />;
        case EModals.SCANWARE_CHECK_ASSET:
            return <ScanwareCheckAsset />;
        case EModals.MCH_DISCLAIMER:
            return <MchDisclaimer />;

        case EModals.TEST_DRIVE_CONTACT_DETAILS:
            return <TDContactDetailsModal />;
        case EModals.TEST_DRIVE_PERSONAL_INFORMATION:
            return <TDPersonalInformationModal />;
        case EModals.CAMPAIGN_DISCLAIMER:
            return <CampaignDisclaimer />;
        case null:
        default:
            return <div />;
    }
}
