import { PropsWithChildren } from 'react';

export function ModalSubHeader({
    children,
}: PropsWithChildren): JSX.Element {

    // ***** Render *****
    return (
        <p className='font-semibold text-xs text-secondary' >
            {children}
        </p>
    );
}
