export function extractType(input: string | undefined): string {
    if (input === undefined) return ' ';
    const parts = input.split('-');
    if (parts.length >= 3) {
        return parts[2];
    }
    return '';
}

export function extractScreen(input: string | undefined): string {
    if (input === undefined) return '';
    const parts = input.split('-');
    return parts[1];
}

export function extractLastWord(input: string | undefined): string {
    if (input === undefined) return '';
    const lastHyphenIndex = input.lastIndexOf('-');
    const lastWord = input.slice(lastHyphenIndex + 1);
    const words = lastWord.split(/(?=[A-Z])/);
    const transformedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return transformedWords.join(' ');
}
