import { useState } from 'react';

import { CheckIconCircle } from '@/components/CheckIconCircle';
import { DisplayComponent } from '@/components/DisplayComponent';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { PlusIconCircle } from '@/components/PlusIconCircle';
import { ProgressBar } from '@/components/ProgressBar';
import { selectEmployments, setEmploymentIndex } from '@/context/borrower';
import { EModals, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { isEmploymentComplete } from '@/utils/isEmploymentComplete';

import { EmploymentButtons } from './EmploymentButtons';

export const baseId = 'screen-employment';

export function Employment(): JSX.Element {
    // ***** Local State *****
    const [showErrors, setShowErrors] = useState(false);

    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const employments = useAppSelector(selectEmployments);

    function handleEmploymentClick(index: number): void {
        dispatch(setEmploymentIndex(index));
        dispatch(setModal(EModals.WORK_HISTORY));
    }

    return (
        <DisplayComponent baseId={baseId} data-testid={baseId}>
            <Header />
            <ProgressBar />
            <DisplayHeading
                title='Work Details'
                infoText='We need to know your last three (3) years of employment history.'
            />
            {employments.map((employment, index) => {
                return (
                    <div
                        key={index}
                        className={`_flex flex-col border border-[#BDBDBD] bg-white rounded-md p-4 cursor-pointer mt-4 ${
                            showErrors &&
                            !isEmploymentComplete(employment) &&
                            'bg-error'
                        }`}
                        onClick={(): void => handleEmploymentClick(index)}
                        id={`${baseId}-screen`}
                    >
                        <div className='_flex justify-between items-center hover:cursor-pointer'>
                            <p>
                                {employment.employerName ??
                                    'Incomplete Employment History'}
                            </p>
                            {isEmploymentComplete(employment) ? (
                                <CheckIconCircle />
                            ) : (
                                <div id={`${baseId}-toggle-expandEmployment`}>
                                    <PlusIconCircle />{' '}
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
            <EmploymentButtons baseId={baseId} setShowErrors={setShowErrors} />
        </DisplayComponent>
    );
}
