export enum EVerificationResponseStatus {
  SUCCESSFUL = 0,
  INCORRECT_VERIFICATION_CODE = 1,
  INVALID_MOBILE_PHONE = 2,
  VERIFICATION_WAS_NOT_REQUESTED = 3,
  UNEXPECTED_ERROR = 4,
}

export interface IVerifyCodeReturn {
  status: EVerificationResponseStatus;
}
