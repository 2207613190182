export enum EParameters {
    BUILD_YEAR = 'buildYear',
    MAKE = 'make',
    MODEL = 'model',
    VARIANT = 'variant',
    DEALER_NAME = 'dealerName',
    DEALER_ACCOUNT_NUMBER = 'dealerAccountNumber',
    ABN = 'abn',
    EMAIL = 'email',
    PHONE = 'phone',
    LEAD_EMAIL = 'leadEmail',
    NVIC = 'nvic',
    VIN = 'vin',
    CONDITION = 'condition',
    STOCK_NUMBER = 'stockNumber',
    AMOUNT = 'amount',
    KILOMETERS = 'kilometers',
    SOURCE_YARD_ID = 'sourceYardId',
    LOAN_USAGE = 'loanUsage',
    SHOW_WELCOME_SCREEN = 'showWelcomeScreen',
    PRIMARY_COLOR = 'primaryColor',
    SECONDARY_COLOR = 'secondaryColor',
    TERTIARY_COLOR = 'tertiaryColor',
    QUATERNARY_COLOR = 'quaternaryColor',
    TERMS_AND_CONDITIONS = 'termsAndConditions',
    SCREEN = 'screen',
    ORIGINAL_URL = 'url',
    LEAD_TOKEN = 'lead',
    LOAN_TERM = 'loanTerm',
    LOGO_URL = 'logoUrl',
    VEHICLE_TYPE = 'vehicleType',
    DEPOSIT_AMOUNT = 'depositAmount',
    TRADE_IN_AMOUNT = 'tradeInAmount',
    BALLOON_PERCENTAGE = 'balloonPercentage',
    ASSET_TYPE = 'assetType',
    ASSET_CATEGORY = 'assetCategory',
    PARTNER_OVERWRITE_ID = 'partnerOverwriteId', // IQ partner id overwrite
    INITIAL_JOURNEY = 'journey',
    CUSTOM_WATERFALL_ID = 'waterfallId',
    IQ_PARTNER_EMAIL = 'partnerEmail',
    QR_SCANWARE_ID = 'qrId',
    TEST_DRIVE_DATE = 'testDriveDate', // DD-MM-YYYY
    TEST_DRIVE_TIME = 'testDriveTime', // HH:MM
}

// yeah, they are too special......
export enum EEasycarsParameters {
    BUILD_YEAR = 'year',
    ABN = 'dealerABN',
    EMAIL = 'dealerEmail',
    PHONE = 'dealerPhone',
    STOCK_NUMBER = 'stocknumber',
    DEALER_ACCOUNT_NUMBER = 'dealeraccountnumber',
    DEALER_NAME = 'dealername',
}

export type IParameter = string | null;
