import { Input } from '@/components/Input';
import { selectEmployerPhone, setEmployerPhone } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'EmployerPhone';

export function EmployerPhone({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectEmployerPhone);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        if (typeof value === 'boolean') return;
        if (value === '') {
            dispatch(setEmployerPhone(value));
            return;
        }
        if (value?.length > 10) return;
        const lastDigit = value[value?.length - 1];
        if (isNaN(Number(lastDigit))) {
            return;
        }
        dispatch(setEmployerPhone(value));
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Employer Phone'
                placeholder='Employer Phone'
                showErrors={
                    showErrors && (!currentState || currentState?.length < 10)
                }
                onChange={handleChange}
                id={`${baseId}-input-employerPhone`}
                value={currentState?.toLocaleString() ?? ''}
                showNumpad={true}
                tooltipContent='The best contact number for this employer'
            />
        </div>
    );
}
