import { Input } from '@/components/Input';
import { selectSavings, setSavings } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'Savings';

export function Savings({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectSavings);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        // MAXIMUM
        const MAXIMUM = 999999999;
        const intValue: number | undefined = getNumber(value, MAXIMUM);
        if (intValue && intValue > MAXIMUM) return;
        if (intValue === currentState) return;
        dispatch(setSavings(intValue));
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Savings'
                placeholder='$'
                showErrors={showErrors}
                onChange={handleChange}
                id={`${baseId}-input-savings`}
                value={currentState?.toLocaleString() ?? ''}
                prefix='$'
                showNumpad={true}
                tooltipContent='How much you have in the bank?'
            />
        </div>
    );
}
