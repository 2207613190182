import { DisplayComponent } from '@/components/DisplayComponent';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import { SellerType } from '@/UserInput/SellerType';

import { SellerButtons } from './SellerButtons';

export const testId = 'Seller';

export function Seller(): JSX.Element {
  // ***** CONSTANTS *****
  const baseId = 'screen-sellerDetails';

  return (
    <DisplayComponent baseId={baseId} data-testid={testId}>
      <Header />
      <ProgressBar />
      <DisplayHeading
        title="Seller's Details"
        infoText='To confirm the quote, we will need some information on the seller'
      />
      <SellerType baseId={baseId} showErrors={false} />
      <SellerButtons />
    </DisplayComponent>
  );
}
