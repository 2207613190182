import { useSelector } from 'react-redux';

import { Input } from '@/components/Input';
import {
    ICreditCard,
    selectCreditCardActiveIndex,
    selectCreditCardByIndex,
    setCreditCardByIndex,
} from '@/context/borrower';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getNumber } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
    setShowErrors: (value: boolean) => void;
}

export const testId = 'CreditCardLimit';

export function CreditCardLimit({
    baseId,
    showErrors,
    setShowErrors,
}: IProps): JSX.Element {
    // ***** Redux *****
    const activeCardIndex = useAppSelector(selectCreditCardActiveIndex);
    const creditCard = useSelector((state: RootState) =>
        selectCreditCardByIndex(state, activeCardIndex)
    );
    const creditCardLimit = creditCard?.creditCardLimit;
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        setShowErrors(false);
        if (typeof value !== 'string') return;
        const MAXIMUM = 999999;
        let intValue: number | undefined = getNumber(value, MAXIMUM);
        if (intValue === 0) intValue = undefined;
        const newCreditCard: ICreditCard = {
            ...creditCard,
            creditCardLimit: intValue,
        };
        if (intValue && isNaN(intValue)) {
            newCreditCard.creditCardLimit = undefined;
        }
        dispatch(
            setCreditCardByIndex({
                index: activeCardIndex,
                creditCard: newCreditCard,
            })
        );
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <Input
                title='Limit'
                placeholder='$2000'
                showErrors={showErrors && !creditCardLimit}
                onChange={handleChange}
                id={`${baseId}-input-limit`}
                value={creditCardLimit?.toLocaleString() ?? ''}
                prefix='$'
                showNumpad={true}
                tooltipContent='What is the maximum limit available on this card?'
            />
        </div>
    );
}
