import { useEffect, useState } from 'react';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { createLoginUrl } from '@/apiCalls/createLoginUrl';
import { submitApplication } from '@/apiCalls/submitApplication';
import { DisplayComponent } from '@/components/DisplayComponent';
import { DisplayHeading } from '@/components/DisplayHeading';
import { DocumentUpload } from '@/components/DocumentUpload';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import {
    ESupportingDocCodes,
    selectFirstName,
    selectSelectedLender,
    selectSupportingDocs,
    setProgressPercent,
} from '@/context/borrower';
import { EDisplays, selectPartnerName } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { selectIsLoggedIn, selectLoginUrl, setLoginUrl } from '@/context/user';
import { TRUCKSTOP_PARTNER_NAME } from '@/partners/truckstop';
import { getQrPushLocation } from '@/utils/getQrPushLocation';
import { EConversionType, reportConversion } from '@/utils/reportConversion';
import { SetFinalScreen } from '@/utils/SetFinalScreen';

import { SupportingDocsButtons } from './SupportingDocsButtons';

export const baseId = 'screen-SupportingDocs';

export function SupportingDocs(): JSX.Element {
    // ***** Local State *****
    const [showErrors, setShowErrors] = useState(false);
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

    // ***** Redux State *****
    const supportingDocs = useAppSelector(selectSupportingDocs);
    const selectedLender = useAppSelector(selectSelectedLender);
    const loginUrl = useAppSelector(selectLoginUrl);
    const firstName = useAppSelector(selectFirstName);
    const requiredDocs = selectedLender?.requiredDocuments;
    const dispatch = useAppDispatch();
    const partnerName = useAppSelector(selectPartnerName);
    const isTruckstop = partnerName === TRUCKSTOP_PARTNER_NAME;

    function getExpandedIndex(): number | null {
        return null;
    }

    function documentUpload(index: number): void {
        if (expandedIndex === index) {
            setExpandedIndex(null);
            return;
        }
        setExpandedIndex(index);
    }

    // Get login url for the QR code
    useEffect(() => {
        const setUrl = (url: string): void => {
            dispatch(setLoginUrl(`${url}--${EDisplays.SUPPORTING_DOCS}`));
        };

        if (!loginUrl) {
            createLoginUrl(getQrPushLocation(), firstName).then((url) => {
                setUrl(url);
            });
        }

        const [url, display] = loginUrl.split('--');
        if (display !== EDisplays.SUPPORTING_DOCS) {
            setUrl(url);
        }
    }, []);

    useEffect(() => {
        const number = getExpandedIndex();
        setExpandedIndex(number);
    }, [supportingDocs]);

    if (!requiredDocs) {
        const loggedIn = useAppSelector(selectIsLoggedIn);
        if (!loggedIn) {
            return <div />;
        }

        dispatch(setProgressPercent(100));
        // Keep before submitApplication
        createOrUpdateBorrower();
        submitApplication();
        reportConversion(EConversionType.APPLY_TO_LENDER);

        SetFinalScreen();
        return <div />;
    }

    return (
        <DisplayComponent baseId={baseId} data-testid={baseId}>
            <Header />
            <ProgressBar />

            <DisplayHeading
                title='Supporting Documents'
                infoText='These are the document your selected lender needs from you.'
            />
            <p className='uppercase text-center text-xs font-bold'></p>
            {Object.entries(requiredDocs).map(([key, value], index) => {
                if (
                    key === ESupportingDocCodes.BANK_STATEMENTS_12M ||
                    key === ESupportingDocCodes.BANK_STATEMENTS_3M ||
                    key === ESupportingDocCodes.BANK_STATEMENTS_6M
                ) {
                    // return <UploadBankStatements key={key} code={key} />;
                    return;
                } else if (
                    key === ESupportingDocCodes.PRIVACY ||
                    key === ESupportingDocCodes.LENDER_SPECIFIC_PRIVACY ||
                    key === ESupportingDocCodes.RATES_TITLE_SEARCH ||
                    key === ESupportingDocCodes.LENDER_SPECIFIC_PRIVACY
                ) {
                    return;
                }

                // Truckstop only needs the driver's license
                if (
                    isTruckstop &&
                    key !== ESupportingDocCodes.DRIVERS_LICENSE_BACK &&
                    key !== ESupportingDocCodes.DRIVERS_LICENSE_FRONT
                ) {
                    return;
                }

                {
                    return (
                        <DocumentUpload
                            key={key}
                            baseId={baseId}
                            showErrors={
                                showErrors && !supportingDocs[key]?.length
                            }
                            code={key}
                            document={supportingDocs[key]}
                            value={value}
                            expanded={expandedIndex === index}
                            handleExpanded={(): void => documentUpload(index)}
                        />
                    );
                }
            })}
            <SupportingDocsButtons
                baseId={baseId}
                setShowErrors={setShowErrors}
            />
        </DisplayComponent>
    );
}
