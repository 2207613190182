export interface CampaignVehicle {
    id: number;
    nvic: string;
    displayName: string;
}

export const demoCampaignVehicles: CampaignVehicle[] = [
    {
        id: 1,
        nvic: '0E9924',
        displayName: 'youX 2024 Model A',
    },
    {
        id: 2,
        nvic: '0DLP24',
        displayName: 'youX 2024 Model B',
    },
];

export const demoLotusVehicles: CampaignVehicle[] = [
    {
        id: 1,
        nvic: '0E9924',
        displayName: 'Emira First Edition',
    },
];

export const campaignVehicles: CampaignVehicle[] = [
    {
        id: 1,
        nvic: '0DLO24',
        displayName:
            'FORD RANGER XL 2.0 HI-RIDER (4x2) PY MY24 C/CHAS DIESEL TURBO 4 1996 cc TCDI 6 SP AUTOMATIC',
    },
    {
        id: 2,
        nvic: '0DLP24',
        displayName:
            'FORD RANGER XL 2.0 HI-RIDER (4x2) PY MY24 SUPER CAB CHASSIS DIESEL TURBO 4 1996 cc TCDI 6 SP AUTOMATIC',
    },
    {
        id: 3,
        nvic: '0DLQ24',
        displayName:
            'FORD RANGER XL 2.0 HI-RIDER (4x2) PY MY24 DOUBLE C/CHAS DIESEL TURBO 4 1996 cc TCDI 6 SP AUTOMATIC',
    },
    {
        id: 4,
        nvic: '0DLR24',
        displayName:
            'FORD RANGER XL 2.0 HI-RIDER (4x2) PY MY24 DOUBLE CAB P/UP DIESEL TURBO 4 1996 cc TCDI 6 SP AUTOMATIC',
    },
    {
        id: 5,
        nvic: '0DLS24',
        displayName:
            'FORD RANGER XLS 2.0 HI-RIDER (4x2) PY MY24 DOUBLE CAB P/UP BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 6,
        nvic: '0DLT24',
        displayName:
            'FORD RANGER XLT 2.0 HI-RIDER (4x2) PY MY24 DOUBLE CAB P/UP BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 7,
        nvic: '0DLU24',
        displayName:
            'FORD RANGER XL 2.0 (4x4) PY MY24 C/CHAS BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 8,
        nvic: '0DLV24',
        displayName:
            'FORD RANGER XL 2.0 (4x4) PY MY24 SUPER C/CHAS BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 9,
        nvic: '0DLW24',
        displayName:
            'FORD RANGER XL 2.0 (4x4) PY MY24 SUPER CAB PICK UP BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 10,
        nvic: '0DLX24',
        displayName:
            'FORD RANGER XLT 2.0 (4x4) PY MY24 SUPER CAB PICK UP BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 11,
        nvic: '0DLY24',
        displayName:
            'FORD RANGER XL 2.0 (4x4) PY MY24 DOUBLE C/CHAS DIESEL TURBO 4 1996 cc TCDI 6 SP AUTOMATIC',
    },
    {
        id: 12,
        nvic: '0DLZ24',
        displayName:
            'FORD RANGER XL 2.0 (4x4) PY MY24 DOUBLE C/CHAS BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 13,
        nvic: '0DM024',
        displayName:
            'FORD RANGER XL 2.0 (4x4) PY MY24 DOUBLE CAB P/UP DIESEL TURBO 4 1996 cc TCDI 6 SP AUTOMATIC',
    },
    {
        id: 14,
        nvic: '0DM124',
        displayName:
            'FORD RANGER XL 2.0 (4x4) PY MY24 DOUBLE CAB P/UP BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 15,
        nvic: '0DM224',
        displayName:
            'FORD RANGER XLS 2.0 (4x4) PY MY24 DOUBLE CAB P/UP BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 16,
        nvic: '0DM324',
        displayName:
            'FORD RANGER XLT 2.0 (4x4) PY MY24 DOUBLE CAB P/UP BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 17,
        nvic: '0DM624',
        displayName:
            'FORD RANGER SPORT 2.0 (4x4) PY MY24 DOUBLE CAB P/UP BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 18,
        nvic: '0DM824',
        displayName:
            'FORD RANGER WILDTRAK 2.0 (4x4) PY MY24 DOUBLE CAB P/UP BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 19,
        nvic: '0DMB24',
        displayName:
            'FORD RANGER WILDTRAK X 2.0 (4x4) PY MY24 DOUBLE CAB P/UP DIESEL TWIN TURBO 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 20,
        nvic: '0EHK24',
        displayName:
            'FORD RANGER XL 2.0 HI-RIDER (4x2) PY MY24.5 C/CHAS DIESEL TURBO 4 1996 cc TCDI 6 SP AUTOMATIC',
    },
    {
        id: 21,
        nvic: '0EHL24',
        displayName:
            'FORD RANGER XL 2.0 HI-RIDER (4x2) PY MY24.5 SUPER CAB CHASSIS DIESEL TURBO 4 1996 cc TCDI 6 SP AUTOMATIC',
    },
    {
        id: 22,
        nvic: '0EHM24',
        displayName:
            'FORD RANGER XL 2.0 HI-RIDER (4x2) PY MY24.5 DOUBLE CAB P/UP DIESEL TURBO 4 1996 cc TCDI 6 SP AUTOMATIC',
    },
    {
        id: 23,
        nvic: '0EHN24',
        displayName:
            'FORD RANGER XLT 2.0 HI-RIDER (4x2) PY MY24.5 DOUBLE CAB P/UP BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 24,
        nvic: '0EHO24',
        displayName:
            'FORD RANGER XL 2.0 (4x4) PY MY24.5 C/CHAS BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 25,
        nvic: '0EHP24',
        displayName:
            'FORD RANGER XL 2.0 (4x4) PY MY24.5 SUPER C/CHAS BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 26,
        nvic: '0EHQ24',
        displayName:
            'FORD RANGER XL 2.0 (4x4) PY MY24.5 DOUBLE C/CHAS DIESEL TURBO 4 1996 cc TCDI 6 SP AUTOMATIC',
    },
    {
        id: 27,
        nvic: '0EHR24',
        displayName:
            'FORD RANGER XL 2.0 (4x4) PY MY24.5 DOUBLE C/CHAS BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 28,
        nvic: '0EHS24',
        displayName:
            'FORD RANGER XL 2.0 (4x4) PY MY24.5 DOUBLE CAB P/UP DIESEL TURBO 4 1996 cc TCDI 6 SP AUTOMATIC',
    },
    {
        id: 29,
        nvic: '0EHT24',
        displayName:
            'FORD RANGER XL 2.0 (4x4) PY MY24.5 DOUBLE CAB P/UP BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 30,
        nvic: '0EHV24',
        displayName:
            'FORD RANGER XLS 2.0 (4x4) PY MY24.5 DOUBLE CAB P/UP BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 31,
        nvic: '0EHW24',
        displayName:
            'FORD RANGER XLT 2.0 (4x4) PY MY24.5 SUPER CAB PICK UP BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 32,
        nvic: '0EHX24',
        displayName:
            'FORD RANGER XLT 2.0 (4x4) PY MY24.5 DOUBLE CAB P/UP BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 33,
        nvic: '0EI024',
        displayName:
            'FORD RANGER SPORT 2.0 (4x4) PY MY24.5 DOUBLE CAB P/UP BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
    {
        id: 34,
        nvic: '0EI324',
        displayName:
            'FORD RANGER WILDTRAK 2.0 (4x4) PY MY24.5 DOUBLE CAB P/UP BI TURBO DIESEL 4 1996 cc BTCDI 10 SP AUTOMATIC',
    },
];
