import { setShowTotalAmountPayable } from '@/context/display';
import { store } from '@/context/store';

export function setInitialShowTotalAmountPayable(
  partnerShowTotalAmountPayable: boolean
): void {
  const stateShowTotalAmountPayable =
    store.getState().displayReducer.showTotalAmountPayable;
  if (stateShowTotalAmountPayable !== partnerShowTotalAmountPayable) {
    store.dispatch(setShowTotalAmountPayable(partnerShowTotalAmountPayable));
  }
}
