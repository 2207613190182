import { Button } from '@/components/Button';
import { ModalComponent } from '@/components/ModalComponent';
import { ModalHeader } from '@/components/ModalHeader';
import { selectPurchasePrice, setDeposit } from '@/context/borrower';
import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { selectIsLoggedIn } from '@/context/user';

export const testId = 'AddDeposit';

export function AddDeposit(): JSX.Element {
    const baseId = 'modal-AddDeposit';
    const dispatch = useAppDispatch();
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const nextModal = isLoggedIn ? null : EModals.REGISTER;
    const purchasePrice = useAppSelector(selectPurchasePrice) ?? 40000;

    // ***** Handlers *****
    function handleNoClick(): void {
        dispatch(setModal(nextModal));
        setNextScreen();
    }
    function handle10Click(): void {
        dispatch(setDeposit(purchasePrice * 0.1));
        dispatch(setModal(nextModal));
        setNextScreen();
    }
    function handle20Click(): void {
        dispatch(setDeposit(purchasePrice * 0.2));
        dispatch(setModal(nextModal));
    }

    function setNextScreen(): void {
        if (isLoggedIn) {
            dispatch(setDisplay(EDisplays.IDENTIFICATION));
        }
    }
    // ***** Render *****
    return (
        <ModalComponent baseId={baseId}>
            <ModalHeader
                title='Would You Like To Add A Deposit?'
                baseId={baseId}
            />
            <div className='text-sm text-center _flex flex-col space-y-2 pb-2'>
                <p>Not a property owner?</p>
                <p>
                    The bigger the deposit you put down, the better your access
                    to lending options.
                </p>
            </div>
            <div className='py-2 mx-16 _flex flex-col space-y-2'>
                <Button onClick={handle20Click} id={`${baseId}-button-close`}>
                    Provide 20% deposit
                </Button>
                <Button onClick={handle10Click} id={`${baseId}-button-close`}>
                    Provide 10% deposit
                </Button>
                <Button onClick={handleNoClick} id={`${baseId}-button-close`}>
                    No Thanks
                </Button>
            </div>
        </ModalComponent>
    );
}
