type EnumType = Record<string, string>;

export function getEnumValueFromString<T extends EnumType>(
  enumType: T,
  str: unknown
): T[keyof T] | undefined {
  if (typeof str !== 'string') return undefined;
  for (const key in enumType) {
    if (enumType[key] === str) {
      return enumType[key] as T[keyof T];
    }
  }
  return undefined;
}

