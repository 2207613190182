import { DisplayComponent } from '@/components/DisplayComponent';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import { RemoteImage } from '@/components/RemoteImage';

import { ServicingSuccessButtons } from './ServicingSuccessButtons';

export const baseId = 'screen-servicingSuccess';

export function ServicingSuccess(): JSX.Element {
    return (
        <DisplayComponent baseId={baseId} data-testid={baseId}>
            <Header />
            <ProgressBar />
            <div className='flex-center pt-4 pb-10 relative'>
                <div className='bg-primary h-40 w-40 rounded-full' />
                <RemoteImage fileName='applause.svg' className='absolute' />
            </div>
            <div className='text-center _flex flex-col space-y-2'>
                <h3 className='uppercase text-xl text-primary font-bold '>
                    Great News!
                    <br />
                    What&apos;s Next?
                </h3>
                <p className='text-secondary '>
                    We&apos;ve found some suitable <br />
                    lenders for your loan.
                </p>
                <p className='text-sm'>
                    Keep going to see your rates, choose
                    <br />
                    your lender and submit to them for <br /> final approval.
                </p>
            </div>
            <ServicingSuccessButtons baseId={baseId} />
        </DisplayComponent>
    );
}
