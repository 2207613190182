import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button } from '@/components/Button';
import { setTestDriveProceededToFinance } from '@/context/borrower';
import {
    EDisplays,
    EJourneys,
    setDisplay,
    setInitialJourney,
} from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';

interface ITestDriveReadyButtonProps {
    baseId: string;
}

export function TestDriveReadyButton({
    baseId,
}: ITestDriveReadyButtonProps): JSX.Element {
    const dispatch = useAppDispatch();

    function handleClick(): void {
        dispatch(setDisplay(EDisplays.QUICK_CALCULATOR));
        dispatch(setInitialJourney(EJourneys.FINANCE));
        dispatch(setTestDriveProceededToFinance(true));
        createOrUpdateBorrower();
        return;
    }

    return (
        <Button onClick={handleClick} id={`screen-${baseId}-button-applyNow`}>
            Apply Now
        </Button>
    );
}
