import { setSendLeadEmail } from '@/context/display';
import { store } from '@/context/store';

export function setInitialSendLeadEmail(initialSentLeadEmail: boolean): void {
    const currentState = store.getState().displayReducer.sendLeadEmail;

    if (initialSentLeadEmail !== currentState) {
        store.dispatch(setSendLeadEmail(initialSentLeadEmail));
    }
}
