import { browserName, browserVersion } from 'react-device-detect';

import { getActiveProgressText } from '@/components/ProgressBar';
import { store } from '@/context/store';
import { getSessionId } from '@/utils/localStorage';

import { postUrl } from '../fetch';

import { CustomerRecordInterface } from './customerRecordType';
import { extractLastWord, extractScreen, extractType } from './helper';

export async function uniqueIdTransfer(
    customerRecordObject: CustomerRecordInterface
): Promise<void> {
    const state = store.getState();

    const partnerId = state.displayReducer.partner.id;

    const garbageRegex = new RegExp(
        `^(?=.*(?:<\\w+|alert|script|union|select|passwd|system\\.ini|\\.owasp\\.org|win\\.ini|response\\.write|set-cookie|UNKNOWN|print|cat |etc|type|sleep|timeout|src=x|onerror|prompt|;|{|%|\\$\\{|www\\.google\\.com|\\d{4,})).*$`
    );

    // Check if partnerId or partnerName matches the garbage regex
    if (garbageRegex.test(partnerId)) {
        console.warn('Potential security threat detected. Aborting operation.');
        return;
    }

    // user information
    const financeType = state.borrowerReducer.loan.financeType;
    const userFlow = state.displayReducer.partner.userFlow;
    const condition = state.borrowerReducer.vehicle.condition;
    const selectedLender = state.borrowerReducer.selectedLender;
    const parentScreen = getActiveProgressText();
    const sessionId = getSessionId();

    let status = 'In Progress';
    if (state.borrowerReducer.application.isDeclined) {
        status = 'Declined';
    }
    if (state.borrowerReducer.application.isSubmitted) {
        status = 'Submitted';
    }
    if (state.borrowerReducer.application.isPreApproved) {
        status = 'Pre Approved';
    }

    const url = `/api/tracking`;

    const location = window.location.href;
    let type = 'unknown';
    if (location.includes('localhost')) {
        type = 'test';
    } else if (location.includes('vroom') || location.includes('youx')) {
        if (
            !(
                location.includes('demo') ||
                location.includes('dev') ||
                location.includes('uat')
            )
        ) {
            type = 'iframe';
        } else {
            type = 'test';
        }
    } else {
        type = 'codesnippet';
    }

    const extendedCustomerRecord: CustomerRecordInterface = {
        ...customerRecordObject,
        instanceData: {
            name: 'Vroom',
            webApplication: {
                name: 'Vroom',
                type,
            },
            partnerDetails: {
                id: partnerId,
            },
            journey: userFlow,
        },
        applicationData: {
            ...customerRecordObject.applicationData,
            status,
            label: extractLastWord(customerRecordObject.applicationData?.id),
            type: extractType(
                customerRecordObject.applicationData?.id ?? 'none'
            ),
            screen: extractScreen(customerRecordObject.applicationData?.id),
            parentScreen,
            finance: {
                type: financeType,
                subtype: condition,
            },
            lenderDetails: {
                id: selectedLender?.matchingEngineLenderId,
                selected: selectedLender?.lenderName,
            },
        },
        userDevice: {
            browser: browserName,
            os: browserVersion,
            size: `${window.innerWidth}x${window.innerHeight}`,
        },
        sessionId,
    };

    // We are limiting the data sent to the backend to only the visibleOnScreen events
    if (extendedCustomerRecord.eventType !== 'visibleOnScreen') return;

    void postUrl(url, extendedCustomerRecord);
}
