import {
    IBorrowerSlice,
    initialAddress,
    initialAgreements,
    initialApplication,
    initialBusiness,
    initialEmployment,
    initialExpenses,
    initialIncome,
    initialLoan,
    initialOtherAssetsDetails,
    initialPersonalDetails,
    initialPersonalLoanDetails,
    initialScores,
    initialSellerState,
    initialShownLenders,
    initialSolar,
    initialTestDrives,
    initialVehicle,
} from '../types';

export const initialBorrowerSlice: IBorrowerSlice = {
    address: {
        address: initialAddress,
        addressIndex: 0,
    },
    employments: {
        currentEmploymentIndex: 0,
        employments: initialEmployment,
    },
    expenses: initialExpenses,
    loan: initialLoan,
    seller: initialSellerState,
    vehicle: initialVehicle,
    supportingDocs: {},
    personalDetails: initialPersonalDetails,
    selectedLender: undefined,
    otherAssets: initialOtherAssetsDetails,
    personalLoans: initialPersonalLoanDetails,
    creditCards: {
        creditCards: [],
        creditCardActiveIndex: 0,
    },
    income: initialIncome,
    motorVehicles: {
        motorVehicles: [],
        motorVehicleActiveIndex: 0,
    },
    realEstates: {
        realEstates: [],
        realEstateActiveIndex: 0,
    },
    solar: initialSolar,
    business: initialBusiness,
    scores: initialScores,
    application: initialApplication,
    testDrives: initialTestDrives,
    shownLenders: initialShownLenders,
    agreements: initialAgreements,
};
