import { addShownLenders, IShownLenders } from '@/context/borrower';
import { EDisplays } from '@/context/display';
import { ILender } from '@/context/lender';
import { store } from '@/context/store';

export function updateShownLenders(
    lenders: ILender | ILender[] | undefined | null,
    display: EDisplays,
    shown = true
): void {
    if (!lenders) return;

    // Convert single lender object to an array for uniform processing
    const lendersArray = Array.isArray(lenders) ? lenders : [lenders];

    const shownLenders = lendersArray.map((lender) => ({
        ...lender,
        lenderName: shown ? lender.lenderName : 'Not shown to user',
    }));

    const shownLender: IShownLenders = {
        screenShown: display,
        vehicleUrl: window.location.href,
        shownLenders: shownLenders,
    };

    store.dispatch(addShownLenders([shownLender]));
}
