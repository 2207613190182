import { useEffect, useState } from 'react';
import {
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon,
} from '@heroicons/react/24/solid';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { Button, EButtonType } from '@/components/Button';
import { Tooltip } from '@/components/Tooltip';
import {
    ELoanUsage,
    selectBalloon,
    selectDeposit,
    selectLoanTerm,
    selectLoanUsage,
    selectPurchasePrice,
    selectSelectedLender,
    selectTradeIn,
    setSelectedLender,
} from '@/context/borrower';
import {
    EDisplays,
    selectShowTotalAmountPayable,
    setDisplay,
    setPreviousDisplay,
} from '@/context/display';
import {
    ELenderRepaymentFrequency,
    ILender,
    selectLenderRepaymentFrequency,
    selectLenders,
} from '@/context/lender';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { calculateTotalAmountPayable } from '@/utils/calculateTotalAmountPayable';
import { roundToTwoDecimalPlaces } from '@/utils/roundToTwoDecimalPlaces';

import { IField } from './types';

export interface IProps {
    lender: ILender;
    lowest?: boolean;
    baseId?: string;
}

export const testId = 'LenderCard';

export function LenderCard({ lender, lowest, baseId }: IProps): JSX.Element {
    // ***** Redux *****
    const dispatch = useAppDispatch();
    const repaymentFrequency = useAppSelector(selectLenderRepaymentFrequency);
    const deposit = useAppSelector(selectDeposit);
    const tradeIn = useAppSelector(selectTradeIn);
    const balloon = useAppSelector(selectBalloon);
    const lenders = useAppSelector(selectLenders);
    const selectedLender = useAppSelector(selectSelectedLender);
    const loanTerm = useAppSelector(selectLoanTerm);
    const loanUsage = useAppSelector(selectLoanUsage);
    const loanAmount = useAppSelector(selectPurchasePrice);

    function calculateBalloonValue(): number {
        if (!loanAmount) return 0;
        return (loanAmount * (balloon || 0)) / 100;
    }

    const balloonValue = calculateBalloonValue();

    const isSelected =
        selectedLender && selectedLender?.lenderName === lender.lenderName;

    const showTotalAmountPayable = useAppSelector(selectShowTotalAmountPayable);
    const totalAmountPayable = calculateTotalAmountPayable(
        loanTerm,
        lender.monthlyRepaymentAmount
    );

    // ***** Local State *****
    const [isExpanded, setIsExpanded] = useState(false);
    const [firstLenderExpanded, setFirstLenderExpanded] = useState(false);
    const result = isExpanded || firstLenderExpanded;
    const firstLender = lenders?.[0];
    const firstLenderTrue = firstLender === lender;

    // ***** Helpers *****

    function toggleExpandedFirstLender(): void {
        setFirstLenderExpanded((expanded) => !expanded);
    }

    function toggleExpanded(): void {
        setIsExpanded((expanded) => !expanded);
    }

    // ***** Event Handlers *****

    function handleChooseLender(): void {
        if (lender === selectedLender) return;
        dispatch(setSelectedLender(lender));
        createOrUpdateBorrower();
    }

    function handleEditLoanStructure(): void {
        dispatch(setPreviousDisplay(EDisplays.INDICATIVELY_APPROVED));
        dispatch(setDisplay(EDisplays.LOAN));
    }

    useEffect(() => {
        if (firstLender === lender) {
            setFirstLenderExpanded(true);
        }
        if (selectedLender === firstLender) {
            setFirstLenderExpanded(false);
        }
        if (lenders?.length === 1) {
            dispatch(setSelectedLender(lenders[0]));
        }
    }, []);

    // Fields to be displayed in the card.
    // Could be any number: the first 3 will be the top row, the rest will
    // be split into 3 columns and occupy as many rows as needed.
    let fields: IField[] = [
        {
            title:
                repaymentFrequency === ELenderRepaymentFrequency.MONTHLY
                    ? 'Monthly Repayments'
                    : repaymentFrequency === ELenderRepaymentFrequency.WEEKLY
                    ? 'Weekly Repayments'
                    : 'Fortnightly Repayments',
            tooltip: 'The amount debited from your account.',
            value: `$${
                repaymentFrequency === ELenderRepaymentFrequency.MONTHLY
                    ? lender.monthlyRepaymentAmount?.toLocaleString()
                    : repaymentFrequency ===
                      ELenderRepaymentFrequency.FORTNIGHTLY
                    ? lender.fortnightlyRepaymentAmount?.toLocaleString()
                    : lender.weeklyRepaymentAmount?.toLocaleString()
            }`,
        },
        {
            title: 'Advertised <br /> Rate',
            tooltip: 'The actual interest rate of this loan (per year).',
            value: `${roundToTwoDecimalPlaces(
                lender.advertisedRateInPercent
            )}%`,
        },
        {
            title: 'Comparison Rate',
            tooltip:
                'This includes the interest rate as well as fees and charges.',
            value: `${roundToTwoDecimalPlaces(
                lender.comparisonRateInPercent
            )}%`,
        },

        {
            title: 'Upfront <br /> Fee',
            tooltip:
                "The lender's establishment fee which is already factored into your repayments.",
            value: `$${lender.upfrontFeeAmount?.toLocaleString() ?? '-'}`,
        },
        {
            title: 'Ongoing <br /> Fee',
            tooltip:
                "The lender's monthly account keeping fee which is already factored into your repayments.",
            value: `$${roundToTwoDecimalPlaces(
                lender.monthlyOngoingFeeAmount
            )}`,
        },
        {
            title: 'Turnaround Time (Days)',
            tooltip: 'The time this lender needs to assess your application',
            value: lender.turnaroundTimeInDays?.toLocaleString() ?? '-',
        },

        {
            title: 'Deposit',
            tooltip: "You can change this by selecting 'Edit Loan Structure'",
            value: `$${deposit?.toLocaleString() ?? 0}`,
        },
        {
            title: 'Trade In',
            tooltip: "You can change this by selecting 'Edit Loan Structure'",
            value: `$${tradeIn?.toLocaleString() ?? 0}`,
        },
        {
            title: 'Balloon Percent',
            tooltip:
                "The % of the loan amount you want to pay as a lump sum at the end. You can change this by selecting 'Edit Loan Structure'",
            value: `${balloon ?? 0}%`,
        },
        {
            title: 'Balloon Value',
            tooltip:
                "The dollar amount you want to pay as a lump sum at the end. You can change this by selecting 'Edit Loan Structure'",
            value: `$${balloonValue.toLocaleString()}`,
        },
    ];

    // Remove the third column (Comparison Rate) the loan usage is business
    if (loanUsage === ELoanUsage.BUSINESS) {
        fields = fields.filter((field) => field.title !== 'Comparison Rate');
    }
    // Remove balloon value if loan usage is personal => Too many fields because comparison rate is also shown
    else if (loanUsage === ELoanUsage.PERSONAL) {
        fields = fields.filter((field) => field.title !== 'Balloon Value');
    }

    // Separate the first 3 fields from the rest
    const topRow: IField[] = fields.slice(0, 3);

    // Separate the rest of the fields into 3 columns
    const bottomRow: IField[][] = [];

    for (let i = 3; i < fields.length; i += 3) {
        bottomRow.push(fields.slice(i, i + 3));
    }

    console.log('bottomRow', bottomRow);
    console.log('lenderCard()');

    // ***** Render *****
    return (
        <div
            data-testid={testId}
            className={` borderOutline py-2 pb-2 _flex flex-col space-y-4  bg-white px-2 ${
                isSelected ? 'border-green-600 border-2' : ''
            }`}
        >
            <div className='_flex justify-between'>
                {lowest && (
                    <div className='w-full _flex space-x-2 items-center'>
                        <div className='h-3 w-3 p-[2px] text-white bg-green-500 rounded-full'>
                            <CheckIcon />
                        </div>
                        <p className={`text-secondary text-[11px]`}>
                            Lowest Repayment
                        </p>
                    </div>
                )}
                {/* Edit Loan Structure Button condition */}
                {selectedLender && (
                    <div className='w-full _flex justify-end'>
                        <div className='w-auto'>
                            <Button
                                id={`${baseId}-button-editLoanStructure`}
                                type={
                                    EButtonType.EDIT_BUTTON_OCR_SUMMARY_SECONDARY_OUTLINE
                                }
                                onClick={handleEditLoanStructure}
                            >
                                <p className='text-[8px]'>
                                    Edit Loan Structure
                                </p>
                            </Button>
                        </div>
                    </div>
                )}
            </div>

            {/* Always Visible */}
            <div className='grid grid-cols-4 relative'>
                <div className='w-[60px] h-[40px] _flex flex-center justify-center absolute top-5'>
                    <img
                        src={`https://matching-engine.driveiq.com.au/wp-content/uploads/2022/08/${lender.matchingEngineLenderId}.svg`}
                        className='w-[50px] extraSmall:w-full'
                    />
                </div>
                <div className='w-20 h-1' />
                {topRow.map((field, index) => {
                    return (
                        <div
                            key={`topRow-${index}`}
                            className='_flex flex-col  justify-between'
                        >
                            <Tooltip
                                maxWidth='180%'
                                content={field.tooltip || ''}
                            >
                                <h3
                                    dangerouslySetInnerHTML={{
                                        __html: field.title,
                                    }}
                                    className='font-bold text-[10px] text-textBody '
                                />
                            </Tooltip>
                            <h4 className='text-primary font-bold'>
                                {field.value}
                            </h4>
                        </div>
                    );
                })}
            </div>
            {/* Sometimes Visible */}
            {result && (
                <>
                    {bottomRow.map((row, rowIndex) => {
                        return (
                            <div
                                key={`bottomRow-${rowIndex}`}
                                className='grid grid-cols-4'
                            >
                                <div />
                                {row.map((field, colIndex) => {
                                    return (
                                        <div
                                            key={`col-${rowIndex}-${colIndex}`}
                                            className='_flex flex-col  justify-between'
                                        >
                                            <Tooltip
                                                maxWidth='180%'
                                                content={field.tooltip || ''}
                                            >
                                                <h3
                                                    dangerouslySetInnerHTML={{
                                                        __html: field.title,
                                                    }}
                                                    className='font-bold text-[10px] text-textBody '
                                                />
                                            </Tooltip>
                                            <h4 className='text-primary font-bold'>
                                                {field.value}
                                            </h4>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                    {/* Total Amount Payable */}
                    {showTotalAmountPayable ? (
                        <div>
                            <p className='text-sm text-center'>
                                Total Amount Payable over{' '}
                                <span className='font-bold'>{loanTerm}</span>{' '}
                                years is $
                                <span className='font-bold'>
                                    {totalAmountPayable?.toLocaleString()}.
                                </span>
                            </p>
                        </div>
                    ) : null}
                    {/* <div className={`${isOnlyLender ? 'px-16' : 'px-4 _flex space-x-4'}`}> */}

                    <div className='_flex w-full space-x-[25px] pt-4 px-4'>
                        <Button
                            id={`${baseId}-button-chooseLender`}
                            onClick={handleChooseLender}
                            type={
                                selectedLender
                                    ? EButtonType.PRIMARY_DISABLED
                                    : EButtonType.PRIMARY
                            }
                        >
                            <p className='text-xs'>Choose Lender</p>
                        </Button>
                    </div>
                    {/* </div> */}
                </>
            )}
            {/* Expanded Toggle */}
            <div className='_flex text-sm'>
                <div
                    className='w-full justify-end _flex px-2 items-center group cursor-pointer'
                    onClick={
                        firstLenderTrue
                            ? toggleExpandedFirstLender
                            : toggleExpanded
                    }
                >
                    <div className='h-5 w-5 group-hover:scale-125 transform delay-75 ease-in-out'>
                        {result ? (
                            <div
                                className='border rounded-full border-textPrimary'
                                id={`${baseId}-toggle-expandLenderCard`}
                            >
                                <ChevronUpIcon />
                            </div>
                        ) : (
                            <div
                                className='border rounded-full border-textPrimary'
                                id={`${baseId}-toggle-expandLenderCard`}
                            >
                                <ChevronDownIcon />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
