import { RadioGroup } from '@/components/RadioGroup';
import {
    selectIsVehicleRegistrationKnown,
    setIsVehicleRegistrationKnown,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getBoolean } from '@/utils/valueFormat';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'RegoKnown';

export function RegoKnown({ baseId, showErrors }: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectIsVehicleRegistrationKnown);
    const dispatch = useAppDispatch();

    // ***** Event Handlers *****
    function handleChange(value: string | boolean | undefined): void {
        const booleanValue = getBoolean(value);
        dispatch(setIsVehicleRegistrationKnown(booleanValue));
        if (!booleanValue) dispatch(setIsVehicleRegistrationKnown(false));
    }

    // ***** Render *****
    const options = [
        {
            label: 'Yes',
            value: 'true',
            id: `${baseId}-button-regoKnown-yes`,
        },
        {
            label: 'Not Yet',
            value: 'false',
            id: `${baseId}-button-regoKnown-no`,
        },
    ];
    return (
        <div data-testid={testId}>
            <RadioGroup
                title='Do you know the vehicle registration number?'
                showErrors={showErrors}
                onChange={handleChange}
                options={options}
                value={`${currentState}`}
                tooltipContent='We can normally find the car details and populate this info for you.'
            />
        </div>
    );
}
