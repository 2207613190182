import { lookupVehicle } from '@/apiCalls/assetLookup';
import { setIsAssetKnownViaParams } from '@/context/display';
import { store } from '@/context/store';

export async function lookupInitialVehicle(partnerId: string): Promise<void> {
    await lookupVehicle(partnerId);

    const description = store.getState().borrowerReducer.vehicle.description;

    if (!description) {
        // If description is not set, then lookup failed
        return;
    }

    store.dispatch(setIsAssetKnownViaParams(true));
}
