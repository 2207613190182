import { selectFullLogoUrl, selectLogo } from '@/context/display';
import { useAppSelector } from '@/context/storeHooks';
import { IMAGE_URL } from '@/utils/constants';

export function PartnerLogo(): JSX.Element {
    const logo = useAppSelector(selectLogo);

    const fullLogoUrl = useAppSelector(selectFullLogoUrl);

    function getLogoUrl(): string {
        if (fullLogoUrl) return logo;
        return `${IMAGE_URL}/${logo}`;
    }

    return (
        <img
            style={{
                maxWidth: '250px',
                height: '100%',
                maxHeight: '40px',
                padding: '1px 0',
            }}
            src={getLogoUrl()}
            alt='logo'
            className={`object-contain h-full ${
                logo === 'drive.svg' && 'h-1/2'
            }`}
        />
    );
}
