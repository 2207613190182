import { useEffect, useState } from 'react';

import { getWaterfallById } from '@/apiCalls/getWaterfallById';
import { getQuickQuote } from '@/apiCalls/matchingEngine';
import { Footer } from '@/components/Footer';
import { Loading } from '@/components/Loading';
import { EventSubscriber } from '@/components/SseSubscriber';
import {
    deleteSupportingDoc,
    ISupportingDoc,
    selectSellerName,
    selectVehicleMake,
    setSupportingDocs,
} from '@/context/borrower';
import {
    selectDisplay,
    selectInitialLoad,
    selectIsLoaded,
    selectModal,
    setWaterfallLenders,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { setParametersFromParams } from '@/utils/setParametersFromParams';
import { EEvents } from '@/utils/subscribeToSseEvent';

import { DisplayScreen } from '../DisplayScreen';
import { ModalScreen } from '../ModalScreen';

function Vroom(): JSX.Element {
    // ***** Redux *****
    const isLoaded = useAppSelector(selectIsLoaded) ?? false;
    const initialLoad = useAppSelector(selectInitialLoad) ?? false;

    // ***** Helper Functions *****
    if (!initialLoad) {
        const fetchData = async (): Promise<void> => {
            await setParametersFromParams();
            // Any other code you need after the parameters are set
        };

        fetchData();
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useEffect(() => {}, [isLoaded]);

    // ***** Render *****
    if (!isLoaded) return <Loading />;
    return <RealApp />;
}

export { Vroom };

function RealApp(): JSX.Element {
    const [displayScroll, setDisplayScroll] = useState<number>(0);

    const dispatch = useAppDispatch();
    const modal = useAppSelector(selectModal);
    const display = useAppSelector(selectDisplay);
    const sellerName = useAppSelector(selectSellerName);
    const assetMake = useAppSelector(selectVehicleMake);
    const partnerMakeWaterfallIdOverwrite = useAppSelector(
        (state) => state.displayReducer.partner.makeWaterfallIdOverwrite
    );

    // Whenever the asset make changes, check if there is a waterfall id overwrite for that make
    // And if there is, get the waterfall and set it in the store
    // Run the getQuickQuote function again to update the lenders
    useEffect(() => {
        if (assetMake && partnerMakeWaterfallIdOverwrite) {
            const lowercaseMake = assetMake
                .toLowerCase()
                .replace(/[^a-z0-9]/g, '');

            const waterfallMakeOverwriteArray = Object.entries(
                partnerMakeWaterfallIdOverwrite
            );
            for (const [make, waterfallId] of waterfallMakeOverwriteArray) {
                if (
                    make.toLowerCase().replace(/[^a-z0-9]/g, '') ===
                    lowercaseMake
                ) {
                    getWaterfallById(waterfallId)
                        .then((response) => {
                            if (response?.tiers) {
                                dispatch(setWaterfallLenders(response.tiers));
                                getQuickQuote();
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });

                    // Leave the loop after the first match
                    break;
                }
            }
        }
    }, [assetMake]);

    return (
        <>
            <EventSubscriber
                eventName={EEvents.DOCUMENT_DELETE}
                onMessage={({ documentId }: { documentId: string }): void => {
                    dispatch(deleteSupportingDoc(documentId));
                }}
            />
            <EventSubscriber
                eventName={EEvents.DOCUMENT_UPLOAD}
                onMessage={(message: {
                    [code: string]: ISupportingDoc[];
                }): void => {
                    Object.entries(message).forEach(([key, value]) => {
                        dispatch(
                            setSupportingDocs({ code: key, document: value })
                        );
                    });
                }}
            />
            <div className='w-full h-full flex-center'>
                {/* Container for the actual application height and width */}
                <div
                    onScroll={(event): void =>
                        setDisplayScroll(event.currentTarget.scrollTop)
                    }
                    data-testid=''
                    className={`h-full w-[394px] min-w-[394px] flex-center overflow-x-hidden relative bg-white ${
                        modal && 'overflow-y-hidden bg-black bg-opacity-50'
                    }`}
                >
                    <div
                        className={`mx-auto h-full w-full max-w-md  _flex flex-col relative`}
                    >
                        {modal && (
                            <div
                                className='fixed inset-0 bg-black opacity-30 z-50'
                                style={{ zIndex: 1 }}
                            />
                        )}
                        <div className='flex-grow'>
                            <DisplayScreen />
                        </div>
                        <Footer />
                    </div>
                    {modal !== null && (
                        <div
                            className='h-full w-full z-50 absolute  top-0 flex-center'
                            style={{ marginTop: `${displayScroll}px` }}
                        >
                            <div className='bg-white self-center w-[90%] rounded-xl max-h-[80%] mx-auto overflow-y-auto overflow-x-hidden p-[20px]'>
                                <ModalScreen />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
