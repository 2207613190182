export function returnDate(value: Date | string | undefined): Date | undefined {
  console.log(typeof value, 'typeof value');
  if (value instanceof Date) {
    console.log(value, 'returning value');
    return value;
  }
  if (typeof value === 'string') {
    console.log(new Date(value), 'returning new Date(value)');
    return new Date(value);
  }
  return undefined;
}
