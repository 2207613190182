import { useSelector } from 'react-redux';

import { Button, EButtonType } from '@/components/Button';
import { ModalComponent } from '@/components/ModalComponent';
import { ModalHead } from '@/components/ModalHead';
import { ModalHeader } from '@/components/ModalHeader';
import { ModalSubHeader } from '@/components/ModalSubHeader';
import {
    ELoanUsage,
    EVehicleAssetType,
    selectAddressByIndex,
    selectFirstName,
    selectLoanUsage,
    selectVehicleAssetType,
} from '@/context/borrower';
import {
    EModals,
    selectAgreedToTermsAndConditions,
    selectSignupOptions,
    setModal,
} from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { FirstName } from '@/UserInput/FirstName';
import { LoginCode } from '@/UserInput/LoginCode';
import { MobileNumber } from '@/UserInput/MobileNumber';
import { PostCode } from '@/UserInput/PostCode';
import { TermsAndConditions } from '@/UserInput/TermsAndConditions';

export const testId = 'Register';

export function Register(): JSX.Element {
    // ***** Constants *****
    const baseId = 'modal-register';

    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const loanUsage = useAppSelector(selectLoanUsage);
    const defaultTermsAndConditionsAgreed = useAppSelector(
        selectAgreedToTermsAndConditions
    );
    const firstName = useAppSelector(selectFirstName);
    const assetType = useAppSelector(selectVehicleAssetType);
    const signupOptions = useAppSelector(selectSignupOptions);
    const address = useSelector((state: RootState) =>
        selectAddressByIndex(state, 0)
    );
    const { postcode } = address;

    const ridingTypes = [
        EVehicleAssetType.MOTORBIKES_REG,
        EVehicleAssetType.MOTORBIKES_UNREG,
        EVehicleAssetType.MOTORCYCLE,
    ];

    const title =
        assetType && ridingTypes.includes(assetType)
            ? "Let's Go Riding!"
            : "Let's Go Driving!";

    // ***** Handlers *****
    function handleLoginClick(): void {
        dispatch(setModal(EModals.LOGIN));
    }

    function isReadyToSendCode(): boolean {
        if (
            loanUsage === ELoanUsage.BUSINESS &&
            !defaultTermsAndConditionsAgreed.isDefaultPrivacyAgreed?.agreed
        ) {
            return false;
        }
        if (!firstName || firstName.length <= 3) {
            // Minimum 3 characters
            return false;
        }

        if (signupOptions?.zipcode) {
            // Postcode is required for ZIP_CODE_SIGNUP
            if (!postcode || postcode.length < 4) {
                return false;
            }
        }
        return true;
    }

    // ***** Render *****
    return (
        <ModalComponent baseId={baseId} className='relative'>
            {/* Header Text */}
            <ModalHead>
                <ModalHeader title={title} baseId='Login' />
                <ModalSubHeader>
                    We will send you a one time passcode. <br />
                    No spam, promise.
                </ModalSubHeader>
            </ModalHead>
            <FirstName baseId={baseId} showErrors={false} />
            <MobileNumber baseId={baseId} showErrors={false} />
            {/* Show postcode for signup for specific user flow*/}
            {signupOptions?.zipcode && (
                <PostCode baseId={baseId} showErrors={false} />
            )}
            {/* Only display the default privacy checkbox for the Business applications */}
            {loanUsage === ELoanUsage.BUSINESS && (
                <>
                    <TermsAndConditions baseId={baseId} />
                    {/* <InsuranceOptIn baseId={baseId} /> */}
                </>
            )}
            <LoginCode
                baseId={baseId}
                showErrors={false}
                disabled={!isReadyToSendCode()}
            />

            <>
                <Button
                    onClick={handleLoginClick}
                    id={`${baseId}-button-signUp`}
                    title='Login'
                    type={EButtonType.SECONDARY_OUTLINE}
                />
            </>
        </ModalComponent>
    );
}
