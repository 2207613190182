import { getMchStockDetails } from '@/actions/getMchStockDetails';
import { setVehicleStockNumber } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialVehicleStockNumber(
    stockNumberParam: IParameter
): void {
    if (!stockNumberParam) {
        return;
    }

    const stateStockNumber =
        store.getState()?.borrowerReducer?.vehicle?.stockNumber;
    if (stateStockNumber !== stockNumberParam) {
        // Setting the stock number
        store.dispatch(setVehicleStockNumber(stockNumberParam));

        // Using the stock number to get the vehicle details
        void getMchStockDetails(false, stockNumberParam);
    }
}
