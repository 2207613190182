import { PayloadAction } from '@reduxjs/toolkit';

import { EEmploymentStatus, IEmployment, IEmploymentByIndex } from '../types';

export const employmentReducers = {
  setEmployments: (state, action: PayloadAction<IEmployment[]>): void => {
    state.employments.employments = action.payload;
  },
  setEmploymentStatusByIndex: (
    state,
    action: PayloadAction<IEmploymentByIndex>
  ): void => {
    state.employments.employments[action.payload.index] =
      action.payload.employment;
  },
  setOccupation: (state, action: PayloadAction<string | undefined>): void => {
    state.employments.employments[
      state.employments.currentEmploymentIndex
    ].occupation = action.payload;
  },
  setEmployerName: (state, action: PayloadAction<string | undefined>): void => {
    state.employments.employments[
      state.employments.currentEmploymentIndex
    ].employerName = action.payload;
  },
  setEmploymentStatus: (
    state,
    action: PayloadAction<EEmploymentStatus | undefined>
  ): void => {
    state.employments.employments[
      state.employments.currentEmploymentIndex
    ].status = action.payload;
  },
  setEmployerPhone: (
    state,
    action: PayloadAction<string | undefined>
  ): void => {
    state.employments.employments[
      state.employments.currentEmploymentIndex
    ].employerPhone = action.payload;
  },
  setEmploymentYears: (
    state,
    action: PayloadAction<number | undefined>
  ): void => {
    state.employments.employments[
      state.employments.currentEmploymentIndex
    ].years = action.payload;
  },
  setEmploymentMonths: (
    state,
    action: PayloadAction<number | undefined>
  ): void => {
    state.employments.employments[
      state.employments.currentEmploymentIndex
    ].months = action.payload;
  },
  setEmploymentIndex: (state, action: PayloadAction<number>): void => {
    const currentLength = state.employments.employments?.length;
    if (action.payload >= currentLength) {
      state.employments.employments[action.payload] = {};
    }
    state.employments.currentEmploymentIndex = action.payload;
  },
};
