import { setInitialLoad, setIsLoaded } from '@/context/display';
import { store } from '@/context/store';

import {
    lookupInitialVehicle,
    setInitialVehicleBuildYear,
    setInitialVehicleCategory,
    setInitialVehicleCondition,
    setInitialVehicleIsKnown,
    setInitialVehicleKilometers,
    setInitialVehicleMake,
    setInitialVehicleModel,
    setInitialVehicleNvic,
    setInitialVehiclePrice,
    setInitialVehicleSource,
    setInitialVehicleSourceYardId,
    setInitialVehicleStockNumber,
    setInitialVehicleType,
    setInitialVehicleVariant,
    setInitialVehicleVin,
    setVehicleUrlFromSite,
} from './helpers';
import { ISetInitialVehicle } from './ISetInitialVehicle';

export async function setInitialVehicle({
    vehicleCondition,
    stockNumber,
    vehiclePrice,
    vehicleKilometers,
    nvic,
    vin,
    sourceYardId,
    buildYear,
    make,
    model,
    variant,
    partnerId,
    originalUrl,
    initialVehicleAssetType,
    initialVehicleAssetCategory,
}: ISetInitialVehicle): Promise<void> {
    setVehicleUrlFromSite(originalUrl);

    setInitialVehicleCondition(
        vehicleCondition.params,
        vehicleCondition.partner
    );
    setInitialVehicleStockNumber(stockNumber);
    setInitialVehiclePrice(vehiclePrice);
    setInitialVehicleKilometers(vehicleKilometers);
    setInitialVehicleNvic(nvic);
    setInitialVehicleVin(vin);
    setInitialVehicleSource(partnerId);
    setInitialVehicleType(
        initialVehicleAssetType.params,
        initialVehicleAssetType.partner
    );
    setInitialVehicleCategory(
        initialVehicleAssetCategory.params,
        initialVehicleAssetCategory.partner
    );
    // Auto Play specific parameters
    setInitialVehicleSourceYardId(sourceYardId);
    // ! Order matters
    // Year > Make > Model > etc.
    // Changing the year resets the make and model etc
    setInitialVehicleBuildYear(buildYear);
    setInitialVehicleMake(make);
    setInitialVehicleModel(model);
    setInitialVehicleVariant(variant);

    // Awaited for a reason
    // Last for a reason
    await lookupInitialVehicle(partnerId);

    setInitialVehicleIsKnown(buildYear, make, model, variant, nvic, vin);

    // Vroom is Loaded
    store.dispatch(setInitialLoad(true));
    store.dispatch(setIsLoaded(true));
}
