import { setSellerAccountNumber } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialSellerAccountNumber(
  paramaterAccountNumber: IParameter,
  partnerAccountNumber: IParameter
): void {
  const sellerAccountNumber =
    store.getState().borrowerReducer.seller.accountNumber;

  const accountNumberToSave = paramaterAccountNumber ?? partnerAccountNumber;

  if (accountNumberToSave && sellerAccountNumber !== accountNumberToSave) {
    store.dispatch(setSellerAccountNumber(accountNumberToSave));
  }
}
