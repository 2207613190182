import { useParams } from 'react-router-dom';

import { lookupVroomCode } from '@/apiCalls/lookupVroomCode';
import { DEFAULT_PARTNER } from '@/partners/defaultPartner';
import { EPartners, IPartnerParams } from '@/partners/partnerTypes';

import { TRUCKSTOP_PARTNER } from '../truckstop';
import { YOU_X_DEMO_YAMAHA_PARTNER } from '../youxdemoyamaha';

export async function getPartnerFromUrl(): Promise<IPartnerParams> {
    const { id } = useParams(); // eg, url/<id>

    let partnerName: string | undefined;

    // code snippet logic
    try {
        // Global const from outside of vroom on the host website

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-undef
        const csParams = VroomCodeSnippetQueryStringParams;

        partnerName = new URLSearchParams(csParams).get('partner') || id;
    } catch {
        partnerName = id;
    }

    let partner: IPartnerParams = DEFAULT_PARTNER;

    const partnerConfig = await lookupVroomCode(partnerName);
    if (partnerConfig) {
        return partnerConfig;
    }

    switch (partnerName?.toLocaleLowerCase()) {
        case 'truckstop':
        case EPartners.TRUCKSTOP.toLowerCase():
            partner = TRUCKSTOP_PARTNER;
            break;
        case 'youxdemoyamahacampaign':
        case EPartners.YOU_X_DEMO_YAMAHA_PARTNER.toLowerCase():
            partner = YOU_X_DEMO_YAMAHA_PARTNER;
            break;
        default:
            partner = DEFAULT_PARTNER;
            break;
    }
    return partner;
}
