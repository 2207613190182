import { SummarySection } from '@/components/SummarySection';
import {
    selectBirthdate,
    selectLicenseExpiry,
    selectLicenseNumber,
    selectLicenseState,
    selectResidencyStatus,
} from '@/context/borrower';
import { EModals, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { getDateFromState } from '@/utils/getDateFromState';
import { getStringFromState } from '@/utils/getStringFromState';

export const testId = 'PersonalInformation';

export interface IProps {
    showErrors: boolean;
    baseId: string;
}

export function PersonalInformation({
    showErrors,
    baseId,
}: IProps): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    // const gender = useAppSelector(selectGender);
    const birthdate = useAppSelector(selectBirthdate);
    const licenseNumber = useAppSelector(selectLicenseNumber);
    const licenseState = useAppSelector(selectLicenseState);
    const licenseExpiry = useAppSelector(selectLicenseExpiry);
    const residencyStatus = useAppSelector(selectResidencyStatus);

    // ***** Event Handlers *****
    function handleClick(): void {
        dispatch(setModal(EModals.PERSONAL_INFORMATION));
    }

    // ***** Helpers *****
    function isComplete(): boolean {
        // if (gender === null || gender === undefined) return false;
        if (birthdate === null || birthdate === undefined) return false;
        if (licenseNumber === '' || licenseNumber === undefined) return false;
        if (licenseState === '' || licenseState === undefined) return false;
        if (licenseExpiry === null || licenseExpiry === undefined) return false;
        if (residencyStatus === null || residencyStatus === undefined)
            return false;
        if (
            licenseExpiry === null ||
            licenseExpiry === undefined ||
            licenseExpiry.toString() === 'Invalid Date'
        )
            return false;
        if (
            birthdate === null ||
            birthdate === undefined ||
            birthdate.toString() === 'Invalid Date'
        )
            return false;
        return true;
    }

    function fullText(): string {
        return `My birthdate is at <strong>${getDateFromState(
            birthdate
        )}</strong>. I am a <strong>${getStringFromState(
            residencyStatus
        ).replace(
            '_',
            ' '
        )}</strong> and my license number is <strong>${getStringFromState(
            licenseNumber
        )}</strong>. My license is valid in <strong>${getStringFromState(
            licenseState
        )}</strong> and expires on the <strong>${getDateFromState(
            licenseExpiry
        )}</strong>.`;
    }

    // ***** Render *****
    return (
        <div data-testid={testId}>
            <SummarySection
                onClick={handleClick}
                id={`${baseId}-button-editPersonalInformation`}
                text={fullText()}
                heading='Personal Information'
                showErrors={showErrors && !isComplete()}
            />
        </div>
    );
}
