import { useSelector } from 'react-redux';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { DisplayButtons } from '@/components/DisplayButtons';
import {
    EAddressOwnershipType,
    EBorrowerCitizenshipStatus,
    ELoanUsage,
    EUserFlow,
    selectAddressByIndex,
    selectDeposit,
    selectHasChildren,
    selectIsVehicleKnown,
    selectLoanUsage,
    selectNumberOfChildren,
    selectResidencyStatus,
    selectVehicleNvic,
    selectVisaNumber,
} from '@/context/borrower';
import {
    EDisplays,
    EModals,
    selectHideSellerScreen,
    selectHideVehicleScreen,
    selectIsAssetKnownViaParams,
    selectPartner,
    selectUserFlow,
    selectVehicleManualEntry,
    setDisplay,
    setModal,
} from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { selectIsLoggedIn } from '@/context/user';
import { isVisaExpiryDateValid } from '@/utils/checkVisaExpiryDate';
import { isGlassLookupPossible } from '@/utils/isGlassLookupPossible';

interface IBasicDetailsButtonsProps {
    setShowErrors: (value: boolean) => void;
    baseId: string;
}

export function BasicDetailsButtons({
    setShowErrors,
    baseId,
}: IBasicDetailsButtonsProps): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    // const gender = useAppSelector(selectGender);
    const address = useSelector((state: RootState) =>
        selectAddressByIndex(state, 0)
    );
    const ownershipType = address?.ownershipType ?? undefined;
    const residencyStatus = useAppSelector(selectResidencyStatus);
    const visaNumber = useAppSelector(selectVisaNumber);
    const haveKids = useAppSelector(selectHasChildren);
    const numberOfKids = useAppSelector(selectNumberOfChildren);
    const hideSellerScreen = useAppSelector(selectHideSellerScreen);
    const hideVehicleScreen = useAppSelector(selectHideVehicleScreen);
    const isVehicleKnown = useAppSelector(selectIsVehicleKnown);
    const isAssetKnownFromParams = useAppSelector(selectIsAssetKnownViaParams);
    const loanUsage = useAppSelector(selectLoanUsage);
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const deposit = useAppSelector(selectDeposit);
    const vehicleManualEntry = useAppSelector(selectVehicleManualEntry);
    const { showQuickQuote } = useAppSelector(selectPartner);
    const userFlow = useAppSelector(selectUserFlow);
    const nvic = useAppSelector(selectVehicleNvic);

    // visa number check
    const isVisaNumberUndefinedOrLessThanThreeDigits =
        visaNumber === undefined || visaNumber.toString().length < 3;

    // ***** Event Handlers *****
    function handleBackClick(): void {
        const backScreen = getBackScreen();
        dispatch(setDisplay(backScreen));
    }

    function handleNextClick(): void {
        setShowErrors(true);
        if (!isComplete()) return;
        setShowErrors(false);
        createOrUpdateBorrower();
        const nextScreen = getNextScreen();
        if (loanUsage === ELoanUsage.BUSINESS) {
            if (
                ownershipType !== EAddressOwnershipType.OWNER &&
                (!deposit || (deposit && deposit < 10))
            ) {
                dispatch(setModal(EModals.ADD_DEPOSIT));
                return;
            } else if (!isLoggedIn) {
                dispatch(setModal(EModals.REGISTER));
                return;
            }
        }
        dispatch(setDisplay(nextScreen));
    }

    // ***** Helpers *****
    function isComplete(): boolean {
        // if (gender === undefined) return false;
        if (ownershipType === undefined) return false;
        if (residencyStatus === undefined) return false;
        if (
            residencyStatus === EBorrowerCitizenshipStatus.VISA_HOLDER &&
            (isVisaNumberUndefinedOrLessThanThreeDigits ||
                !isVisaExpiryDateValid())
        )
            return false;
        if (loanUsage === ELoanUsage.PERSONAL && haveKids === undefined)
            return false;
        if (
            loanUsage === ELoanUsage.PERSONAL &&
            haveKids &&
            numberOfKids === undefined
        )
            return false;

        if (userFlow === EUserFlow.CAMPAIGN && !nvic) return false;
        return true;
    }

    function getNextScreen(): EDisplays {
        if (loanUsage === ELoanUsage.BUSINESS) {
            return EDisplays.IDENTIFICATION;
        }
        return EDisplays.INCOME;
    }

    function getBackScreen(): EDisplays {
        // Business Use
        if (loanUsage === ELoanUsage.BUSINESS) {
            return EDisplays.BUSINESS;
        }

        if (userFlow === EUserFlow.CAMPAIGN) {
            return EDisplays.CAMPAIGN_LANDING;
        }

        // Consumer Use
        if (isVehicleKnown) {
            if (!hideSellerScreen) {
                return EDisplays.SELLER;
            }
            if (hideVehicleScreen && isAssetKnownFromParams) {
                return EDisplays.LOAN;
            }

            if (vehicleManualEntry) {
                // User has gone KNOWN VEHICLE -> Do not know rego -> Cannot find it on glass guide -> Manual entry
                return EDisplays.MANUAL_VEHICLE;
            }

            const glassLookupPossible = isGlassLookupPossible();

            if (glassLookupPossible) {
                return EDisplays.VEHICLE;
            }

            return EDisplays.MANUAL_VEHICLE;
        }

        if (showQuickQuote) return EDisplays.LOAN;

        return EDisplays.NO_LENDER_HOME;
    }

    return (
        <DisplayButtons
            idLeftButton={`${baseId}-button-back`}
            idRightButton={`${baseId}-button-next`}
            rightClick={handleNextClick}
            leftClick={handleBackClick}
        />
    );
}
