import { postUrl } from '@/apiCalls/fetch';
import { setPartnerOverwriteId } from '@/context/borrower';
import { store } from '@/context/store';

import { IInitialApplication } from '../getInitialApplication';

export async function setInitialApplication(
    data: IInitialApplication,
    partnerId: string
): Promise<void> {
    let partnerOverwriteId: string | undefined | null =
        data.paramPartnerOverwriteId;

    // If partner overwrite id is not set, but email is, try fetching the id
    if (!partnerOverwriteId && data.paramIqPartnerEmail) {
        const url = '/api/partner/overwritePartnerId/email';
        partnerOverwriteId = await postUrl<string | undefined>(url, {
            email: data.paramIqPartnerEmail,
            partnerId,
        });
    }

    // If partner overwrite id is still not set, but the partner has an overwrite id, use that
    if (!partnerOverwriteId && data.partnerPartnerOverwriteId) {
        partnerOverwriteId = data.partnerPartnerOverwriteId;
    }

    // Set partner overwrite id to redux
    if (partnerOverwriteId) {
        const currentStateValue =
            store.getState().borrowerReducer.application.partnerOverwriteId;

        if (partnerOverwriteId !== currentStateValue) {
            store.dispatch(setPartnerOverwriteId(partnerOverwriteId));
        }
    }
}
