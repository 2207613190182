import { RadioGroup } from '@/components/RadioGroup';
import {
    selectForseeableChangesExplanation,
    selectNoForseeableChangesToCircumstances,
    setForseeableChangesExplanation,
    setNoForseeableChangesToCircumstances,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

import { ForseeableChangesExplanation } from '../ForseeableChangesExplanation';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'ForseeableChangesToCircumstances';

export function ForseeableChangesToCircumstances({
    baseId,
    showErrors,
}: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(
        selectNoForseeableChangesToCircumstances
    );
    const dispatch = useAppDispatch();
    const forseeableChangesExplanation = useAppSelector(
        selectForseeableChangesExplanation
    );

    // ***** Event Handlers *****
    function handleChange(value: string | boolean | undefined): void {
        if (value === undefined) {
            dispatch(setForseeableChangesExplanation(undefined));
            dispatch(setNoForseeableChangesToCircumstances(undefined));
            return;
        }

        dispatch(
            setNoForseeableChangesToCircumstances(
                value === 'YES' ? true : false
            )
        );
    }

    // ***** Render *****
    const options = [
        {
            label: 'Yes',
            value: 'YES',
            id: `${baseId}-button-noForseeableChangesToCircumstances-yes`,
        },
        {
            label: 'No',
            value: 'NO',
            id: `${baseId}-button-noForseeableChangesToCircumstances-no`,
        },
    ];
    return (
        <div data-testid={testId}>
            <RadioGroup
                title='Any forseeable changes to your circumstances?'
                showErrors={showErrors && currentState === undefined}
                onChange={handleChange}
                options={options}
                value={
                    currentState === undefined
                        ? undefined
                        : currentState
                        ? 'YES'
                        : 'NO'
                }
                tooltipContent='If there is a likely change during the loan that could negatively impact you financially.'
            />
            {currentState && (
                <ForseeableChangesExplanation
                    baseId={baseId}
                    showErrors={showErrors}
                />
            )}
        </div>
    );
}
