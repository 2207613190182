import { Input } from '@/components/Input';
import {
  selectPrimaryBusinessSuburbName,
  setPrimaryBusinessSuburbName,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'PrimaryBusinessSuburbName';

export function PrimaryBusinessSuburbName({
  baseId,
  showErrors,
}: IProps): JSX.Element {
  // ***** Redux *****

  const dispatch = useAppDispatch();
  const primaryBusinessSuburbName = useAppSelector(
    selectPrimaryBusinessSuburbName
  );
  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    if (typeof value !== 'string') return;
    if (typeof value === undefined) return;
    if (value?.length > 12) return;

    dispatch(setPrimaryBusinessSuburbName(value));
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Suburb'
        placeholder='Suburb'
        showErrors={showErrors && !primaryBusinessSuburbName}
        onChange={handleChange}
        id={`${baseId}-input-unitNumber`}
        value={primaryBusinessSuburbName?.toLocaleString() ?? ''}
      />
    </div>
  );
}
