import { setVehicleModel } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialVehicleModel(model: IParameter): void {
  if (!model) {
    return;
  }

  const stateMake = store.getState().borrowerReducer.vehicle.model;

  if (stateMake !== model) {
    store.dispatch(setVehicleModel(model));
  }
}
