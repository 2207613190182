import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { getCheapestLender } from '@/utils/getCheapestLender';

import TDRFinance from './TDRFinance/TDRFinance';
import { TDRImage } from './TDRImage';
import { TDRLenderDetails } from './TDRLenderDetails';
import { TDRVehicleDetails } from './TDRVehicleDetails';

export const testId = 'TestDriveReady';

export function TestDriveReady(): JSX.Element {
    // ***** Redux *****
    const lender = getCheapestLender();

    // ***** CONSTANTS *****
    const baseId = 'screen-TestDriveReady';
    const fullScreen = lender === undefined ? true : false;

    void createOrUpdateBorrower();

    // ***** Render *****
    return (
        <div className='relative w-full h-full'>
            <TDRImage fullScreen={fullScreen} />
            <TDRVehicleDetails fullScreen={fullScreen} baseId={baseId} />
            {fullScreen ? (
                <TDRFinance baseId={baseId} />
            ) : (
                <TDRLenderDetails baseId={baseId} />
            )}
        </div>
    );
}
