import { store } from '@/context/store';

export function areAllRequiredDocsPresent(): boolean {
    const state = store.getState();
    const supportingDocs = state.borrowerReducer.supportingDocs;
    const requiredDocs =
        state.borrowerReducer.selectedLender?.requiredDocuments;

    // Check if requiredDocs is defined
    if (!requiredDocs) {
        return false;
    }

    for (const key in requiredDocs) {
        // Check if the key in requiredDocs is present in supportingDocs
        if (!supportingDocs[key]) {
            return false; // A required document is missing
        }
    }

    return true; // All required documents are present
}
