import { useSelector } from 'react-redux';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { fetchServicing } from '@/apiCalls/fetchServicing';
import { DisplayButtons } from '@/components/DisplayButtons';
import {
    EEmploymentStatus,
    selectEmploymentStatusByIndex,
    selectOtherIncome,
    selectOtherIncomeSource,
    selectPaidAmount,
    selectPaidFrequency,
} from '@/context/borrower';
import {
    EDisplays,
    selectSkipExpenseScreen,
    setDisplay,
} from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

interface IIncomeButtonsProps {
    setShowErrors: (value: boolean) => void;
    baseId: string;
}

export function IncomeButtons({
    setShowErrors,
    baseId,
}: IIncomeButtonsProps): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const employment = useSelector((state: RootState) =>
        selectEmploymentStatusByIndex(state, 0)
    );
    const employmentStatus = employment?.status;
    const paidFrequency = useAppSelector(selectPaidFrequency);
    const amountPaid = useAppSelector(selectPaidAmount);
    const otherIncome = useAppSelector(selectOtherIncome);
    const sourceOfOtherIncome = useAppSelector(selectOtherIncomeSource);
    const skipExpenseScreen = useAppSelector(selectSkipExpenseScreen);

    // ***** Event Handlers *****
    function handleBackClick(): void {
        const backScreen = getBackScreen();
        dispatch(setDisplay(backScreen));
    }

    async function handleNextClick(): Promise<void> {
        setShowErrors(true);
        if (!isComplete()) return;
        createOrUpdateBorrower();
        const nextScreen = await getNextScreen();
        dispatch(setDisplay(nextScreen));
    }

    // ***** Helpers ******
    function isComplete(): boolean {
        if (!employmentStatus) return false;
        if (
            employmentStatus === EEmploymentStatus.CASUAL &&
            employment.years === undefined &&
            employment.months === undefined
        )
            return false;
        if (paidFrequency === undefined) return false;
        if (amountPaid === undefined) return false;
        if (
            otherIncome !== undefined &&
            otherIncome !== 0 &&
            sourceOfOtherIncome === undefined
        )
            return false;
        return true;
    }

    async function getNextScreen(): Promise<EDisplays> {
        if (!skipExpenseScreen) return EDisplays.EXPENSE;

        const servicingMet = await fetchServicing();
        if (servicingMet) {
            return EDisplays.SERVICING_SUCCESS;
        }
        return EDisplays.SERVICING_FAILURE;
    }

    function getBackScreen(): EDisplays {
        return EDisplays.BASIC;
    }
    return (
        <DisplayButtons
            idLeftButton={`${baseId}-button-back`}
            idRightButton={`${baseId}-button-next`}
            rightClick={handleNextClick}
            leftClick={handleBackClick}
        />
    );
}
