import { ILender } from '@/context/lender';
import { store } from '@/context/store';

export function getCheapestLender(): ILender | undefined {
    const lenders = store.getState().lenderReducer.lenders;

    if (!lenders || lenders.length === 0) return undefined;

    return lenders.reduce((cheapest, current) => {
        if (!cheapest) return current;
        return current.weeklyRepaymentAmount < cheapest.weeklyRepaymentAmount
            ? current
            : cheapest;
    }, lenders[0]);
}
