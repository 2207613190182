import { useState } from 'react';

import { Combobox, IComboboxOption } from '@/components/Combobox';
import { selectVehicleNvic, setVehicleNvic } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

import { getCampaignVehicles } from './getCampaignVehicles';

export interface IProps {
    baseId: string;
    showErrors: boolean;
}

export const testId = 'CampaignVehicle';

// Map campaignVehicles to IComboboxOption array

export const CampaignVehicleDropdown = ({
    showErrors,
    baseId,
}: IProps): JSX.Element => {
    // ***** Redux State *****
    const dispatch = useAppDispatch();

    const nvic = useAppSelector(selectVehicleNvic);
    const campaignVehicles = getCampaignVehicles();
    const vehicleOptions: IComboboxOption[] = campaignVehicles.map(
        (vehicle) => ({
            id: vehicle.id,
            value: vehicle.displayName,
        })
    );

    // Find the initial vehicle displayName based on nvic
    const initialVehicle = campaignVehicles.find(
        (vehicle) => vehicle.nvic === nvic
    );
    const initialDisplayName = initialVehicle ? initialVehicle.displayName : '';

    // *****State*****
    const [query, setQuery] = useState<undefined | string>(initialDisplayName); // Keep track of search
    const [options, setOptions] = useState<IComboboxOption[]>([
        ...vehicleOptions,
        { id: campaignVehicles.length + 1, value: initialDisplayName },
    ]); // Keep track of options to display

    // *****Functions*****
    // Handle the onChange event for the input.
    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const { value } = event.target;
        setQuery(value);
        setOptions([...vehicleOptions]);
    }

    // When the user selects an option
    function handleSelect(option: IComboboxOption): void {
        const selectedVehicle = campaignVehicles.find(
            (vehicle) => vehicle.displayName === option.value
        );
        if (selectedVehicle) {
            dispatch(setVehicleNvic(selectedVehicle.nvic));
        }
    }

    if (campaignVehicles.length <= 1) {
        return <></>;
    }

    // *****Render*****
    return (
        <Combobox
            options={options}
            placeholder='Select Vehicle'
            heading='Choose Your Vehicle'
            onChange={handleChange}
            query={query ?? ''}
            onSelect={handleSelect}
            showErrors={showErrors && !nvic}
            baseId={baseId}
            tooltipContent='Select the vehicle for the campaign.'
        />
    );
};
