import { IMotorVehicle, IRealEstate } from '@/context/borrower';

export function titleHelper(array: IRealEstate[] | IMotorVehicle[]): string {
  let modifyTitle = '';
  if (array.length > 1) {
    modifyTitle = `of ${array.length}`;
  }

  return modifyTitle;
}
