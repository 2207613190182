import { setAvailableVehicleAssetTypes } from '@/context/display';
import { store } from '@/context/store';
import { IVehicleAssetTypeButton } from '@/partners/partnerTypes';

export function setInitialAvailableVehicleAssetTypes(
    initialVehicleAssetsTypes: IVehicleAssetTypeButton[]
): void {
    const currentState =
        store.getState().displayReducer.availableVehicleAssetTypes;

    if (initialVehicleAssetsTypes !== currentState) {
        store.dispatch(
            setAvailableVehicleAssetTypes(initialVehicleAssetsTypes)
        );
    }
}
