import { postUrl } from '@/apiCalls/fetch';
import {
    EAddressOwnershipType,
    EBorrowerCitizenshipStatus,
    ECondition,
    ECreditHistory,
    EEmploymentStatus,
    ELoanUsage,
    EQuickQuoteFinanceType,
    ESelfAssessedCreditHistory,
} from '@/context/borrower';
import { setIsLoading } from '@/context/display';
import { ILender, setAndSortLenders } from '@/context/lender';
import { store } from '@/context/store';
import {
    ELenderSorting,
    EMatchingEngineLenders,
} from '@/partners/matchingEngineEnums';
import { EPartners } from '@/partners/partnerTypes';
import { getCommissionAmount } from '@/utils/getCommissionAmount';

import { IQuickQuoteRequest } from './getQuickQuoteTypes';

export async function getQuickQuote(
    customWaterfall?: string[][]
): Promise<void> {
    const url = '/api/quickQuote2Routes/getQuickQuote';

    // ***** Redux State *****
    const state = store.getState();
    // Store
    const isInitialLoaded = state.displayReducer.initialLoad;
    const isLoading = state.displayReducer.isLoading;
    const assetType = state.borrowerReducer.vehicle.assetType;

    if (!isInitialLoaded || isLoading) {
        return;
    }
    const amount = state.borrowerReducer.loan.purchasePrice;
    if (!amount) {
        store.dispatch(setAndSortLenders([]));
        return;
    }
    const loanPurpose = state.borrowerReducer.loan.loanUsage;
    let isToggleApplicationType: 'consumer' | 'commercial' = 'consumer';
    if (loanPurpose === ELoanUsage.BUSINESS) {
        isToggleApplicationType = 'commercial';
    }
    const sellerName = state.borrowerReducer.seller.name as EPartners;
    const residencyStatus =
        state.borrowerReducer.personalDetails?.citizenshipStatus ??
        EBorrowerCitizenshipStatus.AUSTRALIAN_CITIZEN;
    const employmentStatus =
        state.borrowerReducer.employments?.employments[0]?.status ??
        EEmploymentStatus.PERMANENT;
    const financeType = getFinanceType();
    const fixedTerm = state.borrowerReducer.loan.loanTerm;
    const glassGuideValuation =
        state.borrowerReducer.vehicle.glassValuation ?? amount;
    const deposit = state.borrowerReducer.loan.deposit ?? 0;
    const buildYear = state.borrowerReducer.vehicle.buildYear;
    const assetBalloon = state.borrowerReducer.loan.balloon ?? 0;
    const ownershipType =
        state.borrowerReducer.address.address[0].ownershipType ??
        EAddressOwnershipType.OWNER;
    const amountPaidInBankAccountMonthly =
        state.borrowerReducer.income.paidAmount ?? 4000;
    const creditHistory = getCreditHistory();
    const creditScore = getCreditScore();
    const tradeInQuote = state.borrowerReducer.loan.tradeIn;

    const commissionAmount = getCommissionAmount();
    const waterfallLenders = customWaterfall ?? getWaterfallLenders();
    const lenderSorting = getLenderSorting();
    const applicantAge = getApplicantAge();
    const assetCondition = getAssetCondition();
    const dateRegisteredForGST = state.borrowerReducer.business?.gst;
    const establishedDate =
        state.borrowerReducer.business?.abnStatusEffectiveFrom;

    const timeAtAddressMonth = state.borrowerReducer.address.address[0]?.months;
    let timeAtAddressYears = state.borrowerReducer.address.address[0]?.years;

    if (timeAtAddressMonth === undefined && timeAtAddressYears === undefined) {
        timeAtAddressYears = 3;
    }

    const timeAtEmployerMonth =
        state.borrowerReducer.employments.employments[0]?.months;
    let timeAtEmployerYear =
        state.borrowerReducer.employments.employments[0]?.years;

    if (timeAtEmployerMonth === undefined && timeAtEmployerYear === undefined) {
        timeAtEmployerYear = 3;
    }

    const brokerFee = 990;

    const body: IQuickQuoteRequest = {
        isToggleApplicationType,
        brokerFee,
        timeAtAddressMonth,
        timeAtAddressYears,
        timeAtEmployerMonth,
        timeAtEmployerYear,
        dateRegisteredForGST,
        establishedDate,
        applicantAge,
        amount,
        partner: sellerName,
        tradeInQuote,
        residencyStatus,
        employmentStatus,
        assetType,
        financeType,
        fixedTerm,
        deposit,
        buildYear,
        assetBalloon,
        ownershipType,
        glassGuideValuation,
        amountPaidInBankAccountMonthly,
        waterfallLenders,
        commissionAmount,
        comprehensiveEquifaxScore: creditScore,
        vedaEquifaxScore: creditScore,
        creditHistory,
        lenderSorting,
        assetCondition,
    };
    store.dispatch(setIsLoading(true));
    const response = await postUrl<ILender[]>(url, body);
    store.dispatch(setIsLoading(false));

    // Remove broken lenders
    const lenders = response.filter((lender) => lender.advertisedRateInPercent);

    store.dispatch(setAndSortLenders(lenders));

    return;
}

function getApplicantAge(): number | undefined {
    const state = store.getState();
    const dob = state.borrowerReducer.personalDetails?.birthdate;
    if (!dob) {
        return undefined;
    }
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();

    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}

function getFinanceType(): EQuickQuoteFinanceType {
    const state = store.getState();
    const loanUsage = state.borrowerReducer.loan.loanUsage;

    switch (loanUsage) {
        case ELoanUsage.BUSINESS:
            return EQuickQuoteFinanceType.COMMERCIAL_LOW_DOC;
        default:
        case ELoanUsage.PERSONAL:
            return EQuickQuoteFinanceType.CONSUMER_SECURED;
    }
}

function getWaterfallLenders(): EMatchingEngineLenders[][] {
    const state = store.getState();
    const waterfallLenders = state.displayReducer.waterfallLenders;

    const resultLenders: EMatchingEngineLenders[][] = [];
    waterfallLenders.forEach((waterfallLender) => {
        resultLenders.push(waterfallLender.lenders);
    });

    return resultLenders;
}

function getCreditHistory(): ECreditHistory | undefined {
    const state = store.getState();
    const selfAssessedCreditHistory =
        state.borrowerReducer.scores.selfAssessedCreditHistory;

    //todo: dynamic by partner
    switch (selfAssessedCreditHistory) {
        case ESelfAssessedCreditHistory.GREAT:
            return ECreditHistory.CLEAR_CREDIT;
        case ESelfAssessedCreditHistory.DECENT:
            return ECreditHistory.CLEAR_CREDIT;
        case ESelfAssessedCreditHistory.POOR:
            return ECreditHistory.PAID_DEFAULTS;
        default:
            return undefined;
    }
}

function getCreditScore(): number | undefined {
    const state = store.getState();
    const selfAssessedCreditHistory =
        state.borrowerReducer.scores.selfAssessedCreditHistory;

    //todo: dynamic by partner
    switch (selfAssessedCreditHistory) {
        case ESelfAssessedCreditHistory.GREAT:
            return 1200;
        case ESelfAssessedCreditHistory.DECENT:
            return 650;
        case ESelfAssessedCreditHistory.POOR:
            return 450;
    }
}

function getLenderSorting(): ELenderSorting {
    const state = store.getState();
    const lenderSorting = state.displayReducer.lenderSorting;

    return lenderSorting;
}

function getAssetCondition(): ECondition {
    // todo
    // If asset condition is known via params, use params.
    // Else use partner
    // Otherwise check year and kilometers
    const state = store.getState();
    const condition = state.borrowerReducer.vehicle.condition;

    return condition ?? ECondition.NEW;
}
