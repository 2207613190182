import { useSelector } from 'react-redux';

import { Input } from '@/components/Input';
import {
  selectAddressByIndex,
  selectPrimaryBusinessPostCode,
  setPrimaryBusinessPostCode,
} from '@/context/borrower';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface IProps {
  baseId: string;
  showErrors: boolean;
}

export const testId = 'PrimaryBusinessPostCode';

export function PrimaryBusinessPostCode({
  baseId,
  showErrors,
}: IProps): JSX.Element {
  // ***** Redux *****
  const address = useSelector((state: RootState) =>
    selectAddressByIndex(state, 0)
  );
  const { postcode } = address;
  const dispatch = useAppDispatch();
  const primaryBusinessPostCode = useAppSelector(selectPrimaryBusinessPostCode);

  // ***** Event Handlers *****
  function handleChange(value: string | boolean): void {
    if (typeof value !== 'string') return;
    const intValue = parseInt(value);
    if (isNaN(intValue)) return;
    if (intValue > 9999) return;

    dispatch(setPrimaryBusinessPostCode(intValue));
  }

  // ***** Helpers *****
  function determineShowErrors(): boolean {
    if (!showErrors) return false;
    if (primaryBusinessPostCode === undefined) return true;
    if (primaryBusinessPostCode === null) return true;
    if (primaryBusinessPostCode.toString()?.length !== 4) return true;
    return false;
  }

  // ***** Render *****
  return (
    <div data-testid={testId}>
      <Input
        title='Post Code'
        placeholder='2000'
        showErrors={determineShowErrors()}
        onChange={handleChange}
        id={`${baseId}-input-unitNumber`}
        value={primaryBusinessPostCode?.toString() ?? ''}
      />
    </div>
  );
}
