import { MultipleUserInput } from '@/components/MultipleUserInput';
import { Abn } from '@/UserInput/Abn';
import { AbnActiveFromManual } from '@/UserInput/AbnActiveFromManual';
import { GstFrom } from '@/UserInput/GstFrom';
import { NumberOfDirectors } from '@/UserInput/NumberOfDirectors';

interface Props {
    baseId: string;
    showErrors: boolean;
}

export function BusinessManualSection({
    baseId,
    showErrors,
}: Props): JSX.Element {
    return (
        <>
            <Abn baseId={baseId} showErrors={showErrors} disabled={false} />
            <MultipleUserInput>
                <AbnActiveFromManual baseId={baseId} showErrors={showErrors} />
                <GstFrom baseId={baseId} showErrors={showErrors} />
            </MultipleUserInput>
            <MultipleUserInput>
                <NumberOfDirectors baseId={baseId} showErrors={showErrors} />
                <div className='hidden' />
            </MultipleUserInput>
        </>
    );
}
