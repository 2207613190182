import { uniqueIdTransfer } from '@/apiCalls/uniqueIDTransferHelper';

import { EEventType } from '../eventType';

export enum EButtonType {
    PRIMARY = 'PRIMARY',
    ROUNDED_FULL_PRIMARY = 'ROUNDED_FULL_PRIMARY',
    PRIMARY_INVERTED = 'PRIMARY_INVERTED',
    SUCCESS = 'SUCCESS',
    DISABLED = 'DISABLED',
    ROUNDED_DISABLED = 'ROUNDED_DISABLED',
    OUTLINE = 'OUTLINE',
    SECONDARY = 'SECONDARY',
    SECONDARY_OUTLINE = 'SECONDARY_OUTLINE',
    ROUNDED_FULL_SECONDARY_OUTLINE = 'ROUNDED_FULL_SECONDARY_OUTLINE',
    LONGER_ROUNDED_FULL_SECONDARY_OUTLINE = 'LONGER_ROUNDED_FULL_SECONDARY_OUTLINE',
    LONGER_ROUNDED_FULL_PRIMARY = 'LONGER_ROUNDED_FULL_PRIMARY',
    LONGER_ROUNDED_DISABLED = 'LONGER_ROUNDED_DISABLED',
    FULL_ROUNDED_DISABLED = 'FULL_ROUNDED_DISABLED',
    EDIT_BUTTON_OCR_SUMMARY_SECONDARY_OUTLINE = 'EDIT_BUTTON_OCR_SUMMARY_SECONDARY_OUTLINE',
    PRIMARY_DISABLED = 'PRIMARY_DISABLED',
    PRIMARY_OUTLINE = 'PRIMARY_OUTLINE',
}

export interface IButton {
    children?: React.ReactNode;
    onClick: () => void;
    type?: EButtonType;
    eventType?: EEventType;
    square?: boolean;
    id?: string;
    title?: string;
}

/**
 * A button component that can be used for any purpose
 *
 * @param {React.ReactNode} children  - Required    - The text or other elements to be displayed in the button
 * @param {function} onClick          - Required    - The function to be called when the button is clicked
 * @param {ButtonType} type           - Optional    - The type of button to be displayed, default is PRIMARY
 *
 * @author Nathan
 * @date 12-09-2022
 * @version 1
 */
export function Button({
    eventType = EEventType.CLICK,
    children,
    onClick,
    type = EButtonType.PRIMARY,
    square,
    id,
    title,
    ...rest
}: IButton): JSX.Element {
    const isSolar = false; // todo: delete

    function handleClick(
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void {
        onClick(); // Call props with component
        // call api
        const id = e.currentTarget.id;
        uniqueIdTransfer({
            eventType: eventType,
            applicationData: { id: id },
        });
    }
    return (
        <button
            id={id}
            data-testid='button'
            className={`font-bold border text-xs p-1 _flex justify-center items-center w-full rounded-full transition duration-500 transform hover:z-20 ${getButtonTypeClassName(
                type
            )} ${square && 'rounded-none px-0'} ${
                isSolar
                    ? 'h-full'
                    : type ===
                      EButtonType.EDIT_BUTTON_OCR_SUMMARY_SECONDARY_OUTLINE
                    ? 'h-6'
                    : 'h-8'
            }`}
            onClick={handleClick}
            disabled={
                type === EButtonType.DISABLED ||
                type === EButtonType.ROUNDED_DISABLED ||
                type === EButtonType.PRIMARY_DISABLED
            }
            {...rest}
        >
            <p className='text-lg'>{title}</p>
            {children}
        </button>
    );
}

export function getButtonTypeClassName(type: EButtonType): string {
    switch (type) {
        case EButtonType.SUCCESS:
            return `px-2 text-white bg-green hover:scale-110 hover:shadow-lg `;
        case EButtonType.PRIMARY:
            return `bg-primary hover:bg-primaryHover text-white py-2 px-4 rounded-3xl  focus:bg-primaryFocus hover:scale-110 hover:shadow-lg`; // In use
        case EButtonType.PRIMARY_OUTLINE:
            return `bg-transparent text-primary font-semibold border-1 border-solid border border-primary hover:bg-primary hover:text-white py-2 px-4 rounded-3xl  focus:bg-primaryFocus hover:scale-110 hover:shadow-lg`;

        case EButtonType.PRIMARY_INVERTED:
            return ` bg-primary border-primary  text-white px-4 hover:text-primary hover:bg-white focus:text-primaryFocus focus:border-primaryFocus hover:scale-110 hover:shadow-lg`;
        case EButtonType.PRIMARY_DISABLED:
            return `bg-gray-300 text-gray-500  text-white py-2 px-4 rounded-3xl  `;
        case EButtonType.DISABLED:
            return `bg-gray-300 text-gray-500  py-2 px-4 rounded  `;
        case EButtonType.ROUNDED_DISABLED:
            return `w-2/5 bg-gray-300 text-gray-500 py-2 px-4 rounded-full   `;
        case EButtonType.LONGER_ROUNDED_DISABLED:
            return `w-full bg-gray-300 text-gray-500 py-2 px-4 rounded-full cursor-not-allowed  `;
        case EButtonType.FULL_ROUNDED_DISABLED:
            return `w-full bg-gray-300 text-gray-500 py-2 px-4 rounded-full  cursor-not-allowed  `;
        case EButtonType.OUTLINE:
            return `bg-transparent hover:bg-primary text-primary hover:text-white py-2 border border-primary hover:border-transparent rounded hover:scale-110 hover:shadow-lg`;
        case EButtonType.SECONDARY:
            return `bg-secondary hover:bg-secondaryHover text-white py-2 px-4 rounded  focus:bg-secondaryFocus hover:scale-110 hover:shadow-lg`;
        case EButtonType.SECONDARY_OUTLINE:
            return `bg-transparent  text-secondary font-semibold  py-2 px-4 border border-secondary  rounded-3xl focus:bg-secondaryFocus hover:scale-110 hover:shadow-lg`;
        case EButtonType.EDIT_BUTTON_OCR_SUMMARY_SECONDARY_OUTLINE:
            return `bg-transparent text-primary text-xs font-semibold  py-2 px-2 border border-grey shadow-sm  rounded-3xl focus:bg-secondaryFocus hover:scale-110 hover:shadow-lg`;
        case EButtonType.ROUNDED_FULL_SECONDARY_OUTLINE:
            return `w-full bg-transparent hover:bg-secondary text-secondary font-semibold hover:text-white py-2 px-4 border border-secondary hover:border-transparent rounded-full focus:bg-secondaryFocus hover:scale-110 hover:shadow-lg`;
        case EButtonType.LONGER_ROUNDED_FULL_SECONDARY_OUTLINE:
            return `w-3/5 bg-transparent hover:bg-secondary text-secondary font-semibold hover:text-white py-2 px-4 border border-secondary hover:border-transparent rounded-full focus:bg-secondaryFocus hover:scale-110 hover:shadow-lg`;
        case EButtonType.ROUNDED_FULL_PRIMARY:
            return `w-2/5 bg-primary hover:bg-primaryHover text-white py-2 px-4 rounded-full  focus:bg-primaryFocus hover:scale-110 hover:shadow-lg`;
        case EButtonType.LONGER_ROUNDED_FULL_PRIMARY:
            return `w-3/5 bg-primary hover:bg-primaryHover text-white py-2 px-4 rounded-full  focus:bg-primaryFocus hover:scale-110 hover:shadow-lg`;
        default:
            return '';
    }
}
