/**
 * @returns {string} - the pathname + search of the current url for codesnippet or iframe
 */
export function getQrPushLocation(): string {
    // Global const from outside of vroom on the host website

    try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-undef
        const csParams: string = VroomCodeSnippetQueryStringParams;

        if (csParams) {
            const csWidgetId = new URLSearchParams(csParams).get('partner');

            if (csWidgetId) {
                const urlSearch = csParams.replace('partner=' + csWidgetId + '&', '').replace(/&$/, '');
                return '/' + csWidgetId + urlSearch;
            }
        }
    } catch (e) {
        // do nothing
    }

    // Return IFrame location
    return location.pathname + location.search;
}
