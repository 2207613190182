// eslint-disable-next-line simple-import-sort/imports
import { postUrl } from '@/apiCalls/fetch';
import { ESource, IBillingLog, setVehicle } from '@/context/borrower';

// eslint-disable-next-line no-restricted-imports
import { EBillingService } from '@/context/borrower/types/EBillingService';
import { store } from '@/context/store';

import { IVehicleReturn } from './lookupVinTypes';

export async function lookupVin(partnerId: string): Promise<boolean> {
    const state = store.getState();
    const stateVin = state.borrowerReducer.vehicle.vin;
    const stateVehicle = state.borrowerReducer.vehicle;
    const manageOrganisationId =
        state.displayReducer.partner.manageOrganisationId;
    const partnerName = state.displayReducer.partner.id;
    const applicationId = state.borrowerReducer.application.applicationId;

    if (!stateVin) return false;

    const billingData: IBillingLog = {
        service: EBillingService.GLASS_GUIDE_VIN,
        youxAccountUsed: true,
        source: ESource.VROOM,
        externalPartner: partnerName ?? '',
        externalReferenceId: applicationId ?? '',
        managePartnerToBillId: manageOrganisationId ?? '',
    };
    const url = `/api/asset/select/${partnerId}/vin/${stateVin}`;
    const billing = { billingData };
    try {
        const data = await postUrl<IVehicleReturn>(url, billing);

        if (!data) return false;

        const {
            make,
            model,
            vin,
            nvic,
            variant,
            buildYear,
            glassValuation,
            description,
        } = data;

        const isVehicleKnown = glassValuation ? true : false;

        store.dispatch(
            setVehicle({
                ...stateVehicle,
                make,
                model,
                nvic,
                vin,
                variant,
                buildYear,
                glassValuation,
                description,
                isVehicleKnown,
            })
        );

        return isVehicleKnown;
    } catch (error) {
        console.log('Error looking up VIN', error);
        return false;
    }
}
