import { store } from '@/context/store';

import {
  LICENSE_EXPIRY_MAX_LIMIT,
  LICENSE_EXPIRY_MIN_LIMIT,
} from '../constants';
import { isDateValid } from '../generalDateHelper';
import { maximumDateHelper } from '../maximumDateHelper';
import { minimumDateHelper } from '../minimumDateHelper';
import { returnDate } from '../returnDate';

export function isLicenseExpiryDateValid(): boolean {
  const state = store.getState();
  const licenseExpiry = state.borrowerReducer.personalDetails.licenseExpiry;
  if (
    isDateValid(
      returnDate(licenseExpiry),
      minimumDateHelper(LICENSE_EXPIRY_MIN_LIMIT),
      maximumDateHelper(LICENSE_EXPIRY_MAX_LIMIT)
    )
  )
    return true;
  return false;
}
