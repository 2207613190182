import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { createOrUpdateBorrower } from '@/apiCalls/borrower';
import { getTermsAndConditionsAgreement } from '@/apiCalls/borrower';
import {
    EVerificationResponseStatus,
    IVerifyCodeReturn,
    sendEmailCode,
    sendSmsCode,
    verifyEmailCode,
    verifySmsCode,
} from '@/apiCalls/verification';
import { Button, EButtonType } from '@/components/Button';
import { Input } from '@/components/Input';
import {
    ELoanUsage,
    selectAddressByIndex,
    selectEmail,
    selectFirstName,
    selectLoanUsage,
    selectMobile,
} from '@/context/borrower';
import {
    EDisplays,
    EModals,
    selectDisplay,
    selectModal,
    selectSignupOptions,
    selectUserFlow,
    setDisplay,
    setModal,
} from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import {
    EUserLoginOption,
    selectMobileOrEmail,
    setIsLoggedIn,
} from '@/context/user';
import { isEmail } from '@/utils/isEmail';
import { isMobile } from '@/utils/isMobile';
import { EConversionType, reportConversion } from '@/utils/reportConversion';

import { FirstName } from '../FirstName';
import { PostCode } from '../PostCode';

export interface IProps {
    baseId: string;
    showErrors: boolean;
    disabled?: boolean;
}

export const testId = 'LoginCode';

// Todo: Separate the login code and the login button into separate components
export function LoginCode({
    baseId,
    showErrors,
    disabled = false,
}: IProps): JSX.Element {
    // ***** Redux *****
    const currentState = useAppSelector(selectEmail);
    const dispatch = useAppDispatch();
    const loginMethod = useAppSelector(selectMobileOrEmail);
    const mobileNumber = useAppSelector(selectMobile);
    const email = useAppSelector(selectEmail);
    const loanUsage = useAppSelector(selectLoanUsage);
    const display = useAppSelector(selectDisplay);
    const modal = useAppSelector(selectModal);
    const firstName = useAppSelector(selectFirstName);
    const signupOptions = useAppSelector(selectSignupOptions);

    // ***** Local State *****
    const [code, setCode] = useState<string>('');
    const [invalidCode, setInvalidCode] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);
    const [sent, setSent] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [checking, setChecking] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);
    const userFlow = useAppSelector(selectUserFlow);
    const address = useSelector((state: RootState) =>
        selectAddressByIndex(state, 0)
    );
    const { postcode } = address;

    useEffect(() => {
        if (code?.length !== 6) return;
        // todo: dispatch check code
        checkLoginCode();
    }, [code]);

    // ***** Event Handlers *****
    function handleChange(value: string | boolean): void {
        setInvalidCode(false);
        setError(false);
        if (typeof value === 'boolean') return;
        if (value === currentState) return;
        if (value?.length > 6) return;
        setCode(value);
    }

    async function handleClick(): Promise<void> {
        let code: EVerificationResponseStatus | void = undefined;

        if (loginMethod === EUserLoginOption.MOBILE) {
            code = await loginViaMobile();
        } else {
            code = await loginViaEmail();
        }
        useCode(code);
    }

    function login(): void {
        dispatch(setIsLoggedIn(true));
        getTermsAndConditionsAgreement().then((termsAndConditions) => {
            // Save/create the borrower
            createOrUpdateBorrower();

            if (
                loanUsage !== ELoanUsage.BUSINESS &&
                (!termsAndConditions ||
                    !termsAndConditions.isDefaultPrivacyAgreed?.agreed ||
                    !termsAndConditions.isMarketingConsentAgreed?.agreed ||
                    !termsAndConditions.isEquifaxVerificationExchangeAgreed
                        ?.agreed)
            ) {
                dispatch(setModal(EModals.EXPRESS_PRIVACY));
                return;
            }

            dispatch(setModal(null));

            if (display === EDisplays.SERVICING_SUCCESS) {
                dispatch(setDisplay(EDisplays.IDENTIFICATION));
            }

            if (display === EDisplays.QUICK_CALCULATOR) {
                dispatch(setDisplay(EDisplays.LOAN));
            }

            // commercial applications
            if (
                loanUsage === ELoanUsage.BUSINESS &&
                display === EDisplays.BASIC
            ) {
                dispatch(setDisplay(EDisplays.IDENTIFICATION));
            }
        });

        if (display === EDisplays.CAMPAIGN_LANDING) {
            console.log('Campaign Landing');
            if (loanUsage === ELoanUsage.BUSINESS) {
                dispatch(setDisplay(EDisplays.BUSINESS));
            } else {
                dispatch(setDisplay(EDisplays.BASIC));
            }
        }
        // Tracking
        reportConversion(EConversionType.SUBMIT_LOAN_REQUEST);
    }

    // ***** Helpers ******
    async function loginViaMobile(): Promise<EVerificationResponseStatus | void> {
        if (!isMobile(mobileNumber)) return;
        if (mobileNumber === null) return;
        if (mobileNumber === undefined) return;
        setSending(true);

        const response = await sendSmsCode(mobileNumber, notFound);
        setSending(false);
        setSent(true);

        return response;
    }

    async function loginViaEmail(): Promise<EVerificationResponseStatus | void> {
        if (!isEmail(email)) return;
        if (email === null) return;
        if (email === undefined) return;
        setSending(true);

        const response = await sendEmailCode(email);
        setSending(false);
        setSent(true);

        return response;
    }

    // Same functionality for both loginViaMobile and loginViaEmail
    function useCode(code: EVerificationResponseStatus | void): void {
        if (code === EVerificationResponseStatus.SUCCESSFUL) {
            setSent(true);
            return;
        }

        if (code === EVerificationResponseStatus.INCORRECT_VERIFICATION_CODE) {
            setInvalidCode(true);
            return;
        }

        if (!code) {
            setNotFound(true);
            setSent(false);
            setSending(false);
            return;
        }

        setError(true);
        return;
    }

    async function checkLoginCode(): Promise<void> {
        if (code?.length !== 6) return;

        let response: void | IVerifyCodeReturn = undefined;
        if (loginMethod === EUserLoginOption.MOBILE) {
            response = await checkLoginViaMobile();
        } else {
            response = await checkLoginViaEmail();
        }

        useVerificationCode(response?.status);
    }

    // Same functionality for both checkLoginViaMobile and checkLoginViaEmail
    function useVerificationCode(
        code: EVerificationResponseStatus | void
    ): void {
        if (code === EVerificationResponseStatus.SUCCESSFUL) {
            login();
            return;
        }

        if (code === EVerificationResponseStatus.INCORRECT_VERIFICATION_CODE) {
            setInvalidCode(true);
            return;
        }

        setError(true);
        return;
    }

    async function checkLoginViaEmail(): Promise<IVerifyCodeReturn | void> {
        if (!isEmail(email)) return;
        if (email === null) return;
        if (email === undefined) return;

        setChecking(true);

        const response = await verifyEmailCode(email, code);
        setChecking(false);

        return response;
    }

    async function checkLoginViaMobile(): Promise<IVerifyCodeReturn | void> {
        if (!isMobile(mobileNumber)) return;
        if (mobileNumber === null) return;
        if (mobileNumber === undefined) return;

        setChecking(true);

        const response = await verifySmsCode(mobileNumber, code);
        setChecking(false);

        return response;
    }

    function isReadyToSendCode(): boolean {
        // Is Disabled
        if (disabled) return false;
        // Is Not Sending
        if (sending) return false;
        // Is Not Sent
        if (sent) return false;

        // Mobile Valid
        if (loginMethod === EUserLoginOption.MOBILE) {
            if (mobileNumber === null) return false;

            // When user without an account is trying to login
            if (notFound && (firstName === undefined || firstName === ''))
                return false;
            return isMobile(mobileNumber);
        }

        // Email Valid
        if (loginMethod === EUserLoginOption.EMAIL) {
            if (email === null) return false;
            return isEmail(email);
        }

        // Zip Code Sign up
        if (signupOptions?.zipcode) {
            if (!postcode) return false;
            return postcode?.length === 4;
        }

        // No Login Method
        return false;
    }

    // ***** Render *****
    return (
        <div data-testid={testId} className='_flex flex-col'>
            {notFound && modal === EModals.LOGIN && (
                <>
                    <FirstName baseId={baseId} showErrors={false} />{' '}
                    {signupOptions?.zipcode && (
                        <PostCode baseId={baseId} showErrors={false} />
                    )}
                </>
            )}

            <div className='_flex space-x-2'>
                <div className='py-2 mx-auto w-full max-w-md'>
                    <div className='_flex'>
                        <Button
                            id={`${baseId}-button-sendCode`}
                            onClick={handleClick}
                            type={
                                isReadyToSendCode()
                                    ? EButtonType.PRIMARY
                                    : EButtonType.PRIMARY_DISABLED
                            }
                        >
                            <p> {sent ? 'Code Sent' : 'Send Code'}</p>
                        </Button>
                    </div>
                </div>
                <Input
                    placeholder='XXXXXX'
                    showErrors={showErrors}
                    onChange={handleChange}
                    id={`${baseId}-input-verificationCode`}
                    value={code}
                    disabled={!sent && !disabled}
                    showNumpad={true}
                />
            </div>

            {invalidCode && (
                <p className='text-red-500 text-center'>Invalid Code</p>
            )}
            {sending && <p className=' text-center'>Sending...</p>}
            {error && (
                <p className='text-red-500 text-center'>
                    Error, please try again
                </p>
            )}
            {notFound && modal === EModals.LOGIN && (
                <>
                    <p className='text-red-500 text-center text-sm pb-2'>
                        Sorry, you don&apos;t have an account with us
                    </p>
                </>
            )}
            {!notFound && (
                <p className='font-bold text-xs text-textBody text-center py-2'>
                    {modal === EModals.LOGIN ? "Don't" : 'Already'} have an
                    account?
                </p>
            )}
            {checking && <p className='text-center'>Checking ...</p>}
        </div>
    );
}
