import { IComboboxOption } from '@/components/Combobox';
import { RootState } from '@/context/store';

import {
    IGlassOptionsApiResults,
    IMakeApiResults,
    IModelApiResults,
} from '../types';

export const selectVehicleBuildYearApiResults = (
    state: RootState
): IComboboxOption[] | undefined =>
    state.displayReducer.glassGuideLookUpVehicleResults
        .vehicleBuildYearApiResults;
export const selectVehicleMakeApiResults = (
    state: RootState
): IMakeApiResults[] | undefined =>
    state.displayReducer.glassGuideLookUpVehicleResults.vehicleMakeApiResults;
export const selectVehicleMakeCodeForApiCall = (
    state: RootState
): string | undefined =>
    state.displayReducer.glassGuideLookUpVehicleResults
        .vehicleMakeCodeForApiCall;
export const selectVehicleModelApiResults = (
    state: RootState
): IModelApiResults[] | undefined =>
    state.displayReducer.glassGuideLookUpVehicleResults.vehicleModelApiResults;
export const selectVehicleModelCodeForApiCall = (
    state: RootState
): string | undefined =>
    state.displayReducer.glassGuideLookUpVehicleResults
        .vehicleModelCodeForApiCall;

export const selectVehicleVariantApiResults = (
    state: RootState
): IComboboxOption[] | undefined =>
    state.displayReducer.glassGuideLookUpVehicleResults
        .vehicleVariantApiResults;
export const selectGlassOptionsForVehicle = (
    state: RootState
): IGlassOptionsApiResults[] | undefined =>
    state.displayReducer.glassGuideLookUpVehicleResults.glassOptionsForVehicle;
export const selectRequireGlassGuide = (state: RootState): boolean =>
    state.displayReducer.requireGlassGuide;
