import { useState } from 'react';

import { Button } from '@/components/Button';
import { ModalHeader } from '@/components/ModalHeader';
import { selectIndustryType } from '@/context/borrower';
import { setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { BusinessIndustry } from '@/UserInput/BusinessIndustry';

export const testId = 'LenderSpecificQuestionTwo';

export const LenderSpecificQuestionTwo = (): JSX.Element => {
  const [showErrors, setShowErrors] = useState(false);
  // ***** Constants *****
  const baseId = 'modal-LenderSpecificQuestionTwo';

  // ***** Redux *****
  const dispatch = useAppDispatch();
  const primaryIndustry = useAppSelector(selectIndustryType);

  // ** helpers **
  const handleClick = (): void => {
    setShowErrors(true);
    if (!isComplete) {
      return;
    }
    dispatch(setModal(null));
  };

  const isComplete = (): boolean => {
    if (primaryIndustry === undefined) return false;
    if (primaryIndustry === null) return false;
    return true;
  };

  return (
    <div data-testid={testId} className='_flex flex-col justify-between  py-2'>
      <ModalHeader title='Industry Type' baseId={baseId} />
      <BusinessIndustry showErrors={showErrors} baseId={baseId} />
      <div className='py-2 mx-16'>
        <Button onClick={handleClick} id={`${baseId}-button-close`}>
          Save
        </Button>
      </div>
    </div>
  );
};
