export interface IPersonalLoan {
    lenderName?: string | undefined;
    monthlyRepayment?: number | undefined;
    loanBalance?: number | undefined;
}

export const initialPersonalLoanDetails: IPersonalLoanOptions = {
    personalLoans: [],
    personalLoanActiveIndex: 0,
};

export interface IPersonalLoanOptions {
    personalLoans: IPersonalLoan[];
    personalLoanActiveIndex: number;
}
