import { store } from '@/context/store';

import { BIRTHDATE_MAX_LIMIT, BIRTHDATE_MIN_LIMIT } from '../constants';
import { isDateValid } from '../generalDateHelper';
import { minimumDateHelper } from '../minimumDateHelper';
import { returnDate } from '../returnDate';

export function isBirthDateValid(): boolean {
  const state = store.getState();
  const birthDate = state.borrowerReducer.personalDetails.birthdate;
  if (
    isDateValid(
      returnDate(birthDate),
      minimumDateHelper(BIRTHDATE_MIN_LIMIT),
      BIRTHDATE_MAX_LIMIT
    )
  )
    return true;
  return false;
}
