import { DisplayComponent } from '@/components/DisplayComponent';
import { PartnerLogo } from '@/components/PartnerLogo';
import { IMAGE_URL } from '@/utils/constants';

import { TruckstopLandingPageButton } from './TruckstopLandingPageButton';

interface Props {
    src: string;
    alt: string;
    children: React.ReactNode;
    border?: boolean;
}

function Feature({ src, alt, children, border }: Props): JSX.Element {
    return (
        <div
            className={`flex-center flex-col w-full ${
                border && 'border-r'
            } py-4`}
        >
            <img
                src={`${IMAGE_URL}/${src}`}
                alt={alt}
                className='h-18 w-15 text-secondary'
            />
            <p>{children}</p>
        </div>
    );
}

function FeatureContainer({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element {
    return <div className='flex w-full justify-between'>{children}</div>;
}

export function TruckstopLandingPage(): JSX.Element {
    const baseId = 'truckstop-landing-page';

    return (
        <DisplayComponent baseId={baseId} data-testid={baseId}>
            <div className='flex-center w-full py-6'>
                <PartnerLogo />
            </div>
            <div className='text-primary text-lg w-full text-center overflow-x-hidden'>
                <FeatureContainer>
                    <Feature
                        src='lightningBolt.svg'
                        alt='lightning bolt'
                        border
                    >
                        Conditionally <br />
                        approved in <br />
                        {'<'} 2 mins
                    </Feature>
                    <Feature src='odometer.svg' alt='odometer'>
                        No impact <br />
                        on credit <br />
                        score
                    </Feature>
                </FeatureContainer>
                <FeatureContainer>
                    <Feature src='hearts.svg' alt='hearts' border>
                        No hidden <br />
                        fees
                    </Feature>
                    <Feature src='dollar.svg' alt='dollar'>
                        Free of <br />
                        charge
                    </Feature>
                </FeatureContainer>
                <FeatureContainer>
                    <Feature src='puzzle.svg' alt='puzzle' border>
                        Compare with <br />
                        {'>'} 40 lenders
                    </Feature>
                    <Feature src='binoculars.svg' alt='binoculars'>
                        Screen results <br />
                        by importance
                    </Feature>
                </FeatureContainer>
            </div>
            <TruckstopLandingPageButton />
        </DisplayComponent>
    );
}
