import { useState } from 'react';

import {
  ECreditRating,
  selectSelfAssessedCreditRating,
  setSelfAssessedCreditRating,
} from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export interface ICreditRating {
  baseId: string;
  showErrors: boolean;
}

export function CreditRatingForSolar({
  baseId,
  showErrors,
}: ICreditRating): JSX.Element {
  // *** Constants ***
  const infoTextPoorCreditRating =
    'I  have missed multiple repayments and have had defaults listed against my credit report.';
  const infoTextDecentCreditRating =
    'I  have occasionally missed a repayment but have not defaulted.';

  const infoTextGreatCreditRating =
    ' I have never missed a beat on any of my loans.';

  // *** Local State ***
  const [info, setInfo] = useState<string>(infoTextDecentCreditRating);

  // ***** Redux *****
  const dispatch = useAppDispatch();
  const rating = useAppSelector(selectSelfAssessedCreditRating);

  const intRating = getIntRating(rating);

  // ***** Helpers *****
  function getIntRating(rating: ECreditRating | undefined): number {
    if (rating === undefined) return 3; // default
    if (rating === ECreditRating.POOR) return 1;
    if (rating === ECreditRating.DECENT) return 2;
    if (rating === ECreditRating.GREAT) return 3;
    return 2;
  }
  // ***** Event Handlers *****

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    switch (event.target.value) {
      case '1':
        dispatch(setSelfAssessedCreditRating(ECreditRating.POOR));
        break;
      case '2':
        dispatch(setSelfAssessedCreditRating(ECreditRating.DECENT));
        break;
      case '3':
        dispatch(setSelfAssessedCreditRating(ECreditRating.GREAT));
    }
  };

  function getRatingInfo(): string {
    switch (rating) {
      case ECreditRating.POOR:
        return infoTextPoorCreditRating;
      case ECreditRating.DECENT:
        return infoTextDecentCreditRating;
      case ECreditRating.GREAT:
        return infoTextGreatCreditRating;
      default:
        return infoTextDecentCreditRating;
    }
  }

  // ***** Render *****

  return (
    <div data-testid='creditRating' className='text-center mt-5'>
      <p className='fieldHeading'>How is your credit score?</p>
      <label className='fieldHeading text-secondary my-2 text-lg'>
        {rating ?? ECreditRating.GREAT}
      </label>
      <p className='text-xs'>{getRatingInfo()}</p>
      <div className='h-2' />
      <input
        type='range'
        min='1'
        max='3'
        value={intRating}
        onChange={handleChange}
        style={{ width: '300px' }}
        className='hover:cursor-pointer'
      />
    </div>
  );
}
