import { DisplayButtons } from '@/components/DisplayButtons';
import {
  ELoanUsage,
  EVehicleAssetType,
  selectLoanUsage,
  selectVehicleAssetType,
} from '@/context/borrower';
import {
  EDisplays,
  selectHideVehicleScreen,
  selectIsAssetKnownViaParams,
  setDisplay,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export function SellerButtons(): JSX.Element {
  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const loanUsage = useAppSelector(selectLoanUsage);
  const vehicleAssetType = useAppSelector(selectVehicleAssetType);
  const hideVehicleScreen = useAppSelector(selectHideVehicleScreen);
  const isAssetKnownViaParams = useAppSelector(selectIsAssetKnownViaParams);

  // ***** Event Handlers *****
  function handleBackClick(): void {
    const backScreen = getBackScreen();
    dispatch(setDisplay(backScreen));
  }

  function handleNextClick(): void {
    const nextScreen = getNextScreen();
    dispatch(setDisplay(nextScreen));
  }

  function getNextScreen(): EDisplays {
    if (loanUsage === ELoanUsage.BUSINESS) return EDisplays.BUSINESS;
    return EDisplays.BASIC;
  }

  function getBackScreen(): EDisplays {
    if (isAssetKnownViaParams && hideVehicleScreen) {
      return EDisplays.LOAN;
    }
    if (
      loanUsage === ELoanUsage.BUSINESS &&
        vehicleAssetType !== EVehicleAssetType.COMMERCIAL_VEHICLES_LESS_THEN_4_5_TONNES
    ) {
      return EDisplays.MANUAL_VEHICLE;
    }
    return EDisplays.VEHICLE;
  }

  return (
    <DisplayButtons rightClick={handleNextClick} leftClick={handleBackClick} />
  );
}
