import { postUrl } from '@/apiCalls/fetch';
import { store } from '@/context/store';
import { TRUCKSTOP_PARTNER_NAME } from '@/partners/truckstop';
import {
    ELocalStorageVariables,
    removeLocalStorageItem,
    setLocalStorageItem,
} from '@/utils/localStorage';

import {
    EVerificationResponseStatus,
    IVerifyCodeReturn,
} from '../verificationCommonTypes';

import {
    IVerifySmsApiResponse,
    IVerifySmsCodeBody,
} from './verifySmsCodeTypes';

export async function verifySmsCode(
    mobilePhone: string,
    code: string
): Promise<IVerifyCodeReturn> {
    // ***** Redux State *****
    const state = store.getState();
    const borrower = state.borrowerReducer;
    const firstName = state.borrowerReducer.personalDetails.firstName ?? '';
    const termsAndConditionsLink = state.displayReducer.termsAndConditionsLink;
    const sellerName = state.borrowerReducer.seller.name;
    const sellerNameFromVehicle =
        store.getState().borrowerReducer.vehicle.source;

    // ***** Request *****
    const url = '/api/verify';

    let message =
        `Thankyou for agreeing to the terms and conditions found here \n\n` +
        termsAndConditionsLink;

    if (sellerName === TRUCKSTOP_PARTNER_NAME) {
        message =
            `Welcome from Odin Finance! You can find a copy of the terms and conditions here \n\n` +
            termsAndConditionsLink;
    }
    const seller2Name = store.getState().borrowerReducer.seller.name;
    let partner: string | undefined = undefined;
    if (seller2Name === 'Truckstop') {
        partner = 'ODIN_FINANCE';
    } else {
        partner = sellerNameFromVehicle ? sellerNameFromVehicle : sellerName;
    }

    const body: IVerifySmsCodeBody = {
        mobilePhone,
        code,
        borrower,
        firstName,
        termsAndConditionsMessage: message,
        partner,
        test: 'message', // todo: remove this
    };
    const response = await postUrl<IVerifySmsApiResponse>(url, body);

    // ***** Use Response *****
    if (
        response.token &&
        response.status === EVerificationResponseStatus.SUCCESSFUL
    ) {
        setLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN, response.token);
    } else {
        removeLocalStorageItem(ELocalStorageVariables.AUTH_TOKEN);
        removeLocalStorageItem(ELocalStorageVariables.FIRST_NAME);
    }

    // ***** Return *****
    return {
        status: response.status,
    };
}
