import { useEffect, useState } from 'react';

import { Header } from '@/components/Header';
import { RemoteImage } from '@/components/RemoteImage';
import { selectIsPreapproved } from '@/context/borrower';
import { EDisplays, setDisplay, setShowOcrDisplay } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

import './HoldingScreen.css';

export const testId = 'SolarHoldingScreen';

const delayExecution = (mls: number): Promise<unknown> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve('ok'), mls);
  });
};

export function SolarHoldingScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const isPreapproved = useAppSelector(selectIsPreapproved);

  useEffect(() => {
    return;
  }, [isPreapproved]);

  // ***** Local State *****
  const placeholderText = [
    'CHECKING INPUT...',
    'CALCUATING RATES...',
    'CONTACTING LENDER...',
    'AWAITING DECISION...',
    'DECISION RECEIVED!!',
    '...', // Last element is not shown
  ];
  const [state, setState] = useState('');

  const changePlaceholder = async (): Promise<void> => {
    for (let i = 0; i < placeholderText?.length; i++) {
      await delayExecution(2000);
      setState(placeholderText[i]);
    }
  };

  if (state === placeholderText[placeholderText?.length - 1]) {
    console.log('isPreapproved', isPreapproved);
    if (isPreapproved === undefined) {
      console.log('isPreapproved is undefined');
      setState(placeholderText[0]);
    } else if (isPreapproved) {
      console.log('isPreapproved is true');
      dispatch(setDisplay(EDisplays.SOLAR_INDICATIVELY_APPROVED));
      dispatch(setShowOcrDisplay(false));
    } else {
      console.log('isPreapproved is false');
      dispatch(setDisplay(EDisplays.INDICATIVELY_REFERRED));
      dispatch(setShowOcrDisplay(false));
    }
  }

  useEffect(() => {
    changePlaceholder();
  }, []);

  // ***** Redux State *****
  return (
    <div data-testid={testId}>
      <Header />
      <div className='h-screen flex-center relative flex-col'>
        <RemoteImage fileName='blackFingerprint.svg' />
        <div className='text-black'>{state}</div>
      </div>
    </div>
  );
}
