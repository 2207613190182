import { setInitialLoanBalloonPercentage } from './helpers/setInitialLoanBalloonPercentage';
import { setInitialLoanDepositAmount } from './helpers/setInitialLoanDepositAmount';
import { setInitialLoanTerm } from './helpers/setInitialLoanTerm';
import { setInitialLoanTradeInAmount } from './helpers/setInitialLoanTradeInAmount';
import { setInitialLoanType, setInitialLoanUsage } from './helpers';
import { ISetInitialLoan } from './ISetInitialLoan';

export function setInitialLoan({
    partnerLoanUsage,
    parameterLoanUsage,
    partnerLoanType,
    partnerLoanTerm,
    parameterLoanTerm,
    parameterDepositAmount,
    parameterTradeInAmount,
    parameterBalloonPercentage,
}: ISetInitialLoan): void {
    setInitialLoanUsage(partnerLoanUsage, parameterLoanUsage);
    setInitialLoanType(partnerLoanType);
    setInitialLoanTerm(partnerLoanTerm, parameterLoanTerm);
    setInitialLoanDepositAmount(parameterDepositAmount);
    setInitialLoanTradeInAmount(parameterTradeInAmount);
    setInitialLoanBalloonPercentage(parameterBalloonPercentage);
}
