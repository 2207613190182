import { EButtonType } from '@/components/Button';
import { DisplayButtons } from '@/components/DisplayButtons';
import {
    ELoanUsage,
    selectLoanUsage,
    selectVehicleDescription,
} from '@/context/borrower';
import {
    EDisplays,
    selectHideSellerScreen,
    selectPreviousDisplay,
    selectRequireGlassGuide,
    setDisplay,
} from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
// eslint-disable-next-line no-restricted-imports

interface IVehicleButtonsProps {
    setShowErrors: (value: boolean) => void;
    baseId: string;
}

export function VehicleButtons({
    setShowErrors,
    baseId,
}: IVehicleButtonsProps): JSX.Element {
    // ***** CONSTANTS *****
    const vehicleDescription = useAppSelector(selectVehicleDescription);

    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const hideSellerScreen = useAppSelector(selectHideSellerScreen);
    const loanUsage = useAppSelector(selectLoanUsage);
    const previousDisplay = useAppSelector(selectPreviousDisplay);
    const isGlassRequired = useAppSelector(selectRequireGlassGuide);

    // ***** Event Handlers *****
    async function handleNextClick(): Promise<void> {
        setShowErrors(true);
        if (!isComplete()) return;
        setShowErrors(false);
        const nextScreen = getNextScreen();
        dispatch(setDisplay(nextScreen));
    }

    function handleBackClick(): void {
        dispatch(setDisplay(EDisplays.LOAN));
    }

    // ***** Helpers ******
    function isComplete(): boolean {
        if (isGlassRequired && !vehicleDescription) return false;
        return true;
    }

    function getNextScreen(): EDisplays {
        if (previousDisplay === EDisplays.INDICATIVELY_APPROVED) {
            return EDisplays.PREPPROVAL_ANIMATION;
        }
        if (hideSellerScreen) {
            if (loanUsage === ELoanUsage.BUSINESS) {
                return EDisplays.BUSINESS;
            }
            return EDisplays.BASIC;
        }
        return EDisplays.SELLER;
    }

    return (
        <DisplayButtons
            idLeftButton={`${baseId}-button-back`}
            idRightButton={`${baseId}-button-next`}
            rightClick={handleNextClick}
            leftClick={handleBackClick}
            leftType={EButtonType.SECONDARY_OUTLINE}
        />
    );
}
