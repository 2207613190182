import { Button, EButtonType } from '@/components/Button';
import { ModalComponent } from '@/components/ModalComponent';
import { ModalHead } from '@/components/ModalHead';
import { ModalHeader } from '@/components/ModalHeader';
import { ModalSubHeader } from '@/components/ModalSubHeader';
import { EModals, setModal } from '@/context/display';
import { useAppDispatch } from '@/context/storeHooks';
import { LoginCode } from '@/UserInput/LoginCode';
import { MobileNumber } from '@/UserInput/MobileNumber';

export const testId = 'Login';

export function Login(): JSX.Element {
    // ***** Constants *****
    const baseId = 'modal-login';

    // ***** Redux State *****
    const dispatch = useAppDispatch();

    // ***** Handlers *****
    function handleSignUpClick(): void {
        dispatch(setModal(EModals.REGISTER));
    }

    // ***** Render *****
    return (
        <ModalComponent baseId={baseId} className='relative '>
            {/* Header Text */}
            <ModalHead>
                <ModalHeader title='Welcome Back!' baseId='Login' />
                <ModalSubHeader>
                    To log in, simply add your mobile number and <br />{' '}
                    we&apos;ll send you a one-time code.
                </ModalSubHeader>
            </ModalHead>
            {/* <MobileOrEmail baseId={baseId} showErrors={false} /> // Removed the option to choose */}
            <MobileNumber baseId={baseId} showErrors={false} />
            <LoginCode baseId={baseId} showErrors={false} />
            {/* Text */}

            <Button
                onClick={handleSignUpClick}
                id={`${baseId}-button-signUp`}
                title='Sign Up'
                type={EButtonType.SECONDARY_OUTLINE}
            />
        </ModalComponent>
    );
}
