import { setBalloon } from '@/context/borrower';
import { store } from '@/context/store';
import { IParameter } from '@/utils/setParametersFromParams';

export function setInitialLoanBalloonPercentage(
    parameterBalloonPercentage: IParameter
): void {
    const currentBalloonPercentage =
        store.getState().borrowerReducer.loan.balloon;

    const parsedParameter = Number(parameterBalloonPercentage);
    if (
        !isNaN(parsedParameter) &&
        parsedParameter > 0 &&
        parsedParameter <= 100
    ) {
        if (currentBalloonPercentage !== parsedParameter) {
            store.dispatch(setBalloon(parsedParameter));
            return;
        }
    }
}
