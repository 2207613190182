import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { LoginViaCode } from './pages/LoginViaCode';
import { Vroom } from './pages/Vroom';

function App(): JSX.Element {
    try {
        // Global const from outside of vroom on the host website

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-undef
        if (VroomCodeSnippetQueryStringParams) {
            return <Vroom />;
        }
    } catch {
        // do nothing
    }

    return (
        <div data-testid='home' className='w-full h-full flex-center'>
            <Router>
                <Routes>
                    {/* <Vroom /> Known Partner Journey */}
                    <Route path='/iframe/:id' element={<Vroom />} />
                    <Route path='/:id' element={<Vroom />} />
                    {/* Login via SMS Code */}
                    <Route path='/l/:code' element={<LoginViaCode />} />
                    {/* <Vroom /> Unknown Partner Journey */}
                    <Route path='*' element={<Vroom />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
