import { useState } from 'react';
import { useSelector } from 'react-redux';

// eslint-disable-next-line no-restricted-imports
import { getQuickQuoteSolar } from '@/apiCalls/matchingEngine/quickquoteSolar';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { MultipleUserInput } from '@/components/MultipleUserInput';
import { SolarNavigationButtons } from '@/components/SolarNavigationButtons';
import { SolarProgressBar } from '@/components/SolarProgressBar';
import {
  selectAddressByIndex,
  selectBusinessName,
  selectEntityType,
  selectPurchasePrice,
  selectTrusteeName,
  selectTrusteeType,
} from '@/context/borrower';
import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { RootState } from '@/context/store';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';
import { Abn } from '@/UserInput/Abn';
import { AbnActiveFrom } from '@/UserInput/AbnActiveFrom';
import { BusinessLookup } from '@/UserInput/BusinessLookup';
import { Deposit } from '@/UserInput/Deposit';
import { EntityType } from '@/UserInput/EntityType';
import { GstFrom } from '@/UserInput/GstFrom';
import { PropertyOwner } from '@/UserInput/PropertyOwner';
import { PurchasePrice } from '@/UserInput/PurchasePrice';
import { SolarProductDetails } from '@/UserInput/SolarProductDetails';
import { TrusteeName } from '@/UserInput/TrusteeName';
import { TrusteeType } from '@/UserInput/TrusteeType';

export const testId = 'SolarLoanDetails';

export function SolarLoanDetails(): JSX.Element {
  // ***** Local State *****
  const [showErrors, setShowErrors] = useState(false);

  // ***** CONSTANTS *****
  const baseId = 'screen-SolarLoanDetails';

  // ***** Redux State *****
  const dispatch = useAppDispatch();
  const purchasePrice = useAppSelector(selectPurchasePrice);
  const businessName = useAppSelector(selectBusinessName);
  const address = useSelector((state: RootState) =>
    selectAddressByIndex(state, 0)
  );
  const ownershipType = address?.ownershipType;
  const entityType = useAppSelector(selectEntityType);
  const trusteeName = useAppSelector(selectTrusteeName);
  const trusteeType = useAppSelector(selectTrusteeType);

  // ***** Event Handlers *****
  async function handleNextClick(): Promise<void> {
    setShowErrors(true);
    if (!isComplete()) return;
    getQuickQuoteSolar();
    dispatch(setDisplay(EDisplays.SOLAR_LENDERS_LIST));
  }

  function handleBackClick(): void {
    dispatch(setModal(EModals.WELCOME));
  }

  // ***** Helpers ******
  function isComplete(): boolean {
    if (!purchasePrice) return false;
    if (businessName === undefined) return false;
    if (ownershipType === undefined) return false;

    // todo: Make this enums
    if (entityType === 'TRUST') {
      if (trusteeName === undefined) return false;
      if (trusteeType === undefined) return false;
    }

    return true;
  }

  return (
    <>
      <div data-testid={testId} className='px-4'>
        <Header />
        <SolarProgressBar />

        <DisplayHeading
          title='Loan Details'
          infoText='We need some information about your company to provide you with a quote'
        />
        <p className='text-center m-3 text-secondary'>
          Lender rates and repayments are dependant on the details you provide.
        </p>
        <div className=''>
          <div className='_flex space-x-2 flex-center'>
            <SolarProductDetails showErrors={showErrors} baseId={baseId} />
          </div>
          <MultipleUserInput>
            <PurchasePrice showErrors={showErrors} baseId={baseId} />
            <Deposit showErrors={false} baseId={baseId} />
          </MultipleUserInput>
          <BusinessLookup baseId={baseId} showErrors={showErrors} />

          <div className='_flex space-x-2 flex-center'>
            <Abn baseId={baseId} showErrors={false} />
            <GstFrom baseId={baseId} showErrors={false} />
          </div>
          <div className='_flex space-x-2 flex-center'>
            <div style={{ flex: 1 }}>
              <AbnActiveFrom baseId={baseId} showErrors={false} />
            </div>

            <div style={{ flex: 1 }}>
              <EntityType baseId={baseId} showErrors={false} />
            </div>
          </div>
          {businessName?.toLowerCase().includes('trust') && (
            <div className='_flex space-x-2 flex-center'>
              <TrusteeName baseId={baseId} showErrors={showErrors} />
              <TrusteeType baseId={baseId} showErrors={showErrors} />
            </div>
          )}
          <PropertyOwner baseId={baseId} showErrors={showErrors} />
          <p className='text-center m-3 text-secondary'>
            Please note: This is for commercial loans only
          </p>
        </div>
      </div>
      <SolarNavigationButtons
        handleBackClick={handleBackClick}
        handleNextClick={handleNextClick}
        hasPrevious={false}
      />
    </>
  );
}
