export enum EBillingService {
    EQUIFAX_ACCESS_SEEKER = 'EQUIFAX_ACCESS_SEEKER',
    EQUIFAX_SCORE_SEEKER = 'EQUIFAX_SCORE_SEEKER',
    ILLION_ACCESS_SEEKER = 'ILLION_ACCESS_SEEKER',
    GLASS_GUIDE_VIN = 'GLASS_GUIDE_VIN',
    GLASS_GUIDE_PLATE = 'GLASS_GUIDE_PLATE',
    CREDITOR_WATCH_SCORE = 'CREDITOR_WATCH_SCORE',
    CREDITOR_WATCH_REPORT = 'CREDITOR_WATCH_REPORT', // non company
    SMS_VIA_MICROSERVICE = 'SMS_VIA_MICROSERVICE',
    SMS_VIA_ZAPIER = 'SMS_VIA_ZAPIER',
    ZIGNSEC_MICROSERVICE = 'ZIGNSEC_MICROSERVICE',
}
