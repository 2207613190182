export enum EState {
  ACT = 'ACT',
  NSW = 'NSW',
  NT = 'NT',
  QLD = 'QLD',
  SA = 'SA',
  TAS = 'TAS',
  VIC = 'VIC',
  WA = 'WA',
}

export enum ECreditRating {
  POOR = 'POOR',
  DECENT = 'DECENT',
  GREAT = 'GREAT',
}
