import { IMAGE_URL } from '@/utils/constants';

export function Footer(): JSX.Element {
    // ***** Render *****
    const url = window.location.href;
    const isDemo = url.includes('demo') && url.includes('youxpowered');
    const logoExtension = isDemo
        ? 'poweredbyyouxapply.png'
        : 'poweredByYoux.svg';
    const logoLink = 'https://www.youxpowered.com.au/customers/';
    const logoAlt = 'YouX';
    return (
        <footer className='w-full _flex justify-center items-center px-6 border-t border-[#F7F7F7]-4 py-1'>
            <a target='blank' href={logoLink}>
                <img
                    src={`${IMAGE_URL}/${logoExtension}`}
                    alt={logoAlt}
                    className='h-8'
                />
            </a>
        </footer>
    );
}
