import { useState } from 'react';

import { DisplayComponent } from '@/components/DisplayComponent';
import { DisplayHeading } from '@/components/DisplayHeading';
import { Header } from '@/components/Header';
import { ProgressBar } from '@/components/ProgressBar';
import { BuildYearManual } from '@/UserInput/BuildYearManual';
import { Kilometers } from '@/UserInput/Kilometers';
import { MakeManual } from '@/UserInput/MakeManual';
import { ModelManual } from '@/UserInput/ModelManual';
import { VariantManual } from '@/UserInput/VariantManual';
import { userFacingAssetTitle } from '@/utils/userFacingAssetTitle';

import { ManualVehicleButtons } from './ManualVehicleButtons';

export const testId = 'ManualVehicle';

export function ManualVehicle(): JSX.Element {
    // ***** CONSTANTS *****
    const baseId = 'screen-manualVehicle';
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const assetTitle = userFacingAssetTitle();

    return (
        <DisplayComponent baseId={baseId} data-testid={testId}>
            <Header />
            <ProgressBar />

            <DisplayHeading
                title={`Select ${assetTitle}`}
                infoText={`Please enter the ${assetTitle} details below to get an accurate quote`}
            />
            <Kilometers baseId={baseId} showErrors={false} />
            <BuildYearManual baseId={baseId} showErrors={showErrors} />
            <MakeManual baseId={baseId} showErrors={false} />
            <ModelManual baseId={baseId} showErrors={false} />
            <VariantManual baseId={baseId} showErrors={false} />
            <ManualVehicleButtons
                baseId={baseId}
                setShowErrors={setShowErrors}
            />
        </DisplayComponent>
    );
}
