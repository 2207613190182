import { Button } from '@/components/Button';
import { ModalComponent } from '@/components/ModalComponent';
import { RemoteImage } from '@/components/RemoteImage';
import { ELoanUsage, selectLoanUsage } from '@/context/borrower';
import { EDisplays, EModals, setDisplay, setModal } from '@/context/display';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export const testId = 'PostApprovedPermissionModal';
const baseId = 'modal-postApprovedPermission';

export function PostApprovedPermissionModal(): JSX.Element {
    // ***** Redux State *****
    const dispatch = useAppDispatch();
    const loanUsage = useAppSelector(selectLoanUsage);

    // ***** Handlers *****
    function handleClick(): void {
        dispatch(setModal(null));
        if (loanUsage === ELoanUsage.BUSINESS) {
            dispatch(setDisplay(EDisplays.ASSETS_AND_LIABILITIES));
        } else {
            dispatch(setDisplay(EDisplays.EMPLOYMENT));
            dispatch(setModal(EModals.WORK_HISTORY));
        }
    }

    function handleCloseClick(): void {
        dispatch(setModal(null));
    }

    // ***** Render *****
    return (
        <ModalComponent baseId={baseId} className='pb-4 px-8 relative'>
            <RemoteImage
                fileName='confetti.svg'
                className='w-full h-28 pl-8 mb-2'
            />
            <div
                onClick={handleCloseClick}
                className='h-6 w-6 cursor-pointer hover:scale-125 transform delay-75 ease-in-out absolute right-0 top-0'
                id={`${baseId}-button-xButton`}
            >
                {/* X circle icon svg because of unusualy behavior when calling the component itself */}
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='w-6 h-6'
                >
                    <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                    />
                </svg>
            </div>
            <h3 className='text-center font-bold text-2xl pb-4'>
                What&apos;s Next?
            </h3>
            <p className='pb-4 font-semi-bold text-sm text-center text-textBody my-2'>
                To grant formal approval, the lender will need additional
                information and documents to verify your financial situation.{' '}
                <br />
                <br /> The next few screens will request more detail about your
                employment, assets and liabilities. <br />
                <br />
                When all the mandatory supporting documentation has been
                uploaded, you&apos;ll be good to go.{' '}
            </p>
            <Button id={`${baseId}-button-proceed`} onClick={handleClick}>
                <p className='text-xs'>Proceed With Application</p>
            </Button>
        </ModalComponent>
    );
}
