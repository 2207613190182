import { DateInput } from '@/components/DateInput';
import { selectTestDriveDate, setTestDriveDate } from '@/context/borrower';
import { useAppDispatch, useAppSelector } from '@/context/storeHooks';

export function TestDriveDate(): JSX.Element {
    const date = useAppSelector(selectTestDriveDate);
    const dispatch = useAppDispatch();

    function handleChange(value: Date): void {
        dispatch(setTestDriveDate(value));
    }

    return (
        <DateInput
            title=''
            showErrors={false}
            onChange={handleChange} 
            placeholder='DD/MM/YYYY'
            value={date}
        />
    );
}

export default TestDriveDate;
