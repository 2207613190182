import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import { uniqueIdTransfer } from '@/apiCalls/uniqueIDTransferHelper';

import { EEventType } from '../eventType';

export interface IListboxOptions {
    options: IListboxOption[];
    selected: number;
    handleChange: (setSelected: string) => void;
    baseId?: string;
    valueOfElement?: string;
    eventType?: EEventType;
}

export interface IListboxOption {
    value: string;
    id: number;
}

export function Linkbox({
    options,
    selected,
    handleChange,
    baseId,
    valueOfElement,
    eventType = EEventType.DROPDOWN,
}: IListboxOptions): JSX.Element {
    function findActive(): IListboxOption | undefined {
        return options.find((option) => option.id === selected);
    }

    const active = findActive();

    if (!active) return <div />;

    return (
        <div className='w-full'>
            <Listbox
                value={selected}
                onChange={(value): void => {
                    console.log(valueOfElement, 'valueOfElement');
                    uniqueIdTransfer({
                        eventType: eventType,
                        applicationData: {
                            id: `${baseId}-dropdown-${valueOfElement}`,
                        },
                    });
                    return handleChange(value.toLocaleString());
                }}
            >
                <div className='relative mt-1 w-full'>
                    <Listbox.Button className='relative cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left borderOutline  w-full focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 text-xs sm:text-sm'>
                        <span className='block truncate'>{active.value}</span>
                        <span className='pointer-events-none absolute inset-y-0 right-0 _flex items-center pr-2'>
                            <ChevronUpDownIcon
                                className='h-5 w-5 text-gray-400'
                                aria-hidden='true'
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Listbox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 w-50 ring-1 ring-black ring-opacity-5 focus:outline-none text-xs z-20 '>
                            {options.map((option) => (
                                <Listbox.Option
                                    key={option.id}
                                    className={`relative cursor-default select-none py-2 pl-2 sm:pl-10 pr-4 ${
                                        active.id === option.id
                                            ? 'bg-amber-100 text-amber-900'
                                            : 'text-gray-900'
                                    }`}
                                    value={option.value}
                                    id={`${baseId}-dropdown-${valueOfElement}`}
                                >
                                    {({ selected }): JSX.Element => (
                                        <>
                                            <span
                                                className={`block truncate hover:cursor-pointer ${
                                                    selected
                                                        ? 'font-medium'
                                                        : 'font-normal'
                                                }`}
                                            >
                                                {option.value}
                                            </span>
                                            {selected ? (
                                                <span className='absolute inset-y-0 left-0 _flex items-center pl-3 text-amber-600'>
                                                    <CheckIcon
                                                        className='h-5 w-5'
                                                        aria-hidden='true'
                                                    />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    );
}
